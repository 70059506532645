const arraysEqual = (arr1, arr2) => {
    // If the lenght of the list changed clearly the two lists are not the same
    if (arr1.length !== arr2.length) return false

    // Checks element wise if something changed
    for (let i = 0; i < arr1.length; i++) if (arr1[i] !== arr2[i]) return false // Is it checking objects correctly?

    // If the lenght stayed the same and all the elements are equal, it returns true
    return true
}

const isArrayOfObjects = value =>
    Array.isArray(value) && value.every(item => typeof item === "object")

const isObjectOrArrayOfObjects = value => (
    typeof value === "object" &&
        value !== null &&
        (!Array.isArray(value) || isArrayOfObjects(value))
)

const arrayToObject = arr => arr.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})

const findEdits = (original, modified, path = "") => {
    // Starts by instanciatng an empty edits list
    let edits = []

    // Convert arrays of objects to objects if needed (so to treat them the same)
    const originalProcessed = Array.isArray(original) ? arrayToObject(original) : original
    const modifiedProcessed = Array.isArray(modified) ? arrayToObject(modified) : modified

    // This is the id array that would be produced if we just removed the removed elements
    // And appended at the bottom the newly added elements
    // It is used to check if we have changed the order or the array
    // Is is only useful if we are dealing with a list of objects
    let orderedOriginalIds = Array.isArray(original) ? original.map(item => item.id) : []

    // Gathers all fields in the modified and origianl object
    const allKeys = new Set([...Object.keys(originalProcessed), ...Object.keys(modifiedProcessed)])

    allKeys.forEach(key => {
        // For each key, gets the original and modified value together with the path
        const originalValue = originalProcessed[key]
        const modifiedValue = modifiedProcessed[key]
        const currentPath = path ? `${path}.${key}` : key

        // If the current key was both contained in the original and modified object
        if (key in originalProcessed && key in modifiedProcessed) {
            // If the value is an object, we recursively find edits inside of it
            if (isObjectOrArrayOfObjects(originalValue) && isObjectOrArrayOfObjects(modifiedValue)) {
                edits = edits.concat(findEdits(originalValue, modifiedValue, currentPath))
            } else if (Array.isArray(originalValue) && Array.isArray(modifiedValue)) {
                // Else if we are dealing with arrays of primitives, we check element-wise for updates
                if (!arraysEqual(originalValue, modifiedValue))
                    edits.push({ operation: "update", path: currentPath, value: modifiedValue }) 
            } else if (originalValue !== modifiedValue) {
                // If is a primitive and was modified, we update it we the new value
                edits.push({ operation: "update", path: currentPath, value: modifiedValue })
            }
        } else if (key in originalProcessed) {
            // If the key is only in the original, it means it was removed
            edits.push({ operation: "remove", path: currentPath })
            orderedOriginalIds = orderedOriginalIds.filter(id => id !== key)
        } else if (key in modifiedProcessed) {
            // If the key is only present in the modified version, it means it was added
            edits.push({ operation: "add", path, key, value: modifiedValue })
            orderedOriginalIds.push(key)
        }
    })

    // If we are working with an array of objects
    // Detect if, other than removing and appending items, we also changed their position
    if (isArrayOfObjects(original) && isArrayOfObjects(modified)) {
        // This represents the final modified ids
        const modifiedIds = modified.map(item => item.id)

        // If the plainly ordered ids are not in the same order as the modified ids, it means the ordering was changed
        if (!arraysEqual(orderedOriginalIds, modifiedIds))  edits.push({ operation: "move", path, value: modifiedIds }) 
    }

    return edits
}

export { findEdits }
