import { useMemo } from "react"
import Question from "@components/Coding/Question"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import AnalysisTabQuestionsColCrossFilters from "../AnalysisTabQuestionsColCrossFilters"
import { questionsFromSchema } from "../../utils/questions-from-schema"
import { ArrayElement } from "@utils/types/array-element"

type IQuestion = ArrayElement<ReturnType<typeof questionsFromSchema>>
type IAnalysis = ArrayElement<IQuestion["analysis"]>

interface IAnalysisTabQuestionsColProps {
    questions: IQuestion[]
    currentQuestion: IQuestion
    currentAnalysis: IAnalysis
    setCurrentQuestionAndAnalysis: (q: IQuestion, a: IAnalysis) => void
}

export default function AnalysisTabQuestionsCol({
    questions,
    currentQuestion,
    currentAnalysis,
    setCurrentQuestionAndAnalysis
}: Readonly<IAnalysisTabQuestionsColProps>) {
    // #region Contexts
    const { crossQuestionFilters } = useAnalysisTab()
    // #endregion

    // #region Memos
    const hasCrossQuestionFilters = useMemo(() => {
        const doNoFiltersExist = Object.keys(crossQuestionFilters).length === 0
        if (doNoFiltersExist) return false

        const areAllFiltersEmpty = Object.values(crossQuestionFilters).every(codeIds => codeIds.length === 0)
        return !areAllFiltersEmpty
    }, [crossQuestionFilters])
    // #endregion

    return (
        <div className="questions">
            <div className="header ms-6 mb-7 text-[#00000099] font-medium">Questions</div>
            {hasCrossQuestionFilters && <AnalysisTabQuestionsColCrossFilters />}
            <div className="scroll">
                {questions.map(q => (
                    <Question
                        key={q.id}
                        question={q}
                        onClick={setCurrentQuestionAndAnalysis}
                        currentQuestion={currentQuestion}
                        currentAnalysis={currentAnalysis}
                    />
                ))}
            </div>
        </div>
    )
}

