import { useRef, useState } from "react"
import useClickOutside from "../../hooks/useClickOutside"
import { languages } from "../../utils/Variables"

import { MdOutlineArrowDropDown } from "react-icons/md"
import "../../styling/SettingFields.scss"

interface IChooseLanguageSetting {
    options: string[]
    centralizeContents?: boolean
    centralizeLanguageBar?: boolean
    disabled?: boolean
    title?: string
    description?: string
    footer?: React.ReactNode
    /**
     * When the screen is short and the dropdown opens half hidden, use this property.
     */
    alignRight?: boolean
    /**
     * This option implies showing just the globe icon or the icon with the language.
     */
    isMobile?: boolean
    /**
     * It decides if the current selected option is hidden.
     */
    hideSelectedOption?: boolean
    /**
     * Defines if the inner `bar` is stretched over the whole container.
     */
    stretchDropdownBar?: boolean
}

interface IChooseLanguageProps {
    setting: IChooseLanguageSetting
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    hideFlag?: boolean
    width?: string
}

const ChooseLanguage = ({
    setting,
    value,
    setValue,
    hideFlag = false,
    width = "100%"
}: Readonly<IChooseLanguageProps>) => {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)

    // Use the hook to close the list when clickng outside
    useClickOutside(dropdownRef, () => setOpen(false))

    const selectOption = language => {
        setOpen(false)
        setValue(language)
    }

    if (!setting.options) return <></>

    return (
        <div
            className={[
                "language flex flex-col gap-3 justify-center",
                setting?.centralizeContents ? "items-center" : ""
            ].join(" ")}
            style={{ width }}
        >
            {setting?.title && <b>{setting.title}</b>}
            {setting?.description && <span className="text-[#565656] self-start">{setting?.description}</span>}
            <div className={["dropdown w-full", setting?.disabled ? "disabled" : ""].join(" ")} ref={dropdownRef}>
                <div
                    className={[
                        "bar bg-glaut-cards border-glaut-light-grey border-1 rounded-[0.25em]",
                        "min-h-[35px]",
                        setting?.centralizeLanguageBar ? "mx-auto" : ""
                    ].join(" ")}
                    onClick={setting?.disabled ? () => { } : () => setOpen(o => !o)}
                    style={{
                        cursor: setting.options.length && !setting?.disabled ? "pointer" : "not-allowed",
                        borderRadius: "4px",
                        width: setting?.stretchDropdownBar ? "100%" : "" // not possible with tailwindcss
                    }}
                >
                    {!hideFlag && <h3 className="text-[13.3px] flex-1 text-center">{languages[value]?.flag}</h3>}
                    {!setting?.hideSelectedOption && <b className="text-[13.3px]">{languages[value]?.name}</b>}
                    <MdOutlineArrowDropDown className="text-glaut-text-midnight" />
                </div>
                {open && (
                    <div
                        className={[
                            "w-max mx-auto",
                            setting?.centralizeContents ? "left-1/2 -translate-x-2/4" : "",
                            setting?.alignRight ? "right-0" : ""
                        ].join(" ")}
                    >
                        {setting.options.map(
                            l => (
                                <div
                                    key={l}
                                    onClick={() => selectOption(l)}
                                    className="bar"
                                    style={{ justifyContent: "flex-start", gap: "1em" }}
                                >
                                    {!hideFlag && <h3>{languages[l]?.flag}</h3>}
                                    <b>{languages[l]?.name}</b>
                                </div>
                            )
                        )}
                    </div>
                )}
            </div>
            {setting?.footer}
        </div>
    )
}

export default ChooseLanguage
