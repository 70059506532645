import QuestionCardLabel from "@assets/question-card-label.svg"
import CodeItem from "@components/CodeItem"
import { useCallback, useMemo, useState } from "react"
import { FaChevronDown, FaChevronRight } from "react-icons/fa"
import { IEntry } from "src/@types/entry"
import ConversationTranscriptBoxMessage, {
    IConversationTranscriptBoxMessageItemProps
} from "./components/ConversationTranscriptBoxMessage"


interface IConversationTranscriptBoxProps {
    username?: string
    lastUpdateDate: string
    messages: IEntry["messages"]
    codes?: {
        occurrences: number
        label: string
    }[]
    expandable?: boolean
    startExpanded?: boolean
    showFollowUps?: boolean
    /**
     * Implemented for retro-compatibility. The idea is to remake this component
     * for better practices.
     */
    customCodesElement?: JSX.Element
    /**
     * Necessary for playing the audio of messages. If not defined, the audio button will be always hidden.
     */
    projectId?: string
}

export default function ConversationTranscriptBox({
    username,
    messages,
    lastUpdateDate,
    expandable = false,
    codes,
    startExpanded = false,
    showFollowUps = true,
    customCodesElement,
    projectId
}: Readonly<IConversationTranscriptBoxProps>) {
    // #region States
    const [expanded, setExpanded] = useState(expandable ? (startExpanded ?? false) : true)
    // #endregion

    // #region Callbacks
    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded)
    }, [expanded])
    // #endregion

    // #region Memos
    const expandableIcon = useMemo(() => {
        if (!expandable) return undefined

        return expanded
            ? (<FaChevronDown className="h-3 w-3 cursor-pointer" onClick={toggleExpanded} />)
            : (<FaChevronRight className="h-3 w-3 cursor-pointer" onClick={toggleExpanded} />)
    }, [expandable, expanded, toggleExpanded])

    const displayedMessages: IConversationTranscriptBoxMessageItemProps[] = useMemo(() => {
        if (!showFollowUps)
            return messages.map(message => ({
                ...message,
                isFollowUp: false
            }))

        const displayedMessages: IConversationTranscriptBoxMessageItemProps[] = []
        const regexToExtractQuestionId = /<(.*)>$/
        let questionMessageIndex = -1
        let currentQuestionId = ""

        for (let messageIdx = 0; messageIdx < messages.length; messageIdx++) {
            const message = messages[messageIdx]
            if (message.role === "assistant") {
                const extractionResult = regexToExtractQuestionId.exec(message.content)
                if (extractionResult === null) continue

                const questionId = extractionResult[extractionResult.length - 1]
                if (questionId === undefined) continue

                if (currentQuestionId !== questionId) {
                    currentQuestionId = questionId
                    questionMessageIndex = messageIdx
                }
            }

            displayedMessages.push({
                ...message,
                isFollowUp: messageIdx > questionMessageIndex + 1
            })
        }

        return displayedMessages
    }, [messages, showFollowUps])
    // #endregion

    return (
        <div className={`flex flex-col gap-2 py-[10px] px-1 bg-glaut-off-white border-[1px] border-glaut-stroke-glaut
            rounded-md overflow-hidden max-h-full`}>
            <div className="flex flex-row px-3 justify-between">
                <div className="flex flex-row gap-[10px] items-center">
                    {expandable && expandableIcon}
                    <p className="text-sm underline">{username}</p>
                </div>
                <p className="text-xs font-medium">{lastUpdateDate}</p>
            </div>
            <div className={`
                flex flex-col px-3 overflow-auto transition-all gap-2
                ${!expanded ? "h-0 opacity-0" : "h-full opacity-100"}
            `}>
                {displayedMessages.map((message, idx, arr) => (
                    <ConversationTranscriptBoxMessage
                        key={`${message.role}-${message.date ?? idx}`}
                        message={message}
                        shouldRenderFollowUpHeader={idx > 0 && (!arr[idx - 1].isFollowUp && message.isFollowUp)}
                        projectId={projectId}
                    />
                ))}
            </div>
            {codes !== undefined && (
                <div className="flex flex-row flex-wrap px-3" style={{ gap: "4px" }}>
                    <img src={QuestionCardLabel} alt="Codes label" />
                    {codes.map((code, idx) => (
                        <CodeItem
                            key={`${code.label}-${code.occurrences}`}
                            content={code.label}
                            leadContent={String(code.occurrences)}
                            colorIndex={idx}
                        />
                    ))}
                </div>
            )}
            <div className="px-3 w-full">
                {customCodesElement}
            </div>
        </div>
    )
}