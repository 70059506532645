import { useMemo } from "react"
import { UAParser } from "ua-parser-js"

export function useBrowserUserAgent() {
    return useMemo(() => {
        const parser = new UAParser(window.navigator.userAgent)

        return {
            devices: {
                isMobile: parser.getDevice().type === "mobile"
            },
            browsers: {
                isSafari: parser.getBrowser().name === "Safari"
            },
            os: {
                isIos: parser.getOS().name === "iOS"
            }
        }
    }, [])
}