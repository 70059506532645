import { MdTranslate } from "react-icons/md"
import { useWebSocket } from "../../../hooks/useWebSocket"
import React, { useContext, useEffect, useMemo, useState } from "react"
import Loading from "../../../components/loading/Loading"

import { ProjectContext } from "../AutoSaveProject"

const TranslateInterview = ({ lang }) => {
    const [loadingTranslation, setLoadingTranslation] = useState(false)

    const { sendMessage, addCallback } = useWebSocket()

    const { project } = useContext(ProjectContext)

    const sameLang = useMemo(() => project.general_settings.language === lang, [project, lang])

    const translate = () => {
        setLoadingTranslation(true)
        sendMessage({
            operation: "translate",
            target_lang: lang
        })
    }

    // Register the websocket message handling function
    useEffect(() => {
        addCallback(data => {
            if (data.completed_translation) setLoadingTranslation(false)
        })
    }, [])

    return (
        <button
            style={{ height: "100%" }}
            onClick={translate}
            disabled={loadingTranslation || sameLang}
        >
            <div className="bar" style={{ padding: "0.2em 0" }}>
                <MdTranslate />
                {loadingTranslation ? <Loading /> : <span>Translate</span>}
            </div>
        </button>
    )
}

export default TranslateInterview
