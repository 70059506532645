import React, { useState } from "react"
import { LuFilter } from "react-icons/lu"
import { MdOutlineArrowRight, MdArrowDropDown } from "react-icons/md"

import LoadingBar from "../loading/LoadingBar"

const TagsList = ({
    showAll,
    parent,
    question,
    openTags,
    openTag,
    closeTag,
    setSelected,
    setShownQuestion,
    questionFilter,
    indent = 0
}) => parent.children
    .filter(b => b.occurrences)
    .map(
        (bucket, idx) =>
            (idx < 8 || showAll || indent > 0) && (
                <>
                    <tr style={{ cursor: "pointer" }} key={bucket.id}>
                        <td
                            className="bar"
                            style={{ justifyContent: "flex-start", width: "100%" }}
                        >
                            <div style={{ width: `${indent * 40}px` }}></div>
                            <LuFilter
                                className="clickable small"
                                style={
                                    questionFilter?.value === bucket.id
                                        ? { color: "var(--accent-color)" }
                                        : {}
                                }
                                onClick={() => setSelected(bucket.id)}
                            />
                            {bucket.children?.length > 0 &&
                                    (openTags.includes(bucket.id)
                                        ? (
                                            <MdArrowDropDown
                                                className="small clickable"
                                                onClick={() => closeTag(bucket.id)}
                                            />
                                        )
                                        : (
                                            <MdOutlineArrowRight
                                                className="small clickable"
                                                onClick={() => openTag(bucket.id)}
                                            />
                                        ))}

                            <span
                                className="clickable"
                                style={{ flex: 1 }}
                                onClick={() =>
                                    setShownQuestion({
                                        question,
                                        value: {
                                            ...bucket,
                                            parent
                                        }
                                    })
                                }
                            >
                                {bucket.label}
                            </span>
                        </td>
                        <td>
                            <div className="bar">
                                <span style={{ paddingRight: "2em" }}>
                                    {bucket.occurrences}
                                </span>
                                <LoadingBar
                                    percentage={Math.min(
                                        (100 * bucket.value) / parent.value,
                                        100
                                    )}
                                    showPercentage={true}
                                />
                            </div>
                        </td>
                    </tr>
                    {openTags.includes(bucket.id) && (
                        <TagsList
                            parent={bucket}
                            question={question}
                            showAll={showAll}
                            openTags={openTags}
                            openTag={openTag}
                            closeTag={closeTag}
                            setSelected={setSelected}
                            setShownQuestion={setShownQuestion}
                            questionFilter={questionFilter}
                            indent={indent + 1}
                        />
                    )}
                </>
            )
    )

const Tags = ({ tags, root, question, questionFilter, setSelected, setShownQuestion }) => {
    const [showAll, setShowAll] = useState(false)
    const [openTags, setOpenTags] = useState([])

    const openTag = tagId => setOpenTags(ots => [...ots, tagId])
    const closeTag = tagId => setOpenTags(ots => ots.filter(t => t !== tagId))

    return (
        <>
            <table className="simple-table">
                <tbody>
                    <TagsList
                        tags={tags}
                        parent={root}
                        question={question}
                        showAll={showAll}
                        openTags={openTags}
                        openTag={openTag}
                        closeTag={closeTag}
                        setSelected={setSelected}
                        setShownQuestion={setShownQuestion}
                        questionFilter={questionFilter}
                    />
                </tbody>
            </table>
            {tags?.filter(b => b.occurrences) > 8 && (
                <span className="clickable" onClick={() => setShowAll(s => !s)}>
                    <b> {showAll ? "Show less" : `Show all ${tags.length} elements`}</b>
                </span>
            )}
        </>
    )
}

export default Tags
