import React from "react"

const Toggle = ({ options, value, setValue }) => (
    <div className="bar bordered">
        {options.map(o => (
            <div
                key={o.id}
                onClick={() => setValue(o.id)}
                className={o.id === value ? "highlighted" : "clickable"}
            >
                <span>{o.label}</span>
            </div>
        ))}
    </div>
)

export default Toggle
