import React, { useState } from "react"
import { LuFilter } from "react-icons/lu"

import LoadingBar from "../loading/LoadingBar"

const Entities = ({ entities, root, question, setSelected, setShownQuestion }) => {
    const [showAll, setShowAll] = useState(false)
    return (
        <>
            <table className="simple-table">
                <tbody>
                    {entities?.map(
                        (entity, idx) =>
                            (idx < 12 || showAll) && (
                                <tr
                                    key={idx}
                                    onClick={() =>
                                        setShownQuestion({
                                            question,
                                            value: {
                                                ...entity,
                                                parent: root
                                            }
                                        })
                                    }
                                >
                                    <td className="bar" style={{ justifyContent: "flex-start" }}>
                                        <LuFilter
                                            className="clickable small"
                                            onClick={() => setSelected(entity.id)}
                                        />
                                        <span className="clickable">{entity.label}</span>
                                    </td>
                                    <td>
                                        <div className="bar">
                                            <span style={{ paddingRight: "2em" }}>
                                                {entity.occurrences}
                                            </span>
                                            <LoadingBar
                                                percentage={entity.value}
                                                showPercentage={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                    )}
                </tbody>
            </table>
            {entities.length > 12 && (
                <span className="clickable" onClick={() => setShowAll(s => !s)}>
                    <b> {showAll ? "Show less" : `Show all ${entities.length} elements`}</b>
                </span>
            )}
        </>
    )
}

export default Entities
