import CodeItem from "@components/CodeItem"
import ThemeItem from "@components/ThemeItem"
import { useMemo } from "react"
import { useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { MdClose } from "react-icons/md"

export interface ICodeData {
    id: string
    label: string
    leadContent: string
    colorIndex: number
    type: "theme" | "code"
    parentThemeLabel?: string
}

interface IAnalysisTabQuestionsColCrossFiltersQuestionCodeProps {
    code: ICodeData
    questionId: string
}

export default function AnalysisTabQuestionsColCrossFiltersQuestionCode({
    code,
    questionId
}: Readonly<IAnalysisTabQuestionsColCrossFiltersQuestionCodeProps>) {
    // #region Contexts
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Memos
    const removeIconElement = useMemo(() => (
        <div
            className="flex-1"
            onClick={() => {
                analysisTabDispatch({
                    type: "remove-question-cross-filter",
                    codeId: code.id,
                    questionId
                })
            }}>
            <MdClose className={code.type === "code" ? "text-white" : "text-glaut-dark-grey"} />
        </div>
    ), [analysisTabDispatch, questionId, code.id, code.type])
    // #endregion

    return (
        code.type === "code" ? (
            <CodeItem
                content={code.label}
                leadContent={code.leadContent}
                colorIndex={code.colorIndex}
                tooltipMode="parent"
                tooltipPlacement="right"
                parentCodeLabel={code.parentThemeLabel}
                elementOnEnd={removeIconElement}
            />
        ) : (
            <ThemeItem
                label={code.label}
                elementOnEnd={removeIconElement}
                colorIndex={code.colorIndex}
            />
        )
    )
}