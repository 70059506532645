import React from "react"
import { LuFilter } from "react-icons/lu"

import LoadingBar from "../loading/LoadingBar"

const Options = ({ options, lang, setSelected }) =>
    <table className="simple-table">
        <tbody>
            {options?.map(
                (option, idx) =>
                    <tr key={idx}>
                        <td className="bar" style={{ justifyContent: "flex-start" }}>
                            <LuFilter
                                className="clickable small"
                                onClick={() => setSelected(option.id)}
                            />
                            <span>{option.label?.[lang]}</span>
                        </td>
                        <td>
                            <div className="bar">
                                <span style={{ paddingRight: "2em" }}>
                                    {option.occurrences}
                                </span>
                                <LoadingBar
                                    percentage={option.value}
                                    showPercentage={true}
                                />
                            </div>
                        </td>
                    </tr>
            )}
        </tbody>
    </table>


export default Options