import React from "react"
import { BiLockAlt } from "react-icons/bi"
import { BsLink45Deg } from "react-icons/bs"
import { FiCopy } from "react-icons/fi"

const LinkCallout = ({ link, disabled }) => (
    <div
        className={["callout", disabled ? "disabled" : ""].join(" ")}
        style={{width: "100%"}}>
        <div className="bar">
            <BsLink45Deg />
            {
                disabled ? 
                    <span>{link}</span> : 
                    <a target="_blank" href={link} rel="noreferrer">
                        {link}
                    </a>
            }
        </div>
        <FiCopy className="clickable" onClick={() => !disabled && navigator.clipboard.writeText(link)} />
    </div>
)

const PasswordCallout = ({ password }) => (
    <div className="callout">
        <div className="bar">
            <BiLockAlt />
            <span>{password}</span>
        </div>
        <FiCopy onClick={() => navigator.clipboard.writeText(password)} />
    </div>
)

export { LinkCallout, PasswordCallout }
