import { useState } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri"

import EditQuestionPopup from "@components/Popups/EditQuestionPopup"
import { Drag, Drop } from "@components/menus/DragAndDrop"
import Setting from "@components/menus/Setting"
import { blankQuestion, languages } from "@utils/Variables"
import Question from "../../../Outline/Question"
import { FaExclamationCircle } from "react-icons/fa"
import ProjectStatus from "@components/Project/ProjectStatus"

const projectLanguageSettings = [
    {
        slug: "language",
        type: "language",
        options: Object.keys(languages),
        default: "en",
        description: "Select the language the project is written and analyzed in:",
        centralizeContents: true,
        centralizeLanguageBar: true,
        footer: <div className="flex flex-row bg-glaut-cards rounded-[4px] p-3 items-center gap-3">
            <FaExclamationCircle className="text-[#757575] w-[16px] h-[16px]" />
            <div className="flex flex-col flex-1">
                <p className="text-[11.1px]">
                    This is the language you wish to use to set up the project
                    (i.e. writing the context and questions), analyse the data
                    (i.e. themes and codes) and generate the report.
                </p>
                <p className="text-[11.1px]">
                    Keep in mind that you can still translate interviews, by
                    selecting the language you need at the top right corner of
                    this page.
                </p>
            </div>
        </div>
    }
]

const overviewSettings = [
    {
        slug: "context",
        placeholder:
            "You are interviewing accountants for the launch of a new spreadsheets software",
        description: "Explain to Glaut AI-interviewer how they should behave",
        type: "long-text"
    },
    {
        label: "Voice enabled",
        slug: "voice_enabled",
        placeholder: "If enabled, Glaut AI-interviewer will speak to the user",
        type: "bool"
    }
]

const welcomeSettings = [
    {
        slug: "intro_message",
        description: "Intro message",
        placeholder: "What you want the interviewee to see before starting",
        type: "long-text",
        multiLanguage: true,
        default: {},
        labelStyle: {
            gap: "5px"
        }
    },
    {
        label: "Collect email",
        slug: "collect_email",
        placeholder: "Require an email adress to start the conversation",
        type: "bool",
        default: true
    },
    {
        label: "Privacy notice for respondents",
        slug: "privacy_notice",
        type: "bool",
        default: true
    }
]

const brandSettings = [
    {
        slug: "accent_color",
        type: "color",
        default: "black"
    },
    {
        slug: "logo",
        type: "img",
        placeholder: "A link to your logo"
    },
    {
        slug: "redirect_link",
        type: "text",
        placeholder: "This link will be opened as soon as the interview is completed"
    }
]

const Section = ({ title, currentSection, setSection, children, isFlat = false }) => (
    <div
        style={{
            padding: "0.75em",
            paddingRight: isFlat ? "0" : "none",
            width: "100%"
        }}
    >
        {title === currentSection
            ? (
                <>
                    <div
                        className="bar"
                        style={{
                            justifyContent: "space-between",
                            marginBottom: "1em",
                            width: "100%",
                            boxSizing: "content-box"
                        }}
                    >
                        <h3>{title}</h3>
                        <RiArrowDropUpLine />
                    </div>

                    <div className="fade-in">{children}</div>
                </>
            )
            : !isFlat ? (
                <div
                    className="bar cursor-pointer"
                    style={{
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                    onClick={() => setSection(title)}
                >
                    <h3 style={{ cursor: "pointer" }}>{title}</h3>
                    <RiArrowDropDownLine />
                </div>
            ) : (
                <div
                    className="bar"
                    style={{
                        justifyContent: "space-between",
                        width: "100%",
                        boxSizing: "content-box"
                    }}
                >
                    <h3 style={{ cursor: "pointer" }}>{title}</h3>
                    {children}
                </div>
            )}
    </div>
)


const Interview = ({ project, setProject, lang, isEditable, setLang }) => {
    // #region States
    const [section, setSection] = useState("Overview")
    const [newQuestion, setNewQuestion] = useState(false)
    // #endregion

    // #region Callbacks
    const setQuestion = (questionId, value) => {
        setProject(p => ({
            ...p,
            schema: p.schema
                .map(q => {
                    if (q.id === questionId) return value
                    return q
                })
                .filter(s => s)
        }))
    }

    const onDragEnd = result => {
        // Destructure properties from the result
        const { destination, source } = result

        // If there's no destination (e.g. drag is canceled or item is dragged outside droppable area)
        if (!destination)
            return


        // If the source and the destination is the same, no need to do anything
        if (destination.index === source.index)
            return


        // Create a new schema array by reordering the questions
        const newSchema = Array.from(project.schema)
        const [movedQuestion] = newSchema.splice(source.index, 1)
        newSchema.splice(destination.index, 0, movedQuestion)

        setProject(p => ({ ...p, schema: newSchema }))
    }
    // #endregion

    return (
        <div className="column overflow-x-hidden h-full" style={{ alignItems: "center", gap: 0 }}>
            <Section title={"Project status"} currentSection={section} setSection={setSection} isFlat={true}>
                <ProjectStatus project={project} editable={true} />
            </Section>
            <Section title={"Project language"} currentSection={section} setSection={setSection}>
                <div className="column items-center">
                    {projectLanguageSettings.map(s => (
                        <Setting
                            key={`outline--project-language-settings-${s.slug}`}
                            setting={{
                                ...s,
                                disabled: !isEditable
                            }}
                            value={project.general_settings?.[s.slug]}
                            setValue={v => {
                                setLang(v)
                                setProject(p => ({
                                    ...p,
                                    general_settings: { ...p.general_settings, [s.slug]: v }
                                }))
                            }}
                        />
                    ))}
                </div>
            </Section>
            <Section title={"Overview"} currentSection={section} setSection={setSection}>
                <div className="column" style={{ alignItems: "flex-start" }}>
                    {overviewSettings.map((s, idx) => (
                        <Setting
                            key={idx}
                            setting={{ ...s, disabled: !isEditable }}
                            value={project?.interview_settings?.[s.slug] ?? ""}
                            setValue={v =>
                                setProject(p => ({
                                    ...p,
                                    interview_settings: { ...p.interview_settings, [s.slug]: v }
                                }))
                            }
                        />
                    ))}
                </div>
            </Section>
            <Section title={"Questions"} currentSection={section} setSection={setSection}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Drop id="root" type="question">
                        {project?.schema?.map((q, idx) => (
                            isEditable ?
                                <Drag id={q.id} index={idx} key={q.id}>
                                    <Question
                                        key={idx}
                                        index={idx + 1}
                                        question={q}
                                        setQuestion={value => setQuestion(q.id, value)}
                                        schema={project.schema}
                                        lang={lang}
                                        disabled={!isEditable}
                                    />
                                </Drag> :
                                <Question
                                    key={idx}
                                    index={idx + 1}
                                    question={q}
                                    setQuestion={value => setQuestion(q.id, value)}
                                    schema={project.schema}
                                    lang={lang}
                                    disabled={!isEditable}
                                />
                        ))}
                    </Drop>
                </DragDropContext>

                <div
                    onClick={() => isEditable && setNewQuestion(true)}
                    className={["clickable-text", "centered", isEditable ? "" : "disabled"].join(" ")} >
                    + Add Question
                </div>
                {newQuestion && (
                    <EditQuestionPopup
                        originalQuestion={blankQuestion()}
                        close={() => setNewQuestion(false)}
                        setOriginalQuestion={q =>
                            setProject(p => ({ ...p, schema: [...p.schema, q] }))
                        }
                        isNew={true}
                        lang={lang}
                    />
                )}
            </Section>
            <Section title={"Welcome page"} currentSection={section} setSection={setSection}>
                {welcomeSettings.map((s, idx) => (
                    <Setting
                        key={idx}
                        setting={{ ...s, disabled: !isEditable }}
                        lang={lang}
                        value={project?.interview_settings?.[s.slug]}
                        setValue={v =>
                            setProject(p => ({
                                ...p,
                                interview_settings: { ...p.interview_settings, [s.slug]: v }
                            }))
                        }
                    />
                ))}
                {project?.interview_settings?.privacy_notice === true && (
                    <>
                        <Setting
                            setting={{
                                slug: "privacy_policy_link",
                                type: "text",
                                placeholder: "https://www.mysite.com/privacy_notice",
                                description: "Privacy policy link",
                                shouldIncludeTooltip: true,
                                tooltipContent: "If no link is shared, Glaut will display its privacy notice for"
                                    + " respondents",
                                disabled: !isEditable
                            }}
                            value={project?.interview_settings?.privacy_policy_link}
                            setValue={v =>
                                setProject(p => ({
                                    ...p,
                                    interview_settings: {
                                        ...p.interview_settings,
                                        privacy_policy_link: v === "" ? null : v
                                    }
                                }))
                            }
                        />
                        <Setting
                            setting={{
                                slug: "privacy_notice_explicit_consent",
                                label: "Request explicit consent from respondents",
                                type: "bool",
                                default: true,
                                disabled: !isEditable
                            }}
                            lang={lang}
                            value={project?.interview_settings?.privacy_notice_explicit_consent}
                            setValue={v =>
                                setProject(p => ({
                                    ...p,
                                    interview_settings: { ...p.interview_settings, privacy_notice_explicit_consent: v }
                                }))
                            }
                        />
                    </>
                )}
            </Section>

            <Section title={"Branding"} currentSection={section} setSection={setSection}>
                <div className="column" style={{ alignItems: "flex-start" }}>
                    {brandSettings?.map((s, idx) => (
                        <Setting
                            key={idx}
                            setting={{ ...s, disabled: !isEditable }}
                            value={project.brand_settings?.[s.slug]}
                            setValue={v =>
                                setProject(p => ({
                                    ...p,
                                    brand_settings: { ...p.brand_settings, [s.slug]: v }
                                }))
                            }
                        />
                    ))}
                </div>
            </Section>
        </div>
    )
}

export default Interview
