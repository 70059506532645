import React, { useContext, useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import { useAuthInfo } from "@propelauth/react"

import AnalysisBox from "../../components/AnalysisBox"
import QuestionResults from "../../components/Popups/QuestionResults"
import { ProjectContext } from "./AutoSaveProject"
import { explainFilter } from "../../utils/Filters"

const CompletionStats = ({ analysis }) => (
    <div className="bar cell-box centered">
        <div className="labeled-number">
            <span>{analysis.n_started}</span>
            <span>Started</span>
        </div>
        <div className="labeled-number">
            <span>{analysis.n_completed}</span>
            <span>Completed</span>
        </div>
        <div className="labeled-number">
            <span>{((100 * analysis.n_completed) / analysis.n_started).toFixed(0)}%</span>
            <span>Completion rate</span>
        </div>
    </div>
)

export const SelectedStats = ({ schema, selected, filters, setFilters, projectLang }) => (
    <div className="bar cell-box centered" style={{ width: "100%" }}>
        <div className="labeled-number">
            <span>{selected}</span>
            <span>Responses</span>
        </div>
        <div className="column">
            {filters.length === 0 && <span>No filters</span>}
            {filters.map(filter => (
                <div className="bar bordered" key={filter.id} style={{ padding: "0 0 0 1em" }}>
                    <span>{explainFilter(filter, schema, projectLang)}</span>

                    <span
                        className="clickable danger"
                        onClick={() => setFilters(fs => fs.filter(f => f.id !== filter.id))}
                    >
                        ✕
                    </span>
                </div>
            ))}
        </div>
    </div>
)

const Analysis = () => {
    const [analysis, setAnalysis] = useState(null)

    const [shownQuestion, setShownQuestion] = useState(null)
    const [filters, setFilters] = useState([])

    const { projectId } = useParams()
    const axiosAuth = useAxiosAuth()
    const { isLoggedIn } = useAuthInfo()

    const { project } = useContext(ProjectContext)
    const projectLang = useMemo(() => project.general_settings.language, [project])

    useEffect(() => {
        const serializedFilters = encodeURIComponent(JSON.stringify(filters))
        axiosAuth
            .get(`datasets/${projectId}/analysis?filters=${serializedFilters}`)
            .then(res => setAnalysis(res.data))
            .catch(e => console.log(e))
    }, [isLoggedIn, filters])

    if (analysis)
        return (
            <div className="h-full overflow-auto pt-4">
                {project?.source !== "CSV" && !filters.length && (
                    <CompletionStats analysis={analysis} />
                )}
                {filters.length > 0 && (
                    <SelectedStats
                        selected={analysis.n_completed}
                        filters={filters}
                        setFilters={setFilters}
                        schema={project.schema}
                        projectLang={projectLang}
                    />
                )}
                <div className="grid">
                    {analysis &&
                        project?.schema.map((s, idx) => (
                            <AnalysisBox
                                key={idx}
                                projectId={projectId}
                                value={analysis.analysis[s.id] || []}
                                question={s}
                                setShownQuestion={setShownQuestion}
                                filters={filters}
                                setFilters={setFilters}
                                lang={projectLang}
                            />
                        ))}
                </div>

                {shownQuestion && (
                    <QuestionResults
                        projectId={projectId}
                        shownQuestion={shownQuestion}
                        setShownQuestion={setShownQuestion}
                        setAnalysis={setAnalysis}
                        filters={filters}
                    />
                )}
            </div>
        )

}

export default Analysis
