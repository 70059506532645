import { useMemo } from "react"
import { v4 as uuid } from "uuid"
import { questionTypes } from "../utils/Variables"
import { getAllDescendantsIds } from "../utils/nesting"


export const useEditQuestion = (question, lang) => {

    const valid = useMemo(() => {

        if (!question) return false

        // If we haven't defined the header and question, it's not valid
        if (question.value?.[lang]?.length < 4) return false

        // If the question is a multiple choice with empty option, the question is not valid
        if (
            [questionTypes.multipleChoice, questionTypes.select].includes(question.type) &&
            question.options?.filter(o => o.label?.[lang]?.length < 1).length
        )
            return false

        // If the question is of type insight, it should have defined the fixed_tags property
        if (question.type === questionTypes.insight)
            switch (question.fixed_tags) {
                case undefined:
                    return false
                case true:
                    if (!question.buckets || question.buckets.filter(b => b.name).length < 4) return false
                    break
            }

        // Else it's valid
        return true
    }, [question, lang])


    return { valid }
}


export const useEditQuestionOptions = (setQuestion, lang, key = "options") => {

    const generateBaseOption = () => {
        if (key === "options") return { label: { [lang]: "" }, id: uuid() }
        else if (key === "buckets") return { name: "", id: uuid() }
    }

    const addOption = ({ parent } = {}) =>
        setQuestion(p => ({
            ...p,
            [key]: [...(p[key] || []), { ...generateBaseOption(), parent }]
        }))

    const editOption = (value, oId) =>
        setQuestion(p => ({
            ...p,
            [key]: p[key].map(o => {
                if (o.id === oId) {
                    if (key === "options") return { ...o, label: { ...o.label, [lang]: value } }
                    if (key === "buckets") return { ...o, name: value }
                }
                return o
            })
        }))

    const deleteOption = optionId =>
        setQuestion(q => {
            const idsToRemove = [...getAllDescendantsIds(q[key], optionId), optionId]
            return { ...q, [key]: q[key].filter(o => !idsToRemove.includes(o.id)) }
        })

    return { addOption, editOption, deleteOption }
}
