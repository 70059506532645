import React from "react"

import "../../../styling/SettingFields.scss"

const ColorSetting = ({ setting, value, setValue }) => (
    <label
        className={["bordered", "bar", "setting", "image-setting", setting.disabled ? "disabled" : ""].join(" ")}
        style={{
            cursor: "pointer",
            width: "fit-content",
            padding: "0 2em",
            alignSelf: "flex-start"
        }}
    >
        <b>{setting.slug.replaceAll("_", " ")}:</b>
        <input
            type="color"
            className="color-input"
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={setting.disabled}
        />
        <input
            type="text"
            className="borderless-input"
            style={{ width: "6em" }}
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={setting.disabled}
        />
    </label>
)

export default ColorSetting
