import { questionTypes } from "../utils/Variables"

export const sanitizeSelect = (q, lang) => ({
    ...q,
    options: q.options.filter(o => o.label[lang])
})

export const sanitizeInsight = q => ({
    ...q,
    buckets: q.buckets.filter(b => b.name)
})

export const sanitizeQuestion = (q, lang) => {
    switch (q.type) {
        case questionTypes.select:
            return sanitizeSelect(q, lang)
        case questionTypes.insight:
            return sanitizeInsight(q)
        default:
            return q
    }
}