import PropTypes from "prop-types"
import convertYoutubeUrlToEmbed from "../../utils/convert-youtube-url-to-embed"
import { useCallback, useEffect, useMemo, useState } from "react"
import "./index.scss"
import { MdOutlinePlayCircleOutline } from "react-icons/md"

/**
 * @param {Object} props
 * @param {string | undefined} props.id
 * @param {string} props.src
 * @param {React.Ref<HTMLIFrameElement> | undefined} props.customRef
 * @param {((event, player) => void) | undefined} props.onVideoEnded
 * @param {((event, player) => void) | undefined} props.onVideoLoaded
 */
const YoutubeEmbedBox = ({ id, src, customRef, onVideoEnded, onVideoLoaded }) => {
    // #region States
    const [showPlayButton, setShowPlayButton] = useState(true)
    // #endregion

    // #region Memos
    const actualSrc = useMemo(() => convertYoutubeUrlToEmbed(src), [src])
    // #endregion

    // #region Callbacks
    const handlePlayVideo = useCallback(() => {
        if (!showPlayButton) return

        setShowPlayButton(false)
        if (!customRef?.current) return

        customRef.current.contentWindow.postMessage(
            JSON.stringify({
                event: "command",
                func: "playVideo"
            }),
            "*"
        )
    }, [customRef, showPlayButton])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!customRef.current) return

        // Load YouTube IFrame API script
        const tag = document.createElement("script")
        tag.src = "https://www.youtube.com/iframe_api"
        const firstScriptTag = document.getElementsByTagName("script")[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

        let player

        window.onYouTubeIframeAPIReady = () => {
            player = new window.YT.Player(customRef.current, {
                events: {
                    onStateChange: handlePlayerStateChange,
                    onReady: handleOnReady
                }
            })
        }

        const handlePlayerStateChange = event => {
            if (event.data === window.YT.PlayerState.ENDED)
                onVideoEnded?.(event, player)
        }

        const handleOnReady = event => {
            onVideoLoaded?.(event, player)
        }
    }, [customRef, onVideoEnded, onVideoLoaded])
    // #endregion

    if (actualSrc === null) return <></>

    return (
        <div className="youtube-embed-box">
            {showPlayButton && (
                <div id="div--play-button">
                    <div id="div--play-button-wrapper" onClick={handlePlayVideo}>
                        <MdOutlinePlayCircleOutline />
                    </div>
                </div>
            )}
            <iframe
                ref={customRef}
                id={id}
                title="Embedded URL"
                src={actualSrc}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    )
}

YoutubeEmbedBox.propTypes = {
    id: PropTypes.string,
    src: PropTypes.string.isRequired
}

export default YoutubeEmbedBox