import { useMemo } from "react"
import { IQuestion } from "src/@types/question"

interface IAnalysisTabQuestionsColQuestionTitleProps {
    questionType: IQuestion["type"]
    questionLabel: string
    questionLabelClassName?: string
}

export default function AnalysisTabQuestionsColQuestionTitle({
    questionLabel,
    questionType,
    questionLabelClassName
}: Readonly<IAnalysisTabQuestionsColQuestionTitleProps>) {
    // #region Memos
    const questionTypeLabel = useMemo(() => {
        switch (questionType) {
            case "short_text":
                return "Open Text 🏷️"
            case "insight":
                return "Open Text Coding 🏷️"
            case "entity":
                return "Entities 📺"
            case "multiple_choice":
            case "select":
                return "Multiple Choice 🗳️"
            case "nps":
            case "number":
                return "NPS ⭐"
        }
    }, [questionType])
    // #endregion

    return (
        <div className="project-area-question-title">
            <span className={`project-area-question-title-label 
                ${questionLabelClassName ?? "text-[11.11px] font-medium"}
            `}>
                {`${questionLabel} - `}
            </span>
            <span className="project-area-question-type-label text-[11.11px] text-glaut-stroke-button">
                {questionTypeLabel}
            </span>
        </div>
    )
}