import React, { useEffect, useState } from "react"
import BooleanSetting from "./BooleanSetting"

const IntSetting = ({ setting, value, setValue }) => {
    // This boolean stores weather or not the setting is active
    const [active, setActive] = useState(Boolean(value || setting.active))

    // If we disabled the setting, sets the value to null
    useEffect(() => {
        if (!active) setValue(null)
        else if (!value) setValue(setting.default)
    }, [active])

    return (
        <label>
            <b>{setting.title}</b>
            <span>{setting.description}</span>
            <div className="bar">
                {setting.active !== undefined && (
                    <BooleanSetting value={active} setValue={setActive} setting={{ disabled: setting.disabled }} />
                )}
                {active ? (
                    <input
                        type="number"
                        name="tentacles"
                        min={setting.min || 0}
                        max={setting.max || 999999}
                        step={setting.step || 1}
                        value={value}
                        onChange={e => setValue(parseInt(e.target.value))}
                        style={{ width: "300px" }}
                        disabled={setting.disabled ?? false}
                    />
                ) : (
                    <span>Toggle to enable limit</span>
                )}
            </div>
        </label>
    )
}

export default IntSetting
