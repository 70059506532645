import posthog from "posthog-js"
import * as Sentry from "@sentry/react"

export const updateUserInfo = user => {
    if (user) {
        posthog.identify(
            user.id,
            user
        )
        Sentry.setUser(user)
    }
}

export const trackEvent = (eventName, properties, error) => {
    posthog.capture(eventName, properties)
    if (error) Sentry.captureMessage(error)
    
    console.log(`Tracked event: ${eventName}`, properties, error)
}
