import { colors, questionTypes } from "./Variables"

export const stringToColor = str => {
    if (!str) return

    let hash = 0
    for (let i = 0; i < str.length; i++)
        hash = (hash + str.charCodeAt(i)) % colors.length

    return colors[hash]
}

// Helper function to download data to a file
export const download = (content, fileName, contentType) => {
    const a = document.createElement("a")
    const file = new Blob([content], { type: contentType })
    a.href = URL.createObjectURL(file)
    a.download = fileName
    a.click()
}

export const getOptions = question => {
    switch (question.type) {
        case questionTypes.multipleChoice:
            return question.options || []

        case questionTypes.select:
            return question.options || []

        case questionTypes.insight:
            return question.buckets || []

        case questionTypes.entity:
            return question.entities || []
    }
}

/**
 * @template T
 * @param {T} obj
 * @returns {T}
 */
export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
}