import React, { useEffect, useRef } from "react"
import { Drag, Drop } from "../../menus/DragAndDrop"
import { DragDropContext } from "react-beautiful-dnd"

import { v4 as uuid } from "uuid"
import { BiTrash } from "react-icons/bi"
import IntSetting from "../../menus/Setting/IntSetting"
import { normalizeQuestionOrOption } from "../../../utils/strings"
import { useEditQuestionOptions } from "../../../hooks/useEditQuestion"

const EditMultipleChoice = ({ question, setQuestion, deleteOption, scroll, lang, disabled = false }) => {

    const { addOption, editOption } = useEditQuestionOptions(setQuestion, lang)

    const optionsRef = useRef([])

    const handleOptionDragEnd = result => {
        // Destructure properties from the result
        const { destination, source } = result

        // If there's no destination (e.g. drag is canceled or item is dragged outside droppable area)
        if (!destination)
            return


        // If the source and the destination is the same, no need to do anything
        if (destination.index === source.index)
            return


        // Create a new schema array by reordering the questions
        const newOptions = Array.from(question.options)
        const [movedOption] = newOptions.splice(source.index, 1)
        newOptions.splice(destination.index, 0, movedOption)

        setQuestion(p => ({ ...p, options: newOptions }))
    }

    const handleKeyPress = index => event => {
        if (event.key === "Enter") {
            event.preventDefault()

            // If it's the last input, add a new one
            if (index === question.options.length - 1) addOption()
            // Otherwise, focus the next input
            else optionsRef.current[index + 1]?.focus()
        }
    }

    useEffect(() => {
        // Focus on the last input field (newly added) when the inputs array changes
        const lastInputIndex = question.options?.length - 1
        if (lastInputIndex > 0)
            optionsRef.current[lastInputIndex].focus()

    }, [question.options?.length])

    useEffect(() => {
        // If there are no options, add at least one
        if (!question.options?.length) addOption()

        // Update the refs array to the current length of inputs
        optionsRef.current = optionsRef.current.slice(0, question.options || 0)
    }, [question.options])

    return (
        <>
            <IntSetting
                setting={{
                    min: 1,
                    max: question.options?.length,
                    description: "How many options can the user select",
                    disabled
                }}
                value={question.max_choices || 1}
                setValue={v => setQuestion(q => ({ ...q, max_choices: v }))}
            />
            <div style={{ width: "100%", position: "relative", paddingBottom: "1em" }}>
                <DragDropContext onDragEnd={handleOptionDragEnd}>
                    <Drop id="root" type="options">
                        {question.options?.map((o, idx) => (
                            <Drag id={uuid()} key={idx} index={idx} parentScroll={scroll} disabled={disabled}>
                                <div key={idx} className="option">
                                    <span className="index">{idx + 1}</span>
                                    <input
                                        key={idx}
                                        placeholder="Option"
                                        value={o.label?.[lang]}
                                        ref={el => (optionsRef.current[idx] = el)}
                                        onChange={e => editOption(e.target.value, o.id)}
                                        onBlur={e => editOption(normalizeQuestionOrOption(e.target.value), o.id)}
                                        onKeyDown={handleKeyPress(idx)}
                                        disabled={disabled}
                                    />
                                    <BiTrash
                                        className={["clickable danger", disabled ? "disabled" : ""].join(" ")}
                                        onClick={() => !disabled && deleteOption(o.id)}
                                    />
                                </div>
                            </Drag>
                        ))}
                    </Drop>
                </DragDropContext>
                <span
                    onClick={() => !disabled && addOption()}
                    className={["clickable-text", disabled ? "disabled" : ""].join(" ")}
                    style={{ margin: "0 auto" }}
                    disabled={disabled}
                >
                    + Add option
                </span>
            </div>
        </>
    )
}

export default EditMultipleChoice
