import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IOrganization } from "src/@types/organization"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import Loading from "@components/loading/Loading"
import { MdChevronLeft, MdDownload } from "react-icons/md"
import GlautLogo from "@assets/GlautLogo.png"
import { download } from "@utils/miscellaneous"
import { useProjectService } from "@hooks/services/useProjectService"
import { useProject } from "../../contexts/ProjectProvider"

const tabs = ["Outline", "Data", "Analysis", "Reports", "Settings", "Settings"]
export const interviewTabs = [0, 1, 2, 3, 4]
export const csvTabs = [2, 5]
export const csvSetupTabs = [5]

function TopBar({ currentTab, setCurrentTab }) {
    // #region Contexts
    const { org, project, setProject } = useContext(ProjectContext) as {
        org: IOrganization | null
        project: IProject | null
        setProject: React.Dispatch<React.SetStateAction<IProject>>
    }
    const { isAnalysisTabExportDataButtonActive } = useProject()
    // #endregion

    // #region States
    const [edit, setEdit] = useState(false)
    const [tabsToDisplay, setTabsToDisplay] = useState<number[]>([])
    const [isDownloading, setIsDownloading] = useState(false)
    // #endregion

    // #region Navigation
    const navigate = useNavigate()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Callbacks
    const downloadExcel = useCallback(() => {
        if (!project) return

        setIsDownloading(true)
        projectService.downloadExcel({ projectId: project._id })
            .then(response => {
                download(response, project.name + ".xlsx", "application/vnd.vnd.ms-excel")
            })
            .catch(error => {
                console.error("Error downloading the file:", error)
            })
            .finally(() => {
                setIsDownloading(false)
            })
    }, [project, projectService])
    // #endregion

    // #region Effects

    // Update the tabs to display
    useEffect(() => {
        let tabsToDisplay: number[] = interviewTabs
        if (project?.source === "CSV")
            tabsToDisplay = project?.schema?.length > 0 ? csvTabs : csvSetupTabs

        setTabsToDisplay(tabsToDisplay)
    }, [project])

    // #endregion

    if (!project || !org) return <Loading />

    return (
        <div id="div--project-area-topbar" className="flex flex-row justify-between bg-glaut-text-midnight pl-6 pr-20">
            <div className="flex flex-row items-center gap-2 pt-5 mb-auto">
                <MdChevronLeft
                    className="text-[#FCFCFC] h-5 w-5"
                    onClick={() => navigate("/w/" + org.org_id)}
                />
                <img src={GlautLogo} alt="Glaut logo" className="h-7 w-7" />
                {edit ? (
                    <input
                        id="input--project-area-topbar-project-name"
                        className="text-[#FCFCFC] text-lg bg-glaut-text-midnight border-none p-0"
                        placeholder="Project name"
                        value={project.name}
                        onChange={e => setProject(p => ({ ...p, name: e.target.value }))}
                        style={{ flex: 1 }}
                        onBlur={() => setEdit(false)}
                        autoFocus
                    />
                ) : (
                    <span
                        id="span--project-area-topbar-project-name"
                        className="text-white text-lg"
                        onClick={() => setEdit(true)}
                    >
                        {project.name || "Untitled"}
                    </span>
                )}
                <div
                    className={`flex flex-row rounded-[4px] gap-1 px-2 py-1 items-center
                        ${isAnalysisTabExportDataButtonActive ? "bg-glaut-pink cursor-pointer" : "bg-glaut-off-white"}
                    `}
                    onClick={isAnalysisTabExportDataButtonActive ? downloadExcel : undefined}>
                    <MdDownload className={`h-[18px] w-[18px]
                            ${isAnalysisTabExportDataButtonActive ? "text-glaut-off-white" : "text-glaut-grey"}`}
                    />
                    {isDownloading
                        ? <Loading />
                        : <span className={`text-[11.11px] font-medium 
                            ${isAnalysisTabExportDataButtonActive ? "text-glaut-off-white" : "text-glaut-grey"}
                        `}>
                            Export data
                        </span>
                    }
                </div>
            </div>
            <div id="div--project-area-topbar-steps" className="flex flex-row">
                {tabs.map((step, index) => (tabsToDisplay.includes(index) &&
                    <div
                        key={index}
                        className={currentTab === index
                            ? "pt-5 py-3 px-4 border-b-2 border-glaut-pink"
                            : "pt-5 py-3 px-4"}
                        onClick={() => setCurrentTab(index)}
                    >
                        <p className="text-white">{step}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TopBar
