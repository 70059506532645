import useAxiosAuth from "@hooks/useAxiosAuth"
import IProjectGateway from "@services/projects/IProjectGateway"
import ProjectsApiService from "@services/projects/api/projects.api"
import { useMemo } from "react"

/**
 * Obtains the instance of the service to interact with projects.
 */
export function useProjectService(): IProjectGateway {
    const axiosInstance = useAxiosAuth()
    return useMemo(() => new ProjectsApiService({ api: axiosInstance }), [axiosInstance])
}