import { User } from "@propelauth/react"

export const isSuperuser = (user?: User | null) =>
    Boolean(
        user?.email &&
            user.email.endsWith("@glaut.com") &&
            typeof user.properties?.metadata === "object" &&
            user.properties?.metadata !== null &&
            "superuser" in user.properties.metadata &&
            user.properties?.metadata?.superuser
    )
