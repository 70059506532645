import { useEffect } from "react"

export function useWebSocketCleanup(socket?: WebSocket) {
    // #region Effects
    useEffect(() => {

        // Function to close WebSocket
        const closeWebSocket = () => {
            if (socket && socket.readyState === WebSocket.OPEN)
                socket.close(1000)
        }

        // Event listener for closing the WebSocket when the page is closing
        window.addEventListener("beforeunload", closeWebSocket)

        // Cleanup function
        return () => {
            closeWebSocket()
            window.removeEventListener("beforeunload", closeWebSocket)
        }
    }, [socket]) // Dependency array includes the socket
    // #endregion
}

