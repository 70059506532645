import IProjectGateway from "@services/projects/IProjectGateway"
import { questionTypes } from "@utils/Variables"
import { IProject } from "src/@types/project"
import { normalizeAnalysisFromResults } from "./normalize-analysis-from-results"
import { IQuestion } from "src/@types/question"
import { ArrayElement } from "@utils/types/array-element"

export type IQuestionFromSchema = ArrayElement<ReturnType<typeof questionsFromSchema>>
export type IQuestionAnalysisFromSchema = ArrayElement<IQuestionFromSchema["analysis"]>
export type IQuestionAnalysisCodeFromSchema = ArrayElement<IQuestionAnalysisFromSchema["codes"]>

/**
 * Callback filter for parent questions.
 */
function onlyParentQuestions(s: IQuestion) {
    return s.source === null
}

/**
 * Callback filter for filling the `analysis` attribute of questions.
 * 
 * - If it is a CSV project and the question IS NOT a short text, return `s.source === question.id`
 * - If it is a CSV project and the question IS a short text, always return `false`
 * - If it is an interview project, return `s.id === question.id`
 */
function onlyHiddenQuestionsAndIfInterviewRepeatQuestion(s: IQuestion, question: IQuestion, isCsvProject: boolean) {
    const isHiddenQuestion = s.source === question.id

    return !isCsvProject
        ? (isHiddenQuestion || s.id === question.id)
        : (isHiddenQuestion && s.type !== questionTypes.shortText)
}

/**
 * Folds analysis question in the right parent question.
 */
export function questionsFromSchema(
    schema: IProject["schema"],
    analysisResults: Awaited<ReturnType<IProjectGateway["getAnalysis"]>>["analysis"],
    isCsvProject: boolean
) {
    return schema
        .filter(s => onlyParentQuestions(s))
        .map((question, i) => ({
            ...question,
            index: i,
            analysis: schema
                .filter(s => onlyHiddenQuestionsAndIfInterviewRepeatQuestion(s, question, isCsvProject))
                .reverse()
                .map((analysis, ii) => normalizeAnalysisFromResults(analysis, ii, analysisResults[analysis.id]))
                .sort((a, b) => {
                    // it should sort the parent question to the end, and keep the hidden ones to the start

                    if (b.source === a.id)
                        // b is hidden from a
                        return -1

                    if (a.source === b.id)
                        // a is hidden from b
                        return 1

                    return 0
                })
        }))
}