import QuestionCardLabel from "@assets/question-card-label.svg"
import CodeItem from "@components/CodeItem"
import ConversationTranscriptBox from "@components/ConversationTranscriptBox"
import { useFormattedDate } from "@hooks/useFormattedDate"
import { useWebSocket } from "@hooks/useWebSocket"
import { useContext, useEffect, useState } from "react"
import { MdClose, MdOutlineDeleteOutline } from "react-icons/md"
import { IEntry } from "src/@types/entry"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useDataTabDispatch } from "../../contexts/DataTabProvider"
import { useProject, useProjectDispatch } from "../../contexts/ProjectProvider"
import { useTableRowItemLanguage } from "../../hooks/useTableRowItemLanguage"
import { useTableRowItemPlatform } from "../../hooks/useTableRowItemPlatform"
import { useTableRowItemStatus } from "../../hooks/useTableRowItemStatus"
import DeleteConfirmationModal from "../DataTabTableRowItemIdModalDetailsDeleteConfirmationModal"
import EntryList from "../DataTabTableRowItemIdModalDetailsEntryList"

interface IDataTabTableRowItemIdModalDetailsProps {
    open: boolean
    onClose?: () => void
    entry: IEntry
}

export default function DataTabTableRowItemIdModalDetails({
    open,
    onClose,
    entry
}: Readonly<IDataTabTableRowItemIdModalDetailsProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const dataTabDispatch = useDataTabDispatch()
    const { numberOfEntries } = useProject()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region Table row hooks
    const { text: language } = useTableRowItemLanguage(entry.lang)
    const { text: platform } = useTableRowItemPlatform(entry.headers?.["user-agent"] ?? "Unknown")
    const { icon: statusIcon, text: statusText } = useTableRowItemStatus(entry.completed)
    const { text: lastUpdateDate } = useFormattedDate(entry.last_update)
    // #endregion

    // #region States
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
    const [isConversationTranscriptBoxLoaded, setIsConversationTranscriptBoxLoaded] = useState(false)
    // #endregion

    // #region WebSocket
    const { sendMessage } = useWebSocket()
    // #endregion

    // #region Effects

    // onOpenLoadConversationElseUnload
    useEffect(() => {
        // intentional to avoid the playback to continue (when played) when the modal is closed
        setIsConversationTranscriptBoxLoaded(open)
    }, [open])

    // #endregion

    if (!project) return <></>

    return (
        <>
            <div
                className={`
                    fixed inset-0 flex justify-center items-center transition-colors overflow-hidden
                    ${open ? "visible bg-black/20" : "invisible"}
                `}
                onClick={onClose}
            >
                <div
                    onClick={e => e.stopPropagation()}
                    className={`
                    flex flex-col bg-white rounded-xl shadow px-5 py-8 transition-all w-[60%] h-[80%] border-[1px] border-black
                    ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                  `}
                >
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-4 h-9 items-center justify-center">
                            <p className="text-2xl font-light">{entry.identifier}</p>
                            <button
                                className="bg-glaut-cards border-lg border-[1px] border-transparent shadow-none font-normal px-3 py-2 hover:border-[1px] hover:border-glaut-pink transition-colors hover:bg-glaut-cards"
                                onClick={() => {
                                    setIsConfirmingDeletion(true)
                                }}
                            >
                                <p className="text-glaut-pink">Delete</p>
                                <MdOutlineDeleteOutline className="text-glaut-pink" />
                            </button>
                        </div>
                        <button
                            onClick={onClose}
                            className="p-1 rounded-lg bg-white hover:bg-gray-50 hover:text-gray-600 border-0 shadow-none"
                        >
                            {/** @TODO: remove styling in CSS and apply TailwindCSS class (text-midnight) */}
                            <MdClose style={{ color: "#101720", width: "20px", height: "20px" }} />
                        </button>
                    </div>
                    <div className="flex flex-col items-start">
                        <div className="flex flex-row items-center gap-1">
                            Status: {statusText} {statusIcon}
                        </div>
                        <p>Platform: {platform}</p>
                        <p>Language: {language}</p>
                    </div>
                    <div className="flex flex-row pt-3 gap-7 overflow-hidden">
                        <div className="flex-1 flex flex-col gap-4 overflow-auto p-1">
                            {project.schema.map((question, idx) => {
                                const rawThemes = entry.entry?.[question.id]

                                let themes: React.ReactNode
                                if (rawThemes === undefined)
                                    themes = <></>
                                else if (Array.isArray(rawThemes))
                                    themes = (
                                        <EntryList
                                            key={`${question.id}-${idx}`}
                                            themeIds={rawThemes}
                                            questionId={question.id}
                                        />
                                    )
                                else
                                    themes = (
                                        <CodeItem
                                            key={`${question.id}-${idx}`}
                                            leadContent={typeof rawThemes === "number" ? "#" : "-"}
                                            content={String(rawThemes)}
                                            colorIndex={idx}
                                        />
                                    )


                                return (
                                    <div key={question.id} className="flex flex-col gap-2 px-3 py-[10px] bg-glaut-off-white border-[1px] border-glaut-light-grey rounded-md">
                                        <p className="text-xs text-black font-medium">{`Question ${String(idx + 1).padStart(2, "0")}`}</p>
                                        <p className="text-glaut-dark-grey text-sm">{question.value[entry.lang] ?? "asd"}</p>
                                        <div className="flex flex-row flex-wrap" style={{ gap: "4px" }}>
                                            <img src={QuestionCardLabel} alt="Question tags label" />
                                            {themes}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="flex-1 p-1 overflow-hidden">
                            {isConversationTranscriptBoxLoaded && (
                                <ConversationTranscriptBox
                                    messages={entry.messages}
                                    lastUpdateDate={lastUpdateDate}
                                    showFollowUps={false}
                                    projectId={project._id}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <DeleteConfirmationModal
                open={isConfirmingDeletion}
                onClose={() => {
                    setIsConfirmingDeletion(false)
                }}
                onConfirm={() => {
                    sendMessage({ operation: "delete_entry", id: entry._id })
                    onClose?.()

                    // Better UX
                    setTimeout(() => {
                        dataTabDispatch({ type: "remove-entry", entryId: entry._id })
                        projectDispatch({ type: "set-number-of-entries", numberOfEntries: numberOfEntries - 1 })
                    }, 1000)
                }}
            />
        </>
    )
}