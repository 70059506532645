import React, { useRef, useState } from "react"
import { RiArrowDropDownLine } from "react-icons/ri"
import useClickOutside from "../../hooks/useClickOutside"

const Options = ({ options, chosen, setChosen }) => {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef(null)

    // Use the hook to close the list when clickng outside
    useClickOutside(dropdownRef, () => setOpen(false))

    const selectOption = id => {
        setOpen(false)
        setChosen(id)
    }

    if (!options) return <></>

    return (
        <div className="dropdown" ref={dropdownRef} style={{ width: "fit-content" }}>
            <div onClick={() => setOpen(o => !o)} style={{ width: "fit-content" }}>
                <span>
                    {chosen !== null
                        ? options.find(o => o.slug === chosen)?.title
                        : "None selected"}
                </span>
                <RiArrowDropDownLine />
            </div>
            {open && (
                <div>
                    {options.map(o => (
                        <div
                            key={o.slug}
                            onClick={() => selectOption(o.slug)}
                            className="column"
                            style={{ alignItems: "flex-start", gap: "0.5em" }}
                        >
                            <b>{o.title}</b>
                            {o.description}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Options
