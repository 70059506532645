import GlautInfoIcon2 from "@assets/glaut-info-icon-2.svg"
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import EmptySpaceBox from "@components/EmptySpaceBox"
import Pagination from "@components/navigation/Pagination"
import IProjectGateway from "@services/projects/IProjectGateway"
import { copy, getCopy } from "@utils/Copy"
import React, { useEffect, useMemo } from "react"
import { MdOutlineChat } from "react-icons/md"
import { IEntry } from "src/@types/entry"
import useModifyEntry from "../../../useModifyEntry"
import {
    IQuestionAnalysisFromSchema,
    IQuestionFromSchema
} from "../../utils/questions-from-schema"
import AnalysisTabVerbatimsColItem from "../AnalysisTabVerbatimsColItem"
import { ArrayElement } from "@utils/types/array-element"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"

type IEntryProp = ArrayElement<Awaited<ReturnType<IProjectGateway["getEntries"]>>["entries"]>

interface IAnalysisTabVerbatimsColProps {
    question: IQuestionFromSchema
    analysis?: IQuestionAnalysisFromSchema
    entries: IEntryProp[]
    nEntries: number
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    nPages: number
    modifyVerbatim: ReturnType<typeof useModifyEntry>
    lang?: string
}

export default function AnalysisTabVerbatimsCol({
    question,
    analysis,
    entries,
    nEntries,
    page,
    setPage,
    nPages,
    modifyVerbatim,
    lang
}: Readonly<IAnalysisTabVerbatimsColProps>) {
    // #region Contexts
    const { selectedCode } = useAnalysisTab()
    // #endregion

    // #region Memos
    const allValidSelectedCodeIds = useMemo(() => {
        if (!selectedCode || !analysis) return undefined
        if (selectedCode.id === "empty") return ["empty"]

        const validSelectedCodes: string[] = []

        // needs recursion here
        /**********************************************************************/
        function markAllChildrenAsSelected(
            code: ArrayElement<NonNullable<typeof analysis>["codes"]>
        ) {
            validSelectedCodes.push(code.id)

            for (const childCode of code.subcodes)
                markAllChildrenAsSelected(childCode)
        }
        /**********************************************************************/

        markAllChildrenAsSelected(selectedCode)

        return validSelectedCodes
    }, [analysis, selectedCode])

    const sortedEntries = useMemo(() => {
        // no codes or no entries => no need to sort
        if (!analysis?.codes || entries.length === 0)
            return entries

        const entriesWithNoCodes: IEntryProp[] = []
        const entriesWithCodes: IEntryProp[] = []
        for (const entry of entries) {
            const values = analysis ? entry.entry?.[analysis.id] || null : null

            // no codes for entry => add to the end
            // there are codes for entry => add to the start
            const isEmptyArray = Array.isArray(values) && values.length === 0
            if (!values || isEmptyArray)
                entriesWithNoCodes.push(entry)
            else
                entriesWithCodes.push(entry)
        }

        return entriesWithCodes.concat(entriesWithNoCodes)
    }, [entries, analysis])
    // #endregion

    // #region Effects
    useEffect(() => monitorForElements({
        onDrop: ({ source, location }) => {
            // Unpacks the relevant values
            const destination = location.current.dropTargets[0]
            if (!destination) return

            const { data: sourceData } = source
            const { data: destData } = destination || {}

            // Checks that we have the data we need
            if (!sourceData || !destData) return

            // Unpacks entry, question and source code
            const { entry } = destData as { entry?: IEntry }
            if (!entry) return

            const { code, question } = sourceData as { question: { id: string }, code: { id: string } }

            // If we have a target entry, updates the value
            const entryData = entry.entry?.[question.id]
            if (Array.isArray(entryData)) {
                const newValue = [...entryData, code.id]
                modifyVerbatim(question.id, entry._id, newValue)
            }
        }
    }))
    // #endregion

    return <>
        <div className="flex flex-row justify-between mb-4">
            <span className="text-sm text-[#00000099]">
                {getCopy(copy.coding.verbatimsHeader)}
            </span>
            <div className="flex flex-row gap-2">
                <MdOutlineChat className="text-glaut-grey" />
                <span className="text-glaut-midnight">
                    {`${sortedEntries.length} out of ${nEntries}`}
                </span>
            </div>
        </div>
        <div className="scroll pb-2">
            {sortedEntries.length > 0
                ? (
                    <div className="verbatims">
                        {sortedEntries.map((e, idx) => <AnalysisTabVerbatimsColItem
                            key={`${e.identifier}-${idx}`}
                            question={question}
                            analysis={analysis}
                            entry={e}
                            lang={lang}
                            modifyVerbatim={modifyVerbatim}
                            startExpanded
                            allValidSelectedCodeIds={allValidSelectedCodeIds}
                        />)}
                        <div>
                            <Pagination page={page} setPage={setPage} nPages={nPages} />
                        </div>
                    </div>
                ) : (
                    <EmptySpaceBox
                        message={getCopy(copy.coding.emptyVerbatimsColumnMessage)}
                        leftIcon={<img src={GlautInfoIcon2} alt="Info" />}
                    />
                )
            }
        </div>
    </>
}
