import { useAuthInfo, useLogoutFunction } from "@propelauth/react"
import GlautLogo from "../../assets/GlautLogo.png"

import { BiExpandVertical } from "react-icons/bi"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Popup from "../../components/Popups/Popup"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import TextualSetting from "../../components/menus/Setting/TextualSetting"
import { urlRegex } from "../../utils/Variables"
import { FiLogOut } from "react-icons/fi"
import Loading from "../../components/loading/Loading"

const tabs = ["Projects", "Settings"]

export const CreateWorkspacePopup = ({setOpen}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState("")
    const [domain, setDomain] = useState("")

    const axiosAuth = useAxiosAuth()
    const { refreshAuthInfo } = useAuthInfo()
    const navigate = useNavigate()

    const createWorkspace = () => {
        setIsLoading(true)

        axiosAuth.post("/orgs/", {name, domain})
            .then(res => refreshAuthInfo().then(() => navigate("/w/" + res.data.org_id)))
            .catch(e => console.log(e))
    }

    return <Popup close={() => setOpen(false)}>
        <h2>Create Workspace</h2>
        <TextualSetting setting={{ slug: "workspace_name", placeholder: "Acme, Inc" }} value={name} setValue={setName}/>
        <TextualSetting 
            setting={{ slug: "workspace_domain", placeholder: "acme.com (optional)", validation: urlRegex }} 
            value={domain}
            setValue={setDomain}
        />
        {isLoading ?
            <Loading /> :
            <button className="accent-button" onClick={createWorkspace} disabled={name.length < 3}>
                Create workspace
            </button>
        }
    </Popup>
}

const TopBar = ({ currentTab, setCurrentTab, orgs, currentOrg }) => {
    const [filteredOrgs, setFilteredOrgs] = useState(orgs)
    const [search, setSearch] = useState("")
    const { user } = useAuthInfo()
    const logout = useLogoutFunction()
    const navigate = useNavigate()

    const dropdownRef = useRef()

    const [openCreateWorkspace, setOpenCreateWorkspace] = useState(false)
    const [openOrgs, setOpenOrgs] = useState(false)

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) 
            setOpenOrgs(false)
        
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    useEffect(() => {
        setFilteredOrgs(orgs.filter(org => org.name.toLowerCase().includes(search.toLowerCase())))
    }, [search])

    return (
        <>
            <div
                className="bar fixed-top"
                style={{
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "1em 2em",
                    background: "white"
                }}
            >
                <div className="bar">
                    <img src={GlautLogo} className="large-icon" />
                    <div className="options-input" ref={dropdownRef} style={{ minWidth: "300px"}}>
                        <div className="bar clickable" onClick={() => setOpenOrgs(o => !o)}>
                            <h2>{currentOrg.name}</h2>
                            <BiExpandVertical className="small-icon" />
                        </div>
                        {openOrgs && (
                            <div className="org-list column" style={{ alignItems: "flex-start", padding: 0 }}>
                                {orgs.length > 10 ? <TextualSetting
                                    setting={{ slug: "search", placeholder: "Search...", hideLabel: true }}
                                    value={search}
                                    setValue={setSearch}
                                /> : null}
                                <div style={{
                                    maxHeight: "355px",
                                    overflowY: "auto",
                                    width: "100%"
                                }}>
                                    {filteredOrgs.map(
                                        o =>
                                            o.org_id !== currentOrg.org_id && (
                                                <div
                                                    className="org clickable"
                                                    key={o.org_id}
                                                    style={{
                                                        fontWeight: "bold",
                                                        boxSizing: "border-box",
                                                        width: "100%"
                                                    }}
                                                    onClick={() => navigate(`/w/${o.org_id}`)}
                                                >
                                                    {o.name}
                                                </div>
                                            )
                                    )}
                                </div>
                                {user.email.endsWith("@glaut.com") &&
                                    <div 
                                        className="secondary-button" 
                                        style={{ alignSelf: "center", padding: "1em 0"}} 
                                        onClick={() => setOpenCreateWorkspace(true)}
                                    >
                                        + Add workspace
                                    </div>
                                }
                                <div
                                    style={{
                                        borderTop: "1px solid var(--border-color)",
                                        width: "100%"
                                    }}
                                ></div>
                                <div className="bar" style={{ padding: "0.5em 1em" }}>
                                    <img src={user?.pictureUrl} style={{ width: "2em", borderRadius: "100%" }}/>
                                    <span className="column" style={{ gap: "0" }}>
                                        <span>{user?.firstName}</span>

                                        <div
                                            className="bar"
                                            style={{ cursor: "pointer" }}
                                            onClick={logout}
                                        >
                                            <FiLogOut style={{ height: "16px" }} />
                                            <span
                                                style={{
                                                    fontSize: "small",
                                                    fontWeight: "lighter"
                                                }}
                                                className="secondary-button"
                                            >
                                                Log Out
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="bar">
                    <div className="steps" style={{ marginRight: "2em" }}>
                        {tabs.map((t, index) => (
                            <div
                                key={t}
                                className={currentTab === index ? "active" : ""}
                                onClick={() => setCurrentTab(index)}
                            >
                                {t}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {openCreateWorkspace && <CreateWorkspacePopup setOpen={setOpenCreateWorkspace}/>}
        </>
    )
}

export default TopBar
