import { useTableRowItemLanguage } from "../../hooks/useTableRowItemLanguage"

interface IDataTabTableRowItemLanguageProps {
    language: string
}

export default function DataTabTableRowItemLanguage({
    language
}: Readonly<IDataTabTableRowItemLanguageProps>) {
    // #region Table row hooks
    const { text } = useTableRowItemLanguage(language)
    // #endregion

    return (
        <td className="rounded-lg bg-glaut-cards" style={{ borderRight: "0px", padding: "0" }}>
            <div className="flex flex-row gap-2 justify-center items-center px-[12px] py-[4px]">
                <p className={`text-[13.33px] 
                    ${text !== "Not available" ? "text-glaut-midnight" : "text-glaut-light-grey"}
                `}>
                    {text}
                </p>
            </div>
        </td>
    )
}