/**
 * @param {string} url
 * @param {Object} options
 * @param {boolean | undefined} options.autoplay
 * 
 * @returns {string | null}
 * 
 * @see https://kilianvalkhof.com/2024/javascript/the-problem-with-new-url-and-how-url-parse-fixes-that/
 */
export default function convertYoutubeUrlToEmbed(url) {
    if (!url.includes("youtube.com") && !url.includes("youtu.be")) return null

    let newUrl = url
    if (url.includes("/watch?v="))
        newUrl = newUrl.replace("/watch?v=", "/embed/")

    try {
        const objUrl = new URL(newUrl)

        if (!objUrl.searchParams.has("enablejsapi"))
            objUrl.searchParams.append("enablejsapi", "1")

        if (!objUrl.searchParams.has("rel"))
            objUrl.searchParams.append("rel", "0")

        if (!objUrl.searchParams.has("controls"))
            objUrl.searchParams.append("controls", "0")

        return objUrl.toString()
    } catch (e) {
        return null
    }
}