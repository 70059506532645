import React from "react"
import { BsToggleOff, BsToggleOn } from "react-icons/bs"

import "../../../styling/SettingFields.scss"

const BooleanSetting = ({ setting, value, setValue }) => (
    <div
        className={["column", "setting", "boolean-setting", setting?.disabled ? "disabled" : ""].join(" ")}
        style={{ margin: "1em 0", gap: setting ? "0.5em" : 0, alignItems: "flex-start" }}>
        <div className="bar">
            {value
                ? (
                    <BsToggleOn
                        onClick={() => {
                            if (setting?.disabled) return
                            setValue(false)
                        }}
                        style={{ color: "var(--accent-color)" }}
                        className={["w-[18px] h-[18px]", setting.disabled ? "disabled" : "cursor-pointer"].join(" ")}
                    />
                )
                : (
                    <BsToggleOff
                        onClick={() => {
                            if (setting?.disabled) return
                            setValue(true)
                        }}
                        className={["w-[18px] h-[18px]", setting.disabled ? "disabled" : "cursor-pointer"].join(" ")}
                    />
                )}
            <label className="text-[13.33px] text-glaut-midnight font-medium">
                {setting?.label ?? setting?.slug?.replace("_", " ")}
            </label>
        </div>
        {setting?.placeholder && (
            <span className="text-[11.11px] text-glaut-text-midnight font-medium">
                {setting?.placeholder}
            </span>
        )}
    </div>
)

export default BooleanSetting
