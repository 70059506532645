import { FaHashtag, FaPercent } from "react-icons/fa"
import {
    useAnalysisTabCodeOccurrences,
    useAnalysisTabCodeOccurrencesDispatch
} from "../../contexts/AnalysisTabCodeOccurrencesProvider"
import { useCallback } from "react"


export default function AnalysisTabAnalysisColCodeOccurrencesModeToggle() {
    // #region Contexts
    const { shouldShowCodeOccurrencesAsNumber } = useAnalysisTabCodeOccurrences()
    const analysisTabCodeOccurrencesDispatch = useAnalysisTabCodeOccurrencesDispatch()
    // #endregion

    // #region Callbacks
    const handleClassNameForIcon = useCallback((type: "number" | "percentage") => {
        const isActive = type === "number" ? shouldShowCodeOccurrencesAsNumber : !shouldShowCodeOccurrencesAsNumber
        return isActive ? "bg-glaut-light-grey-50% text-glaut-midnight" : "text-glaut-grey"
    }, [shouldShowCodeOccurrencesAsNumber])
    // #endregion

    return (
        <div className="flex flex-row gap-[8px] items-center">
            <FaHashtag
                className={`w-[16px] h-[16px] rounded-[2px] p-[3px] ${handleClassNameForIcon("number")}`}
            />
            <label className="relative inline-flex cursor-pointer items-center">
                <input
                    id="input--code-occurrences-mode-switch"
                    type="checkbox"
                    className="peer sr-only"
                    onChange={() => {
                        analysisTabCodeOccurrencesDispatch({
                            type: "change-global-code-occurrences-mode",
                            shouldShowCodeOccurrencesAsNumber: !shouldShowCodeOccurrencesAsNumber
                        })
                    }}
                />
                <label htmlFor="input--code-occurrences-mode-switch" className="hidden"></label>
                <div className={`peer h-6 w-9 rounded-full bg-glaut-midnight 
                            after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:transition-all
                            after:border-2 after:border-glaut-midnight after:bg-white after:rounded-full
                            after:content-['']
                            peer-checked:bg-glaut-midnight peer-checked:after:translate-x-[0.75rem]`}>
                </div>
            </label>
            <FaPercent
                className={`w-[16px] h-[16px] rounded-[2px] p-[3px] ${handleClassNameForIcon("percentage")}`}
            />
        </div>
    )
}