import { useAuthInfo, useLogoutFunction } from "@propelauth/react"
import axios from "axios"
import { useEffect } from "react"
import { settings } from "../utils/Variables"

const BASE_URL = settings.apiUrl

// Autheniticated axios instance
const axiosAuth = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
})

const useAxiosAuth = () => {
    const { accessToken, isLoggedIn } = useAuthInfo()
    const logout = useLogoutFunction()

    useEffect(() => {
        // Updates the Bearer Token in axios request
        const requestIntercept = axiosAuth.interceptors.request.use(
            request => {
                if (!request.headers.Authorization)
                    request.headers.Authorization = `Bearer ${accessToken}`

                return request
            },
            error => Promise.reject(error)
        )

        // Intercepts the response, if it returned an error we should login again
        const responseIntercept = axiosAuth.interceptors.response.use(
            response => response,
            async error => {
                // Logouts if we got an Unauthorized error
                if (error?.response?.status === 401 && isLoggedIn) logout(false)
                return Promise.reject(error)
            }
        )

        // On cleanup, removes the interceptors
        return () => {
            axiosAuth.interceptors.request.eject(requestIntercept)
            axiosAuth.interceptors.response.eject(responseIntercept)
        }
    }, [accessToken, isLoggedIn, logout])

    return axiosAuth
}

export { BASE_URL }
export default useAxiosAuth
