import { useMemo, useState } from "react"
import { MdOutlineInfo } from "react-icons/md"
import { Tooltip } from "react-tooltip"
import { v4 as uuid } from "uuid"

const TextualSetting = ({ setting, value, setValue, lang }) => {
    // #region States
    const [valid, setValid] = useState(true)
    // #endregion

    // #region Memos
    const inputClassName = useMemo(
        () => setting.inputClassName
            ?? `text-glaut-grey text-[13.33px] font-medium border-0 border-b-1 border-b-glaut-light-grey rounded-none
                focus:shadow-none`,
        [setting]
    )
    const tooltipId = useMemo(() => setting.slug ?? uuid(), [setting])
    // #endregion

    // #region Callbacks
    const validate = e => {
        setValue(e.target.value)
        if (setting.validation) setValid(setting.validation.test(e.target.value))
    }
    // #endregion

    return (
        <label style={setting.labelStyle}>
            <div className="flex flex-row justify-between w-full">
                <span className={setting.descriptionClassName ?? "text-glaut-dark-grey font-medium text-[11.11px]"}>
                    {setting.hideLabel ? null : setting.description || setting.slug?.replaceAll("_", " ")}
                </span>
                {setting.shouldIncludeTooltip && (
                    <MdOutlineInfo
                        className="w-[16px] h-[16px] text-glaut-grey"
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={setting.tooltipContent}
                        data-tooltip-place={setting.tooltipPlace ?? "top"}
                    />
                )}
            </div>

            {lang
                ? (
                    <input
                        key={setting.slug}
                        name={setting.slug}
                        placeholder={setting.placeholder}
                        disabled={setting.disabled}
                        value={value[lang]}
                        onChange={e => setValue({ ...value, [lang]: e.target.value })}
                        className={inputClassName}
                    />
                )
                : (
                    <input
                        key={setting.slug}
                        name={setting.slug}
                        placeholder={setting.placeholder}
                        disabled={setting.disabled}
                        value={value}
                        onChange={validate}
                        style={valid ? {} : { borderColor: "coral" }}
                        className={inputClassName}
                    />
                )}

            {setting.shouldIncludeTooltip && (
                <Tooltip id={tooltipId} className="z-50" />
            )}
        </label>
    )
}

export default TextualSetting
