import React from "react"

const Summaries = ({ value }) => (
    <table className="simple-table">
        <tbody>
            {value.map((v, idx) => (
                <tr style={{ cursor: "pointer" }} key={idx}>
                    <td className="bar" style={{ justifyContent: "flex-start", width: "30%" }}>
                        <span>{v.label}</span>
                    </td>
                    <td>{v.summary}</td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default Summaries
