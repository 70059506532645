import { MutableRefObject, useCallback, useEffect, useMemo } from "react"

interface IFloatingLayoutProps {
    id?: string
    children: React.ReactNode
    className?: string
    anchorRef?: MutableRefObject<HTMLElement | null>
    onClose?: (event: MouseEvent) => void
    horizontalOffset?: number
    verticalOffset?: number
    boundingBoxOffset?: number
}

export default function FloatingLayout({
    id,
    className,
    anchorRef,
    onClose,
    verticalOffset = 0,
    horizontalOffset = 10,
    boundingBoxOffset = 0,
    children
}: Readonly<IFloatingLayoutProps>) {
    // #region Memos
    const absoluteCoordinates = useMemo(() => ({
        x: (anchorRef?.current?.getBoundingClientRect()?.right ?? 0) + window.scrollX + horizontalOffset,
        y: (anchorRef?.current?.getBoundingClientRect()?.top ?? 0) + window.scrollY + verticalOffset
    }), [anchorRef, verticalOffset, horizontalOffset])
    // #endregion

    // #region Callbacks
    const handleAnchorMouseLeave = useCallback((event: MouseEvent) => {
        if (!anchorRef?.current || !onClose) return

        const { top, bottom, left, right } = anchorRef.current.getBoundingClientRect()

        // The mouse can only leave for the right without closing the menu
        // This avoids having a code menu stuck on the page
        if (
            event.clientX <= right && (
                event.clientY <= top - boundingBoxOffset ||
                event.clientY >= bottom + boundingBoxOffset ||
                event.clientX <= left - boundingBoxOffset)
        ) onClose(event)
    }, [anchorRef, onClose, boundingBoxOffset])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (!anchorRef?.current) return

        const anchorRefCurrent = anchorRef.current
        anchorRefCurrent.addEventListener("mouseleave", handleAnchorMouseLeave)

        return () => {
            anchorRefCurrent.removeEventListener("mouseleave", handleAnchorMouseLeave)
        }
    }, [anchorRef, handleAnchorMouseLeave])
    // #endregion

    return (
        <div
            id={id}
            className={className}
            style={{ top: `${absoluteCoordinates.y}px`, left: `${absoluteCoordinates.x}px` }}
            onMouseLeave={event => onClose?.(event.nativeEvent)}>
            {children}
        </div>
    )
}