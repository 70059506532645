import ProjectMetricsBox from "../ProjectMetricsBox"
import DataTabTable from "../DataTabTable"
import DataTabProvider from "../../contexts/DataTabProvider"

export default function DataTab() {
    return (
        <DataTabProvider>
            <div className="container mx-auto h-full flex flex-col flex-1 gap-8 overflow-hidden">
                <ProjectMetricsBox />
                <DataTabTable />
            </div>
        </DataTabProvider>
    )
}