import Loading from "@components/loading/Loading"
import { useMemo } from "react"
import { useProject } from "../../contexts/ProjectProvider"
import AnalysisTabAnalysisColNpsContentItem from "../AnalysisTabAnalysisColNpsContentItem"
import { INPSAnalysis } from "src/@types/analysis"

interface IAnalysisTabAnalysisColNpsContentProps {
    analysisId: string
}

export default function AnalysisTabAnalysisColNpsContent({
    analysisId
}: Readonly<IAnalysisTabAnalysisColNpsContentProps>) {
    // #region Contexts
    const { analysisData } = useProject()
    // #endregion

    // #region Memos
    const rawAnalysis = useMemo(() => analysisData?.analysis[analysisId], [analysisData, analysisId])
    const isNpsAnalysis = useMemo(() => {
        if (!rawAnalysis) return false
        return "values" in rawAnalysis
    }, [rawAnalysis])
    const totalOccurrences = useMemo(() => {
        if (!isNpsAnalysis) return 0
        return Object.values((rawAnalysis as INPSAnalysis).values).reduce((prev, curr) => prev + curr, 0)
    }, [isNpsAnalysis, rawAnalysis])

    // #endregion

    if (!rawAnalysis) return <Loading />
    if (!isNpsAnalysis) return <></>

    return (
        <div className="mt-[0.75em]">
            {Object.entries((rawAnalysis as INPSAnalysis).values)
                .sort(([scoreA], [scoreB]) => Number(scoreB) - Number(scoreA))
                .map(([score, occurrences]) => (
                    <AnalysisTabAnalysisColNpsContentItem
                        key={score}
                        occurrences={occurrences}
                        percentage={totalOccurrences === 0 ? 0 : Math.round((occurrences / totalOccurrences) * 100)}
                        score={Number(score)}
                        analysisId={analysisId}
                    />
                ))}
        </div>
    )
}