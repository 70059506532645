import { useTableRowItemPlatform } from "../../hooks/useTableRowItemPlatform"

interface IDataTabTableRowItemPlatformProps {
    platform: string
}

export default function DataTabTableRowItemPlatform({
    platform
}: Readonly<IDataTabTableRowItemPlatformProps>) {
    // #region Memos
    const { text, icon } = useTableRowItemPlatform(platform)
    // #endregion

    return (
        <td className="rounded-lg bg-glaut-cards" style={{ borderRight: "0px", padding: "0" }}>
            <div className="flex flex-row gap-2 justify-center items-center px-[12px] py-[4px]">
                {icon}
                <p className={`text-[13.33px] 
                    ${text !== "Not available" ? "text-glaut-midnight" : "text-glaut-light-grey"}
                `}>
                    {text}
                </p>
            </div>
        </td>
    )
}