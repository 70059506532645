interface IModalLayoutProps {
    isOpen: boolean
    onClose?: () => void
    className?: string
    children: React.ReactNode
}

export default function ModalLayout({
    isOpen,
    onClose,
    className,
    children
}: Readonly<IModalLayoutProps>) {
    return (
        <div
            className={`
                fixed inset-0 flex justify-center items-center transition-colors overflow-hidden z-50
                ${isOpen ? "visible bg-black/20" : "invisible"}
            `}
            onClick={onClose}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={className}
            >
                {children}
            </div>
        </div>
    )
}