import { getLanguageKey } from "../language"

const wsError = {

    errorGeneric: {
        en: "Error while saving data",
        it: "Errore durante il salvataggio dei dati",
        es: "Error al guardar los datos",
        fr: "Erreur lors de l'enregistrement des données",
        de: "Fehler beim Speichern von Daten"
    },
    errorGenericDetail: {
        en: "Someting went wrong while saving the data",
        it: "Qualcosa è andato storto durante il salvataggio dei dati",
        es: "Algo salió mal al guardar los datos",
        fr: "Quelque chose s'est mal passé lors de l'enregistrement des données",
        de: "Beim Speichern der Daten ist ein Fehler aufgetreten"
    },
    errorMissingTranslation: {
        en: "Missing translation",
    },
    errorMissingTranslationQuestionDetail: {
        en: (e, lang) => `Missing translation for question "${getLanguageKey(e.question, lang)}"`,
    },
    errorMissingTranslationOptionDetail: {
        en: (e, lang) => `Missing translation for option "${getLanguageKey(e.option, lang)}" in question "${getLanguageKey(e.question, lang)}"`,
    },
    errorSelectOptionTooShortIntro: {
        en: "Select option too short",
    },
    errorSelectOptionTooShortIntroDetail: {
        en: (e, lang) => `Select option "${getLanguageKey(e.option, lang)}" too short in question "${getLanguageKey(e.question, lang)}"`,
    },
    invalidFilter: {
        en: "Invalid filter",
    },
    invalidFilterDetail: {
        en: (e, lang) => `Invalid filter in question "${getLanguageKey(e.question, lang)}"`,
    },
    invalidStatusChange: {
        en: "Invalid status change",
    },
    invalidStatusChangeDetail: {
        en: e => `Invalid status change from "${e.from}" to "${e.to}"`,
    }
}

const errorTypes = {
    missing_translation: (errorMessage, lang) => {
        if (
            errorMessage?.question && 
            errorMessage?.option
        ) return [
            getLanguageKey(wsError.errorMissingTranslation, lang),
            getLanguageKey(wsError.errorMissingTranslationOptionDetail, lang)(errorMessage, lang)
        ]
        return [
            getLanguageKey(wsError.errorMissingTranslation, lang),
            getLanguageKey(wsError.errorMissingTranslationQuestionDetail, lang)(errorMessage, lang)
        ]
    },
    select_option_too_short: (errorMessage, lang) => {
        return [
            getLanguageKey(wsError.errorSelectOptionTooShortIntro, lang),
            getLanguageKey(wsError.errorSelectOptionTooShortIntroDetail, lang)(errorMessage, lang)
        ]
    },
    invalid_filter: (errorMessage, lang) => {
        return [
            getLanguageKey(wsError.invalidFilter, lang),
            getLanguageKey(wsError.invalidFilterDetail, lang)(errorMessage, lang)
        ]
    },
    invalid_status_change: (errorMessage, lang) => {
        return [
            getLanguageKey(wsError.invalidStatusChange, lang),
            getLanguageKey(wsError.invalidStatusChangeDetail, lang)(errorMessage, lang)
        ]
    }
}

export const getWsErrorCopy = (errorMessage, lang) => {
    if (errorTypes[errorMessage?.error_type]) return errorTypes[errorMessage?.error_type](errorMessage, lang)
    return [getLanguageKey(wsError.errorGeneric, lang), getLanguageKey(wsError.errorGenericDetail, lang)]
}
