import { useMemo } from "react"
import { FaCheckCircle } from "react-icons/fa"
import EntryUncompletedIcon from "@assets/entry-uncompleted-icon.svg"

export function useTableRowItemStatus(isCompleted: boolean) {
    return useMemo(() => ({
        text: isCompleted
            ? "Completed"
            : "Uncompleted",
        icon: isCompleted
            ? <FaCheckCircle className="text-glaut-entry-completed-icon h-[14px] w-[14px]" />
            : <img src={EntryUncompletedIcon} alt="Uncompleted" className="h-[14px] w-[14px]" />
    }), [isCompleted])
}