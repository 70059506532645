import React, { useContext, useMemo, useState } from "react"
import { LinkCallout } from "@components/values/Callouts"
import Interview from "../OutlineTabInterviewSettings"
import Conversation from "../../../../Conversation"
import { languages } from "@utils/Variables"
import { ProjectContext } from "../../../AutoSaveProject"
import ChooseLanguage from "@components/menus/ChooseLanguage"
import TranslateInterview from "../../../Outline/TranslateInto"
import { IProject } from "src/@types/project"
import Loading from "@components/loading/Loading"
import { isSuperuser } from "@utils/auth"
import { useAuthInfo } from "@propelauth/react"
import { IOrganization } from "src/@types/organization"

export default function OutlineTab() {
    // #region Contexts
    const { org, project, setProject } = useContext(ProjectContext) as {
        org: IOrganization | null
        project: IProject | null
        setProject: React.Dispatch<React.SetStateAction<IProject>>
    }
    // #endregion

    // #region Hooks
    const { user, userClass } = useAuthInfo()
    // #endregion

    // #region States
    const [lang, setLang] = useState(project?.general_settings?.language ?? "English")
    // #endregion

    // #region Memos
    const isLegacyReviews = useMemo(
        () => project !== null && "sources" in project && Array.isArray(project.sources)
            && project?.sources?.length > 1,
        [project]
    )
    const isEditableStatus = useMemo(
        () => (
            project?.source === "interview" && ["not_set", "set_up"].includes(project.status)
        ), [project]
    )
    const isInterviewAvailable = useMemo(
        () => (
            project?.source === "interview" && ["not_set", "test", "live"].includes(project.status)
        ), [project]
    )
    const isEditable = useMemo(
        () => (
            org && user && userClass && isEditableStatus && (
                isSuperuser(user) || userClass?.getOrg(org.org_id)?.isAtLeastRole("Member")
            )
        ), [isEditableStatus, org, user, userClass]
    )
    // #endregion

    // For retro compatibility
    if (isLegacyReviews) return <></>

    if (!project) return <Loading />

    return (
        <div className="horizontal-split h-full overflow-hidden">
            <div className="scroll h-full" style={{ maxWidth: "600px" }}>
                <Interview
                    project={project}
                    setProject={setProject}
                    lang={lang}
                    isEditable={isEditable}
                    setLang={setLang}
                />
            </div>
            <div className="column h-full">
                <div className="long-bar">
                    <LinkCallout
                        link={`${window.location.origin}/i/${project._id}?lang=${lang}`}
                        disabled={!isInterviewAvailable}
                    />
                    <ChooseLanguage
                        value={lang}
                        setValue={setLang}
                        setting={{ options: Object.keys(languages) }}
                    />
                    <TranslateInterview lang={lang} disabled={!isEditable} />
                </div>
                <div className="border-1 border-[#C1C1C1] rounded-[8px] h-full w-full">
                    <Conversation previewProject={project} language={lang} />
                </div>
            </div>
        </div>
    )
}
