import { useMemo } from "react"
import { FaEdit } from "react-icons/fa"
import { MdOutlineSettings, MdOutlineStopCircle, MdOutlineWifiTethering, MdRemoveRedEye } from "react-icons/md"
import { IProjectStatus } from "src/@types/project"

interface IProjectMetricsBoxStatusLabelProps {
    status: IProjectStatus
}

interface ILabelMappingValue {
    text: string
    icon: React.ReactNode
}

const labelMapping: Record<IProjectMetricsBoxStatusLabelProps["status"], ILabelMappingValue> = {
    not_set: {
        text: "Not set",
        icon: <MdOutlineSettings className="text-glaut-stroke-button w-3 h-3" />
    },
    set_up: {
        text: "Set up",
        icon: <FaEdit className="text-glaut-codes-blue w-3 h-3" />
    },
    ended: {
        text: "Ended",
        icon: <MdOutlineStopCircle className="text-glaut-codes-red w-3 h-3" />
    },
    live: {
        text: "Live",
        icon: <MdOutlineWifiTethering className="text-glaut-codes-green w-3 h-3" />
    },
    test: {
        text: "Test",
        icon: <MdRemoveRedEye className="text-glaut-codes-orange w-3 h-3" />
    }
} as const

export default function ProjectMetricsBoxStatusLabel({
    status
}: Readonly<IProjectMetricsBoxStatusLabelProps>) {
    // #region Memos
    const labelData = useMemo(() => labelMapping[status], [status])
    // #endregion

    return (
        <div className={`flex flex-col justify-center rounded-[0.5em] text-center p-[0.5em] min-w-40 h-full
            bg-glaut-cards 
        `}>
            <div className={`flex flex-row w-full items-center justify-center gap-2 rounded-[4px] h-7
                bg-glaut-cards 
            `}>
                {labelData.icon}
                <span className="text-glaut-dark-grey text-[13.3px]">{labelData.text}</span>
            </div>
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">Status</p>
        </div>
    )
}