import React, { useEffect } from "react"
import { BiTrash } from "react-icons/bi"

const EditEntity = ({ question, setQuestion, deleteOption, disabled = false }) => {
    useEffect(() => {
        // If the question is of type entity, add the entities list
        if (!question.entities) setQuestion(p => ({ ...p, entities: [] }))
    }, [])
    return (
        <div className="options">
            {question.entities?.map((o, idx) => (
                <div className="long-bar" key={idx}>
                    <span>{o.label}</span>
                    <BiTrash
                        className={["clickable danger", disabled ? "disabled" : ""].join(" ")}
                        onClick={() => !disabled && deleteOption(o.id, "entities")}
                    />
                </div>
            ))}
        </div>
    )
}

export default EditEntity
