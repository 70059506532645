import React, { useEffect, useState } from "react"
import { v4 as uuid } from "uuid"

import { Drop, RecursiveBucketDrag } from "../../menus/DragAndDrop"
import { DragDropContext } from "react-beautiful-dnd"
import { flatListToNested } from "../../../utils/nesting"

import Setting from "../../menus/Setting"

const insightSettings = [
    {
        slug: "fixed_tags",
        placeholder: "Fixing tags means Glaut will no longer look out for new tags",
        type: "bool"
    },
    {
        slug: "probing_instructions",
        type: "long-text",
        toggable: true,
        description: "Probe deeper with follow-ups (Optional)",
        placeholder: "Instruct the moderator which topics to follow-up on, i.e. follow up to further understand X and Y"
    }
]

const EditInsight = ({ question, setQuestion, deleteOption, scroll, disabled = false }) => {
    // Tree representation of this question's tag
    const [bucketsTree, setBucketsTree] = useState(flatListToNested(question.buckets || []))

    // Edits a bucket's name starting from its id
    const editBucket = (value, id) => {
        setQuestion(p => ({
            ...p,
            buckets: p.buckets.map(b => {
                if (b.id === id) return { ...b, name: value }
                return b
            })
        }))
    }

    // Adds a new bucket from the list
    const addBucket = (e, parent = "root") =>
        setQuestion(p => ({
            ...p,
            buckets: [...(p.buckets || []), { id: uuid(), name: "", parent }]
        }))

    // Toggle the fixed_tags setting, meaning wheater or not the tags are fixed (not accepting any new tag)
    const setFixedTags = value => setQuestion(q => ({ ...q, fixed_tags: value }))

    // We make tags fixed
    const fixTags = () => {
        setFixedTags(true)
        if (!question?.buckets?.lengths) addBucket()
    }

    const handleBucketDragEnd = result => {
        const { draggableId, source, destination } = result

        // Ignore if no destination is found
        if (!destination) return

        // Ignore if we did not change the location
        if (source.droppableId === destination.droppableId && source.index === destination.index) return

        // This bug might happen
        if (source.droppableId === draggableId) return

        // Now we create a new list by first deleting the original item
        const newList = question.buckets.filter(el => el.id !== draggableId)

        let currentIndex = -1
        const targetParent =
            destination.droppableId === "root" ? undefined : destination.droppableId

        // Then we need to find out where to add it.
        // The destination index doesn't take nesting into account, so we need to manage that manually
        for (let i = 0; i < newList.length; i++) {
            // if the parent is the same as destination droppable, we increment our index
            if ([newList[i].parent, newList[i].id].includes(targetParent)) currentIndex++

            // if the current index is the destination index and the parent is the same as the destination droppable
            if (currentIndex === destination.index) {
                newList.splice(i, 0, {
                    ...question.buckets.find(el => el.id === draggableId),
                    parent: targetParent
                })
                // Sets the flatList
                setQuestion(p => ({ ...p, buckets: newList }))
                break
            }
        }
    }

    // Creating nested list structure from flat list, adds bucket if there is no list
    useEffect(() => {
        if (!question.buckets) setQuestion(q => ({ ...q, buckets: [] }))

        setBucketsTree(flatListToNested(question.buckets))
    }, [question.buckets])

    return (
        <>
            {question.fixed_tags !== undefined
                ? (
                    <>
                        <div className="column" style={{ alignItems: "flex-start" }}>
                            {insightSettings.map(s => (
                                <Setting
                                    key={s.slug}
                                    setting={{ ...s, disabled }}
                                    value={question?.[s.slug]}
                                    setValue={v =>
                                        setQuestion(q => ({
                                            ...q,
                                            [s.slug]: v
                                        }))
                                    }
                                />
                            ))}
                        </div>

                        <div className="divider"></div>
                        <div style={{ width: "100%", position: "relative" }}>
                            <DragDropContext onDragEnd={handleBucketDragEnd}>
                                <Drop id="root" type="root">
                                    {bucketsTree?.map((b, idx) => (
                                        <RecursiveBucketDrag
                                            key={idx}
                                            bucket={b}
                                            editBucket={editBucket}
                                            addBucket={addBucket}
                                            deleteBucket={bId => deleteOption(bId, "buckets")}
                                            index={idx}
                                            parentScroll={scroll}
                                            disabled={disabled}
                                        />
                                    ))}
                                </Drop>
                            </DragDropContext>

                            <span
                                onClick={() => !disabled && addBucket()}
                                className={["clickable-text", disabled ? "disabled" : ""].join(" ")}
                                style={{ margin: "0 auto" }}
                                disabled={disabled}
                            >
                                + Add tag
                            </span>
                        </div>
                    </>
                )
                : (
                    <div className="horizontal-split" style={{ padding: 0 }}>
                        <div className="bordered clickable column" onClick={fixTags}>
                            <h3>I have an ontology</h3>
                        </div>
                        <div className="bordered clickable column" onClick={() => setFixedTags(false)}>
                            <h3>Let Glaut find tags</h3>
                        </div>
                    </div>
                )}
        </>
    )
}

export default EditInsight
