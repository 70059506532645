import { useRef } from "react"

const Button = ({ id, label, icon, reverse, onClick, disabled, className, acceptFile, handleFileChange }) => {
    const fileInputRef = useRef(null)

    if (acceptFile) return (<label
        className={"button " + (className || "primary")}
        disabled={disabled}
    >
        {reverse && icon}
        <input type="file" onChange={handleFileChange} ref={fileInputRef} accept={acceptFile} />
        <span>{label}</span>
        {!reverse && icon}
    </label>)

    return (<button
        id={id}
        className={className || "primary"}
        onClick={onClick}
        disabled={disabled}
    >
        {reverse && icon}
        <span className="font-medium">{label}</span>
        {!reverse && icon}
    </button>)
}

export default Button
