import React, { useContext, useEffect, useMemo, useRef, useState } from "react"

import { ProjectContext } from "../../../pages/Project/AutoSaveProject"

import { questionTypes } from "../../../utils/Variables"

import Popup from "../Popup"
import { QuestionFilters } from "./QuestionFilter"
import EditMultipleChoice from "./EditMultipleChoice"
import EditInsight from "./EditInsights"
import EditEntity from "./EditEntity"
import ImageInput from "../../inputs/ImageInput"
import { normalizeQuestionOrOption } from "../../../utils/strings"
import { useEditQuestion } from "../../../hooks/useEditQuestion"

const selectQuestionType = [
    {
        slug: "insight",
        title: "💡 Insight",
        description: "Ask an open-ended question, and identify emerging themes."
    },
    {
        slug: "select",
        title: "🗳️ Multiple choice",
        description: "Ask a close-ended question with multiple options"
    },
    {
        slug: "entity",
        title: "👤 Entities",
        description: "Collect cleaned data on of people, organizations, and products"
    },
    {
        slug: "short_text",
        title: "✍️ Custom field",
        description: "Collect the interviewees' responses as they were expressed"
    },
    {
        slug: "number",
        title: "🔢 Number",
        description: "Collect numerical data"
    },
    {
        slug: "nps",
        title: "⭐ NPS",
        description: "Measure customer loyalty"
    }
]

const EditQuestionPopup = ({ originalQuestion, close, setOriginalQuestion, isNew, lang, disabled = false }) => {
    const [question, setQuestion] = useState(originalQuestion)
    const [showAdvanced, setShowAdvanced] = useState(false)

    const { project } = useContext(ProjectContext)
    const projectLang = useMemo(() => lang || project.general_settings.language || "en", [project])

    const parentRef = useRef(null)
    const textAreaRef = useRef(null)

    // State to hold scroll value
    const [scroll, setScroll] = useState(0)

    const { valid } = useEditQuestion(question, lang)

    // Add event listener for scroll event
    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => setScroll(parentRef.current.scrollTop)

        const parent = parentRef.current
        parent.addEventListener("scroll", handleScroll)

        // Clean up listener on unmount
        return () => {
            parent.removeEventListener("scroll", handleScroll)
        }
    }, [])

    const submit = () => {
        // to aviod problems with the delays using setState
        const savedQuestion = question
        // Delete empty buckets
        if (question.type === questionTypes.insight)
            savedQuestion.buckets = savedQuestion.buckets.filter(b => b.name)


        // Delete empty options
        if ([questionTypes.multipleChoice, questionTypes.select].includes(question.type))
            savedQuestion.buckets = savedQuestion.options.filter(b => b.label[projectLang])


        // Delete invalid filters
        if (question.filters)
            savedQuestion.filters = savedQuestion.filters.filter(f => f.id && f.operator && f.value)


        setOriginalQuestion(savedQuestion)
        close()
    }

    const deleteSelf = () => {
        // Settig it to null means deleting it (if already present)
        if (!isNew) setOriginalQuestion(null)
        close()
    }

    const setImage = img => setQuestion(q => ({ ...q, img }))

    const deleteOption = (optionId, key = "options") =>
        setQuestion(p => ({ ...p, [key]: p[key].filter(o => o.id !== optionId) }))

    // Auto resizes the question textbox
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto" // Reset height to auto before resizing
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
        }
    }, [question.value, textAreaRef.current])

    const editQuestion = (e, normalize) =>
        setQuestion(q => ({
            ...q,
            value: {
                ...q.value,
                [projectLang]: normalize ? normalizeQuestionOrOption(e.target.value) : e.target.value
            }
        }))

    return (
        <Popup close={close} popupRef={parentRef}>
            {question.type && (
                <>
                    <div className="long-bar">
                        <span className="tag bordered">
                            {selectQuestionType.find(t => t.slug === question.type)?.title}
                        </span>
                        <span className="clickable-text" onClick={() => setShowAdvanced(s => !s)}>
                            {showAdvanced ? "hide" : "show"} advanced settings
                        </span>
                    </div>
                    {showAdvanced && (
                        <>
                            <input
                                value={question.header}
                                onChange={e => setQuestion(q => ({ ...q, header: e.target.value }))}
                                placeholder="Column name"
                                disabled={disabled}
                            />
                            {question.img ? (
                                <>
                                    <img
                                        src={question.img}
                                        style={{
                                            width: "100%",
                                            borderRadius: "var(--border-radius)"
                                        }}
                                    />
                                    <button
                                        className={["secondary-button danger", disabled ? "disabled" : ""].join(" ")}
                                        onClick={() => !disabled && setImage(null)}
                                    >
                                        Clear
                                    </button>
                                </>
                            ) : (
                                <ImageInput imageId={question.id} setUrl={setImage} disabled={disabled} />
                            )}
                            <QuestionFilters
                                question={question}
                                setQuestion={setQuestion}
                                lang={lang || projectLang}
                                disabled={disabled}
                            />
                        </>
                    )}
                    <textarea
                        placeholder="Write you question here"
                        className="borderless-input"
                        style={{ fontSize: "large", flex: "0 0 auto" }}
                        ref={textAreaRef}
                        value={question.value?.[projectLang]}
                        onChange={editQuestion}
                        onBlur={e => { editQuestion(e, true) }}
                        disabled={disabled}
                    />
                </>
            )}
            {!question.type && (
                <div className="column">
                    <h2>Choose the type for this question</h2>
                    {selectQuestionType.map(o => (
                        <div
                            key={o.slug}
                            onClick={() => setQuestion(p => ({ ...p, type: o.slug }))}
                            className="column clickable"
                            style={{ alignItems: "flex-start", gap: "0.5em", width: "100%" }}
                        >
                            <h3>{o.title}</h3>
                            <span>{o.description}</span>
                        </div>
                    ))}
                </div>
            )}
            {[questionTypes.multipleChoice, questionTypes.select].includes(question.type) && (
                <EditMultipleChoice
                    question={question}
                    setQuestion={setQuestion}
                    deleteOption={deleteOption}
                    scroll={scroll}
                    lang={projectLang}
                    disabled={disabled}
                />
            )}
            {question.type === questionTypes.insight && (
                <EditInsight
                    question={question}
                    setQuestion={setQuestion}
                    scroll={scroll}
                    deleteOption={deleteOption}
                    disabled={disabled}
                />
            )}
            {question.type === questionTypes.entity && (
                <EditEntity
                    question={question}
                    setQuestion={setQuestion}
                    deleteOption={deleteOption}
                    disabled={disabled}
                />
            )}
            {question?.source && (
                <p className="bordered">
                    This question is being asked relatiave to the column:{" "}
                    <b>{project.schema.find(q => q.id === question?.source)?.header}</b>
                </p>
            )}
            <div
                className="long-bar"
                style={{ justifyContent: "space-between", paddingTop: "1em" }}
            >
                <button
                    className={["danger secondary-button", disabled ? "disabled" : ""].join(" ")}
                    onClick={() => !disabled && deleteSelf()}
                    disabled={disabled}>
                    {isNew ? "Cancel" : "Delete question"}
                </button>
                <button
                    className={["accent-button", disabled ? "disabled" : ""].join(" ")}
                    style={{ flexGrow: 1 }}
                    onClick={() => !disabled && submit()}
                    disabled={disabled || !valid}
                >
                    {isNew ? "Create" : "Save"}
                </button>
            </div>
        </Popup>
    )
}

export default EditQuestionPopup
