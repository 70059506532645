import React from "react"

const NPS = ({ value, setSelected }) => (
    <div className="long-bar" style={{ justifyContent: "space-around" }}>
        <div className="labeled-number clickable" onClick={() => setSelected(7, "lt")}>
            <span>{value.promoters?.toFixed()}%</span>
            <span>Promoters</span>
        </div>
        <div className="labeled-number clickable" onClick={() => setSelected(8, "gt")}>
            <span>{value.detractors?.toFixed(0)}%</span>
            <span>Detractors</span>
        </div>
        <div className="labeled-number">
            <span>{value.nps?.toFixed(0)}%</span>
            <span>NPS</span>
        </div>
    </div>
)

export default NPS
