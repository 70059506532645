export const flatListToNested = flatList => {
    if (!flatList?.length) return []

    const roots = []
    const childrenByParentId = {}

    flatList.forEach(item => {
        if (!item.parent || item.parent === "root") {
            roots.push(item)
        } else {
            if (!childrenByParentId[item.parent]) 
                childrenByParentId[item.parent] = []
            

            childrenByParentId[item.parent].push(item)
        }
    })

    let currentIdx = 0

    const buildTree = nodes => {
        if (!nodes) 
            return []
        

        return nodes.map(item => ({
            ...item,
            title: item.name,
            index: currentIdx++,
            children: buildTree(childrenByParentId[item.id])
        }))
    }

    return buildTree(roots)
}

export const getAllDescendants = (options, id) => {
    let descendants = options.filter(option => option.parent === id)

    // Recursively searches for descendants
    for (const option of descendants) descendants = descendants.concat(getAllDescendants(options, option.id))
    
    return descendants
}

export const getAllDescendantsIds = (options, id) => getAllDescendants(options, id).map(o => o.id)

export const getAllChildIds = (targetId, nodes) => {
    const ids = []

    // Recursive function to traverse the data
    const traverseData = node => {
        // Add the current node's ID
        ids.push(node.id)

        // If there are children, traverses them
        if (node.children) node.children.forEach(child => traverseData(child))
    }

    const findAndTraverse = (targetId, nodes) => {
        for (const node of nodes) {

            // The target node is top level, so traverses it to return the list of selected children
            if (node.id === targetId) {
                traverseData(node)
                break
            }

            // If the target Id wasn't the top level node, searches among the children
            if (node.children && node.children.length > 0) findAndTraverse(targetId, node.children)
        }
    }

    findAndTraverse(targetId, nodes)

    return ids
}

