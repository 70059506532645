import { copy, getCopy } from "../utils/Copy"
import ErrorLevel from "../utils/ErrorLevel"
import Exclamation from "../assets/exclamation.png"
import GlautLogo from "../assets/GlautLogo.png"
import Cloud from "../assets/cloud.svg"
import Separator from "../assets/separator.png"
import "../styling/Error.scss"


const defaultProps = {
    message: null,
    detail: null,
    showCode: true,
    errorLevel: ErrorLevel.Warning,
    icon: null,
    iconExtraClass: "",
    separatorExtraClass: ""
}

const ErrorPage = ({
    lang,
    code,
    message,
    detail,
    showCode,
    errorLevel,
    icon,
    iconExtraClass,
    separatorExtraClass
}) => {
    message = message || getCopy(copy[`error${code}`], lang) || getCopy(copy.errorGeneric, lang)
    detail = detail || getCopy(copy[`error${code}Detail`], lang) || getCopy(copy.errorGenericDetail, lang)
    icon = icon || errorLevel >= ErrorLevel.Warning ? (errorLevel >= ErrorLevel.Error ? Exclamation : Cloud) : GlautLogo

    return (
        <div
            className="error-page"
        >
            <div
                className="error-message"
            >
                <img className={"error-icon " + iconExtraClass} src={icon} />
                <h2>{showCode && code ? code + ": " : ""}{ message }</h2>
                <img className={"separator " + separatorExtraClass} src={Separator} />
                <div className="detail">{ detail }</div>
            </div>
        </div>
    )
}
ErrorPage.defaultProps = defaultProps

export default ErrorPage
