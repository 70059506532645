import { isMobile } from "../device"

const onClickPrivacyNotice = (e, url) => {
    e.preventDefault()
    const width = 800;
    const height = 600;
    const left = window.screen.width - width;
    const top = 0;
    window.open(url, 'newwindow', `width=${width},height=${height},left=${left},top=${top}`);
}

export const interview = {
    interview: {
        welcome: {
            en: "Welcome, and thank you for joining this interview!\n\nYou can speak naturally through your microphone as if talking with a real person or switch to typing whenever you prefer. To use the microphone, please make sure to grant your browser access to it when requested.\n\n",
            it: "Benvenuto e grazie per aver partecipato a questa intervista!\n\nPuoi parlare liberamente nel microfono come se stessi parlando con una persona reale o passare a digitare quando preferisci. Per utilizzare il microfono, assicurati di concedere l'accesso al tuo browser quando richiesto.\n\n",
            es: "¡Bienvenido y gracias por unirte a esta entrevista!\n\nPuedes hablar libremente a través de tu micrófono como si estuvieras hablando con una persona real o cambiar a escribir cuando prefieras. Para usar el micrófono, asegúrate de conceder acceso a tu navegador cuando se te solicite.\n\n",
            fr: "Bienvenue et merci de participer à cette interview !\n\nVous pouvez parler librement dans votre microphone comme si vous parliez à une vraie personne ou passer à la saisie au clavier quand vous le préférez. Pour utiliser le microphone, assurez-vous d'accorder l'accès à votre navigateur lorsque cela vous est demandé.\n\n",
            de: "Willkommen und vielen Dank, dass Sie an diesem Interview teilnehmen!\n\nSie können frei in Ihr Mikrofon sprechen, als würden Sie mit einer echten Person sprechen, oder jederzeit zur Eingabe wechseln. Um das Mikrofon zu verwenden, stellen Sie bitte sicher, dass Sie Ihrem Browser den Zugriff darauf gewähren, wenn Sie dazu aufgefordert werden.\n\n"
        },
        start: {
            en: "Start!",
            it: "Cominciamo!",
            es: "¡Empezamos!",
            fr: "Commencer!",
            de: "Starten!"
        },
        privacyNoticeWithOrgName: {
            en: (orgName) => `Your responses are confidential and only shared with\n${orgName}.`,
            it: (orgName) => `Le tue risposte sono riservate e condivise solo con\n${orgName}.`,
            es: (orgName) => `Sus respuestas son confidenciales y sólo se comparten con\n${orgName}.`,
            fr: (orgName) => `Vos réponses sont confidentielles et partagées uniquement avec\n${orgName}.`,
            de: (orgName) => `Ihre Antworten sind vertraulich und werden nur an\n${orgName} weitergegeben.`
        },
        privacyNoticeAcceptanceWithOrgName: {
            en: (orgName, privacyPolicyUrl) => <>
                I accept {orgName}{"'"}s use of my data and
                everything{"\n"}else described in their <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    target="_blank"
                    className="text-glaut-codes-blue font-medium">
                    privacy policy
                </a>.
            </>,
            it: (orgName, privacyPolicyUrl) => <>
                Accetto l'utilizzo dei miei dati da parte di {orgName}
                e{"\n"}tutto quanto descritto nella loro <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    informativa sulla privacy
                </a>.
            </>,
            es: (orgName, privacyPolicyUrl) => <>
                Acepto el uso de mis datos por parte de {orgName}
                y{"\n"}todo lo descrito en su <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    política de privacidad
                </a>.
            </>,
            fr: (orgName, privacyPolicyUrl) => <>
                J'accepte l'utilisation de mes données par {orgName} et
                tout{"\n"}ce qui est décrit dans leur <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    politique de confidentialité
                </a>.
            </>,
            de: (orgName, privacyPolicyUrl) => <>
                Ich akzeptiere die Verwendung meiner Daten durch
                {orgName}{"\n"}und alles Weitere, was in ihrer <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    Datenschutzrichtlinie
                </a> beschrieben ist.
            </>
        },
        privacyNoticeWithUrlAndOrgName: {
            en: (orgName, privacyPolicyUrl) => <>
                Your responses are confidential and only shared with{"\n"}
                {orgName}. See their <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    privacy policy
                </a>.
            </>,
            it: (orgName, privacyPolicyUrl) => <>
                Le tue risposte sono riservate e condivise solo con{"\n"}
                {orgName}. Consulta la loro <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    politica sulla privacy
                </a>.
            </>,
            es: (orgName, privacyPolicyUrl) => <>
                Sus respuestas son confidenciales y solo se comparten con{"\n"}
                {orgName}. Consulte su <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    política de privacidad
                </a>.
            </>,
            fr: (orgName, privacyPolicyUrl) => <>
                Vos réponses sont confidentielles et ne seront partagées qu'avec{"\n"}
                {orgName}. Voir leur <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    politique de confidentialité
                </a>.
            </>,
            de: (orgName, privacyPolicyUrl) => <>
                Ihre Antworten sind vertraulich und werden nur an{"\n"}
                {orgName} weitergegeben. Siehe deren <a
                    href="#"
                    onClick={(e) => onClickPrivacyNotice(e, privacyPolicyUrl)}
                    className="text-glaut-codes-blue font-medium">
                    Datenschutzrichtlinie
                </a>.
            </>
        },
        conversationEnded: {
            en: "Conversation ended",
            it: "Conversazione terminata",
            es: "Conversación terminada",
            fr: "Fin de la conversation",
            de: "Gespräch beendet"
        },
        typeHere: {
            en: "Write your answer here",
            it: "Scrivi qui la tua risposta",
            es: "Escribe tu respuesta aquí",
            fr: "Écrivez votre réponse ici",
            de: "Schreiben Sie hier Ihre Antwort"
        },
        typeMailHere: {
            en: "Type your email",
            ir: "Digita la tua mail",
            es: "Escriba su correo electrónico",
            fr: "Tapez votre email",
            de: "Geben Sie Ihre E-Mail ein"
        },
        provideEmail: {
            en: "Before starting, please provide your email:",
            it: "Prima di cominciare, inserisci la tua email:",
            es: "Antes de empezar, indíquenos su dirección de correo electrónico:",
            fr: "Avant de commencer, veuillez indiquer votre adresse électronique:",
            de: "Bevor Sie beginnen, geben Sie bitte Ihre E-Mail-Adresse an:"
        },
        optionalQuestion: {
            en: "This question is optional",
            it: "Questa domanda è opzionale",
            es: "Esta pregunta es opcional",
            fr: "Cette question est facultative",
            de: "Diese Frage ist fakultativ"
        },
        rateGlaut: {
            en: "How would you evaluate your experience taking this survey compared to traditional surveys you have taken before?",
            it: "Come valuteresti questo questionario rispetto ai questionari tradizionali che hai completato in passato?",
            es: "¿Cómo evaluaría su experiencia al realizar esta encuesta en comparación con las encuestas tradicionales que ha realizado anteriormente?",
            fr: "Comment évaluez-vous votre expérience de cette enquête par rapport aux enquêtes traditionnelles auxquelles vous avez participé auparavant ?",
            de: "Wie würden Sie Ihre Erfahrungen mit dieser Umfrage im Vergleich zu anderen Umfragen, an denen Sie bisher teilgenommen haben, bewerten?"
        },
        ratingOptions: {
            en: {
                better: "I liked it better",
                equal: "It was the same",
                worse: "I liked it less"
            },
            it: {
                better: "Mi è piaciuto di più",
                equal: "Mi è stato indifferente",
                worse: "Mi è piaciuto di meno"
            },
            es: {
                better: "Me gustó más",
                equal: "Era igual",
                worse: "Me gustó menos"
            },
            fr: {
                better: "J'ai préféré",
                equal: "C'était pareil",
                worse: "J'ai moins aimé"
            },
            de: {
                better: "Es hat mir besser gefallen",
                equal: "Es war das Gleiche",
                worse: "Es hat mir weniger gefallen"
            }
        },
        recording: {
            en: "Recording...",
            it: "Registrazione in corso...",
            es: "Grabación...",
            fr: "Enregistrement en cours...",
            de: "Aufnahme läuft..."
        },
        recordingStopTooltip: {
            en: isMobile() ? "(Press Stop ◻️ when you are done)" : "(Click Stop ◻️ when you are done)",
            it: isMobile() ? "(Premi Stop ◻️ quando hai finito)" : "(Clicca su Stop ◻️ quando hai finito)",
            es: isMobile() ? "(Presiona Detener ◻️ cuando hayas terminado)" : "(Haz clic en Detener ◻️ cuando hayas terminado)",
            fr: isMobile() ? "(Appuyez sur Stop ◻️ lorsque vous avez terminé)" : "(Cliquez sur Stop ◻️ lorsque vous avez terminé)",
            de: isMobile() ? "(Drücken Sie Stop ◻️, wenn Sie fertig sind)" : "(Klicken Sie auf Stop ◻️, wenn Sie fertig sind)"
        },
        recordingStartTooltip: {
            en: "Press to record your answer",
            it: "Premi per registrare la tua risposta",
            es: "Presione para grabar su respuesta",
            fr: "Appuyez pour enregistrer votre réponse",
            de: "Drücken Sie , um Ihre Antwort aufzuzeichnen"
        },
        introInfoMessage: {
            en: "Your answers are securely stored and accessible only to the Researcher.",
            it: "Le tue risposte sono sicure e condivise solo con l'intervistatore.",
            es: "Sus respuestas son seguras y se comparten solo con el entrevistador.",
            fr: "Vos réponses sont sécurisées et partagées uniquement avec l'interviewer.",
            de: "Ihre Antworten sind sicher und werden nur mit dem Interviewer geteilt."
        },
        minutes: {
            en: "min",
            it: "min",
            es: "min",
            fr: "min",
            de: "inu"
        },
        selectOption: {
            en: n => !n || n == 1 ? "Select an option:" : `Choose at maximum ${n} options:`,
            it: n => !n || n == 1 ? "Seleziona un'opzione:" : `Seleziona fino a ${n} opzioni:`,
            es: n => !n || n == 1 ? "Elige una opción:" : `Seleccione hasta ${n} opciones:`,
            fr: n => !n || n == 1 ? "Sélectionnez une option:" : `Sélectionnez jusqu'à ${n} options:`,
            de: n => !n || n == 1 ? "Wählen Sie eine Option aus:" : `Wählen Sie bis zu ${n} Optionen aus:`
        },
        disableModeratorVoice: {
            en: "Disable the moderator voice",
            it: "Disabilita la voce del moderatore",
            es: "Desactivar la voz del moderador",
            fr: "Désactiver la voix du modérateur",
            de: "Moderatorstimme deaktivieren"
        },
        enableModeratorVoice: {
            en: "Enable the moderator voice",
            it: "Abilita la voce del moderatore",
            es: "Habilitar la voz del moderador",
            fr: "Activer la voix du modérateur",
            de: "Moderatorstimme aktivieren"
        },
        activateKeyboardMode: {
            en: "Click here to type your answer",
            it: "Clicca qui per digitare la tua risposta",
            es: "Haga clic aquí para escribir su respuesta",
            fr: "Cliquez ici pour saisir votre réponse",
            de: "Klicken Sie hier, um Ihre Antwort einzugeben"
        },
        activateMicrophoneMode: {
            en: "Click here to use the microphone",
            it: "Clicca qui per utilizzare il microfono",
            es: "Haga clic aquí para usar el micrófono",
            fr: "Cliquez ici pour utiliser le microphone",
            de: "Klicken Sie hier, um das Mikrofon zu verwenden"
        }
    }
}