import { IEntityAnalysis, IInsightAnalysis, ISelectAnalysis } from "src/@types/analysis"
import { IQuestionEntityEntity, IQuestionInsightBucket, IQuestionSelectOption } from "src/@types/question"

/**
 * Normalizes a bucket/option/entity to a structured object.
 */
export function normalizeCode(
    code: IQuestionInsightBucket | IQuestionEntityEntity | IQuestionSelectOption | undefined,
    index: number,
    analysisId: string,
    analysisResult: IInsightAnalysis | ISelectAnalysis | IEntityAnalysis | undefined,
    parentIndex?: number
) {
    return {
        ...(code ?? {}),
        id: code?.id ?? analysisResult?.id,
        analysisId,
        parent: (code && "parent" in code && code?.parent) || "root",
        label: ((typeof analysisResult?.label === "string" && analysisResult?.label) ||
            (code && (
                ("label" in code && code?.label) ||
                ("name" in code && code?.name)
            ))
        ) ?? "",
        description: (code && "description" in code) ? (code.description) : undefined,
        order: (parentIndex ?? index) * 10,
        colorIndex: (code && "colorIndex" in code && code?.colorIndex as number) || ((parentIndex ?? index) % 10) + 1,
        value: analysisResult?.value,
        occurrences: analysisResult?.occurrences,
        subcodes: [] as ReturnType<typeof normalizeCode>[],
        canDelete: true,
        canRename: true,
        canMergeWith: false,
        canCreateSubtheme: false
    }
}