import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { IoStopSharp } from "react-icons/io5"
import { MdMicNone } from "react-icons/md"
import { Tooltip } from "react-tooltip"
import "webrtc-adapter"

import axios from "axios"
import { useParams } from "react-router-dom"
import Loading from "../../components/loading/Loading"
import { BASE_URL } from "../../hooks/useAxiosAuth"
import "../../styling/Microphone.scss"
import { copy, getCopy } from "../../utils/Copy"
import { useConversation } from "./Interview/contexts/ConversationProvider"
import { useToast } from "@contexts/ToastProvider"
import ErrorLevel from "../../utils/ErrorLevel"

const mimeType = "audio/webm;codec=opus"

/**
 * @param {Object} props
 * @param {string} props.lang
 * @param {boolean | null} props.isPlaying
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsRecording
 * @param {boolean} props.isRecording
 * @param {boolean} props.isTheFirstOpenQuestion
 * @param {React.Ref<HTMLIFrameElement> | undefined} props.embedVideoRef
 * @param {React.Dispatch<React.SetStateAction<boolean | null>>} props.setIsPlaying
 */
const AudioRecorder = ({
    lang,
    isPlaying,
    setIsRecording,
    isRecording,
    isTheFirstOpenQuestion,
    embedVideoRef,
    setIsPlaying,
    setTopBarMessage,
    setMessages,
    lastMessage,
    color
}) => {
    // #region Params
    const { projectId } = useParams()
    // #endregion

    // #region Contexts
    const { LKroom, isWebRTC } = useConversation()
    // #endregion

    // #region Toasts
    const { addToast } = useToast()
    // #endregion

    // #region Refs

    const recordingStartTime = useRef()

    const chunks = useRef([])
    const mediaRecorderRef = useRef(null)
    const recordingRef = useRef(false)

    // #endregion

    // #region States
    const [loadingTranscription, setLoadingTranscription] = useState(false)
    // #endregion

    // #region Callbacks
    const getRecordingLength = () => {
        const endTime = new Date()
        return (endTime - recordingStartTime.current) / 1000
    }

    // Formats the recording bytes and sends it via websocket
    const sendRecordingWS = useCallback(audioBlob => {
        // Waiting for the transcription
        setLoadingTranscription(true)

        // Create a FormData object, containing recording in .webm format and its length
        const formData = new FormData()
        formData.append("recording", audioBlob)
        formData.append("duration", getRecordingLength())
        formData.append("context", lastMessage?.content.replace(/<(.+?)>/g, ""))
        formData.append("lang", lang)

        axios
            .post(`${BASE_URL}/interviews/${projectId}/transcribe`, formData)
            .then(res => {
                setMessages(ms => [...ms, res.data])
                setLoadingTranscription(false)
            })
            .catch(() => {
                setLoadingTranscription(false)
                setTopBarMessage(getCopy(copy.errorTranscription, lang))
            })
    }, [lang, projectId, setMessages, setTopBarMessage])

    const startRecordingLK = useCallback(() => {
        if (!LKroom) return

        // Starts streaming the voice trough LiveKit
        LKroom.localParticipant.setMicrophoneEnabled(true)
        setIsRecording(true)
    }, [LKroom, setIsRecording])

    const startRecordingWS = useCallback(() => {
        if (!navigator?.mediaDevices?.getUserMedia) {
            addToast("Error", "Error accessing the microphone", ErrorLevel.Error, 3000)
            return
        }

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(stream => {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)()
                const source = audioContext.createMediaStreamSource(stream)
                const analyser = audioContext.createAnalyser()
                analyser.fftSize = 2048

                source.connect(analyser)

                const mediaRecorder = new MediaRecorder(stream)
                chunks.current = []

                mediaRecorder.ondataavailable = e => {
                    chunks.current.push(e.data)
                }

                // Updates state
                setIsRecording(true)

                mediaRecorder.onstop = () => {
                    // undefined means we reset it
                    if (recordingRef.current === undefined) return

                    // Updates state
                    setIsRecording(false)

                    const blob = new Blob(chunks.current, { type: mimeType })

                    sendRecordingWS(blob)
                }

                // Starts the recording and forces a re-render
                mediaRecorderRef.current = mediaRecorder
                mediaRecorder.start()
                recordingRef.current = true
            })
            .catch(err => {
                console.log(err)
                alert("Error accessing the microphone ", err)
            })
    }, [addToast, sendRecordingWS, setIsRecording])

    const startRecording = useCallback(() => {
        recordingStartTime.current = new Date()

        if (embedVideoRef?.current)
            // @TODO: it only works for YouTube. Needs to handle also other type of videos
            embedVideoRef.current.contentWindow.postMessage(
                JSON.stringify({
                    event: "command",
                    func: "pauseVideo"
                }),
                "*"
            )

        if (isWebRTC) {
            startRecordingLK()
            return
        }

        startRecordingWS()
    }, [embedVideoRef, isWebRTC, startRecordingWS, startRecordingLK])

    const stopRecording = useCallback(() => {
        if (getRecordingLength() < 1) return

        setTimeout(() => {
            if (isWebRTC) {
                // Mutes the microphone, triggering a response
                LKroom.localParticipant.setMicrophoneEnabled(false)
                setIsRecording(false)
            } else {
                recordingRef.current = false
                if (mediaRecorderRef.current?.state === "recording") mediaRecorderRef.current.stop()
            }
        }, 500)

        // for disabling the microphone icon after sending the recording
        setIsPlaying(null)
    }, [LKroom, isWebRTC, setIsPlaying, setIsRecording])

    const resetRecordingWS = useCallback(() => {
        // Stop recording if it's ongoing
        if (isRecording) mediaRecorderRef.current.stop()

        // Clear the recorded chunks
        chunks.current = []

        // Reset relevant state and refs
        recordingRef.current = undefined
        recordingStartTime.current = null

        // Stop and release the media stream
        if (mediaRecorderRef.current && mediaRecorderRef.current.stream)
            mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop())
    }, [isRecording])
    // #endregion

    // #region Effects

    // onVoicePlayingForceStopRecording
    useEffect(() => {
        if (isWebRTC) return

        // If for some reason this happens, stop the recording (the voice always wins)
        if (isPlaying && mediaRecorderRef.current?.state === "recording") resetRecordingWS()
    }, [isWebRTC, isPlaying, resetRecordingWS])

    // onPressSpacebarToggleRecording
    useEffect(() => {
        if (isWebRTC) return

        const listenForSpacebar = e => {
            // 32 is the keyCode for space bar
            if (e.keyCode === 32 && !isPlaying && lastMessage.role === "assistant")
                recordingRef.current ? stopRecording() : startRecording()
        }

        // Add event listener
        window.addEventListener("keydown", listenForSpacebar)

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("keydown", listenForSpacebar)
        }
    }, [isWebRTC, isPlaying, lastMessage, stopRecording, startRecording])

    // #endregion

    // #region Element memos
    const controlElements = useMemo(() => {
        if (isRecording)
            return (
                <button
                    data-tooltip-id="recording-tooltip"
                    onClick={stopRecording}
                    className={`recording square blink2 border-none rounded-[1.75em] w-[23.3vw] max-w-[96px] z-50
                            aspect-square bg-glaut-codes-pink mb-[0.75em] shadow-[0_0_8px_0_rgba(0,0,0,0.15)]`}
                >
                    <IoStopSharp className="w-[36px] h-[36px] text-white" />
                </button>
            )

        if (!isWebRTC && loadingTranscription)
            return (
                <Loading color={color} />
            )

        return (
            <button
                data-tooltip-id="microphone-tooltip"
                data-tooltip-place="top"
                onClick={startRecording}
                disabled={isPlaying || isPlaying === null}
                className={`microphone square blink border-none rounded-[1.75em] w-[23.3vw] max-w-[96px]
                            aspect-square mb-[0.75em] shadow-[0_0_8px_0_rgba(0,0,0,0.15)] group
                            disabled:bg-glaut-cards disabled:border-none z-50`}
            >
                <MdMicNone
                    className={`w-[36px] h-[36px] text-glaut-text-midnight
                                group-hover:text-glaut-pink 
                                group-disabled:text-glaut-light-grey group-hover:group-disabled:text-glaut-light-grey
                            `}
                />
            </button>
        )
    }, [isRecording, isWebRTC, loadingTranscription, startRecording, isPlaying, stopRecording, color])
    // #endregion

    return (
        <>
            {controlElements}
            <Tooltip
                id="microphone-tooltip"
                place="top"
                isOpen={isPlaying === false && !isRecording && !loadingTranscription && isTheFirstOpenQuestion}
                content={getCopy(copy.interview.recordingStartTooltip, lang)}
                className="z-50"
            />
            <Tooltip
                id="recording-tooltip"
                place="top"
                isOpen={isPlaying === false && isRecording && !loadingTranscription && isTheFirstOpenQuestion}
                content={<div>
                    <h2>{getCopy(copy.interview.recording, lang)}</h2>
                    {getCopy(copy.interview.recordingStopTooltip, lang)}
                </div>}
                className="z-50"
            />
        </>
    )
}

export default AudioRecorder
