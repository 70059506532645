interface IProjectMetricsBoxEntryTextProps {
    value: string
    description: string
}

export default function ProjectMetricsBoxTextEntry({
    description,
    value
}: Readonly<IProjectMetricsBoxEntryTextProps>) {
    return (
        <div className={`flex flex-col justify-center rounded-[0.5em] text-center p-[0.5em] flex-1 h-full
            bg-glaut-cards 
        `}>
            <p className={`
                ${value !== "Not available" ? "text-[19.2px] text-glaut-text-midnight" : ""}
                ${value === "Not available" ? "text-[13.33px] leading-[28.8px] text-glaut-light-grey" : ""}
            `}>
                {value}
            </p>
            <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                {description}
            </p>
        </div>
    )
}