import React, { useState, useRef } from "react"
import FloatingMenu from "../layouts/FloatingMenu"
import { useWebSocket } from "../../hooks/useWebSocket"
import { FaEdit } from "react-icons/fa"
import { LuRadio } from "react-icons/lu"
import { MdSettings, MdOutlineStopCircle, MdRemoveRedEye } from "react-icons/md"
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri"

import "../../styling/ProjectStatus.scss"

const projectStatusMap = {
    set_up: "Set up",
    test: "Test",
    live: "Live",
    ended: "Ended",
    not_set: "Not set"
}

const projectStatusTargetMap = {
    set_up: ["test", "live"],
    test: ["set_up", "live"],
    live: ["ended"],
    ended: [],
    not_set: ["set_up", "test", "live", "ended"]
}
const projectStatusIconMap = {
    set_up: <FaEdit className="icon"/>,
    test: <MdRemoveRedEye className="icon"/>,
    live: <LuRadio className="icon"/>,
    ended: <MdOutlineStopCircle className="icon"/>,
    not_set: <MdSettings className="icon"/>
}

// interface IProjectStatusMenuProps {
//     project: {
//         _id: string
//         name: string
//         status: string
//     }
//     anchorRef: React.RefObject<HTMLDivElement>
//     onClose: () => void
// }

export const ProjectStatusMenu = ({
    project,
    anchorRef,
    onClose
}) => {
    const { sendMessage } = useWebSocket()
    const availableStatus = projectStatusTargetMap[project.status || "not_set"]
    const elements = ["set_up", "test", "live", "ended"].map(s => 
        ({
            icon: projectStatusIconMap[s],
            text: projectStatusMap[s],
            onClick: availableStatus.includes(s) ? () => sendMessage({
                operation: "update_project_status",
                status: s
            }) : null,
            class: s
        })
    )

    return <FloatingMenu
        title="Move to"
        anchorRef={anchorRef}
        onClose={onClose}
        elements={elements}
        className="project-status-menu"
        boundingBoxOffset={30}
    />
}

// interface IProjectStatusProps {
//     project: {
//         _id: string
//         name: string
//         status: string
//     }
//     editable: boolean
// }

const ProjectStatus = ({project, editable}) => {
    const projectStatusRef = useRef(null)
    const [menuOpen, setMenuOpen] = useState(false)

    const status = project.status || "not_set"

    return (
        <>
            <div
                ref={projectStatusRef}
                className={[
                    "project-status",
                    status,
                    editable ? "editable" : ""
                ].join(" ")}
                onClick={() => editable && setMenuOpen(!menuOpen)}
            >
                {projectStatusIconMap[status]}
                {projectStatusMap[status]}
                {editable && (
                    menuOpen ? 
                        <RiArrowDropUpLine className="arrow-up" /> : 
                        <RiArrowDropDownLine className="arrow-down" />
                )}
            </div>
            {editable && menuOpen && <ProjectStatusMenu
                project={project}
                anchorRef={projectStatusRef}
                onClose={() => setMenuOpen(false)} 
            />}
        </>
    )
}

export default ProjectStatus
