import React from "react"
import "./Popup.css"

const Popup = ({ close, fullWidth, children, popupRef = null }) => (
    <div className="popup">
        <div className="overlay" onClick={close}></div>
        <div className="popup-contents fade-in" ref={popupRef}>
            <div
                className="scroll column"
                style={{
                    width: fullWidth ? "min(95vw, 1200px)" : "min(95vw, 600px)",
                    alignItems: "center",
                    maxHeight: "80vh",
                    padding: "0 2em"
                }}
            >
                {children}
            </div>
        </div>
    </div>
)

export default Popup
