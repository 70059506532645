import { IEntry } from "src/@types/entry"
import DataTabTableRowItemDate from "../DataTabTableRowItemDate"
import DataTabTableRowItemId from "../DataTabTableRowItemId"
import DataTabTableRowItemLanguage from "../DataTabTableRowItemLanguage"
import DataTabTableRowItemPlatform from "../DataTabTableRowItemPlatform"
import DataTabTableRowItemStatus from "../DataTabTableRowItemStatus"

interface IDataTabTableRowItemProps {
    entry: IEntry
}

export default function DataTabTableRowItem({
    entry
}: Readonly<IDataTabTableRowItemProps>) {
    return <tr>
        <DataTabTableRowItemId id={entry.identifier} entry={entry} />
        <DataTabTableRowItemStatus isCompleted={entry.completed ?? false} />
        <DataTabTableRowItemDate date={entry.last_update ?? entry.date} />
        <DataTabTableRowItemPlatform platform={entry.headers?.["user-agent"] ?? "Not available"} />
        <DataTabTableRowItemLanguage language={entry.lang} />
    </tr>
}