import { questionTypes } from "@utils/Variables"
import { IQuestion } from "src/@types/question"
import { ICodeBook, normalizeCodesFromResults } from "./normalize-codes-from-results"
import { IAnalysisResponseValue } from "./types/get-analysis-response-value"

export const normalizeAnalysisFromResults = (
    analysis: IQuestion,
    index: number,
    analysisResults?: IAnalysisResponseValue
) => {
    // Return an analysis question with a normalized and flat array of codes in which each code contains subcodes
    let codeBook: ICodeBook | null = null

    switch (analysis.type) {
        case questionTypes.multipleChoice:
        case questionTypes.select:
            codeBook = analysis.options
            break
        case questionTypes.insight:
            codeBook = analysis.buckets
            break
        case questionTypes.entity:
            codeBook = analysis.entities
            break
        default:
            break
    }

    if (codeBook === null)
        return {
            ...analysis,
            index,
            codes: []
        }


    const codes = normalizeCodesFromResults(
        codeBook,
        codeBook && analysisResults ? analysisResults : [],
        analysis.id
    )[1]

    return {
        ...analysis,
        index,
        codes
    }
}

