import { BiTrash } from "react-icons/bi"
import TextBox from "../../menus/Setting/TextBox"
import { useEditQuestionOptions } from "../../../hooks/useEditQuestion"
import { IoIosAddCircle } from "react-icons/io"
import ToggleInput from "../../inputs/ToggleInput"
import { useEffect, useMemo, useRef } from "react"
import { flatListToNested } from "../../../utils/nesting"
import { copy, getCopy } from "../../../utils/Copy"


const RecursiveCode = ({ code, addCode, editCode, deleteCode, inputref, onKeyPress, handleKeyPress }) => {

    const childrenRef = useRef([])


    useEffect(() => {
        // If a new children was added, focus
        if (code.children?.length) childrenRef.current[code.children.length - 1].focus()
    }, [code.children?.length])

    return <>
        <div key={code.id} className="option">
            <IoIosAddCircle
                className="small-icon"
                style={{ color: "var(--accent-color)" }}
                onClick={() => addCode({ parent: code.id })}
            />
            <input
                placeholder="Write code"
                value={code.name}
                ref={inputref}
                onKeyDown={onKeyPress}
                onChange={e => editCode(e.target.value, code.id)}
            />
            <BiTrash
                className="clickable danger"
                onClick={() => deleteCode(code.id)}
            />
        </div>

        <div
            style={{
                paddingLeft: "1em",
                height: "fit-content",
                borderRadius: 0,
                width: "fill-available",
                borderLeft: "1px solid var(--border-color)"
            }}
        >
            {code.children.map((c, idx) =>
                <RecursiveCode
                    key={c.id}
                    code={c}
                    addCode={addCode}
                    editCode={editCode}
                    deleteCode={deleteCode}
                    inputref={el => (childrenRef.current[idx] = el)}
                    onKeyPress={handleKeyPress(idx, code.id, childrenRef)}
                    handleKeyPress={handleKeyPress}
                />
            )}
        </div>
    </>

}

const FixedInsightDialogue = ({ question, setQuestion, lang }) => {

    const codesRef = useRef([])
    const { addOption, editOption, deleteOption } = useEditQuestionOptions(setQuestion, lang, "buckets")

    const nestedBuckets = useMemo(() => flatListToNested(question.buckets || []), [question.buckets])

    useEffect(() => {

        // Always creates one top level code by default
        if (!nestedBuckets?.length) addOption()

        // Else focuses on the last
        else codesRef.current[nestedBuckets?.length - 1].focus()
    }, [nestedBuckets?.length])

    const handleKeyPress = (index, parent, ref) => event => {
        if (event.key === "Enter") {
            event.preventDefault()

            // Removes deleted options
            ref.current = ref.current.filter(e => e)

            // If it's the last input, add a new one
            if (index === ref.current.length - 1) addOption({ parent })
            // Otherwise, focus the next input
            else ref.current[index + 1]?.focus()
        }
    }

    return <div className="column" style={{ width: "100%", gap: 0 }}>
        {nestedBuckets?.map((c, idx) =>
            <RecursiveCode
                code={c}
                key={c.id}
                addCode={addOption}
                editCode={editOption}
                deleteCode={deleteOption}
                inputref={el => (codesRef.current[idx] = el)}
                onKeyPress={handleKeyPress(idx, "root", codesRef)}
                handleKeyPress={handleKeyPress}
            />
        )}
        <button onClick={addOption} className="tertiary"> <IoIosAddCircle />Add code</button>
    </div>
}

const OpenInsightDialogue = ({
    question,
    setQuestion,
    descriptionClassName,
    textareaClassName,
    labelStyle
}) => {

    // Resets codes when we switch the fixed tags toggle
    useEffect(() => {
        setQuestion(q => ({ ...q, buckets: [] }))
    }, [question.fixed_tags])


    return <TextBox
        setting={{
            description: "Context",
            descriptionClassName,
            textareaClassName,
            labelStyle,
            placeholder: "Add some context to help Glaut analyze verbatims better..."
        }}
        value={question.analysis_context}
        setValue={v => setQuestion(q => ({ ...q, analysis_context: v }))}
    />
}

const InsightDialogue = ({
    question,
    setQuestion,
    lang,
    descriptionClassName,
    textareaClassName,
    toggleInputOptionClassName,
    toggleInputClassName,
    labelStyle
}) => {

    const Component = useMemo(
        () => question.fixed_tags ? FixedInsightDialogue : OpenInsightDialogue,
        [question.fixed_tags])

    // Set the question to let Glaut find the codes by default
    useEffect(() => {
        if (question.fixed_tags === undefined) setQuestion(q => ({ ...q, fixed_tags: false }))
    }, [])

    return <>
        <ToggleInput
            options={[
                { id: true, label: getCopy(copy.coding.codingMode.fixed) },
                { id: false, label: getCopy(copy.coding.codingMode.flexible) }
            ]}
            selected={question.fixed_tags}
            selectOption={v => setQuestion(q => ({ ...q, fixed_tags: v }))}
            optionClassName={toggleInputOptionClassName}
            toggleClassName={toggleInputClassName}
        />
        <Component
            question={question}
            setQuestion={setQuestion}
            lang={lang}
            descriptionClassName={descriptionClassName}
            textareaClassName={textareaClassName}
            labelStyle={labelStyle}
        />
    </>
}

export default InsightDialogue
