interface IDataTabTableHeaderItemProps {
    title: string
    icon: React.ReactNode
    actionIcon?: React.ReactNode
}

export default function DataTabTableHeaderItem(props: Readonly<IDataTabTableHeaderItemProps>) {
    // #region Props
    const { icon, title, actionIcon } = props
    // #endregion

    /** The inline styles overrides the CSS files (pls migrate to TailwindCSS) */
    return (
        <th scope="col" className="p-0 border-b-2" style={{ borderRight: "0px" }}>
            <div className="flex flex-row items-center">
                <div>
                    {icon}
                </div>
                <div className="flex-1 text-glaut-dark-grey">
                    <span className="text-[13.33px] font-[500]">{title}</span>
                </div>
                {actionIcon && (
                    <div>
                        {actionIcon}
                    </div>
                )}
            </div>
        </th>
    )
}