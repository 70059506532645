import {
    useAnalysisTabCodeOccurrences,
    useAnalysisTabCodeOccurrencesDispatch
} from "@pages/Project/ProjectArea/contexts/AnalysisTabCodeOccurrencesProvider"
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useState } from "react"

interface ICodeOccurrencesBoxProps {
    number: number
    percentage: number
}

export default function CodeOccurrencesBox({
    number,
    percentage
}: Readonly<ICodeOccurrencesBoxProps>) {
    // #region Contexts

    // can be {} when in legacy components
    const {
        shouldShowTooltipOnCodeHover,
        shouldShowCodeOccurrencesAsNumber
    } = useAnalysisTabCodeOccurrences()

    // can be {} when in legacy components
    const analysisTabCodeOccurrencesDispatch = useAnalysisTabCodeOccurrencesDispatch()

    // #endregion

    // #region States
    const [shouldShowNumberOverPercentage, setShouldShowNumberOverPercentage] = useState<boolean>()
    // #endregion

    // #region Memos

    const localShouldShowNumber = useMemo(() => {
        if (shouldShowCodeOccurrencesAsNumber === undefined) return true
        if (shouldShowNumberOverPercentage === undefined)
            return shouldShowCodeOccurrencesAsNumber

        return shouldShowNumberOverPercentage
    }, [shouldShowNumberOverPercentage, shouldShowCodeOccurrencesAsNumber])

    // For open text (not coding) questions, `percentage` comes as the identifier. The validation guarantees "-"
    const validatedPercentage = useMemo(() => {
        if (isNaN(Number(percentage))) return "-"
        return percentage
    }, [percentage])

    // #endregion

    // #region Callbacks
    const handleClickOnCodeOccurrences = useCallback((e: ReactMouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        setShouldShowNumberOverPercentage(prev => prev === undefined ? !shouldShowCodeOccurrencesAsNumber : !prev)

        if (typeof analysisTabCodeOccurrencesDispatch !== "function") return

        if (!shouldShowTooltipOnCodeHover)
            analysisTabCodeOccurrencesDispatch({ type: "mark-code-tooltip-as-viewed" })
    }, [analysisTabCodeOccurrencesDispatch, shouldShowCodeOccurrencesAsNumber, shouldShowTooltipOnCodeHover])
    // #endregion

    // #region Effects
    useEffect(() => {
        if (shouldShowNumberOverPercentage === shouldShowCodeOccurrencesAsNumber)
            setShouldShowNumberOverPercentage(undefined)
    }, [shouldShowNumberOverPercentage, shouldShowCodeOccurrencesAsNumber])
    // #endregion

    return (
        <span
            className={`code-number cursor-pointer border-1 border-transparent
                text-[11.11px] text-glaut-midnight font-medium
                hover:border-glaut-dark-grey
            `}
            onClick={handleClickOnCodeOccurrences}
            data-tooltip-id="tooltip--analysis-tab"
            data-tooltip-content="Click to toggle occurrences mode"
            data-tooltip-place="top">
            <div className="flex flex-row items-center justify-center h-full">
                {localShouldShowNumber ? number : (
                    <>
                        {String(validatedPercentage).split(".").join(",")}
                        {validatedPercentage !== "-" && (<p className="font-bold text-[6.6px]">%</p>)}
                    </>
                )}
            </div>
        </span>
    )
}