import { useRef } from "react"
import ReactDOM from "react-dom"
import FloatingMenu from "./FloatingMenu"
import { IoCloseSharp } from "react-icons/io5"
import { BiDotsVerticalRounded } from "react-icons/bi"

export const BoxMenuLine = ({
    title,
    description,
    icon,
    onClick,
    style,
    menuLineClassName,
    menuLineTextClassName,
    menuLineTitleClassName,
    menuLineDescriptionClassName
}) => (
    <div className={menuLineClassName ?? "menu-line"} onClick={onClick} style={style}>
        {icon && <div className="menu-line-icon">
            {icon}
        </div>}
        <div className={menuLineTextClassName ?? "menu-line-text"}>
            <div className={menuLineTitleClassName ?? "menu-line-title"}>
                {title}
            </div>
            {description && (
                <div className={menuLineDescriptionClassName ?? "menu-line-description"}>
                    {description}
                </div>
            )}
        </div>
    </div>
)

const Box = ({
    id,
    title,
    onClose,
    footer,
    className,
    parentStyle,
    style,
    children,
    onClick,
    showMenu,
    setShowMenu,
    menuElements,
    boxHeaderTitleClassName
}) => {
    const boxRef = useRef()

    return (<div id={id} className={"box " + className} onClick={onClick} ref={boxRef} style={parentStyle}>
        <div className="box-header">
            <span className={boxHeaderTitleClassName ?? "text-xs"}>{title}</span>
            {onClose && <IoCloseSharp onClick={onClose} className="box-close" />}
            {setShowMenu && <BiDotsVerticalRounded onClick={() => setShowMenu(!showMenu)} className="box-menu-button" />}
        </div>
        {children && <div className={"box-content"} style={style}>
            {children}
        </div>}
        {footer && <div className="box-footer">
            {footer}
        </div>}
        {showMenu && ReactDOM.createPortal(
            <FloatingMenu
                onClose={() => setShowMenu(false)}
                anchorRef={boxRef}
                elements={menuElements}
            />,
            document.getElementById("portal")
        )}
    </div>)
}

export default Box
