interface IConversationButtonsProps {
    lastMessage: {
        id: string
        options: {
            id: string
            label: Record<string, string>
        }[]
    }
    addClosedMessage: (content: string, questionId: string, value: string) => void
    isPlaying: boolean | null
    color: string
    lang: string
}

export default function ConversationButtons({
    lastMessage,
    addClosedMessage,
    isPlaying,
    color,
    lang
}: Readonly<IConversationButtonsProps>) {
    return (
        <div className="options">
            {lastMessage.options.map((o, idx) => (
                <button
                    key={`${o.id}-${idx}`}
                    disabled={!!isPlaying}
                    style={{ color: isPlaying ? "gray" : color }}
                    onClick={() => addClosedMessage(o.label[lang], lastMessage.id, o.id)}
                >
                    {o.label[lang]}
                </button>
            ))}
        </div>
    )
}