import { useRef, useEffect } from "react"

const delay = 1000

const useAutoSave = (save, data, open, dependencies = []) => {
    // Store timeout saving function so we can postpone it when needed
    const debouncedSave = useRef(null)

    useEffect(() => {
        // If new changes where detected we start the debouncing mechanism

        // Only attemps to save when the (websocket) connection is active
        if (open) {
            // Clear existing timer, postponing the saving, so we don't save while the user is still editing
            if (debouncedSave.current) clearTimeout(debouncedSave.current)

            // Restart the timer so we are going to save {delay} seconds from now
            debouncedSave.current = setTimeout(save, delay)
        }

        // Cleanup function to clear timer if component unmounts
        return () => clearTimeout(debouncedSave.current)
    }, [data, open])

    // Save when dependencies change
    useEffect(save, dependencies)
}

export default useAutoSave
