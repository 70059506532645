import React from "react"

const Loading = ({ fullPage, powered, color = "black" }) => (
    <div className="loading column" style={{ height: fullPage ? "80vh" : "", justifyContent: "center" }}>
        {powered && <h2>Powered by Glaut</h2>}
        <div className="bouncing-loader">
            {[1, 2, 3].map((_, index) => (
                <div key={index} style={{ backgroundColor: color }}></div>
            ))}
        </div>
    </div>
)

export default Loading
