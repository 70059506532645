export const getLanguage = () => navigator.language || navigator.userLanguage

export const getLanguageKey = (obj, lang, defaultLang="en") => {
    if (!obj) return null

    // If the language is not set use the browser language
    lang = lang || getLanguage()

    let result

    // If a key for the given language exists return it
    if (obj[lang]) result = obj[lang]
    // If the language is in locale format (ex. en-GB) try to use the first 2 chars
    else if (obj[lang.substring(0, 2)]) result = obj[lang.substring(0, 2)]
    // If a key for the default language exists return it
    else if (obj[defaultLang]) result = obj[defaultLang]

    // If the key is an object, print all the values
    if (!result) for (const entry of Object.entries(obj)) 
        if (entry[1]) return entry[1]

    // Return the first key
    return result || ""
}

export const getProjectLang = proj => proj.general_settings?.language || "en"
