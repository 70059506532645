import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import Code from "@components/Coding/Code"
import ConversationTranscriptBox from "@components/ConversationTranscriptBox"
import {
    IConversationTranscriptBoxMessageItemProps
} from "@components/ConversationTranscriptBox/components/ConversationTranscriptBoxMessage"
import { useFormattedDate } from "@hooks/useFormattedDate"
import IProjectGateway from "@services/projects/IProjectGateway"
import { ArrayElement } from "@utils/types/array-element"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import useModifyEntry from "../../../useModifyEntry"
import { IQuestionAnalysisFromSchema, IQuestionFromSchema } from "../../utils/questions-from-schema"

interface IAnalysisTabVerbatimsColItemProps {
    question: IQuestionFromSchema
    analysis?: IQuestionAnalysisFromSchema
    entry: ArrayElement<Awaited<ReturnType<IProjectGateway["getEntries"]>>["entries"]>
    modifyVerbatim: ReturnType<typeof useModifyEntry>
    lang?: string
    startExpanded?: boolean
    allValidSelectedCodeIds?: string[]
}

export default function AnalysisTabVerbatimsColItem({
    question,
    analysis,
    entry,
    modifyVerbatim,
    lang,
    startExpanded = false,
    allValidSelectedCodeIds
}: Readonly<IAnalysisTabVerbatimsColItemProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region States
    const [draggedOver, setDraggedOver] = useState(false)
    // #endregion

    // #region Memos
    const values = useMemo(() => analysis ? entry.entry?.[analysis.id] || null : null, [analysis, entry])
    const text = useMemo(() => entry.entry?.[question.id], [question, entry.entry])
    const displayedMessages: IConversationTranscriptBoxMessageItemProps[] = useMemo(() => {
        const displayedMessages: IConversationTranscriptBoxMessageItemProps[] = []
        if (project?.source !== "interview") return []

        let flagIncludeMessage = false
        let firstMessageIndex = -1

        for (let messageIdx = 0; messageIdx < entry.messages.length; messageIdx++) {
            const message = entry.messages[messageIdx]
            if (message.role === "assistant") {
                flagIncludeMessage = message.content.includes(question.id)
                firstMessageIndex = flagIncludeMessage && firstMessageIndex === -1 ? messageIdx : firstMessageIndex
            }

            if (flagIncludeMessage && firstMessageIndex !== -1)
                displayedMessages.push(message)
        }

        return displayedMessages
    }, [entry, question.id, project])
    // #endregion

    // #region Refs
    const codesRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Custom hooks
    const { text: lastUpdateDate } = useFormattedDate(entry.last_update ?? entry.date)
    // #endregion

    // #region Callbacks
    const removeCode = useCallback((codeId: string) => {
        if (!analysis) return

        const entryAnalysis = entry.entry?.[analysis.id]
        if (!Array.isArray(entryAnalysis)) return

        modifyVerbatim(analysis.id, entry._id, entryAnalysis.filter(c => c !== codeId))
    }, [analysis, entry._id, entry.entry, modifyVerbatim])
    // #endregion

    // #region Effects

    //  This turns the code into a draggable element and themes into a droppable element
    useEffect(() => {
        if (codesRef.current === null) return

        return dropTargetForElements({
            element: codesRef.current,
            onDragEnter: () => setDraggedOver(true),
            getData: () => ({ entry, question }),
            onDragLeave: () => setDraggedOver(false),
            onDrop: () => setDraggedOver(false)
        })
    }, [entry, question])

    // #endregion

    // #region Element memos
    const listOfVerbatimCodesElement = useMemo(() => {
        if (!analysis?.codes) return undefined

        if (!Array.isArray(values)) {
            if (!values) return undefined

            const colorIndex = Number(values) % 10

            /**
             * Imitates the return type of `normalizeCode()`
             * @see {normalizeCode()}
             */
            const code = {
                analysisId: analysis?.id,
                canCreateSubtheme: false,
                canDelete: false,
                canMergeWith: false,
                canRename: false,
                colorIndex,
                id: values,
                label: values,
                occurrences: "-",
                order: 0,
                parent: "root",
                subcodes: [],
                value: values
            }

            // Number or NPS
            return (
                <Code
                    question={analysis}
                    code={code}
                    type="small"
                    lang={lang}
                    showMenuButton={false}
                    showDeleteButton={true}
                    colorIndex={colorIndex}
                />
            )
        }

        if (!values.length)
            return text ? <span className="small">This answer has no codes, drag one to assign it</span> : undefined

        // Insight, Select or Entities
        return analysis.codes.filter(c => values.includes(c.id))
            .sort((a, b) => {
                if (allValidSelectedCodeIds) {
                    const isASelected = allValidSelectedCodeIds.includes(a.id)
                    const isBSelected = allValidSelectedCodeIds.includes(b.id)

                    if (isASelected && isBSelected) return 0
                    if (isASelected) return -1
                    return 1
                }

                return a.order - b.order
            })
            .map(c => (
                <Code
                    key={c.id || c}
                    question={analysis}
                    code={c}
                    type="small"
                    lang={lang}
                    showMenuButton={false}
                    colorIndex={null}
                    removeCode={() => removeCode(c.id)}
                    hideSubcodes={true}
                    disabled={allValidSelectedCodeIds ? !allValidSelectedCodeIds.includes(c.id) : false}
                />
            ))
    }, [analysis, lang, allValidSelectedCodeIds, removeCode, text, values])

    const listOfVerbatimCodesContainerElement = useMemo(() => (
        <div
            className={["codes flex flex-row flex-wrap p-[0.2em]", draggedOver && "dragged-over"].join(" ")}
            ref={codesRef}
            style={{ gap: "0.625em" }}
        >
            {listOfVerbatimCodesElement}
        </div>
    ), [listOfVerbatimCodesElement, draggedOver])
    // #endregion

    if (project?.source === "CSV")
        return (
            <div className={`verbatim flex flex-col gap-[0.5em] bg-glaut-cards border-1 border-glaut-light-grey
                py-[0.5em] px-[0.8em]`}>
                <div className="identifier">
                    <span className="text-xs underline">{entry.identifier}</span>
                </div>
                <div className={["content", text ? "" : "empty"].join(" ")}>
                    <span className="text">{text ? "\"" + text + "\"" : "empty"}</span>
                </div>
                {listOfVerbatimCodesContainerElement}
            </div>
        )

    return (
        <div className="verbatim max-h-max">
            <ConversationTranscriptBox
                lastUpdateDate={lastUpdateDate}
                messages={displayedMessages}
                startExpanded={startExpanded}
                username={entry.identifier}
                expandable
                customCodesElement={listOfVerbatimCodesContainerElement}
                projectId={project?._id}
            />
        </div>
    )
}