import { useState, useEffect, useContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import Papa from "papaparse"

import { v4 as uuid } from "uuid"
import { copy, getCopy } from "../../utils/Copy"
import Button from "../../components/layouts/Button"
import Input from "../../components/layouts/Input"
import { VerbatimHeader, VerbatimRow } from "../../components/Coding/Verbatims"
import Setting from "../../components/menus/Setting"
import { MdUpload, MdOutlineCancel } from "react-icons/md"
import { FaFileCsv, FaExclamationCircle } from "react-icons/fa"
import { languages, questionTypes } from "../../utils/Variables"
import { ProjectContext } from "./AutoSaveProject"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import { useAuthInfo } from "@propelauth/react"
import csv from "../../assets/csv.png"
import { DeleteProjectPopup } from "../Project/Settings"

import "../../styling/CSVSettings.scss"

const settingsCopy = copy.coding.settings

const generalSettings = [
    {
        slug: "language",
        type: "language",
        options: Object.keys(languages),
        default: "en",
        title: getCopy(settingsCopy.csvSettings.language),
        description: getCopy(settingsCopy.csvSettings.languageDescription)
    }
]
const csvSettings = [
    {
        slug: "context",
        type: "text",
        default: "",
        title: "",
        description: getCopy(settingsCopy.csvSettings.contextDescription),
        active: false,
        placeholder: getCopy(settingsCopy.csvSettings.contextPlaceholder),
        disabled: true
    }
]


export const SelectCSVFile = ({ setFile }) => {

    const handleFileChange = event => {
        const file = event.target.files[0]

        setFile(file)
    }

    return (<div className="import-wrapper">
        <div className="import">
            <img src={csv} />
            <h1 className="title">{getCopy(settingsCopy.selectCsv.title)}</h1>
            <h2 className="subtitle">{getCopy(settingsCopy.selectCsv.subtitle)}</h2>
            <div className="instructions">
                <div className="instructions-title">
                    <FaExclamationCircle />
                    {getCopy(settingsCopy.selectCsv.instructionsTitle)}
                </div>
                <div className="instructions-body">{getCopy(settingsCopy.selectCsv.instructions)}</div>
                <div className="instructions-footer">{getCopy(settingsCopy.selectCsv.instructionsFooter)}</div>
            </div>
            <Button
                label={getCopy(settingsCopy.selectCsv.uploadCsvFile)}
                icon={<MdUpload />}
                acceptFile=".csv"
                handleFileChange={handleFileChange}
            />
        </div>
    </div>)
}


export const ProjectSettings = ({ file, fileContent, setFileContent, selectedColumn, setSelectedColumn }) => {
    const [openDelete, setOpenDelete] = useState(false)
    const { project, setProject } = useContext(ProjectContext)
    const { projectId } = useParams()
    const axiosAuth = useAxiosAuth()

    // Set questionIds from schema
    const questionIds = useMemo(
        () => project && project.schema.filter(q => q.type === questionTypes.shortText).map(q => q.id),
        [project]
    )
    // Set headers from file or from schema
    const headers = useMemo(
        () => fileContent
            ? fileContent.headers.slice(1)
            : project.schema.filter(q => q.type === questionTypes.shortText).map(q => q.header),
        [fileContent, project]
    )

    const setHeaders = (index, value) => {
        if (fileContent) {
            // Update column header during file import
            const newHeaders = [
                ...fileContent.headers
            ]
            newHeaders[index + 1] = value
            setFileContent({
                ...fileContent,
                headers: newHeaders
            })
        } else {
            // Update project question
            setProject(p => {
                const newSchema = [
                    ...p.schema
                ]
                const questionIndex = newSchema.map(q => q.id).indexOf(questionIds[index])
                newSchema[questionIndex] = {
                    ...newSchema[questionIndex],
                    header: value
                }

                return {
                    ...p,
                    schema: newSchema
                }
            })
        }
    }

    const importCsv = () => {
        const schema = fileContent.headers.map((h, i) => ({
            header: h,
            type: fileContent.types[i],
            value: { [project.general_settings.language]: h },
            id: uuid()
        }))
        const table = fileContent.rows.map(r => Object.fromEntries(r.map((c, i) => [fileContent.headers[i], c])))
        // Upload data
        axiosAuth
            .post(`datasets/${projectId}/table`, { table, schema })
            .then(() => window.location.reload())
            .catch(e => console.log(e))
    }

    const deleteColumn = index => {
        if (fileContent) {
            // Delete column during file import
            const types = [...fileContent.types]
            types.splice(index, 0)
            const headers = [...fileContent.headers]
            headers.splice(index, 1)
            const rows = fileContent.rows.map(r => {
                const nr = [...r]
                nr.splice(index, 1)
                return nr
            })
            setFileContent({
                types,
                headers,
                rows
            })
        } else {
            // Delete column (question) from project
            setProject(p => ({
                ...p,
                schema: p.schema.filter(q => q.id !== questionIds[index - 1])
            }))
        }
    }

    return (<div className="project-settings h-full w-full max-w-[40%]">
        <h2 className="title">
            {getCopy(settingsCopy.projectSettings)}
        </h2>
        <div className="scroll">
            {file && <div className="file-name">
                <FaFileCsv className="icon" />
                <span>{file.name}</span>
                <MdOutlineCancel className="delete" onClick={() => setFileContent(null)} />
            </div>}
            <div className="general-settings">
                {generalSettings?.map((s, idx) => (
                    <Setting
                        key={idx}
                        setting={{ ...s, disabled: Boolean(!file) }}
                        value={project.general_settings?.[s.slug]}
                        setValue={v => setProject(p => ({
                            ...p,
                            general_settings: { ...p.general_settings, [s.slug]: v }
                        }))}
                    />
                ))}
            </div>
            <div className="instructions">
                <div className="instructions-title">
                    <FaExclamationCircle />
                    {getCopy(settingsCopy.csvSettings.writeYourQuestion)}
                </div>
                <div className="instructions-body">{getCopy(settingsCopy.csvSettings.writeYourQuestionText)}</div>
            </div>
            <div className="column-names">
                {headers.map((s, i) => (
                    <div key={i} className={["headers", selectedColumn === i ? "selected" : ""].join(" ")}>
                        <Input
                            value={s || ""}
                            placeholder={getCopy(settingsCopy.csvSettings.questionPlaceholder)(i + 1)}
                            onChange={e => setHeaders(i, e.target.value)}
                            onFocus={() => setSelectedColumn(i)}
                            onBlur={() => setSelectedColumn(null)}
                            onDelete={() => deleteColumn(i + 1)}
                            deleteDisabled={headers.length === 1}
                        />
                    </div>
                ))}
            </div>
            <div className="other-settings">
                {csvSettings?.map((s, idx) => (
                    <Setting
                        key={idx}
                        setting={s}
                        value={project.csv_settings?.[s.slug] || ""}
                        setValue={v => setProject(p => ({
                            ...p,
                            csv_settings: { ...p.csv_settings, [s.slug]: v }
                        }))}
                    />
                ))}
                {!file && <div className="box danger-zone" style={{ border: "1px solid var(--danger-color)" }}>
                    <h2>Danger zone</h2>
                    <div className="long-bar">
                        <div className="column" style={{ gap: "4px", alignItems: "flex-start" }}>
                            <span style={{ fontWeight: "bold" }}>Delete this project</span>
                            <span>There is no going back. Please be certain.</span>
                        </div>
                    </div>

                    <div className="long-bar">
                        <button className="danger-button" onClick={() => setOpenDelete(true)}>
                            Delete
                        </button>
                    </div>
                </div>}
                {openDelete && (
                    <DeleteProjectPopup
                        id={project._id}
                        close={() => setOpenDelete(false)}
                    />
                )}
            </div>
            {fileContent && <div>
                <Button
                    className="primary centered import"
                    label={getCopy(settingsCopy.csvSettings.importData)}
                    onClick={importCsv}
                />
            </div>}
        </div>
    </div>)
}


export const normalizeCSVData = (data, hasHeaders) => {
    const rows = data.splice(hasHeaders ? 1 : 0)
    // Find max column length
    const columnCount = data.reduce(
        (prev, current) => (prev.length > current.length) ? prev : current).length
    // Get headers
    const headers = Array.from(
        { length: columnCount }, (v, i) => data[0][i] || getCopy(settingsCopy.csvSettings.defaultColumnHeader)(i + 1))

    const identifyType = index => {
        // If the header name is "id" or similar return identifier
        if (["id", "token"].includes(headers[index].toLowerCase()))
            return questionTypes.identifier

        // // Gets a representative sub set of the column
        // const subsetSize = Math.min(50, rows.length - 1)
        // const column = rows
        //     .map(row => row[index])
        //     .filter(v => v)
        //     .slice(0, subsetSize)

        // // We cannot draw any conclusions
        // if (column.length === 0) return questionTypes.shortText

        // // Checks if the values are all numbers
        // if (column.every(value => /^[\d.]+$/.test(String(value))))
        //     return questionTypes.number

        // // If less than 80% of values are not unique, we are dealing with a multiple choice
        // const values = new Set(column)
        // if (values.size < column.length * 0.8) 
        //     return questionTypes.multipleChoice

        return questionTypes.shortText
    }

    // Identify column types
    const types = Array.from({ length: columnCount }, (v, i) => identifyType(i))
    const hasId = types.find(t => t === questionTypes.identifier)

    const realColumnCount = hasId ? columnCount : columnCount + 1
    const emptyRow = Array.from({ length: columnCount }, () => null)

    return {
        types: (hasId ? [] : [questionTypes.identifier]).concat(types),
        headers: (hasId ? [] : ["ID"]).concat(headers),
        // Be sure that each line has same length
        rows: rows.map((row, i) => (
            hasId ? [] : [i + 1]).concat(row).concat(emptyRow).splice(0, realColumnCount))
    }
}

export const VerbatimsPreview = ({ fileContent, selectedColumn }) => {
    const [entries, setEntries] = useState([])
    const { project } = useContext(ProjectContext)
    const { projectId } = useParams()

    const axiosAuth = useAxiosAuth()
    const { isLoggedIn } = useAuthInfo()

    // Set questionIds from schema
    const questionIds = useMemo(
        () => project && project.schema.filter(q => q.type === questionTypes.shortText).map(q => q.id),
        [project]
    )
    // Set headers from file or from schema
    const headers = useMemo(
        () => fileContent
            ? fileContent.headers
            : ["ID"].concat(project.schema.filter(q => q.type === questionTypes.shortText).map(q => q.header)),
        [fileContent, project]
    )
    // Set rows from file or from entries
    const rows = useMemo(
        () => fileContent
            ? fileContent.rows
            : entries.map(e => [e.identifier].concat(questionIds.map(id => e.entry[id]))),
        [fileContent, entries]
    )

    // Load entries if needed
    useEffect(() => {
        !fileContent && axiosAuth
            .get(`datasets/${projectId}/entries?page=0`)
            .then(res => {
                setEntries(res.data.entries)
            })
            .catch(e => console.log(e))
    }, [isLoggedIn, fileContent, project?.schema?.length])

    return <div className="verbatims-preview max-w-[60%]">
        <h2 className="title">
            {getCopy(settingsCopy.preview)}
        </h2>
        <div className="scroll">
            <div className="verbatims-table">
                <VerbatimHeader headers={headers} selectedColumn={selectedColumn} />
                {rows.map((r, idx) => (
                    <VerbatimRow
                        key={`${r[0]}-${idx}`}
                        row={r}
                        selectedColumn={selectedColumn}
                    />
                ))}
            </div>
        </div>
    </div>
}

const CSVSettings = () => {
    const { project } = useContext(ProjectContext)

    const [file, setFile] = useState(null)
    const [fileContent, setFileContent] = useState(null)

    const [hasHeaders] = useState(true)
    const [selectedColumn, setSelectedColumn] = useState(null)
    const [normalizedFileContent, setNormalizedFileContent] = useState(null)

    const showImport = useMemo(
        () => !normalizedFileContent && !project?.schema?.length,
        [normalizedFileContent, project?.schema]
    )

    // Parse CSV file when selected
    useEffect(() => {
        if (file) Papa.parse(file, {
            complete: result => {
                setFileContent(result.data)
            },
            headers: false
        })
    }, [file])

    // Normalize file contente after parsing the CSV
    useEffect(() => {
        if (fileContent)
            setNormalizedFileContent(
                normalizeCSVData(fileContent, hasHeaders)
            )
    }, [fileContent, hasHeaders])

    return (<div className="csv-settings h-full mx-8 mt-8 overflow-hidden">
        {showImport && <SelectCSVFile setFile={setFile} />}
        {!showImport && <div className="flex flex-row h-full">
            <ProjectSettings
                file={file}
                fileContent={normalizedFileContent}
                setFileContent={setNormalizedFileContent}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
            />
            <VerbatimsPreview fileContent={normalizedFileContent} selectedColumn={selectedColumn} />
        </div>}
    </div>)
}

export default CSVSettings