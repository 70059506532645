import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { useMemo } from "react"
import { FaTag } from "react-icons/fa"
import { Tooltip } from "react-tooltip"
import slugify from "slugify"

interface IThemeItemProps {
    label: string
    colorIndex?: number,
    elementOnEnd?: React.ReactNode
}

export default function ThemeItem({
    label,
    colorIndex = 1,
    elementOnEnd
}: Readonly<IThemeItemProps>) {
    // #region Memos
    const tooltipId = useMemo(() => `theme-item-${slugify(label)}`, [label])
    // #endregion

    // #region Custom hooks
    const { browsers: { isSafari } } = useBrowserUserAgent()
    // #endregion

    return (
        <>
            <div
                className="flex flex-row items-center gap-2 overflow-hidden"
                data-tooltip-id={tooltipId}
                data-tooltip-content={label}
                data-tooltip-place="top">
                <FaTag className="w-4 h-4" style={{ color: `var(--color${(colorIndex % 10) + 1})` }} />
                <p className="text-glaut-midnight text-sm whitespace-nowrap overflow-hidden text-ellipsis font-medium">
                    {label}
                </p>
                {elementOnEnd}
            </div>
            {!isSafari && (
                <Tooltip
                    id={tooltipId}
                    className="z-50 max-w-96"
                />
            )}
        </>
    )
}