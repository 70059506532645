import { useEffect, useRef } from "react"
import { useEditQuestionOptions } from "../../../hooks/useEditQuestion"
import { BiTrash } from "react-icons/bi"
import { normalizeQuestionOrOption } from "../../../utils/strings"
import { IoIosAddCircle } from "react-icons/io"


const SelectDialogue = ({question, setQuestion, lang}) => {

    const { addOption, editOption, deleteOption } = useEditQuestionOptions(setQuestion, lang)

    // One ref for each option
    const optionsRef = useRef([])

    const handleKeyPress = index => event => {
        if (event.key === "Enter") {
            event.preventDefault()

            // If it's the last input, add a new one
            if (index === question.options.length - 1) addOption()
            // Otherwise, focus the next input
            else optionsRef.current[index + 1]?.focus()
        }
    }

    useEffect(() => {
        // Add the first option if the array is empty
        if (!question.options?.length) addOption()

        // Else focuses on the last
        else optionsRef.current[question.options?.length - 1].focus()
    }, [question.options?.length])

    return <div className="options">
        {question.options?.map((o, idx) => 
            <div key={o.id}>
                <span className="index">{idx + 1}</span>
                <input
                    placeholder={`Option ${idx + 1}`}
                    onKeyDown={handleKeyPress(idx)}
                    value={o.label?.[lang]}
                    onChange={e => editOption(e.target.value, o.id)}
                    ref={el => (optionsRef.current[idx] = el)}
                    onBlur={e => editOption(normalizeQuestionOrOption(e.target.value), o.id)}
                />
                <BiTrash
                    className="clickable danger"
                    onClick={() => deleteOption(o.id)}
                />
                
            </div>)
        }
        <button onClick={addOption} className="tertiary"> <IoIosAddCircle/>Add option</button>
    </div>
}

export default SelectDialogue