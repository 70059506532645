import React, { useMemo } from "react"
import { LuFilter } from "react-icons/lu"

import PercentagePie from "../charts/PercentagePie"

import { openQuestions, questionTypes } from "../../utils/Variables"
import Tags from "./Tags"
import Summaries from "./Summaries"
import Entities from "./Entities"
import NPS from "./NPS"
import { explainFilter } from "../../utils/Filters"
import Options from "./Options"

const AnalysisBox = ({ value, question, setShownQuestion, filters, setFilters, lang }) => {
    const currentFilter = useMemo(() => filters.find(f => f.id === question?.id), [filters])

    const deleteFilter = () => setFilters(fs => fs.filter(f => f.id !== question.id))

    const setSelected = (value, operator = "is") => {
        // If no filter is applied to this question, adds one in the filters array
        if (!currentFilter) setFilters(f => [...f, { id: question.id, value, operator }])
        // If the user clicked on the active filters, discards it
        else if (currentFilter.value === value) deleteFilter()
        // If the user clicked on a new value, changes the current filter
        else 
            setFilters(fs =>
                fs.map(f => {
                    if (f.id === question.id) return { ...f, value }
                    return f
                })
            )
        
    }

    if (
        !question ||
        !value ||
        [questionTypes.shortText, questionTypes.date].includes(question.type)
    )  return 

    // This shouldn't be filtered
    if (filters.length > 0 && question.type === questionTypes.open) return

    const root = { label: "All", value: 100, children: value }

    return (
        <div className="cell-box" key={question.id} style={{ width: "100%" }}>
            <div className="column">
                <h2
                    style={{ textAlign: "center" }}
                    className="hover-underline"
                    onClick={() =>
                        openQuestions.includes(question.type) &&
                        setShownQuestion({
                            question,
                            value: root
                        })
                    }
                >
                    {question.value[lang] || question.header}
                </h2>
                {question.type === questionTypes.number && (
                    <div className="long-bar" style={{ justifyContent: "space-around" }}>
                        {Object.entries(value).map(([key, value]) => (
                            <div className="labeled-number" key={key}>
                                <span>{value.toFixed(1)}</span>
                                <span>{key}</span>
                            </div>
                        ))}
                    </div>
                )}
                {question.type === questionTypes.nps && (
                    <NPS value={value} setSelected={setSelected} />
                )}
                {(question.type === questionTypes.multipleChoice || question.max_choices === 1) && (
                    <PercentagePie
                        data={value.map(v => ({
                            ...v,
                            name: question.options.find(o => o.id === v.id)?.label?.[lang]
                        }))}
                        setSelected={setSelected}
                    />
                )}
                {question.type === questionTypes.select && question.max_choices > 1 && 
                    <Options
                        options={value}
                        lang={lang}
                        question={question}
                        setSelected={setSelected}
                    />
                }
                {question.type === questionTypes.open && <Summaries value={value} />}
                {question.type === questionTypes.insight && (
                    <Tags
                        tags={value}
                        root={root}
                        question={question}
                        questionFilter={currentFilter}
                        setSelected={setSelected}
                        setShownQuestion={setShownQuestion}
                    />
                )}
                {question.type === questionTypes.entity && (
                    <Entities
                        entities={value}
                        root={root}
                        question={question}
                        setSelected={setSelected}
                        setShownQuestion={setShownQuestion}
                    />
                )}

                {currentFilter && (
                    <div
                        className="bar"
                        style={{
                            border: "1px solid var(--border-color)",
                            paddingLeft: "0.5em",
                            width: "fit-content",
                            margin: "0 auto"
                        }}
                    >
                        <LuFilter />
                        <span>{explainFilter(currentFilter, [question], lang)}</span>
                        <span>|</span>
                        <span className="clickable danger" onClick={deleteFilter}>
                            ✕
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AnalysisBox
