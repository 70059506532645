interface IAgentMessageProps {
    message: string
    isSpeaking: boolean
}

export default function AgentMessage({
    message,
    isSpeaking
}: Readonly<IAgentMessageProps>) {
    return (
        <h2>
            {message.split(" ").filter(w => w).map((word, i) => (
                <span
                    key={`${i} - ${word}`}
                    className="fade-in text-[16px] md:text-[24px]"
                    style={{
                        opacity: 0,
                        whiteSpace: "pre-wrap",
                        display: "inline",
                        animationFillMode: "forwards",
                        animationDelay: `${i * (isSpeaking ? 0.28 : 0.03)}s`
                    }}
                >
                    {word}{" "}
                </span>
            ))}
        </h2>
    )
}
