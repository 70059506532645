export const coding = {
    coding: {
        settings: {
            selectCsv: {
                title: {
                    en: "Import as CSV"
                },
                subtitle: {
                    en: "Import a file containing multiple anserws and analyze its content"
                },
                instructionsTitle: {
                    en: "Formatting instructions:"
                },
                instructions: {
                    en: (<ul>
                        <li>Field names or labels must be listed in the first row (heading)</li>
                        <li>Fields must be delimited by, (comma)</li>
                        <li>The fields can be in any order</li>
                        <li>Field values containing a comma must be enclosed by " (double quotes)</li>
                        <li>Use two double quotes within field values if the value is quoted. For example, A "quoted" word and "Some, ""quotes within"" must be doubled." are both valid.</li>
                        <li>Headings (first line of the CSV):</li>
                        <li>(Choose a form or list to see the headings to use in the CSV)</li>
                    </ul>)
                },
                instructionsFooter: {
                    en: "→ You will have a chance to review the data before finalising the upload."
                },
                uploadCsvFile: {
                    en: "Upload CSV file"
                },
            },
            csvSettings: {
                language: {
                    en: "Language"
                },
                languageDescription: {
                    en: "Select the language the project is written and analyzed in:"
                },
                contextDescription: {
                    en: "Add some context to help Glaut analyze text better"
                },
                contextPlaceholder: {
                    en: "Write here.."
                },
                writeYourQuestion: {
                    en: "Write your questions in Glaut"
                },
                writeYourQuestionText: {
                    en: "What was the prompt that users answered? We asumme that is whatever is written on the header. In order to help Glaut analyze text better, please write down the specific prompt that your respondents saw before responding."
                },
                questionPlaceholder: {
                    en: i => `Question ${i}`
                },
                defaultColumnHeader: {
                    en: i => `column ${i}`
                },
                importData: {
                    en: "Import"
                }
            },
            projectSettings: {
                en: "Project Settings"
            },
            preview: {
                en: "Preview"
            },
        },
        themesAndCodesHeader: {
            en: "Themes and codes",
        },
        resultsHeader: {
            en: "Results",
        },
        analysisColumnHeader: {
            en: "Analysis results",
        },
        verbatimsHeader: {
            en: "Verbatims"
        },
        questionOrAnalysisHeader: {
            en: (question, analysis) => analysis ? `Analysis ${question.index + 1}.${analysis.index + 1} 🔍` : `Question ${question.index + 1}`,
        },
        questionAndAnalysisHeader: {
            en: (question, analysis) => analysis ? `Question ${question.index + 1} - Analysis ${analysis.index + 1}` : `Question ${question.index + 1}`,
        },
        createSubtheme: {
            selectCodes: {
                en: "Select the codes to cluster as a sub-theme"
            },
            selectName: {
                en: "What is this sub-theme about?"
            },
            selectNamePlaceholder: {
                en: "Write the name of your sub-theme..."
            },
            continue: {
                en: "Continue"
            },
            back: {
                en: "Back"
            },
            confirm: {
                en: "Confirm Sub-theme"
            }
        },
        merge: {
            mergeCodeWithTitle: {
                en: "Select the another code to merge",
            },
            mergeCodeWithDescription: {
                en: "The first selected code will keep the name of the merge.",
            },
            confirmTitle: {
                en: "Are you sure you want to merge this codes?"
            },
            confirmButton: {
                en: "Confirm"
            },
            cancelButton: {
                en: "Cancel"
            }
        },
        codingMode: {
            fixed: {
                en: "Use existing codebook"
            },
            flexible: {
                en: "Let Glaut create a codebook"
            },
        },
        analyze: {
            en: "Analyze!",
        },
        addHiddenQuestion: {
            en: "Add hidden question"
        },
        addHiddenQuestionOptionsTitle: {
            en: "Decide how to analyze this information"
        },
        addQuestion: {
            en: "Add question",
        },
        pinQuestion: {
            en: "Pin question",
        },
        saveCodebook: {
            en: "Save codebook",
        },
        deleteQuestion: {
            en: "Delete question",
        },
        addTheme: {
            addTheme: {
                en: "Add Theme",
            },
            title: {
                en: "Write the name of your new code...",
            },
            placeholder: {
                en: "New theme name",
            },
            confirm: {
                en: "Confirm Theme",
            },
        },
        analysisMenu: {
            openTextCoding: {
                en: "Open Text Coding 🏷️",
            },
            openTextCodingDetail: {
                en: "Write a question to create codes/subcodes with the main concepts within the text",
            },
            multipleChoice: {
                en: "Multiple Choice 🗳️",
            },
            multipleChoiceDetail: {
                en: "Apply the categories from a multiple-options question",
            },
            entities: {
                en: "Entities 📺",
            },
            entitiesDetail: {
                en: "Identify codes of brands and celebrities",
            },
            numerical: {
                en: "NPS ⭐",
            },
            numericalDetail: {
                en: "Identify the numerical score",
            },
        },
        emptyAnalysisColumnMessage: {
            en: "Analysis has not been run yet"
        },
        emptyVerbatimsColumnMessage: {
            en: "Nothing to show here"
        },
        filterByTheme: {
            en: "Filter by theme"
        },
        filterByScore: {
            en: "Filter by score"
        }
    },
}
