import { IInsightAnalysis } from "src/@types/analysis"

/**
 * Search recursively for the code ID parent in a certain insight analysis.
 */
export function searchCodeParentInAnalyses(
    analyses: IInsightAnalysis[],
    codeId: string,
    parentAnalysis: IInsightAnalysis | null = null
): IInsightAnalysis | null {
    for (const analysis of analyses) {
        if (analysis.id === codeId) return parentAnalysis

        const childAnalysis = searchCodeParentInAnalyses(analysis.children, codeId, analysis)
        if (childAnalysis !== null) return childAnalysis
    }

    return null
}
