import IProjectGateway from "@services/projects/IProjectGateway"
import { createContext, useContext, useReducer } from "react"

// #region Type definitions
type AnalysisState = Awaited<ReturnType<IProjectGateway["getAnalysis"]>>

interface IProjectProviderProps {
    children: React.ReactNode
}

interface IProjectContextState {
    numberOfEntries: number
    analysisData?: AnalysisState
    isAnalysisTabExportDataButtonActive: boolean
}

type IProjectContextAction = {
    type: "set-analysis"
    analysisData: AnalysisState
} | {
    type: "set-number-of-entries"
    numberOfEntries: number
} | {
    type: "set-analysis-tab-export-data-button-state"
    isActive: boolean
}
// #endregion

// #region Context definitions
const ProjectContext = createContext({} as IProjectContextState)
const ProjectContextDispatch = createContext({} as React.Dispatch<IProjectContextAction>)
// #endregion

// #region Hooks definitions
export function useProject() {
    return useContext(ProjectContext)
}

export function useProjectDispatch() {
    return useContext(ProjectContextDispatch)
}
// #endregion

// #region Provider definition
export default function ProjectProvider({
    children
}: Readonly<IProjectProviderProps>) {
    const initialState: IProjectContextState = {
        numberOfEntries: 0,
        isAnalysisTabExportDataButtonActive: false
    }

    const [state, dispatch] = useReducer(ProjectReducer, initialState)

    return (
        <ProjectContext.Provider value={state}>
            <ProjectContextDispatch.Provider value={dispatch}>
                {children}
            </ProjectContextDispatch.Provider>
        </ProjectContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function ProjectReducer(state: IProjectContextState, action: IProjectContextAction): IProjectContextState {
    switch (action.type) {
        case "set-analysis": {
            return {
                ...state,
                analysisData: action.analysisData
            }
        }
        case "set-number-of-entries": {
            return {
                ...state,
                numberOfEntries: action.numberOfEntries
            }
        }
        case "set-analysis-tab-export-data-button-state": {
            return {
                ...state,
                isAnalysisTabExportDataButtonActive: action.isActive
            }
        }
        default: {
            return state
        }
    }
}
// #endregion