import React from "react"

const Bucket = ({ bucket, setValue, indent, lang, colorIndex, objKey = "name" }) => (
    <div
        style={{ width: "85%", justifyContent: "flex-start" }}
        className="bar clickable"
        onClick={() => setValue(bucket.id)}
    >
        <div style={{ width: `${30 * (indent || 0)}px`, height: "20px" }}></div>
        <div
            style={{
                width: "16px",
                height: "16px",
                background: `var(--color${colorIndex}${indent ? "-light" : ""})`
            }}
        ></div>
        <span key={bucket.id}>{lang ? bucket[objKey][lang] : bucket[objKey]}</span>
    </div>
)

export default Bucket
