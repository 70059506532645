import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import useAxiosAuth from "@hooks/useAxiosAuth"
import { isSuperuser } from "@utils/auth"
import { useAuthInfo } from "@propelauth/react"
import ProjectStatus from "../../components/Project/ProjectStatus"

interface Org {
    _id: string
    org_id: string
    projects: {
        _id: string
        name: string
        version: "insights" | "legacy"
    }[]
}

interface IProjectsProps {
    org: Org
}

function Projects({ org }: Readonly<IProjectsProps>) {
    const navigate = useNavigate()
    const axiosAuth = useAxiosAuth()
    const { user, userClass } = useAuthInfo()

    const userOrg = useMemo(() => userClass?.getOrg(org.org_id), [org])

    const createNewProject = () => {
        axiosAuth
            .post(`/datasets/${org._id}`)
            .then(res => {
                navigate(`/p/${res.data._id}`)
            })
            .catch(e => console.log(e))
    }

    return (
        <div className="project-list column" style={{ marginTop: "6em" }}>
            {(isSuperuser(user) || userOrg?.isAtLeastRole("Owner")) && (
                <span className="clickable" onClick={createNewProject}>
                    + New Project
                </span>
            )}
            {org?.projects?.filter(p => p.version !== "insights").map(p => (
                <div
                    key={p._id}
                    onClick={() => navigate(`/p/${p._id}`)}
                    className="project clickable box long-bar"
                >
                    <h3>{p.name || "Untitled"}</h3>
                    <ProjectStatus project={p} />
                </div>
            ))}
        </div>
    )
}

export default Projects
