import { questionTypes } from "../../utils/Variables"


const useModifyEntry = (setEntries, setEdits, project, canEdit) =>
    (property, id, value) => {
        if (!canEdit) return

        // Locally updates the entry
        setEntries(es =>
            es.map(e => {
                if (e._id === id) return { ...e, entry: { ...e.entry, [property]: value } }
                return e
            })
        )

        // Makes sure it's passing a float for number questions
        if (project.schema.find(s => s.id === property)?.type === questionTypes.number) 
            value = parseFloat(value)
        

        setEdits(ms => {
            // Find the index of an existing item with the same property and id
            const existingIndex = ms.findIndex(item => item.property === property && item.id === id)

            // Use a ternary operator to conditionally update or add a new change
            const updatedChanges =
                existingIndex !== -1
                    ? ms.map((item, index) => (index === existingIndex ? { ...item, value } : item))
                    : [...ms, { property, id, value }]

            // Return the updated array of changes
            return updatedChanges
        })
    }


export default useModifyEntry
