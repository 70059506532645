import platform from "platform"
import { widthBreakpoint } from "./Variables"


export const isIos = () => {
    const platform = getPlatformInfo()

    return platform.os.family === "iOS"
}

export const isMobile = () => window.innerWidth < widthBreakpoint

export const isSafari = () => platform.name === "Safari"

const getPlatformInfo = () => platform

export default getPlatformInfo
