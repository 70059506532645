import React, { useEffect, useState } from "react"

import { useAuthInfo } from "@propelauth/react"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "../../components/loading/Loading"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import { copy, getCopy } from "../../utils/Copy"
import { ErrorLevel } from "../../utils/ErrorLevel"
import ErrorPage from "../Error"
import Projects from "./Projects"
import Settings from "./Settings"
import TopBar from "./TopBar"

import "../../styling/Workspaces.scss"

const Workspace = () => {
    const { isLoggedIn } = useAuthInfo()
    const axiosAuth = useAxiosAuth()

    const { orgId } = useParams()
    const navigate = useNavigate()

    const [error, setError] = useState(null)
    const [orgs, setOrgs] = useState()
    const [currentOrg, setCurrentOrg] = useState()
    const [currentTab, setCurrentTab] = useState(0)

    useEffect(() => {
        orgId && isLoggedIn &&
            axiosAuth
                .get(`orgs/${orgId}`)
                .then(res => setCurrentOrg(res.data))
                .catch(e => {
                    console.log(e)
                    setError({
                        code: e?.response?.status
                    })
                })
    }, [orgId, isLoggedIn])

    useEffect(() => {
        isLoggedIn && axiosAuth
            .get("orgs/")
            .then(res => setOrgs(
                // Remove duplicated
                res.data.reduce((array, elem) => {
                    if (!array.find(e => e.org_id === elem.org_id)) array.push(elem)
                    return array
                }, [])))
            .catch(e => {
                console.log(e)
                setError({
                    code: e?.response?.status
                })
            })
    }, [isLoggedIn])

    useEffect(() => {
        // TODO use browser language
        // Once loaded, if orgs is empty, show the error page
        if (orgs && orgs.length === 0) {
            setError({
                message: getCopy(copy.errorNoOrg),
                detail: getCopy(copy.errorNoOrgDetail),
                errorLevel: ErrorLevel.Error,
                separatorExtraClass: "position2"
            })
            // Once loaded, if the org is not specified, navigate the first org
        } else if (orgs?.length && !orgId) {
            const lsOrgId = localStorage.getItem("orgId")
            let orgId = orgs[orgs.length - 1].org_id
            if (lsOrgId && orgs.find(e => e.org_id === lsOrgId))
                orgId = localStorage.getItem("orgId")
            navigate(`/w/${orgId}`)
        }
    }, [orgs])

    // This use effects monitors for new organizations that have to be added to the orgs array
    useEffect(() => {
        if (currentOrg) localStorage.setItem("orgId", currentOrg.org_id)

        if (orgs?.every(o => o.org_id !== currentOrg.org_id))
            setOrgs(os => [...os, currentOrg])
    }, [currentOrg])

    // Error page
    if (error) return <ErrorPage
        code={error.code}
        message={error.message}
        detail={error.detail}
        errorLevel={error.errorLevel}
        separatorExtraClass={error.separatorExtraClass}
    />

    if (!orgs || !currentOrg || currentOrg.org_id !== orgId) return <Loading />

    return (
        <div className="workspace page">
            <TopBar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                orgs={orgs}
                currentOrg={currentOrg}
            />

            {currentTab === 0 && <Projects org={currentOrg} />}
            {currentTab === 1 && <Settings org={currentOrg} setOrg={setCurrentOrg} />}
        </div>
    )
}

export default Workspace
