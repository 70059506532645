import EditQuestionPopup from "../../../components/Popups/EditQuestionPopup"
import { getQuestionIcon, questionTypes } from "../../../utils/Variables"
import React, { useEffect, useRef, useState } from "react"
import { normalizeQuestionOrOption } from "../../../utils/strings"

const Question = ({ index, question, setQuestion, schema, lang, disabled }) => {
    const lastOptionRef = useRef(null)
    const textAreaRef = useRef(null)
    const initialMountRef = useRef(true)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto" // Reset height to auto before resizing
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
        }
    }, [question.value[lang]])

    // focus the newly created option
    useEffect(() => {
        if (initialMountRef.current) initialMountRef.current = false
        else if (lastOptionRef.current) lastOptionRef.current.focus()
    }, [question?.options?.length])

    if (!question) return

    const editQuestion = (e, normalize) =>
        setQuestion({
            ...question,
            value: { ...question.value, [lang]: normalize ? normalizeQuestionOrOption(e.target.value) : e.target.value }
        })

    return (
        <>
            <div className="long-bar legacy-container" style={{ padding: "0 1em", flexGrow: 1 }}>
                <span className="index">{index}</span>
                <textarea
                    placeholder="Question"
                    className="borderless-input"
                    ref={textAreaRef}
                    value={question.value[lang] || ""}
                    onChange={editQuestion}
                    onBlur={e => editQuestion(e, true)}
                    disabled={disabled}
                />
                {getQuestionIcon(question.type || questionTypes.choose, {
                    className: ["clickable", "bordered"].join(" "),
                    onClick: () => setOpen(true)
                })}
            </div>
            {open && (
                <EditQuestionPopup
                    originalQuestion={question}
                    close={() => setOpen(false)}
                    setOriginalQuestion={setQuestion}
                    schema={schema}
                    lang={lang}
                    disabled={disabled}
                />
            )}
        </>
    )
}

export default Question
