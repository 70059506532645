import React, { useEffect, useState } from "react"
import useAxiosAuth from "../hooks/useAxiosAuth"
import { useAuthInfo } from "@propelauth/react"

import CustomLineChart from "../components/charts/PercentLineChart"
import StackedAreaChart from "../components/charts/StackedAreaChart"

import { copy } from "../utils/Copy"
import Toggle from "../components/menus/Toggle"

const Admin = () => {
    const [timeSpan, setTimeSpan] = useState("week")
    const [usage, setUsage] = useState()
    const [feedback, setFeedback] = useState()
    const [engagement, setEngagement] = useState()
    const [connections, setConnections] = useState()

    const { isLoggedIn } = useAuthInfo()
    const axiosAuth = useAxiosAuth()

    useEffect(() => {
        axiosAuth
            .get(`/admin/usage/${timeSpan}`)
            .then(res => {
                setUsage(res.data.total_weekly)
                setFeedback(res.data.feedback_weekly)
                setEngagement(res.data.engagement)
                setConnections(res.data.connections)
            })
            .catch(e => console.log(e))
    }, [isLoggedIn, timeSpan])

    if (!usage) return

    return (
        <div className="page column">
            <Toggle
                options={[
                    { id: "week", label: "weekly" },
                    { id: "month", label: "monthly" }
                ]}
                value={timeSpan}
                setValue={setTimeSpan}
            />
            <CustomLineChart
                data={usage}
                dataKeys={["count"]}
                title={`Surveys started by ${timeSpan}`}
            />
            <StackedAreaChart
                data={feedback}
                dataKeys={Object.keys(copy.interview.ratingOptions.en)}
                title={`Survey feedback by ${timeSpan}`}
            />
            <div className="box" style={{ alignItems: "center" }}>
                <h2>Reply statistics</h2>
                <div className="long-bar">
                    <div className="labeled-number">
                        <span>
                            {engagement.total_voice_replies + engagement.total_text_replies}
                        </span>
                        <span>Total replies</span>
                    </div>
                    <div className="labeled-number">
                        <span>
                            {(
                                (100 * engagement.total_voice_replies) /
                                (engagement.total_text_replies + engagement.total_voice_replies)
                            ).toFixed(1)}
                            %
                        </span>
                        <span>Voice replies</span>
                    </div>
                    <div className="labeled-number">
                        <span>
                            {(
                                engagement.total_voice_characters / engagement.total_voice_replies
                            ).toFixed(1)}
                        </span>
                        <span>Avg voice chars</span>
                    </div>
                    <div className="labeled-number">
                        <span>
                            {(
                                engagement.total_text_characters / engagement.total_text_replies
                            ).toFixed(1)}
                        </span>
                        <span>Avg text chars</span>
                    </div>
                </div>
            </div>
            <div className="box" style={{ alignItems: "center" }}>
                <h2>Connections</h2>
                <div className="long-bar">
                    <div className="labeled-number">
                        <span>{connections.interviews}</span>
                        <span>Active interviews</span>
                    </div>
                    <div className="labeled-number">
                        <span>{connections.editors}</span>
                        <span>Active editors</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin
