import React from "react"

const Divider = () => (
    <div
        style={{
            height: "1px",
            backgroundColor: "var(--border-color)",
            width: "100%",
            margin: "2em 0"
        }}
    ></div>
)

export default Divider
