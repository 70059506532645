import { useAuthInfo } from "@propelauth/react"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useProjectService } from "../../../hooks/services/useProjectService"
import useAutoSave from "../../../hooks/useAutoSave"
import { useWebSocket } from "../../../hooks/useWebSocket"
import "./index.scss"
import { ProjectContext } from "../AutoSaveProject"
import AnalysisTabAnalysisCol from "../ProjectArea/components/AnalysisTabAnalysisCol"
import AnalysisTabQuestionsCol from "../ProjectArea/components/AnalysisTabQuestionsCol"
import AnalysisTabVerbatimsCol from "../ProjectArea/components/AnalysisTabVerbatimsCol"
import { useAnalysisTab, useAnalysisTabDispatch } from "../ProjectArea/contexts/AnalysisTabProvider"
import { questionsFromSchema } from "../ProjectArea/utils/questions-from-schema"
import useModifyEntry from "../useModifyEntry"
import Loading from "@components/loading/Loading"
import { useProject } from "../ProjectArea/contexts/ProjectProvider"
import AnalysisTabCodeOccurrencesProvider from "../ProjectArea/contexts/AnalysisTabCodeOccurrencesProvider"
import usePrevProps from "../../../hooks/usePrevProps"

const Coding = () => {
    // #region Contexts
    const { project, canEdit } = useContext(ProjectContext)
    const { crossQuestionFilters, selectedCode } = useAnalysisTab()
    const { analysisData } = useProject()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Refs
    const analysisColumnRef = useRef()
    const verbatimsColumnRef = useRef()
    // #endregion

    // #region States
    const [analysisResults, setAnalysisResults] = useState(null)
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [currentAnalysis, setCurrentAnalysis] = useState(null)

    const [entries, setEntries] = useState()
    const [nEntries, setNEntries] = useState(0)
    const [page, setPage] = useState(0)
    const [nPages, setNPages] = useState(0)
    const [shouldReFetchData, setShouldReFetchData] = useState(true)

    const [hasLoadedPage, setHasLoadedPage] = useState(false)

    const [isRunningAnalysis, setIsRunningAnalysis] = useState(false)
    const [runningAnalysisProgress, setRunningAnalysisProgress] = useState(0)
    const [lastLoadingStepNumber, setLastLoadingStepNumber] = useState(0)

    const [edits, setEdits] = useState([])
    // #endregion

    // #region Other hooks

    const { projectId } = useParams()
    const { isLoggedIn } = useAuthInfo()
    const { addCallback, removeCallback, sendMessage, socket } = useWebSocket()

    // Auto saves the changes to the dataset
    useAutoSave(
        () => { if (edits.length) sendMessage({ operation: "data_edits", edits }) },
        edits,
        socket?.readyState === WebSocket.OPEN
    )

    // Builds function to modify the entry for each verbatim
    const modifyVerbatim = useModifyEntry(setEntries, setEdits, project, canEdit)

    // #endregion

    // #region Memos
    const lang = useMemo(() => project?.general_settings?.language, [project])
    const questions = useMemo(
        () => analysisResults && project
            ? questionsFromSchema(project.schema, analysisResults?.analysis, project?.source === "CSV")
            : [],
        [project, analysisResults])

    const shouldShowVerbatims = useMemo(() => {
        if (!analysisData || !currentAnalysis) return true

        const analysis = analysisData.analysis[currentAnalysis.id]
        if (!analysis) return true

        // Do not show anything if NPS, number, or select/multiple choice
        return !["nps", "number", "select", "multiple_choice"].includes(currentAnalysis.type)
    }, [analysisData, currentAnalysis])
    // #endregion

    // #region Callbacks
    const buildCrossQuestionFilters = useCallback(
        (includeSelectedCode = true) => [
            ...Object.entries(crossQuestionFilters).map(
                ([questionId, codes]) => codes.map(code => ({
                    id: questionId,
                    operator: "is",
                    // Workaround to parse to `number` when analysis is NPS
                    value: (
                        !Array.isArray(analysisData?.analysis[questionId]) &&
                        "values" in (analysisData?.analysis[questionId] ?? {})
                    ) ? Number(code.codeId) : code.codeId
                }))
            ).flat(),
            (includeSelectedCode && selectedCode)
                ? ({ id: selectedCode.analysisId, operator: "is", value: selectedCode.id })
                : null
        ].filter(i => i !== null),
        [analysisData, crossQuestionFilters, selectedCode]
    )

    const refreshAnalysis = useCallback(() => {
        const filters = buildCrossQuestionFilters(false)

        // update analysis for analysis tab
        projectService.getAnalysis({ projectId, serializedFilters: JSON.stringify(filters) }).then(analysisResponse => {
            setAnalysisResults(analysisResponse)
        })
    }, [buildCrossQuestionFilters, projectService, projectId])

    const handleWSMessage = useCallback(data => {
        const isRunningAnalysis = data.job_status?.completion !== undefined
        setIsRunningAnalysis(isRunningAnalysis)
        if (isRunningAnalysis)
            setRunningAnalysisProgress(data.job_status?.completion)

        // Receives a new parsed value
        if (data.parsed_value && data.id)
            setEntries(es =>
                es?.map(e => {
                    if (e._id === data.id) console.log("Updated: ", e.identifier)
                    if (e._id === data.id)
                        return { ...e, entry: { ...e.entry, ...data.parsed_value } }

                    return e
                })
            )


        // Resets the changes made to the data when saved
        if (data.saved) {
            setEdits([])
            refreshAnalysis()
        }

        // Append new entries
        if (data.new_entry) setEntries(es => [...es, data.new_entry])
    }, [refreshAnalysis])

    const setCurrentQuestionAndAnalysis = (q, a) => {
        setCurrentQuestion(q)
        setCurrentAnalysis(a)
        setHasLoadedPage(true)
    }
    // #endregion

    // #region Prev props
    const prevAnalysisFiltersForApi = usePrevProps(buildCrossQuestionFilters())
    useEffect(
        () => setShouldReFetchData(
            JSON.stringify(prevAnalysisFiltersForApi) !== JSON.stringify(buildCrossQuestionFilters())),
        [prevAnalysisFiltersForApi, buildCrossQuestionFilters]
    )
    // #endregion

    // #region Effects

    // Resets the selected code when the user changes question
    useEffect(() => {
        analysisTabDispatch({ type: "remove-verbatims-code-filter" })
        analysisTabDispatch({ type: "clear-code-book" })
    }, [analysisTabDispatch, currentQuestion?.id]) // `currentQuestion?.id` intentional to avoid pointer issues

    // Refresh currentQuestion, currentAnalysis and selectedCode after updating questions
    useEffect(() => {
        if (!currentQuestion || !currentAnalysis) return
        const refreshedQuestion = questions.find(q => q.id === currentQuestion.id)
        if (!refreshedQuestion) return
        setCurrentQuestion(refreshedQuestion)

        const refreshedAnalysis = refreshedQuestion.analysis.find(a => a.id === currentAnalysis.id)
        setCurrentAnalysis(refreshedAnalysis)
    }, [questions, currentAnalysis, currentQuestion, analysisTabDispatch])

    // Recomputes analysis every time the project schema changes
    useEffect(
        refreshAnalysis,
        [
            refreshAnalysis,
            isLoggedIn,
            project?.schema,
            crossQuestionFilters,
            buildCrossQuestionFilters,
            projectId,
            projectService
        ]
    )

    // Register the websocket message handling function
    useEffect(() => {
        addCallback(handleWSMessage)

        return () => {
            removeCallback(handleWSMessage)
        }
    }, [addCallback, removeCallback, handleWSMessage])

    // Resets entries and edits when loading
    useEffect(() => {
        setEntries(null)
        setEdits([])
    }, [projectId])

    // When the page changes, scrolls the verbatims to top
    useEffect(() => {
        if (!verbatimsColumnRef.current) return
        verbatimsColumnRef.current.scrollTop = 0
    }, [page])

    // By default, opens the first analysis
    useEffect(() => {
        if (hasLoadedPage) return

        if (!currentAnalysis && questions?.length)
            setCurrentQuestionAndAnalysis(questions[0], questions[0].analysis?.[0])
    }, [analysisResults, currentAnalysis, questions, hasLoadedPage])

    useEffect(() => {
        setShouldReFetchData(true)
    }, [page])

    // Load filtered entries
    useEffect(() => {
        if (!isLoggedIn || !shouldReFetchData) return

        const filters = buildCrossQuestionFilters()
        projectService.getEntries({ projectId, page, serializedFilters: JSON.stringify(filters) })
            .then(entriesData => {
                setEntries(entriesData.entries)
                setNPages(entriesData.n_pages)
                setNEntries(entriesData.total_entries)
            })
    }, [
        isLoggedIn,
        shouldReFetchData,
        projectId,
        page,
        projectService,
        currentAnalysis,
        currentQuestion,
        crossQuestionFilters,
        buildCrossQuestionFilters
    ])

    // onRunningAnalysisRefreshAnalysisEvery5Percent
    useEffect(() => {
        if (!isRunningAnalysis) {
            if (lastLoadingStepNumber !== 0)
                setLastLoadingStepNumber(0)

            return
        }

        if (runningAnalysisProgress > lastLoadingStepNumber + 5) {
            refreshAnalysis()
            setLastLoadingStepNumber(Math.round(runningAnalysisProgress))
        }
    }, [isRunningAnalysis, runningAnalysisProgress, lastLoadingStepNumber, refreshAnalysis])

    // #endregion

    if (!project || !currentQuestion || !entries) return <Loading />

    return (
        <div className="coding flex overflow-hidden h-full">
            <div
                id="div--analysis-tab-questions-col"
                className="h-full w-[28.34%] overflow-y-hidden bg-[#EDEDED] pt-12"
            >
                <AnalysisTabQuestionsCol
                    questions={questions}
                    currentQuestion={currentQuestion}
                    currentAnalysis={currentAnalysis}
                    setCurrentQuestionAndAnalysis={setCurrentQuestionAndAnalysis}
                />
            </div>
            <div className="flex flex-row justify-center gap-6 mx-20 mt-14 w-full">
                <AnalysisTabCodeOccurrencesProvider>
                    <div className="column1 flex-1" ref={analysisColumnRef}>
                        {currentAnalysis && entries && (
                            <AnalysisTabAnalysisCol
                                analysis={currentAnalysis}
                                lang={lang}
                            />
                        )}
                    </div>
                    <div className="column2 flex-1" ref={verbatimsColumnRef}>
                        {shouldShowVerbatims && currentQuestion && entries && (
                            <AnalysisTabVerbatimsCol
                                question={currentQuestion}
                                analysis={currentAnalysis}
                                entries={entries}
                                nEntries={nEntries}
                                page={page}
                                setPage={setPage}
                                nPages={nPages}
                                modifyVerbatim={modifyVerbatim}
                                lang={lang}
                            />
                        )}
                    </div>
                </AnalysisTabCodeOccurrencesProvider>
            </div>
        </div>
    )
}

export default Coding
