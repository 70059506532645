import IProjectGateway from "@services/projects/IProjectGateway"
import { createContext, useContext, useReducer } from "react"
import { IQuestionAnalysisFromSchema } from "../utils/questions-from-schema"
import { ArrayElement } from "@utils/types/array-element"

// #region Type definitions
type AnalysisDataState = Awaited<ReturnType<IProjectGateway["getAnalysis"]>>

interface IAnalysisTabProviderProps {
    children: React.ReactNode
}

interface IAnalysisTabContextState {
    /**
     * Filtered analysis for the analysis tab.
     */
    analysisData?: AnalysisDataState
    /**
     * Cross-question filters.
     */
    crossQuestionFilters: {
        [questionId: string]: {
            codeId: string
        }[]
    }
    /**
     * Theme/code selected for filtering **verbatims**.
     */
    selectedCode?: ArrayElement<IQuestionAnalysisFromSchema["codes"]>
    /**
     * Code book review data
     */
    codeBook: {
        selectedThemeId?: string
        edits: {
            [themeId: string]: {
                newLabel: string
                instruction: string
            }
        }
    }
}

type IAnalysisTabContextAction = {
    type: "add-question-cross-filter"
    questionId: string
    codeId: string
} | {
    type: "remove-question-cross-filter"
    questionId: string
    codeId: string
} | {
    type: "clear-cross-filter"
} | {
    type: "set-analysis-data"
    analysisData: AnalysisDataState
} | {
    type: "set-verbatims-code-filter"
    selectedCode: ArrayElement<IQuestionAnalysisFromSchema["codes"]>
} | {
    type: "remove-verbatims-code-filter"
} | {
    type: "set-code-book-selected-theme"
    themeId: string
} | {
    type: "add-code-book-edit"
    themeId: string
    newLabel: string
    instruction: string
} | {
    type: "clear-code-book"
}
// #endregion

// #region Context definitions
const AnalysisTabContext = createContext({} as IAnalysisTabContextState)
const AnalysisTabContextDispatch = createContext({} as React.Dispatch<IAnalysisTabContextAction>)
// #endregion

// #region Hook definitions
export function useAnalysisTab() {
    return useContext(AnalysisTabContext)
}

export function useAnalysisTabDispatch() {
    return useContext(AnalysisTabContextDispatch)
}
// #endregion

// #region Util functions
function doesFilterExistWith(state: IAnalysisTabContextState, questionId: string, codeId: string) {
    return state.crossQuestionFilters[questionId]?.find(code => code.codeId === codeId) !== undefined
}
// #endregion

// #region Provider definition
export default function AnalysisTabProvider({
    children
}: Readonly<IAnalysisTabProviderProps>) {
    const initialState: IAnalysisTabContextState = {
        crossQuestionFilters: {},
        codeBook: { edits: {} }
    }

    const [state, dispatch] = useReducer(AnalysisTabReducer, initialState)

    return (
        <AnalysisTabContext.Provider value={state}>
            <AnalysisTabContextDispatch.Provider value={dispatch}>
                {children}
            </AnalysisTabContextDispatch.Provider>
        </AnalysisTabContext.Provider>
    )
}
// #endregion

// #region Reducer definition
function AnalysisTabReducer(
    state: IAnalysisTabContextState,
    action: IAnalysisTabContextAction
): IAnalysisTabContextState {
    switch (action.type) {
        case "add-question-cross-filter": {
            if (doesFilterExistWith(state, action.questionId, action.codeId)) return state

            return {
                ...state,
                crossQuestionFilters: {
                    ...state.crossQuestionFilters,
                    [action.questionId]: [
                        ...(state.crossQuestionFilters[action.questionId] ?? []),
                        {
                            codeId: action.codeId
                        }
                    ]
                }
            }
        }
        case "remove-question-cross-filter": {
            if (!doesFilterExistWith(state, action.questionId, action.codeId)) return state

            return {
                ...state,
                crossQuestionFilters: {
                    ...state.crossQuestionFilters,
                    [action.questionId]: [
                        ...state.crossQuestionFilters[action.questionId].filter(
                            code => action.codeId !== code.codeId
                        )
                    ]
                }
            }
        }
        case "clear-cross-filter": {
            return {
                ...state,
                crossQuestionFilters: {}
            }
        }
        case "set-analysis-data": {
            return {
                ...state,
                analysisData: action.analysisData
            }
        }
        case "set-verbatims-code-filter": {
            return {
                ...state,
                selectedCode: action.selectedCode
            }
        }
        case "remove-verbatims-code-filter": {
            return {
                ...state,
                selectedCode: undefined
            }
        }
        case "set-code-book-selected-theme": {
            return {
                ...state,
                codeBook: {
                    ...state.codeBook,
                    selectedThemeId: action.themeId
                }
            }
        }
        case "add-code-book-edit": {
            if (!action.newLabel && !action.instruction) {
                // remove edit
                delete state.codeBook.edits[action.themeId]
                return {
                    ...state,
                    codeBook: {
                        ...state.codeBook,
                        edits: {
                            ...state.codeBook.edits
                        }
                    }
                }
            }

            return {
                ...state,
                codeBook: {
                    ...state.codeBook,
                    edits: {
                        ...state.codeBook.edits,
                        [action.themeId]: {
                            newLabel: action.newLabel,
                            instruction: action.instruction
                        }
                    }
                }
            }
        }
        case "clear-code-book": {
            return {
                ...state,
                codeBook: { edits: {} }
            }
        }
        default: {
            return state
        }
    }
}
// #endregion