import { getOptions } from "./miscellaneous"

export const explainFilter = (filter, schema, lang) => {
    // Finds the question we are filtering for
    const question = schema.find(q => q.id === filter.id)
    const displayQuestion = question.header || question.value?.[lang]

    // This only applies for "is" operator
    if (filter.operator === "is") {
        // Gets the option we are filtering for
        const option = getOptions(question).find(o => o.id === filter.value) || {}

        return `${displayQuestion} = ${option.label?.[lang] || option.name || "No answer"}`
    }

    // Displays a less than filter
    if (filter.operator === "lt") return `${displayQuestion} < ${filter.value}`

    // Displays greater than filter
    if (filter.operator === "gt") return `${displayQuestion} > ${filter.value}`
}
