import PropTypes from "prop-types"
import { useMemo } from "react"
import YoutubeEmbedBox from "./components/YoutubeEmbedBox"
import obtainEmbedType from "./utils/obtain-embed-type"

/**
 * @param {Object} props
 * @param {string | undefined} props.id
 * @param {string} props.src
 * @param {React.Ref<HTMLIFrameElement> | undefined} props.customRef
 * @param {((event, player) => void) | undefined} props.onVideoEnded
 * @param {((event, player) => void) | undefined} props.onVideoLoaded
 */
const EmbedBox = ({ src, id, customRef, onVideoEnded, onVideoLoaded }) => {
    // #region Memos
    const type = useMemo(() => obtainEmbedType(src), [src])
    // #endregion

    return (
        <>
            {type === "youtube" && (
                <YoutubeEmbedBox
                    id={id}
                    src={src}
                    customRef={customRef}
                    onVideoEnded={onVideoEnded}
                    onVideoLoaded={onVideoLoaded}
                />
            )}
        </>
    )
}

EmbedBox.propTypes = {
    src: PropTypes.string.isRequired
}

export default EmbedBox