import ErrorLevel from "../utils/ErrorLevel"
import { GoAlertFill } from "react-icons/go"
import { IoCloseSharp } from "react-icons/io5"
import { MdInfo } from "react-icons/md"

import "../styling/TopBarMessage.css"

const TopBarMessage = ({
    message,
    messageLevel = ErrorLevel.Info,
    setMessage
}) => {
    const className = messageLevel >= ErrorLevel.Warning ? "warning" : "info"
    return (
        message && <div
            className={"top-bar-message " + className}
        >
            <span className="left">{messageLevel >= ErrorLevel.Warning ? <GoAlertFill /> : <MdInfo />}</span>
            <span>{message}</span>
            <span className="right"><IoCloseSharp onClick={() => { setMessage() }} /></span>
        </div>
    )
}

export default TopBarMessage
