import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

export function useGetInterviewEngine() {
    // #region Query parameters
    const [searchParams] = useSearchParams()
    // #endregion

    // #region States
    const [engine, setEngine] = useState<"webrtc" | "websocket">()
    // #endregion

    // #region Effects
    useEffect(() => {
        if (engine !== undefined) return

        const isCurrentlyWebRTC = searchParams.get("webrtc") === "true"
        setEngine(isCurrentlyWebRTC ? "webrtc" : "websocket")
    }, [engine, searchParams])
    // #endregion

    return { engine }
}