import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts"
import { stringToColor } from "../../utils/miscellaneous"

const StackedAreaChart = ({ data, dataKeys, title }) => (
    <div className="box chart-container">
        <h2>{title}</h2>
        <ResponsiveContainer width={900} height={300}>
            <AreaChart
                width={500}
                height={400}
                data={data.map(d => ({ ...d, ...d.percentages }))}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date_start" />
                <YAxis />
                <Tooltip />
                {dataKeys.map(key => (
                    <Area
                        key={key}
                        type="monotone"
                        dataKey={key}
                        stackId="1"
                        stroke={stringToColor(key)}
                        fill={stringToColor(key)}
                    />
                ))}
            </AreaChart>
        </ResponsiveContainer>
    </div>
)

export default StackedAreaChart
