import React, { useMemo } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

interface IPaginationProps {
    /**
     * Zero-indexed page number.
     */
    page: number
    /**
     * Set-page-state function from your component.
     */
    setPage: React.Dispatch<React.SetStateAction<number>>
    /**
     * Total number of pages one-indexed.
     */
    nPages: number
}

const Pagination = ({ page, setPage, nPages }: Readonly<IPaginationProps>) => {
    // #region Memos
    const currentPage = useMemo(() => page + 1, [page])
    const totalNumberOfPages = useMemo(() => nPages > 0 ? nPages : 1, [nPages])

    const backArrowClassName = useMemo(() => page === 0 ? "un-clickable" : "clickable", [page])
    const forwardArrowClassName = useMemo(
        () => nPages <= 1 || page === nPages - 1 ? "un-clickable" : "clickable",
        [page, nPages]
    )
    // #endregion

    return (
        <div className="pagination bordered">
            <IoIosArrowBack
                className={backArrowClassName}
                onClick={() => {
                    if (backArrowClassName === "un-clickable") return

                    setPage(p => Math.max(0, p - 1))
                }}
            />
            <span>
                {currentPage} of {totalNumberOfPages}
            </span>
            <IoIosArrowForward
                className={forwardArrowClassName}
                onClick={() => {
                    if (forwardArrowClassName === "un-clickable") return

                    setPage(p => Math.min(p + 1, nPages - 1))
                }}
            />
        </div>
    )
}

export default Pagination
