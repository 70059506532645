import React from "react"

const NPSInput = ({ addClosedMessage, message, isPlaying }) => (
    <div id="nps-input" className="wrap-row">
        {Array.from({ length: 10 }, (_, i) => (
            <button
                key={i}
                style={{ padding: "1em 0" }}
                onClick={() => addClosedMessage((i + 1).toString(), message.id, i + 1)}
                disabled={isPlaying}
            >
                <h3 style={{ textAlign: "center" }}>{i + 1}</h3>
            </button>
        ))}
    </div>
)

export default NPSInput
