

const ToggleInput = ({
    options,
    selected,
    selectOption,
    toggleClassName,
    optionClassName
}) => (
    <div className={toggleClassName ?? "toggle"}>
        {options.map(o =>
            <div
                key={o.id}
                className={[
                    optionClassName ?? "",
                    selected === o.id ? "selected" : undefined
                ].join(" ")}
                onClick={() => selectOption(o.id)}
            >
                {o.label}
            </div>)}
    </div>
)

export default ToggleInput
