import { isValid, toDate } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { useMemo } from "react"

export function useFormattedDate(date: string) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // force UTC
    // it works only for dates retrieved from the API as YYYY-MM-DDThh:mm:ss.mmmmmm
    const actualDateStr = date ? `${date}Z` : ""

    return useMemo(
        () => ({
            text: isValid(toDate(actualDateStr))
                ? formatInTimeZone(toDate(actualDateStr), timeZone, "do LLL yyyy hh:mmaaa")
                : "Not available"
        }),
        [actualDateStr, timeZone]
    )
}