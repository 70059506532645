import { useMemo } from "react"
import { MdPhoneIphone } from "react-icons/md"
import { IoMdDesktop } from "react-icons/io"

interface IProjectMetricsBoxGraphProportionEntryProps {
    mobilePercentage: number
    desktopPercentage: number
}

export default function ProjectMetricsBoxGraphProportionEntry({
    desktopPercentage,
    mobilePercentage
}: Readonly<IProjectMetricsBoxGraphProportionEntryProps>) {
    // #region Memos
    const { mobileWidth, desktopWidth } = useMemo(() => {
        // Total sum in pixels: 115px
        const totalWidth = 115

        if (mobilePercentage + desktopPercentage !== 100)
            return {
                mobileWidth: totalWidth / 2,
                desktopWidth: totalWidth / 2
            }


        let mobileWidth = Math.floor((mobilePercentage / 100) * totalWidth)
        const desktopWidth = Math.floor((desktopPercentage / 100) * totalWidth)

        if (mobileWidth + desktopWidth < totalWidth)
            mobileWidth = totalWidth - desktopWidth


        return { mobileWidth, desktopWidth }
    }, [mobilePercentage, desktopPercentage])

    const mobileBarClassNames = useMemo(() => {
        const baseClassName = "bg-[#5465FE] h-2 rounded-r"
        const hasWrongPercentageSum = desktopPercentage + mobilePercentage > 100
        const hasOnlyMobile = desktopPercentage === 0 && mobilePercentage === 100

        if (hasWrongPercentageSum || !hasOnlyMobile) return baseClassName
        return `${baseClassName} rounded-l`
    }, [desktopPercentage, mobilePercentage])

    const desktopBarClassNames = useMemo(() => {
        const baseClassName = "bg-[#FF6392] h-2 rounded-l"
        const hasWrongPercentageSum = desktopPercentage + mobilePercentage > 100
        const hasOnlyDesktop = mobilePercentage === 0 && desktopPercentage === 100

        if (hasWrongPercentageSum || !hasOnlyDesktop) return baseClassName
        return `${baseClassName} rounded-r`
    }, [desktopPercentage, mobilePercentage])
    // #endregion

    return (
        <div className="flex flex-row items-center gap-2 p-[1.25em] bg-glaut-cards rounded-[0.5em]">
            <p className="text-[19.2px]">{mobilePercentage}%</p>
            <MdPhoneIphone className="text-glaut-grey w-[14px] h-[14px]" />
            <div className="flex flex-row gap-[1px]">
                <div className={mobileBarClassNames} style={{ width: `${mobileWidth}px` }}></div>
                <div className={desktopBarClassNames} style={{ width: `${desktopWidth}px` }}></div>
            </div>
            <div className="rounded-none">
                <IoMdDesktop className="text-glaut-grey w-[14px] h-[14px]" />
            </div>
            <p className="text-[19.2px]">{desktopPercentage}%</p>
        </div>
    )
}