import { IInsightAnalysis } from "src/@types/analysis"

/**
 * Search recursively for the code ID in a certain insight analysis.
 */
export function searchCodeInAnalyses(analyses: IInsightAnalysis[], codeId: string): IInsightAnalysis | null {
    for (const analysis of analyses) {
        if (analysis.id === codeId) return analysis

        const childAnalysis = searchCodeInAnalyses(analysis.children, codeId)
        if (childAnalysis !== null) return childAnalysis
    }

    return null
}
