import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import FloatingMenu from "@components/layouts/FloatingMenu"
import LoadingBar from "@components/loading/LoadingBar"
import { useAnalysisTab, useAnalysisTabDispatch } from "@pages/Project/ProjectArea/contexts/AnalysisTabProvider"
import { copy, getCopy } from "@utils/Copy"
import { questionTypes } from "@utils/Variables"
import { getLanguageKey } from "@utils/language"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import ReactDOMServer from "react-dom/server"
import { BiDotsVerticalRounded } from "react-icons/bi"
import { FaChevronDown, FaChevronUp, FaTag, FaTags } from "react-icons/fa"
import { IoMdArrowUp } from "react-icons/io"
import { IoCloseSharp } from "react-icons/io5"
import {
    MdCancel,
    MdCheckCircle,
    MdClose,
    MdEditNote,
    MdInfoOutline,
    MdMergeType,
    MdOutlineChat,
    MdOutlineDescription,
    MdOutlineFilterAlt,
    MdOutlinePalette
} from "react-icons/md"
import CodeOccurrencesBox from "./components/CodeOccurrencesBox"
import "./index.scss"

export const CodeMenu = ({
    isSubcode,
    lang,
    anchorRef,
    onClose,
    onMergeWith,
    onCreateSubtheme,
    onEditName,
    onChangeDescription,
    onSelectColor,
    onMakeTheme,
    onDeleteCode,
    onFilterByCode
}) => {
    const elements = [
        onFilterByCode && {
            icon: <MdOutlineFilterAlt className="w-4 h-4" />,
            text: "Filter by theme",
            textClassName: "text-[13.33px]",
            onClick: onFilterByCode,
            class: ""
        },
        onMergeWith && {
            icon: <MdMergeType className="w-4 h-4" />,
            text: getCopy(copy.codes.mergeWith, lang),
            textClassName: "text-[13.33px]",
            onClick: onMergeWith,
            class: "merge-with"
        },
        onCreateSubtheme && {
            icon: <FaTags className="w-4 h-4" />,
            text: getCopy(copy.codes.createSubtheme, lang),
            textClassName: "text-[13.33px]",
            onClick: onCreateSubtheme,
            class: "create-subtheme"
        },
        onEditName && {
            icon: <MdEditNote className="w-4 h-4" />,
            text: getCopy(copy.codes.editName, lang),
            textClassName: "text-[13.33px]",
            onClick: onEditName,
            class: "edit-name"
        },
        onChangeDescription && {
            icon: <MdOutlineDescription className="w-4 h-4" />,
            text: getCopy(copy.codes.changeDescription, lang),
            textClassName: "text-[13.33px]",
            onClick: onChangeDescription,
            class: "change-description"
        },
        onSelectColor && {
            icon: <MdOutlinePalette className="w-4 h-4" />,
            text: getCopy(copy.codes.selectColor, lang),
            textClassName: "text-[13.33px]",
            onClick: onSelectColor,
            class: "select-color"
        },
        onMakeTheme && {
            icon: <IoMdArrowUp className="w-4 h-4" />,
            text: getCopy(copy.codes.makeTheme, lang),
            textClassName: "text-[13.33px]",
            onClick: onMakeTheme,
            class: "make-theme"
        },
        onDeleteCode && {
            icon: <MdClose />,
            text: isSubcode ? getCopy(copy.codes.deleteSubcode, lang) : getCopy(copy.codes.deleteCode, lang),
            textClassName: "text-[13.33px]",
            onClick: onDeleteCode,
            class: "delete-code"
        }
    ]

    return <FloatingMenu
        anchorRef={anchorRef}
        onClose={onClose}
        elements={elements}
        className="code-menu py-3"
    />
}

const EditNameButtons = ({ onEditConfirm, onEditCancel }) => <span className="code-edit-buttons">
    <MdCheckCircle className="confirm" onClick={onEditConfirm} />
    <MdCancel className="cancel" onClick={onEditCancel} />
</span>

const Code = ({
    question,
    code,
    type = "",
    lang,
    colorIndex,
    number,
    percentage,
    setCodeName,
    deleteCode,
    removeCode,
    onClick,
    onMergeWith,
    onMergeToTarget,
    onCreateSubtheme,
    onCreateSubthemeAddRemove,
    selectedCode,
    section,
    codeToMerge,
    makeTheme,
    createSubthemeTheme,
    isClickable,
    hideSubcodes,
    disabled = false,
    showMenuButton = true
}) => {
    // #region Contexts
    const { selectedCode: globalSelectedCode } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States

    const [showSubcodes, setShowSubcodes] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [editName, setEditName] = useState(false)
    const [newName, setNewName] = useState("")

    // Monitors dra
    const [draggedOver, setIsDraggedOver] = useState(false)

    // #endregion

    // #region Refs
    const codeRef = useRef()
    const row1Ref = useRef()
    const childrenRef = useRef()
    // #endregion

    // #region Memos
    const isSubcodeSelected = useMemo(() => {
        const containsId = (code, targetId) => {
            if (code.id === targetId) return true
            return code.subcodes?.some(subcode => containsId(subcode, targetId))
        }
        return selectedCode ? containsId(code, selectedCode.id) : false
    }, [selectedCode])

    // Codes with parent are subcodes
    const isSubcode = code.parent && code.parent !== "root"
    // Subcodes with subcodes are subthemes
    const isSubtheme = isSubcode && code.subcodes?.length
    // If codeToMerge change, check if current code is a merge target
    const expandSubcodes = useMemo(
        () => code.subcodes?.length > 0 && !hideSubcodes && (
            showSubcodes ||
            // isSubcodeSelected ||
            isSubtheme ||
            (codeToMerge && codeToMerge.parent === code.id) ||
            (createSubthemeTheme && createSubthemeTheme.id === code.id)
        ),
        [
            code.subcodes?.length,
            hideSubcodes,
            showSubcodes,
            isSubcodeSelected,
            isSubtheme,
            codeToMerge,
            createSubthemeTheme
        ])
    // If codeToMerge change, check if current code is a merge target
    const isMergeTarget = useMemo(
        () => codeToMerge && codeToMerge.parent === code.parent && codeToMerge.id !== code.id && !code.subcodes?.length,
        [codeToMerge])
    // If createSubthemeTheme change, check if current code is a valid target
    const isCreateSubthemeTarget = useMemo(
        () => createSubthemeTheme && createSubthemeTheme.id === code.parent && !code.subcodes?.length,
        [createSubthemeTheme])
    // Check if during specific operation the code must be able to grab
    const isGrabEnabled = useMemo(
        () => // During merge with
            (codeToMerge && isMergeTarget) ||
            // During create subtheme
            (createSubthemeTheme && isCreateSubthemeTarget) ||
            // Default
            (!codeToMerge && !createSubthemeTheme && isClickable)
        , [isClickable, codeToMerge, createSubthemeTheme])
    // Check if during specific operation the code must be gray
    const percentageValue = useMemo(() => percentage || code.value || 0, [percentage, code.value])
    const isGray = useMemo(
        () =>
            // If disabled
            disabled ||
            // Gray out when there is no occurrences
            percentageValue === 0 ||
            // During merge with
            (codeToMerge && !isMergeTarget && code.parent !== "root") ||
            // During create subtheme
            (createSubthemeTheme && !isCreateSubthemeTarget && code.parent !== "root")
        , [disabled, codeToMerge, createSubthemeTheme, percentageValue])

    const isFilterable = useMemo(
        () => [
            questionTypes.insight,
            questionTypes.select,
            questionTypes.number,
            questionTypes.nps
        ].includes(question.type),
        [question]
    )

    let label = code.name !== undefined ? code.name : (code.label !== undefined ? code.label : code)
    if (typeof label === "object") label = getLanguageKey(label, lang)
    colorIndex = colorIndex || code.colorIndex || 1
    number = number || code.occurrences || (code.subcodes ? code.subcodes.length : "-")
    const color = `color${colorIndex}`
    // #endregion

    // #region Callbacks
    const toggleSelection = e => {
        e.stopPropagation()
        setShowSubcodes(!showSubcodes)
    }
    const toggleMenu = e => {
        e.stopPropagation()
        setShowMenu(!showMenu)
    }
    const onEditName = e => {
        e.stopPropagation()
        setNewName(label)
        setShowMenu(false)
        setEditName(true)
    }
    const onEditConfirm = e => {
        e.stopPropagation()
        setEditName(false)
        setCodeName(question.id, code.id, newName)
    }
    const onEditCancel = e => {
        e.stopPropagation()
        setNewName(label)
        setEditName(false)
    }
    const onDeleteCode = e => {
        e.stopPropagation()
        setShowMenu(false)
        deleteCode(question.id, code.id)
    }
    const onMakeTheme = e => {
        e.stopPropagation()
        setShowMenu(false)
        makeTheme?.(question.id, code.id)
    }

    const onCreateSubthemeWrapper = e => {
        e.stopPropagation()
        setShowMenu(false)
        onCreateSubtheme(question.id, code.id)
    }
    const onMergeWithWrapper = e => {
        e.stopPropagation()
        setShowMenu(false)
        onMergeWith(question.id, code.id)
    }
    const onClickWrapper = e => {
        if (isGrabEnabled) {
            e.stopPropagation()

            if (codeToMerge) {
                onMergeToTarget(question.id, code.id)
            }
            else if (createSubthemeTheme) {
                onCreateSubthemeAddRemove(question.id, code.id)
            }
            else if (onClick) {
                onClick(e, code)
            }
            else
                if (globalSelectedCode?.id === code.id) {
                    analysisTabDispatch({
                        type: "remove-verbatims-code-filter"
                    })
                }
                else {
                    analysisTabDispatch({
                        type: "set-verbatims-code-filter",
                        selectedCode: code
                    })

                    setShowSubcodes(true)
                }
        }
    }

    const handleFilterByCode = useCallback(() => {
        analysisTabDispatch({
            type: "add-question-cross-filter",
            questionId: code.analysisId,
            codeId: code.id
        })
    }, [analysisTabDispatch, code])
    // #endregion

    // #region Effects

    //  This turns the code into a draggable element
    useEffect(() => draggable({
        element: isSubcode ? codeRef.current : row1Ref.current,
        getInitialData: () => ({ code, question }),
        canDrag: () => [type, section].includes("stack")
    }), [showSubcodes, isSubcodeSelected])

    // Turns the theme into a droppable container
    useEffect(() => {
        if (isSubcode) return
        if (question.type !== "insight") return

        return dropTargetForElements({
            element: childrenRef.current || codeRef.current,
            onDragEnter: ({ source }) => {
                // Only allow for dragged over id the code being dragged has a different parent
                setIsDraggedOver(![source.data.code.parent, source.data.code.id].includes(code.id))
            },
            getData: () => ({ code, question }),
            onDragLeave: () => setIsDraggedOver(false),
            onDrop: () => setIsDraggedOver(false),
            canDrop: () => [type, section].includes("stack")
        })
    }, [showSubcodes, code, isSubcode, question, section, type])

    // #endregion

    return [
        (<div
            key={code.id}
            className={[(isSubcode ? "subcode-wrapper" : "code-wrapper"), type !== "subcode" ? type : ""].join(" ")}
        >
            <div
                className={[
                    (isSubcode ? "subcode" : "code"),
                    !isGray ? color : "color0",
                    (editName ? "edit" : ""),
                    type,
                    (draggedOver && !showSubcodes && !isSubcodeSelected) ? "dragged-over" : "",
                    editName ? "editing" : ""
                ].join(" ")}
                onClick={!isGray ? onClickWrapper : undefined}
                ref={codeRef}
            >
                <div className={`row1 ${isGrabEnabled ? "isGrabbable" : ""}`} ref={row1Ref}>
                    <div>
                        {type === "stack"
                            ? isSubtheme
                                ? <FaTags className="code-icon small-icon" />
                                : <FaTag className="code-icon small-icon" />
                            : (
                                <CodeOccurrencesBox
                                    number={number}
                                    percentage={percentageValue}
                                />
                            )
                        }
                        {editName ?
                            <input
                                value={newName}
                                onChange={e => setNewName(e.target.value)}
                                onClick={e => e.stopPropagation()}
                                autoFocus
                            /> :
                            <span className="code-label">{label}</span>}
                        {!isSubcode && code?.description && (
                            <MdInfoOutline
                                className="text-glaut-light-grey w-[16px] h-[16px]"
                                data-tooltip-id="tooltip--analysis-tab"
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <div className="max-w-72">
                                        <p className="text-wrap break-words">
                                            {code.description}
                                        </p>
                                    </div>
                                )}
                                data-tooltip-place="right"
                            />
                        )}
                    </div>

                    {editName && <EditNameButtons
                        onEditConfirm={onEditConfirm}
                        onEditCancel={onEditCancel}
                    />}
                    {!editName && <span className="code-buttons">
                        {type === "stack" && (
                            <span className="code-number">
                                <span className="text-sm font-medium p-0">{number}</span>
                                <MdOutlineChat className="text-glaut-grey" />
                            </span>
                        )}
                        {showMenuButton && isFilterable && (
                            <BiDotsVerticalRounded
                                className="menu-button text-glaut-dark-grey hover:text-glaut-light-grey"
                                onClick={!isGray ? toggleMenu : undefined}
                            />
                        )}
                        {removeCode &&
                            <IoCloseSharp className="close-button circular-button" onClick={removeCode} />}
                    </span>}
                </div>
                {type === "stack" && !isSubtheme && (
                    <div className="row2 flex flex-row items-center justify-between gap-2">
                        <div className="code-percentage max-w-[80%]">
                            <LoadingBar percentage={percentageValue} showPercentage={true} precision={0} />
                        </div>
                        {code.subcodes?.length > 0 && !isGray && (
                            <div
                                onClick={toggleSelection}
                                className="code-toggle-subcodes ml-2 text-xs text-[#5465FE] underline select-none cursor-pointer">
                                {showSubcodes
                                    ? (
                                        <div className="flex flex-row items-center gap-1">
                                            Hide codes <FaChevronUp className="w-3 h-3" />
                                        </div>
                                    )
                                    : (
                                        <div className="flex flex-row items-center gap-1">
                                            Show codes <FaChevronDown className="w-3 h-3" />
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                )}
            </div>
            {showMenu && ReactDOM.createPortal(
                <CodeMenu
                    isSubcode={isSubcode}
                    lang={lang}
                    onClose={toggleMenu}
                    anchorRef={codeRef}
                    onEditName={code.canRename && onEditName}
                    onDeleteCode={code.canDelete && onDeleteCode}
                    onMergeWith={code.canMergeWith && onMergeWithWrapper}
                    onMakeTheme={isSubcode && onMakeTheme}
                    onCreateSubtheme={code.canCreateSubtheme && onCreateSubthemeWrapper}
                    onFilterByCode={handleFilterByCode}
                />,
                document.getElementById("portal")
            )}
        </div>),
        (expandSubcodes && <div
            key="subcodes"
            ref={childrenRef}
            className={["subcode-container", draggedOver ? "dragged-over" : "", type].join(" ")}>
            {code.subcodes
                .sort((a, b) => {
                    // Sub themes (with children codes) should go first, else sorts by occurrences
                    if (a.subcodes?.length && !b.subcodes?.length) return 1
                    if (!a.subcodes?.length && b.subcodes?.length) return -1
                    return b.occurrences - a.occurrences
                }).map(subcode => (
                    <Code
                        type={subcode?.subcodes?.length ? "stack" : "subcode"}
                        question={question}
                        code={subcode}
                        key={subcode.id}
                        lang={lang}
                        setCodeName={setCodeName}
                        deleteCode={deleteCode}
                        onClick={onClick}
                        onMergeWith={onMergeWith}
                        onMergeToTarget={onMergeToTarget}
                        onCreateSubtheme={onCreateSubtheme}
                        makeTheme={makeTheme}
                        onCreateSubthemeAddRemove={onCreateSubthemeAddRemove}
                        selectedCode={selectedCode}
                        createSubthemeTheme={createSubthemeTheme}
                        section={section || type}
                        codeToMerge={codeToMerge}
                        isClickable={isClickable}
                    />
                ))
            }
        </div>)
    ]
}

export default Code
