import { BsTextParagraph } from "react-icons/bs"
import { CiHashtag, CiShoppingTag, CiStar } from "react-icons/ci"
import { TfiArrowCircleDown } from "react-icons/tfi"
import { IoPersonOutline } from "react-icons/io5"
import { AiOutlineQuestion } from "react-icons/ai"
import { v4 as uuid } from "uuid"
import { IconBaseProps } from "react-icons/lib"

export const languages: Record<string, { name: string, flag: string } | undefined> = {
    ar: {
        name: "Arabic",
        flag: "🇸🇦"
    },
    zh: {
        name: "Chinese",
        flag: "🇨🇳"
    },
    hr: {
        name: "Croatian",
        flag: "🇭🇷"
    },
    cs: {
        name: "Czech",
        flag: "🇨🇿"
    },
    nl: {
        name: "Dutch",
        flag: "🇳🇱"
    },
    en: {
        name: "English (United States)",
        flag: "🇺🇸"
    },
    en_GB: {
        name: "English (United Kingdom)",
        flag: "🇬🇧"
    },
    en_AU: {
        name: "English (Australia)",
        flag: "🇦🇺"
    },
    fi: {
        name: "Finnish",
        flag: "🇫🇮"
    },
    fr: {
        name: "French",
        flag: "🇫🇷"
    },
    de: {
        name: "German",
        flag: "🇩🇪"
    },
    /**
     * Switzerland have several dialects for german. After some research, it was chosen the one that Azure uses.
     * 
     * @see https://www.localeplanet.com/icu/de-CH/index.html de_CH
     * @see https://www.localeplanet.com/icu/gsw-CH/index.html gsw_CH
     */
    de_CH: {
        name: "German (Switzerland)",
        flag: "🇨🇭"
    },
    el: {
        name: "Greek",
        flag: "🇬🇷"
    },
    hi_IN: {
        name: "Hindi (India)",
        flag: "🇮🇳"
    },
    it: {
        name: "Italian",
        flag: "🇮🇹"
    },
    id: {
        name: "Indonesian",
        flag: "🇮🇩"
    },
    ja: {
        name: "Japanese",
        flag: "🇯🇵"
    },
    no: {
        name: "Norwegian",
        flag: "🇳🇴"
    },
    pl: {
        name: "Polish",
        flag: "🇵🇱"
    },
    pt: {
        name: "Portuguese",
        flag: "🇵🇹"
    },
    pt_BR: {
        name: "Portuguese (Brazil)",
        flag: "🇧🇷"
    },
    ru: {
        name: "Russian",
        flag: "🇷🇺"
    },
    es: {
        name: "Spanish",
        flag: "🇪🇸"
    },
    es_AR: {
        name: "Spanish (Argentina)",
        flag: "🇦🇷"
    },
    es_MX: {
        name: "Spanish (Mexico)",
        flag: "🇲🇽"
    },
    es_CL: {
        name: "Spanish (Chile)",
        flag: "🇨🇱"
    },
    es_CO: {
        name: "Spanish (Colombia)",
        flag: "🇨🇴"
    },
    sv: {
        name: "Swedish",
        flag: "🇸🇪"
    },
    tr: {
        name: "Turkish",
        flag: "🇹🇷"
    },
    uk: {
        name: "Ukrainian",
        flag: "🇺🇦"
    }
} as const

export const widthBreakpoint = 1200 as const

export const colors = [
    "#1F8A70",
    "#BFDB38",
    "#FC7300",
    "#47A992",
    "#D864A9",
    "#6F61C0",
    "#FFC26F"
] as const

export const questionTypes = {
    shortText: "short_text",
    number: "number",
    date: "date",
    multipleChoice: "multiple_choice",
    select: "select",
    entity: "entity",
    insight: "insight",
    open: "open",
    nps: "nps",
    identifier: "identifier",
    choose: "choose"
} as const

type QuestionTypeKey = keyof typeof questionTypes
type QuestionTypeValue = (typeof questionTypes)[QuestionTypeKey]

export const getQuestionIcon = (
    questionType: QuestionTypeValue,
    props: IconBaseProps
) => {
    switch (questionType) {
        case questionTypes.shortText:
            return <BsTextParagraph {...props} />
        case questionTypes.number:
            return <CiHashtag {...props} />
        case questionTypes.select:
        case questionTypes.multipleChoice:
            return <TfiArrowCircleDown {...props} />
        case questionTypes.insight:
            return <CiShoppingTag {...props} />
        case questionTypes.entity:
        case questionTypes.identifier:
            return <IoPersonOutline {...props} />
        case questionTypes.nps:
            return <CiStar {...props} />
        case questionTypes.choose:
            return <AiOutlineQuestion {...props} />
        default:
            throw new Error("Not mapped.")
    }
}

export const classifiableQuestions = [
    questionTypes.multipleChoice,
    questionTypes.select
] as const
export const openQuestions = [
    questionTypes.insight,
    questionTypes.entity,
    questionTypes.shortText
] as const
export const buttonQuestions = [
    questionTypes.multipleChoice,
    questionTypes.select,
    questionTypes.nps
] as const

// This regex came from https://emailregex.com/
// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const tagRegex = /(<[^>]*>?|\[[^\]]*\]?)/g
export const urlRegex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/g

export const wsReconnectableErrors = [1006, 1005, 1002, 1012, 1013] as const

export const staging = false as const

export const blankQuestion = () => ({
    header: "",
    value: {},
    type: "",
    id: uuid()
})

const environment = import.meta.env.VITE_APP_ENV || import.meta.env.NODE_ENV

const getEnvVariable = (key: string): string => {
    let env = "DEV"
    if (environment === "production") env = "PROD"
    else if (environment === "staging") env = "STAGING"
    else if (environment === "ngrok") env = "NGROK"
    return import.meta.env[`VITE_APP_${env}_${key}`] ?? ""
}

export const settings = {
    environment,
    release: import.meta.env.VITE_APP_RELEASE,
    authUrl: getEnvVariable("AUTH_URL"),
    apiUrl: getEnvVariable("API_URL"),
    wsUrl: getEnvVariable("WS_URL"),
    liveKitUrl: import.meta.env.VITE_APP_LIVEKIT_URL,
    sentryDsn: getEnvVariable("SENTRY_DSN"),
    sentryTracesSampleRate: parseFloat(
        getEnvVariable("SENTRY_TRACES_SAMPLE_RATE")
    ),
    sentryReplaysSessionSampleRate: parseFloat(
        getEnvVariable("SENTRY_REPLAYS_SESSION_SAMPLE_RATE")
    ),
    posthogKey: getEnvVariable("PUBLIC_POSTHOG_KEY"),
    posthogOptions: {
        api_host: getEnvVariable("PUBLIC_POSTHOG_HOST")
    },
    interviewReconnectionsLimit: parseInt(getEnvVariable("INTERVIEW_RECONNECTIONS_LIMIT") || "10")
}

export const privacyNoticeUrls = {
    en: "https://glaut-resources.s3.eu-west-3.amazonaws.com/privacy/20240731-glaut-informativa-privacy-(responsabile-ENG).pdf",
    it: "https://glaut-resources.s3.eu-west-3.amazonaws.com/privacy/20240731-glaut-informativa-privacy-(responsabile-ITA).pdf"
}