import { useInterviewService } from "@hooks/services/useInterviewService"
import ErrorLevel from "@utils/ErrorLevel"
import { settings } from "@utils/Variables"
import { DisconnectReason, RemoteParticipant, Room, RoomEvent } from "livekit-client"
import React, { useCallback, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { IMessage } from "src/@types/interviews"

interface IWebRTCError {
    code: number
    codeDetail: string
    showCode: boolean
    errorLevel: number
    eventName: string
}

interface IUseWebRTCInterviewProps {
    projectId: string
    identifier: string
    lang: string
    userId: string
    messages: IMessage[]
    error: IWebRTCError
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>
    setHasNewMessage: React.Dispatch<React.SetStateAction<boolean>>
    setRunning: React.Dispatch<React.SetStateAction<boolean>>
    setCompletion: React.Dispatch<React.SetStateAction<number>>
    setParticipant: React.Dispatch<React.SetStateAction<RemoteParticipant>>
    setError: React.Dispatch<React.SetStateAction<IWebRTCError>>
}

export function useWebRTCInterview({
    userId,
    identifier,
    lang,
    projectId,
    messages,
    error,
    setMessages,
    setHasNewMessage,
    setRunning,
    setCompletion,
    setParticipant,
    setError
}: Readonly<IUseWebRTCInterviewProps>) {
    // #region Query parameters
    const [searchParams] = useSearchParams()
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region States
    const [liveKitRoom, setLiveKitRoom] = useState<Room>()
    const [hasLiveKitRoomStarted, setHasLiveKitRoomStarted] = useState(false)
    // #endregion

    // #region Refs
    const trackElementRef = useRef<HTMLMediaElement>()
    // #endregion

    // #region Error handling

    // Used to make error accessible in the handlers
    const errorRef = useRef(error)
    const setErrorRef = useCallback((data: IWebRTCError) => {
        errorRef.current = data
        setError(data)
    }, [setError])

    // #endregion

    // #region Callbacks
    const initializeConnection = useCallback((previousRoom?: Room) => {
        if (errorRef.current) {
            console.log("Error found, re-connection skipped")
            return
        }

        // Establish a LiveKit room and returns the token
        const prefills = { ...Object.fromEntries(Array.from(searchParams.entries())), tracking_id: userId }
        interviewService.startWebRTCInterview({ projectId, identifier, lang, prefills })
            .then(({ token }) => {
                console.log("Connecting to room: ", previousRoom)
                if (liveKitRoom || previousRoom) return

                const decoder = new TextDecoder()
                const room = new Room()

                // First thing first, connects to the room with a retry mechanism
                room.connect(settings.liveKitUrl, token)
                    .then(() => setLiveKitRoom(room))
                    .catch(e => {
                        console.log(e)
                        setTimeout(initializeConnection, 1000)
                    })

                const sessionTimeout = () => {
                    setErrorRef({
                        code: 3008,
                        codeDetail: "websocket_disconnection",
                        showCode: false,
                        errorLevel: ErrorLevel.Info,
                        eventName: "interview_session_expired"
                    })
                }

                // Handles disconnections from the room, usually because another interviewee is connected
                room.on(RoomEvent.Disconnected, reason => {
                    if (!reason) {
                        console.log(`Disconnected from room ${identifier} for no reason.`)
                        return
                    }

                    console.log(`Disconnected from room ${identifier} due to ${DisconnectReason[reason]}`)

                    // If the disconnection was initiated by the client, we don't want to show the error
                    if (reason !== DisconnectReason.CLIENT_INITIATED) sessionTimeout()
                })

                // Creates a listener for JSON inputs
                room.on(RoomEvent.DataReceived, payload => {
                    const data = JSON.parse(decoder.decode(payload))
                    console.log("Received data: ", data)

                    if (data?.start) {
                        console.log("Room started")
                        setHasLiveKitRoomStarted(true)
                        return
                    }

                    if (data?.close) {
                        console.log("Room closed by interviewer")
                        room.disconnect()

                        sessionTimeout()
                        return
                    }

                    // Loads previous messages
                    if (data.messages && !messages.length) {
                        data.messages[data.messages.length - 1].restarted = true
                        setMessages(data.messages)
                    }

                    // Adds a new message to the chat, both from assistant or user
                    if (data.new_message) {
                        setMessages(ms => [...ms, data.message])
                        setHasNewMessage(true)
                    }

                    // Updates the last message
                    if (data.message)
                        setMessages(ms =>
                            ms.map((m, idx) => {
                                if (idx === ms.length - 1) return data.message
                                return m
                            })
                        )

                    // Changes running state
                    if (data.running !== undefined) setRunning(data.running)

                    // Updates survey's completion status
                    if (data.completion) setCompletion(data.completion)
                })

                // Attaches the input track to an element
                room.on(RoomEvent.TrackSubscribed, (track, _, participant) => {
                    const trackElement = track.attach()

                    // Appends the element to the DOM
                    const root = document.getElementById("root")!
                    root.appendChild(trackElement)

                    // Updates the state
                    setParticipant(participant)
                    trackElementRef.current = trackElement

                })

                // If the server disconnects, we try again in 1 second
                room.on(RoomEvent.ParticipantDisconnected, participant => {
                    if (participant.identity !== "interviewer") return
                    setTimeout(() => initializeConnection(room), 1000)
                })
            })
            // If we couldn't connect, try again
            .catch(e => {
                console.log(e)
                setTimeout(initializeConnection, 1000)
            })
    }, [
        identifier,
        interviewService,
        lang,
        liveKitRoom,
        messages.length,
        projectId,
        searchParams,
        setCompletion,
        setErrorRef,
        setHasNewMessage,
        setMessages,
        setParticipant,
        setRunning,
        userId
    ])
    // #endregion

    return {
        liveKitRoom,
        hasLiveKitRoomStarted,
        trackElementRef,
        initializeConnection
    }
}