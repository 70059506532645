import { useState } from "react"
import {
    MdDateRange,
    MdOutlineArrowDownward
} from "react-icons/md"
import DataTabTableHeaderItemDateModalFilter from "../DataTabTableHeaderItemDateModalFilter"

export default function DataTabTableHeaderItemDate() {
    // #region States
    const [showFilterModal, setShowFilterModal] = useState(false)
    // #endregion

    return (
        <th scope="col" className="p-0 border-b-2" style={{ borderRight: "0px" }}>
            <div className="flex flex-row items-center">
                <div>
                    <MdDateRange className="text-glaut-grey mr-1 w-[14px] h-[14px]" />
                </div>
                <div className="flex-1 text-glaut-dark-grey">
                    <span className="text-[13.33px] font-[500]">Date</span>
                </div>
                <div>
                    <MdOutlineArrowDownward className="text-glaut-grey w-[14px] h-[14px]" onClick={() => {
                        setShowFilterModal(!showFilterModal)
                    }} />
                </div>
                {showFilterModal && (
                    <DataTabTableHeaderItemDateModalFilter
                        onClose={() => {
                            setShowFilterModal(false)
                        }}
                    />
                )}
            </div>
        </th>
    )
}