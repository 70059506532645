import React, { useEffect, useRef } from "react"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"

import "../../../styling/SettingFields.scss"

const TextBox = ({
    setting,
    value,
    setValue,
    lang
}) => {
    const textareaRef = useRef(null)

    // Auto resizes the text box
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 5}px`
        }
    }, [value])

    const setEmptyValue = () => {
        if (setting.multiLanguage) setValue({})
        else setValue("")
    }

    return (
        <label
            className={["column", "setting", "textbox-setting", setting.disabled ? "disabled" : ""].join(" ")}
            style={{ ...setting.labelStyle, alignItems: "flex-start" }}
        >
            <span
                className={"bar " + (setting.descriptionClassName ?? "text-glaut-midnight text-[13.33px] font-medium")}
            >
                {setting.toggable && (value === null ?
                    <MdCheckBoxOutlineBlank onClick={() => !setting.disabled && setEmptyValue()} /> :
                    <MdCheckBox onClick={() => !setting.disabled && setValue(null)} />
                )}
                {setting.description || setting.slug.replaceAll("_", " ")}
            </span>

            {value !== null && (setting.multiLanguage
                ? (
                    <textarea
                        ref={textareaRef}
                        key={setting.slug}
                        placeholder={setting.placeholder}
                        value={value?.[lang] || ""}
                        onChange={e => setValue({ ...value, [lang]: e.target.value })}
                        disabled={setting.disabled}
                        className={setting.textareaClassName}
                    />
                )
                : (
                    <textarea
                        ref={textareaRef}
                        key={setting.slug}
                        placeholder={setting.placeholder}
                        value={value ?? ""}
                        onChange={e => setValue(e.target.value)}
                        disabled={setting.disabled}
                        className={setting.textareaClassName}
                    />
                ))}
        </label>
    )
}

export default TextBox
