import React from "react"
import { Cell, Pie, PieChart, Tooltip } from "recharts"
import { colors } from "../../utils/Variables"
import CustomTooltip from "./CustomTooltip"

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    )
}

const RenderLegend = ({ payload, setSelected }) => (
    <div className="column" style={{ alignItems: "flex-start" }}>
        {payload.map((entry, index) => (
            <div key={index} className="clickable" onClick={() => setSelected(entry.id)}>
                <span
                    style={{
                        backgroundColor: colors[index % colors.length],
                        height: "16px",
                        width: "16px",
                        borderRadius: "4px",
                        border: "1px solid var(--border-color)",
                        display: "inline-block",
                        marginRight: "6px"
                    }}
                ></span>
                <span
                    key={`item-${index}`}
                    style={{ maxWidth: "200px", color: colors[index % colors.length] }}
                >
                    {entry.name}
                </span>
            </div>
        ))}
    </div>
)

const PercentagePie = ({ data, setSelected }) =>
    data && (
        <div className="horizontal-split" style={{ alignItems: "center" }}>
            <PieChart width={250} height={250} style={{ margin: "0 auto" }}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    outerRadius={100}
                    fill="#8884d8"
                    label={renderCustomizedLabel}
                    labelLine={false}
                >
                    {data.map((value, index) => (
                        <Cell
                            key={`cell-${index}`}
                            onClick={() => setSelected(value.id)}
                            fill={colors[index % colors.length]}
                            className="pointer"
                        />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip unit={"%"} />} />
            </PieChart>
            <RenderLegend payload={data} setSelected={setSelected} />
        </div>
    )

export default PercentagePie
