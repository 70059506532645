import React from "react"
import ImageInput from "../../inputs/ImageInput"

import "../../../styling/SettingFields.scss"

const ImageSetting = ({ setting, value, setValue }) => (
    <label className={["setting", "image-setting", setting.disabled ? "disabled" : ""].join(" ")}>
        {setting.slug.replaceAll("_", " ")}
        <div className="long-bar bordered" style={{ padding: "0 1em" }}>
            <img style={{ maxHeight: "28px" }} src={value} />
            <div className="bar">
                <ImageInput
                    imageId={"logo"} setUrl={!setting.disabled && setValue} value={value} disabled={setting.disabled}
                />
                <button
                    className="danger-button"
                    onClick={() => !setting.disabled && setValue(null)}
                    disabled={!value || setting.disabled}
                >
                    Remove
                </button>
            </div>
        </div>
    </label>
)

export default ImageSetting
