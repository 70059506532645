import { FFmpeg } from "@ffmpeg/ffmpeg"
import { fetchFile } from "@ffmpeg/util"

export const convertToMp3 = async (inputUrl, originalFormat, targetFormat) => {

    const inputFileName = "input." + originalFormat
    const outputFileName = "output." + targetFormat

    // Loads ffmpeg instance
    const ffmpeg = new FFmpeg()
    await ffmpeg.load()

    // Loads original file from URL
    await ffmpeg.writeFile(inputFileName, await fetchFile(inputUrl))

    // Converts into target format and returns a URL
    await ffmpeg.exec(["-i", inputFileName, outputFileName])
    const data = await ffmpeg.readFile(outputFileName)
    const blob = new Blob([data.buffer], { type: "audio/mpeg" })
    const blobURL = URL.createObjectURL(blob)

    return blobURL
}
