import { useEffect, useRef } from "react"

import { MdOutlineCancel } from "react-icons/md"

const Input = ({
    title,
    description,
    name,
    value,
    placeholder,
    icon,
    disabled,
    deleteDisabled,
    className,
    autofocus,
    autofocusDelay,
    onChange,
    onFocus,
    onBlur,
    onDelete,
    onKeyDown
}) => {
    const inputRef = useRef()

    // We focus the input if autofocus is true
    useEffect(() => {
        if (autofocus && inputRef.current)
            if (autofocusDelay) setTimeout(() => {
                if (inputRef.current) inputRef.current.focus()
            }, autofocusDelay)
            else inputRef.current.focus()
    }, [])

    return (<div className={className ?? "input"}>
        <div className="row1">
            {title && <div className="title">{title}</div>}
            {description && <div className="description">
                {description}
            </div>}
        </div>
        <div className="row2">
            {icon}
            <input
                className="w-full"
                ref={inputRef}
                name={name}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
            />
            {onDelete && <MdOutlineCancel
                className={"delete " + (deleteDisabled || disabled ? "disabled" : "")}
                onClick={deleteDisabled || disabled ? () => { } : onDelete}
            />}
        </div>
    </div>)
}

export default Input
