import { AxiosInstance } from "axios"
import IInterviewGateway, {
    IInterviewGatewayGetInterviewLanguageRequest,
    IInterviewGatewayGetInterviewLanguageResponse,
    IInterviewGatewayGetRecordingUrlRequest,
    IInterviewGatewayGetRecordingUrlResponse,
    IInterviewGatewaySaveInterviewLanguageRequest,
    IInterviewGatewaySaveInterviewLanguageResponse,
    IInterviewGatewayStartWebRTCInterviewRequest,
    IInterviewGatewayStartWebRTCInterviewResponse
} from "../IInterviewGateway"

interface IInterviewsApiServiceProps {
    api: AxiosInstance
}

export default class InterviewsApiService implements IInterviewGateway {
    private props: IInterviewsApiServiceProps

    constructor(props: IInterviewsApiServiceProps) {
        this.props = props
    }

    public async getRecordingUrl(
        request: IInterviewGatewayGetRecordingUrlRequest
    ): Promise<IInterviewGatewayGetRecordingUrlResponse> {
        const { projectId, recordingId } = request

        return await this.props.api
            .get(`interviews/${projectId}/get-recording/${recordingId}`)
            .then(res => ({ recordingUrl: res.data }))
            .catch(e => {
                console.log(e)
                return { recordingUrl: "" }
            })
    }

    public async startWebRTCInterview(
        request: IInterviewGatewayStartWebRTCInterviewRequest
    ): Promise<IInterviewGatewayStartWebRTCInterviewResponse> {
        const { identifier, lang, prefills, projectId } = request

        return await this.props.api
            .post(`interviews/${projectId}/${identifier}/start/${lang}`, { prefills })
            .then(res => ({
                token: res.data.token
            }))
            .catch(err => {
                console.log(err)

                return {
                    token: ""
                }
            })
    }

    public async saveInterviewLanguage(
        request: IInterviewGatewaySaveInterviewLanguageRequest
    ): Promise<IInterviewGatewaySaveInterviewLanguageResponse> {
        const { projectId, lang } = request

        localStorage.setItem(`interview-${projectId}-language`, lang)

        return Promise.resolve({
            isSuccessful: true
        })
    }

    public async getInterviewLanguage(
        request: IInterviewGatewayGetInterviewLanguageRequest
    ): Promise<IInterviewGatewayGetInterviewLanguageResponse> {
        const { projectId } = request

        return Promise.resolve({
            lang: localStorage.getItem(`interview-${projectId}-language`) ?? undefined
        })
    }

}