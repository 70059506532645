import { ProjectContext } from "../../../AutoSaveProject"
import { useContext, useMemo } from "react"
import { IProject } from "src/@types/project"
import { useProject } from "../../contexts/ProjectProvider"
import { searchCodeOccurrencesInAnalyses } from "../../utils/search-code-occurrences-in-analyses"
import CodeItem from "@components/CodeItem"
import { IInsightAnalysis, ISelectAnalysis } from "src/@types/analysis"

interface IDataTabTableRowItemIdModalDetailsEntryListProps {
    themeIds: string[]
    questionId: string
}

export default function DataTabTableRowItemIdModalDetailsEntryList({
    themeIds,
    questionId
}: Readonly<IDataTabTableRowItemIdModalDetailsEntryListProps>) {
    // #region Contexts
    const { analysisData } = useProject()
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region Types
    type ITheme = {
        id: string
        index: number
        name: string
        occurrences: number
    }
    // #endregion

    // #region Memos
    const themes: ITheme[] = useMemo(() => {
        if (!project) return [] as ITheme[]

        const question = project.schema.find(question => question.id === questionId)

        if (question?.type === "insight")
            return question.buckets.map((bucket, idx) => ({ ...bucket, index: idx }))
                .filter(bucket => themeIds.includes(bucket.id))
                .map(bucket => {
                    const questionAnalyses = analysisData?.analysis[questionId] as IInsightAnalysis[] | undefined

                    return {
                        id: bucket.id,
                        index: bucket.index,
                        name: bucket.name ?? "",
                        occurrences: questionAnalyses ? searchCodeOccurrencesInAnalyses(questionAnalyses, bucket.id) : 0
                    } as ITheme
                })

        if (question?.type === "select")
            return question.options.filter(option => themeIds.includes(option.id))
                .map((option, idx) => {
                    const questionAnalyses = analysisData?.analysis[questionId] as ISelectAnalysis[] | undefined

                    return {
                        id: option.id,
                        name: option.label[project.general_settings.language],
                        occurrences: questionAnalyses?.find(analysis => analysis.id === option.id)?.occurrences ?? 0,
                        index: idx
                    } as ITheme
                })

        return [] as ITheme[]
    }, [project, analysisData?.analysis, questionId, themeIds])
    // #endregion

    return (
        <>
            {themes.map(theme => (
                <CodeItem
                    key={theme.id}
                    leadContent={String(theme.occurrences)}
                    content={theme.name ?? ""}
                    colorIndex={theme.index}
                />
            ))}
        </>
    )
}