import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { useAuthInfo } from "@propelauth/react"

import { getWsErrorCopy } from "../utils/Copy/wsErrors"
import { useToast } from "@contexts/ToastProvider"
import useAuthSocket from "./useAuthSocket"
import { useWebSocketCleanup } from "./useWebSocketCleanup"
import { ErrorLevel } from "../utils/ErrorLevel"
import { wsReconnectableErrors } from "../utils/Variables"
import ErrorPage from "../pages/Error"

// Creating a context for the WebSocket. This context will be used to provide WebSocket functionalities
// to components.
export const WebSocketContext = createContext(null)

// Custom hook to use the WebSocket context. This ensures that components can easily access the WebSocket
// functionalities.
export const useWebSocket = () => {
    const context = useContext(WebSocketContext)
    if (!context)
        throw new Error("useWebSocket must be used within a WebSocketProvider")

    return context
}

// The provider component that manages the WebSocket connection and provides functionalities to child components.
export const WebSocketProvider = ({ children, url }) => {
    // State to store the WebSocket object
    const { addToast } = useToast()
    const [error, setError] = useState(null)
    const [socket, setSocket] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    // Stores the ws reconnect time to reduce the chances of clogging the server
    const reconnectTime = useRef(1000)

    // State to store the list of callback functions registered by components
    const callbacks = useRef([])

    // Propel Auth hooks
    const { isLoggedIn, refreshAuthInfo, accessToken } = useAuthInfo()
    const authSocket = useAuthSocket()

    // const accessTokenRef = useRef(accessToken)

    const connect = () => {
        // Check if there's an existing WebSocket connection
        if (socket?.readyState === WebSocket.OPEN) socket.close(1000)

        // Creating a new WebSocket connection.
        const ws = authSocket(url)

        // Event handler for when the WebSocket connection opens.
        ws.onopen = () => {
            setIsOpen(true)
            ws.send(JSON.stringify({ token: accessToken, silly: "yes" }))
        }

        // When receiving a message, pass the parsed data to all registered callbacks
        ws.onmessage = event => {
            const data = JSON.parse(event.data)

            // Shows error messages
            if (data.error) {
                const error = getWsErrorCopy(data.error)
                addToast(
                    error[0],
                    error[1],
                    ErrorLevel.Error,
                    10000
                )
            }

            callbacks.current.forEach(callback => callback(data))
        }

        // Event handler for when the WebSocket connection closes. TODO: Should try to open it again?
        ws.onclose = e => {
            setIsOpen(false)

            console.log("Reconnect time: ", reconnectTime.current)

            // If we had a client side error, tries to reconnect
            if (wsReconnectableErrors.includes(e.code)) { setTimeout(connect, reconnectTime.current) }
            // If the jwt has expired, we refresh the credentials before trying again
            else if (e.code === 4001) { refreshAuthInfo().then(setTimeout(connect, reconnectTime.current)) }
            // If the ws was disconnected correctly resets the reconnect time
            else if (e.code === 1000) { reconnectTime.current = 1000 }
            else {
                console.log("Disconnection error: ", e.code)
                setError({
                    code: e.code === 4404 ? 404 : e.code
                })
            }

            // After a closing event, increases the reconnect time
            reconnectTime.current = reconnectTime.current * 1.5
        }

        // Updating the state with the new WebSocket object.
        setSocket(ws)

        return ws
    }

    // Effect to establish the WebSocket connection and set up its event handlers.
    useEffect(() => {
        // Only establish the connection if the user is logged in
        if (!isLoggedIn) return

        // Connects to the websocket
        connect()
    }, [url, isLoggedIn])

    // Function to send messages through the WebSocket.
    const sendMessage = message => {
        if (isOpen) socket.send(JSON.stringify(message))
    }

    // Function for components to register their callback functions.
    const addCallback = callback => {
        callbacks.current = [...callbacks.current, callback]
    }

    const removeCallback = callback => {
        callbacks.current = callbacks.current.filter(c => c !== callback)
    }

    // Closes the socket when closing the page
    useWebSocketCleanup(socket)

    if (error)
        return (<ErrorPage
            code={error.code}
        />)

    // Providing the WebSocket functionalities through context to child components.
    return (
        <WebSocketContext.Provider value={{ socket, sendMessage, addCallback, removeCallback }}>
            {children}
        </WebSocketContext.Provider>
    )
}
