import { IInsightAnalysis } from "src/@types/analysis"

/**
 * Search recursively for the occurrences of a certain code ID.
 * 
 * @deprecated Use `searchCodeInAnalyses()`.
 */
export function searchCodeOccurrencesInAnalyses(analyses: IInsightAnalysis[], codeId: string): number {
    for (const analysis of analyses) {
        if (analysis.id === codeId) return analysis.occurrences

        const occurrences = searchCodeOccurrencesInAnalyses(analysis.children, codeId)
        if (occurrences !== 0) return occurrences
    }

    return 0
}
