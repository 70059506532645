import { useAuthInfo } from "@propelauth/react"
import { useLocation } from "react-router-dom"

const useProtectedRoute = () => {
    const { isLoggedIn } = useAuthInfo()
    const location = useLocation()

    // List all your protected routes here
    const openRoutes = ["/i/", "/r/"]

    const isOpen = openRoutes.some(route => location.pathname.startsWith(route))

    return { isOpen, isLoggedIn }
}

export default useProtectedRoute
