import Loading from "@components/loading/Loading"
import { useContext, useMemo } from "react"
import { Tooltip } from "react-tooltip"
import { IEntityAnalysis, IInsightAnalysis, INPSAnalysis, ISelectAnalysis } from "src/@types/analysis"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useProject } from "../../contexts/ProjectProvider"
import { searchCodeInAnalyses } from "../../utils/search-code-in-analysis"
import { searchCodeParentInAnalyses } from "../../utils/search-code-parent-in-analyses"
import QuestionCode, { ICodeData } from "../AnalysisTabQuestionsColCrossFiltersQuestionCode"
import { IQuestion } from "src/@types/question"

interface IAnalysisTabQuestionsColCrossFiltersQuestionProps {
    questionId: string
    codes: {
        id: string
    }[]
}

export default function AnalysisTabQuestionsColCrossFiltersQuestion({
    questionId,
    codes
}: Readonly<IAnalysisTabQuestionsColCrossFiltersQuestionProps>) {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { analysisData } = useProject()
    // #endregion

    // #region Memos
    const tooltipId = useMemo(() => `tooltip--${questionId}-cross-question-filter`, [questionId])

    const question = useMemo(() => {
        if (!project) return undefined

        let questionIndex = -1
        let question: IQuestion | undefined

        // is it a question?
        const mainQuestions = project.schema.filter(q => q.source === null)
        questionIndex = mainQuestions.findIndex(question => question.id === questionId)
        if (questionIndex === -1) {
            // it is an analysis
            const analysis = project.schema.find(q => q.id === questionId)
            questionIndex = project.schema.findIndex(q => q.id === analysis?.source)

            question = analysis
        } else {
            question = mainQuestions[questionIndex]
        }

        if (questionIndex === -1 || question === undefined) return undefined

        return {
            ...question,
            index: questionIndex + 1
        }
    }, [project, questionId])

    const questionLabelElement = useMemo(() => {
        if (!project || question === undefined) return undefined
        const questionHeader = question.value[project.general_settings.language] ?? ""

        return (
            <div className="flex flex-row overflow-hidden gap-1 mb-2">
                <span className="text-glaut-midnight text-xs font-medium w-max whitespace-nowrap">
                    {`Question ${question.index} -`}
                </span>
                <span
                    data-tooltip-id={tooltipId}
                    data-tooltip-place="top"
                    data-tooltip-content={questionHeader}
                    className={`text-glaut-stroke-button text-xs font-medium whitespace-nowrap text-ellipsis 
                        overflow-hidden`}>
                    {questionHeader}
                </span>
            </div>
        )
    }, [project, question, tooltipId])

    const codeData: ICodeData[] = useMemo(() => {
        if (!project || question === undefined || analysisData === undefined) return []

        if (question.type === "insight") {
            const analyses = analysisData.analysis[question.id] as IInsightAnalysis[]
            return codes
                .map((code, codeIdx) => {
                    const analysis = searchCodeInAnalyses(analyses, code.id)
                    if (analysis === null) return undefined
                    // @TODO: better approach to search only once instead of twice
                    const parentCode = searchCodeParentInAnalyses(analyses, code.id, null)

                    return {
                        ...analysis,
                        index: codeIdx,
                        type: parentCode === null ? "theme" : "code",
                        parentThemeLabel: parentCode?.label
                    }
                })
                .filter(c => c !== undefined)
                .map(c => ({ // does not type correctly as strict IInsightAnalysis
                    id: c?.id ?? "",
                    label: c?.label ?? "",
                    leadContent: String(c?.occurrences ?? 0),
                    colorIndex: c?.index ?? 0,
                    type: c?.type ?? "theme",
                    parentThemeLabel: c?.parentThemeLabel ?? ""
                } as ICodeData))
        }

        if (["multiple_choice", "select"].includes(question.type)) {
            const analyses = analysisData.analysis[question.id] as ISelectAnalysis[]
            return codes
                .map((code, codeIdx) => {
                    const analysis = analyses.find(analysis => analysis.id === code.id)
                    if (analysis === undefined) return undefined
                    return { ...analysis, index: codeIdx }
                })
                .filter(c => c !== undefined)
                .map(c => ({ // does not type correctly as strict ISelectAnalysis
                    id: c?.id ?? "",
                    label: c?.label[project.general_settings.language] ?? "",
                    leadContent: String(c?.occurrences ?? 0),
                    colorIndex: c?.index ?? 0,
                    type: "theme"
                } as ICodeData))
        }

        if (question.type === "entity") {
            const analyses = analysisData.analysis[question.id] as IEntityAnalysis[]
            return codes
                .map((code, codeIdx) => {
                    const analysis = analyses.find(analysis => analysis.id === code.id)
                    if (analysis === undefined) return undefined
                    return { ...analysis, index: codeIdx }
                })
                .filter(c => c !== undefined)
                .map(c => ({ // does not type correctly as strict IEntityAnalysis
                    id: c?.id ?? "",
                    label: c?.label ?? "",
                    leadContent: String(c?.occurrences ?? 0),
                    colorIndex: c?.index ?? 0,
                    type: "theme"
                } as ICodeData))
        }

        if (question.type === "nps") {
            const analysis = analysisData.analysis[question.id] as INPSAnalysis

            function getColorIndexFromScore(score: number) {
                if (score >= 9 && score <= 10)
                    return 0
                else if (score >= 7 && score <= 8)
                    return 6

                return 7
            }

            return codes
                .map((code, codeIdx) => ({
                    id: code.id,
                    label: code.id,
                    leadContent: analysis.values[code.id],
                    colorIndex: getColorIndexFromScore(Number(code.id)),
                    score: code.id,
                    index: codeIdx,
                    type: "theme"
                }))
        }

        // @TODO: number

        return []
    }, [project, question, analysisData, codes])
    // #endregion

    if (!project) return <Loading />

    return (
        <div>
            {questionLabelElement}
            <div className="flex flex-row flex-wrap" style={{ rowGap: "4px", columnGap: "8px" }}>
                {codeData.map(code => (
                    <QuestionCode
                        key={`${questionId}-${code.id}`}
                        questionId={questionId}
                        code={code}
                    />
                ))}
            </div>
            <Tooltip id={tooltipId} />
        </div>
    )
}