import Loading from "@components/loading/Loading"
import Pagination from "@components/navigation/Pagination"
import { useProjectService } from "@hooks/services/useProjectService"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    MdLanguage
} from "react-icons/md"
import { IProject } from "src/@types/project"
import { ProjectContext } from "../../../AutoSaveProject"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"
import DataTabTableHeaderItem from "../DataTabTableHeaderItem"
import DataTabTableHeaderItemDate from "../DataTabTableHeaderItemDate"
import DataTabTableHeaderItemId from "../DataTabTableHeaderItemId"
import DataTabTableHeaderItemPlatform from "../DataTabTableHeaderItemPlatform"
import DataTabTableHeaderItemStatus from "../DataTabTableHeaderItemStatus"
import DataTabTableRowEmpty from "../DataTabTableRowEmpty"
import DataTabTableRowItem from "../DataTabTableRowItem"

export default function DataTabTable() {
    // #region Contexts
    const { conversationsData, entriesFilters, entriesSorts } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region States
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [isTableLoading, setIsTableLoading] = useState(true)
    // #endregion

    // #region Refs
    const containerDivRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const isTableEmpty = useMemo(
        () => conversationsData === undefined || conversationsData.conversations.length === 0,
        [conversationsData]
    )

    const emptyTableCustomMessage = useMemo(
        () => Object.values(entriesFilters).every(filter => filter === undefined)
            ? undefined
            : "No matching data for the selected filters",
        [entriesFilters]
    )
    // #endregion

    // #region Effects

    // onLoadRetrieveEntries
    useEffect(() => {
        if (!project) return

        projectService.getConversations({
            projectId: project._id,
            page,
            identifier: entriesFilters.id,
            platforms: entriesFilters.platform ? [entriesFilters.platform] : undefined,
            include: entriesFilters.status !== undefined ? {
                completed: entriesFilters.status === "completed",
                uncompleted: entriesFilters.status === "uncompleted"
            } : undefined,
            sortBy: "last_update",
            sortOrder: entriesSorts.date
        }).then(conversationsData => {
            dataTabDispatch({ type: "set-conversations-data", conversationsData })
            setMaxPage(conversationsData.n_pages)

            setIsTableLoading(false)
            containerDivRef.current?.scroll({ top: 0, behavior: "smooth" })
        })
    }, [project, page, entriesFilters, dataTabDispatch, projectService, entriesSorts])

    // onChangePageScrollToTop
    useEffect(() => {
    }, [page])
    // #endregion

    return (
        <div ref={containerDivRef} className="overflow-auto">
            <table className="border-separate border-spacing-x-[21px] border-spacing-y-[6px] w-full">
                <thead>
                    <tr>
                        <DataTabTableHeaderItemId />
                        <DataTabTableHeaderItemStatus />
                        <DataTabTableHeaderItemDate />
                        <DataTabTableHeaderItemPlatform />
                        <DataTabTableHeaderItem
                            title="Language"
                            icon={<MdLanguage className="text-glaut-grey mr-1 w-[14px] h-[14px]" />}
                        />
                    </tr>
                </thead>
                {/**
                  * weird class names to create a margin between thead and tbody (margin and padding don't work)
                  * @see https://stackoverflow.com/questions/9258754/spacing-between-thead-and-tbody
                  */}
                <tbody className="before:content-['@'] before:block before:leading-[4px] before:indent-[-999999px]">
                    {!isTableLoading && conversationsData?.conversations?.map(entry => (
                        <DataTabTableRowItem key={entry.identifier} entry={entry} />
                    ))}
                </tbody>
            </table>
            {isTableLoading && (
                <div className="flex flex-1 flex-col justify-center items-center">
                    <Loading />
                </div>
            )}
            {!isTableLoading && isTableEmpty && (
                <DataTabTableRowEmpty
                    customMessage={emptyTableCustomMessage}
                />
            )}
            {!isTableLoading && !isTableEmpty && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    nPages={maxPage}
                />
            )}
        </div>
    )

}