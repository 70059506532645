interface IAnalysisTabAnalysisColNumberMetricsProps {
    mean: number
    median: number
}

export default function AnalysisTabAnalysisColNumberMetrics({
    mean,
    median
}: Readonly<IAnalysisTabAnalysisColNumberMetricsProps>) {
    return (
        <div className={`flex flex-row justify-center items-center h-[75px] w-full gap-[38px] border-1 rounded-[10px]
            border-glaut-grey shadow-[0px_0px_8px_0px_#00000026]`}>
            <div className="flex flex-col items-center">
                <p className="text-[23.04px] font-medium">{String(mean.toFixed(2)).padStart(2, "0")}</p>
                <p className="text-[16px] font-normal">Mean</p>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-[23.04px] font-medium">{String(median.toFixed(2)).padStart(2, "0")}</p>
                <p className="text-[16px] font-normal">Median</p>
            </div>

        </div>
    )
}