import { FaTag } from "react-icons/fa"
import { MdClose, MdEditNote } from "react-icons/md"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import DeleteModal from "../AnalysisTabAnalysisColReviewCodeBookModalThemeItemDeleteModal"

interface IAnalysisTabAnalysisColReviewCodeBookModalThemeItemProps {
    colorIndex: number
    label: string
    themeId: string
    removeCodeCallback: (themeId: string) => void
}

export default function AnalysisTabAnalysisColReviewCodeBookModalThemeItem({
    colorIndex,
    label,
    themeId,
    removeCodeCallback
}: Readonly<IAnalysisTabAnalysisColReviewCodeBookModalThemeItemProps>) {
    // #region Contexts
    const { codeBook: { selectedThemeId, edits } } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region State
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    // #endregion

    // #region Memos
    const isThemeSelected = useMemo(() => themeId === selectedThemeId, [themeId, selectedThemeId])
    const newThemeLabel = useMemo(() => edits[themeId]?.newLabel ?? "", [themeId, edits])
    const themeInstructions = useMemo(() => edits[themeId]?.instruction ?? "", [themeId, edits])
    // #endregion

    // #region Callbacks
    const handleChangeThemeLabel = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        analysisTabDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: themeInstructions,
            newLabel: event.target.value
        })
    }, [analysisTabDispatch, themeId, themeInstructions])
    const handleChangeThemeInstructions = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        analysisTabDispatch({
            type: "add-code-book-edit",
            themeId,
            instruction: event.target.value,
            newLabel: newThemeLabel
        })
    }, [analysisTabDispatch, themeId, newThemeLabel])

    const handleConfirmDeletion = useCallback(() => {
        setIsDeleteModalOpen(false)

        // Timeout for UX purposes
        setTimeout(() => {
            removeCodeCallback(themeId)
        }, 500)
    }, [removeCodeCallback, themeId])
    // #endregion

    return (
        <div className="border-t-1 border-t-glaut-stroke-glaut rounded-none">
            <div className={`flex flex-row justify-between rounded-none p-[8px] 
                ${isThemeSelected ? "bg-glaut-cards" : ""}
            `}>
                <div className="flex flex-row gap-[8px] max-w-[90%] flex-1">
                    <FaTag style={{ color: `var(--color${(colorIndex % 10) + 1})` }} />
                    {!isThemeSelected && (
                        <p className="overflow-hidden whitespace-nowrap text-ellipsis text-[13.33px] font-medium">
                            {newThemeLabel || label}
                        </p>
                    )}
                    {isThemeSelected && (
                        <input
                            className={`border-none shadow-none rounded-none focus:shadow-none bg-transparent m-0 p-0
                                text-[13.33px] font-medium
                                placeholder-shown:text-ellipsis   
                            `}
                            placeholder={label || "Write a new theme here"}
                            value={newThemeLabel}
                            onChange={handleChangeThemeLabel}
                        />
                    )}
                </div>
                <div className="flex flex-row gap-[8px]">
                    <MdEditNote
                        className={isThemeSelected ? "text-glaut-midnight bg-glaut-stroke-glaut" : "text-glaut-grey"}
                        onClick={() => {
                            analysisTabDispatch({ type: "set-code-book-selected-theme", themeId })
                        }}
                    />
                    <MdClose
                        className="text-glaut-dark-grey"
                        onClick={() => {
                            setIsDeleteModalOpen(true)
                        }}
                    />
                </div>
            </div>
            {isThemeSelected && (
                <div className="px-[8px] pb-[8px] ms-[32px] mt-[8px]">
                    <p className="text-[13.33px] text-glaut-dark-grey font-medium">
                        Add instruction (optional)
                    </p>
                    <textarea
                        className="rounded-[4px] border-1 border-glaut-light-grey"
                        style={{ // @TODO: remove global styling applied to textarea component
                            borderColor: "#BCBCBC",
                            padding: "8px 12px"
                        }}
                        value={themeInstructions}
                        onChange={handleChangeThemeInstructions}
                        placeholder={"Include in this theme all mentions about ABC..."}
                    />
                </div>
            )}

            <DeleteModal
                isOpen={isDeleteModalOpen}
                onConfirm={handleConfirmDeletion}
                onClose={() => {
                    setIsDeleteModalOpen(false)
                }}
            />
        </div>
    )
}