import React from "react"
import { useParams } from "react-router-dom"
import useAxiosAuth from "../../hooks/useAxiosAuth"

const ImageInput = ({ imageId, setUrl, value, disabled }) => {
    const axiosAuth = useAxiosAuth()
    const { projectId } = useParams()

    const handleImageChange = e => {
        const file = e.target.files[0]
        if (file) submitImage(file)
    }

    const submitImage = imageFile => {
        const formData = new FormData()
        formData.append("image", imageFile)
        formData.append("image_id", imageId)

        axiosAuth
            .post(`datasets/${projectId}/upload-image`, formData)
            .then(response => {
                console.log(response.data)
                setUrl(response.data)
            })
            .catch(error => console.error(error))
    }

    return (
        <div style={{ margin: "1em" }}>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="fileInput"
                disabled={disabled}
            />
            <label htmlFor="fileInput" className={["button", disabled ? "disabled" : ""].join()}>
                {value ? "Change Image" : "Choose Image"}
            </label>
        </div>
    )
}

export default ImageInput
