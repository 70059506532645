import { MdOutlineSms } from "react-icons/md"

interface IEmptySpaceBoxProps {
    message: string
    leftIcon?: React.ReactNode
    bottomIcon?: React.ReactNode
}

export default function EmptySpaceBox({
    message,
    bottomIcon,
    leftIcon
}: Readonly<IEmptySpaceBoxProps>) {
    return (
        <div className="flex-1 flex flex-col w-full h-full justify-center items-center">
            <div className="flex bg-glaut-cards rounded-full w-28 h-28 justify-center items-center">
                <MdOutlineSms
                    className="text-white overflow-visible -rotate-[11deg]"
                    style={{ height: "82px", width: "82px" }} />
            </div>
            <div className="flex flex-row gap-2 mt-5 text-glaut-stroke-button">
                {leftIcon} {message}
            </div>
            {bottomIcon}
        </div>
    )
}