import { useContext } from "react"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { ProjectContext } from "../../../AutoSaveProject"
import { IProject } from "src/@types/project"
import Loading from "@components/loading/Loading"
import AnalysisTabQuestionsColCrossFiltersQuestion from "../AnalysisTabQuestionsColCrossFiltersQuestion"
import { MdFilterListAlt } from "react-icons/md"

export default function AnalysisTabQuestionsColCrossFilters() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    const { crossQuestionFilters } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    if (!project) return <Loading />

    return (
        <div className="flex flex-col gap-2 border-1 border-glaut-bar py-[10px] px-3 mb-[14px] ml-2 mr-3 rounded-[4px]">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center gap-1">
                    <MdFilterListAlt className="text-glaut-stroke-button h-[14px] w-[14px]" />
                    <p className="text-xs text-glaut-stroke-button font-medium">Filters:</p>
                </div>
                <p
                    className="text-xs underline text-glaut-dark-grey cursor-pointer"
                    onClick={() => {
                        analysisTabDispatch({ type: "clear-cross-filter" })
                    }}>
                    Remove all
                </p>
            </div>
            {
                Object.entries(crossQuestionFilters)
                    .filter(([, codes]) => codes.length > 0)
                    .map(([questionId, codes]) => (
                        <AnalysisTabQuestionsColCrossFiltersQuestion
                            key={questionId}
                            questionId={questionId}
                            codes={codes.map(code => ({ id: code.codeId }))}
                        />
                    ))
            }
        </div>
    )
}