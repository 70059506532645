import React, { useCallback, useContext, useEffect, useState } from "react"
import { CiViewTable } from "react-icons/ci"
import { useNavigate, useParams } from "react-router-dom"
import GlautLogo from "../../assets/GlautLogo.png"
import Loading from "../../components/loading/Loading"
import { useProjectService } from "../../hooks/services/useProjectService"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import { WebSocketProvider } from "../../hooks/useWebSocket"
import { ProjectContext, ProjectProvider as ProjectProviderLegacy } from "./AutoSaveProject"
import CSVSettings from "./CSVSettings"
import AnalysisTab from "./ProjectArea/components/AnalysisTab"
import DataTab from "./ProjectArea/components/DataTab"
import OutlineTab from "./ProjectArea/components/OutlineTab"
import TopBar from "./ProjectArea/components/TopBar"
import ProjectProvider, { useProject, useProjectDispatch } from "./ProjectArea/contexts/ProjectProvider"
import Reports from "./Reports"
import Settings from "./Settings"

const ChooseSource = ({ setSource, setCurrentStep }) => {
    const axiosAuth = useAxiosAuth()
    const navigate = useNavigate()
    const { projectId } = useParams()

    const deleteProject = e => {
        e.preventDefault()

        axiosAuth
            .delete(`/datasets/${projectId}`)
            .then(() => navigate("/"))
            .catch(e => console.log(e))
    }

    return (
        <div className="column" style={{ maxWidth: "600px", margin: "auto" }}>
            <button
                className="secondary-button danger"
                style={{ marginBottom: "4em" }}
                onClick={deleteProject}
            >
                Cancel
            </button>
            <div
                className="box clickable column"
                onClick={() => {
                    setSource("interview")
                    setCurrentStep(0)
                }}
            >
                <div className="bar">
                    <img src={GlautLogo} className="large-icon" />
                    <h2>New Research Project</h2>
                </div>
                <p>Define the outline and run interviews</p>
            </div>
            <div className="box clickable column" onClick={() => setSource("CSV")}>
                <div className="bar">
                    <CiViewTable className="large-icon" />
                    <h2>Import a CSV</h2>
                </div>
                <p>Import a file containing open-ends and analyze its content</p>
            </div>
        </div>
    )
}

const ProjectContent = ({ currentTab, setCurrentTab }) => {
    // #region Contexts
    const { project, setProject } = useContext(ProjectContext)
    const { numberOfEntries } = useProject()
    const projectDispatch = useProjectDispatch()
    // #endregion

    // #region States
    const [projectInfo, setProjectInfo] = useState("")
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Callback
    const handleProjectDataFetch = useCallback(() => {
        if (!project) return

        const projectEntriesPromise = projectService.getEntries({ projectId: project._id })
        const projectAnalysisPromise = projectService.getAnalysis({ projectId: project._id })

        Promise.all([projectEntriesPromise, projectAnalysisPromise]).then(([
            projectEntries,
            projectAnalysis
        ]) => {
            projectDispatch({ type: "set-analysis", analysisData: projectAnalysis })
            projectDispatch({ type: "set-number-of-entries", numberOfEntries: projectEntries.total_entries })
        })
    }, [projectService, project, projectDispatch])
    // #endregion

    // #region Effects

    // Defaults to the data tab when changing project or to the correct CSV view
    useEffect(() => {
        if (currentTab !== null || !project?.source) return

        const isCsvProject = project?.source === "CSV"
        if (isCsvProject) {
            setCurrentTab(project?.schema?.length ? 2 : 5)
            return
        }

        setCurrentTab(1)
    }, [project, currentTab, setCurrentTab])

    // onLoadProjectGetProjectData
    useEffect(() => {
        handleProjectDataFetch()

        const timeout = 1000 * 60 * 2 // minutes
        const interval = setInterval(() => {
            handleProjectDataFetch()
        }, timeout)

        return () => {
            clearInterval(interval)
        }
    }, [handleProjectDataFetch])

    // onChangeNumberOfEntries
    useEffect(() => {
        setProjectInfo(`${numberOfEntries} Entries`)
        projectDispatch({
            type: "set-analysis-tab-export-data-button-state",
            isActive: numberOfEntries !== 0
        })
    }, [projectDispatch, numberOfEntries])

    // #endregion

    if (!project)
        return (
            <div className="flex flex-col flex-1">
                <Loading />
            </div>
        )

    return (
        <>
            {project?.source
                ? <>
                    <TopBar
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        projectInfo={projectInfo}
                    />
                    {currentTab === 0 && <OutlineTab />}
                    {currentTab === 1 && <DataTab />}
                    {currentTab === 2 && <AnalysisTab />}
                    {currentTab === 3 && <Reports />}
                    {currentTab === 4 && <Settings />}
                    {currentTab === 5 && <CSVSettings />}
                </>
                : <ChooseSource
                    setCurrentStep={setCurrentTab}
                    setSource={source => setProject(p => ({ ...p, source }))}
                />
            }
        </>
    )
}

const Project = () => {
    const [currentTab, setCurrentTab] = useState(null)

    const { projectId } = useParams()

    return (
        <WebSocketProvider url={`/datasets/${projectId}`}>
            <ProjectProviderLegacy currentTab={currentTab}>
                <ProjectProvider>
                    <div className="h-screen flex flex-col">
                        <ProjectContent currentTab={currentTab} setCurrentTab={setCurrentTab} />
                    </div>
                </ProjectProvider>
            </ProjectProviderLegacy>
        </WebSocketProvider>
    )
}

export default Project
