import { useMemo, useState } from "react"
import {
    MdCheck,
    MdFilterList
} from "react-icons/md"
import DataTabTableHeaderItemStatusModalFilter from "../DataTabTableHeaderItemStatusModalFilter"
import { useDataTab } from "../../contexts/DataTabProvider"

export default function DataTabTableHeaderItemStatus() {
    // #region Contexts
    const { entriesFilters } = useDataTab()
    // #endregion

    // #region States
    const [showFilterModal, setShowFilterModal] = useState(false)
    // #endregion

    // #region Memos
    const filterIconClassNames = useMemo(
        () => "w-[14px] h-[14px] " + (entriesFilters.status === undefined
            ? "text-glaut-grey"
            : "text-glaut-midnight bg-glaut-light-grey-50% rounded-sm"),
        [entriesFilters]
    )
    // #endregion

    return (
        <th scope="col" className="p-0 border-b-2" style={{ borderRight: "0px" }}>
            <div className="flex flex-row items-center">
                <div>
                    <MdCheck className="text-glaut-grey mr-1 w-[14px] h-[14px]" />
                </div>
                <div className="flex-1 text-glaut-dark-grey">
                    <span className="text-[13.33px] font-[500]">Status</span>
                </div>
                <div>
                    <MdFilterList className={filterIconClassNames} onClick={() => {
                        setShowFilterModal(!showFilterModal)
                    }} />
                </div>
                {showFilterModal && (
                    <DataTabTableHeaderItemStatusModalFilter
                        onClose={() => {
                            setShowFilterModal(false)
                        }}
                    />
                )}
            </div>
        </th>
    )
}