import React, { useMemo, useState } from "react"
import { useAuthInfo } from "@propelauth/react"

import { LuSend } from "react-icons/lu"
import Divider from "../../components/layouts/Divider"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import Options from "../../components/menus/Options"
import { useToast } from "@contexts/ToastProvider"
import { isSuperuser } from "../../utils/auth"
import { emailRegex } from "../../utils/Variables"
import Popup from "../../components/Popups/Popup"

const roles = [
    {
        slug: "Owner",
        title: "Owner",
        description: "Can edit projects and add or remove members of the workspace"
    },
    {
        slug: "Member",
        title: "Member",
        description: "Can edit projects but cannot add or remove members of the workspace"
    },
    {
        slug: "Viewer",
        title: "Viewer",
        description: "Can only view the projects in the workspace"
    }
]

const DeleteOrgPopup = ({ id, orgName, close }) => {
    const [name, setName] = useState("")

    const axiosAuth = useAxiosAuth()

    const deleteOrg = e => {
        e.preventDefault()
        if (name !== orgName) return

        axiosAuth
            .delete(`/orgs/${id}`)
            .then(() => window.location.replace("/"))
            .catch(e => console.log(e))
    }

    return (
        <Popup close={close}>
            <h2>Delete workspace</h2>
            <form onSubmit={deleteOrg}>
                To confirm, type &quot;
                {orgName}
                &quot; in the box below
                <input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    style={{ width: "100%" }}
                />
                <button
                    onClick={deleteOrg}
                    disabled={name !== orgName}
                    className="danger-button"
                    style={{ width: "100%" }}
                >
                    Delete this Workspace
                </button>
            </form>
        </Popup>
    )
}

const Settings = ({ org, setOrg }) => {
    const axiosAuth = useAxiosAuth()
    const { user, userClass } = useAuthInfo()
    const { addToast } = useToast()
    const userOrg = userClass.getOrg(org.org_id)

    const [openDelete, setOpenDelete] = useState(false)
    const [inviteAdress, setInviteAdress] = useState("")
    const [inviteRole, setInviteRole] = useState("Member")

    const emailValid = useMemo(() => emailRegex.test(inviteAdress), [inviteAdress])

    const invite = () => {
        const encodedInviteAddress = encodeURIComponent(inviteAdress)
        axiosAuth
            .put(`orgs/${org.org_id}/invite?email=${encodedInviteAddress}&role=${inviteRole}`)
            .then(() => {
                addToast("Invitation sent to " + inviteAdress)
                setInviteAdress("")
            })
            .catch(e => console.log(e))
    }

    const changeRole = (userId, role) => {
        axiosAuth
            .put(`orgs/${org.org_id}/change_role?user_id=${userId}&role=${role}`)
            .then(() => {
                setOrg(o => ({
                    ...o,
                    users: o.users.map(u => {
                        if (u.user_id === userId) return { ...u, role_in_org: role }
                        return u
                    })
                }))
            })
            .catch(e => console.log(e))
    }

    console.log(org)

    return (
        <div className="column" style={{ maxWidth: "1200px", margin: "6em auto" }}>
            <div className="box">
                <h3>Invite new member into the workspace</h3>
                {isSuperuser(user) || userOrg?.isAtLeastRole("Owner") ? (
                    <div className="bar" style={{ width: "100%" }}>
                        <input
                            value={inviteAdress}
                            onChange={e => setInviteAdress(e.target.value)}
                            placeholder="mail@adress.com"
                            style={{ flex: 1 }}
                            onKeyDown={e => e.key === "Enter" && emailValid && invite()}
                        />
                        <Options options={roles} chosen={inviteRole} setChosen={setInviteRole} />
                        <button disabled={!emailValid} onClick={invite}>
                            Invite
                            <LuSend className="small-icon" />
                        </button>
                    </div>
                ) : (
                    <span>You cannot invite others</span>
                )}
                <Divider />
                <h3 style={{ marginBottom: "2em" }}>Workspace members</h3>
                {org?.users?.map(u => (
                    <div className="long-bar" key={u.user_id} onClick={() => console.log(u)}>
                        <div className="bar">
                            <img
                                src={u.picture_url}
                                style={{ width: "2em", borderRadius: "100%" }}
                            />
                            <div className="column left-aligned" style={{ gap: 0 }}>
                                <b>
                                    {u.first_name} {u.last_name}
                                </b>
                                <span style={{ fontSize: "small" }}>{u.email}</span>
                            </div>
                        </div>
                        {isSuperuser(user) || userOrg.isAtLeastRole("Owner") && u.user_id !== userClass.userId ? (
                            <Options
                                options={roles}
                                chosen={u.role_in_org}
                                setChosen={v => changeRole(u.user_id, v)}
                            />
                        ) : (
                            <div>{u.role_in_org}</div>
                        )}
                    </div>
                ))}
            </div>
            <div className="box" style={{ border: "1px solid var(--danger-color)" }}>
                <h2>Danger zone</h2>
                <div className="long-bar">
                    <div className="column" style={{ gap: "4px", alignItems: "flex-start" }}>
                        <span style={{ fontWeight: "bold" }}>Delete this workspace</span>
                        <span>There is no going back. Please be certain.</span>
                    </div>
                    <button className="danger-button" onClick={() => setOpenDelete(true)}>
                        Delete
                    </button>
                </div>
            </div>
            {openDelete && (
                <DeleteOrgPopup
                    id={org.org_id}
                    orgName={org.name}
                    close={() => setOpenDelete(false)}
                />
            )}
        </div>
    )
}

export default Settings
