interface IGlautButtonSecondaryProps {
    children?: React.ReactNode
    onClick?: () => void
}

export default function GlautButtonSecondary({
    children,
    onClick
}: Readonly<IGlautButtonSecondaryProps>) {
    return (
        <button
            className="rounded-md bg-glaut-cards text-glaut-pink font-medium border-0 shadow-none"
            onClick={onClick}
        >
            {children}
        </button>
    )
}