import { useMemo } from "react"
import { UAParser } from "ua-parser-js"
import { MdPhoneIphone } from "react-icons/md"
import { IoMdDesktop } from "react-icons/io"

export function useTableRowItemPlatform(platform: string) {
    return useMemo(() => {
        if (platform === "Not available") return { text: platform }

        const parser = new UAParser(platform)
        const device = parser.getDevice().type
        const browser = parser.getBrowser().name
        const os = parser.getOS().name

        if (device === "mobile")
            return {
                text: `Mobile - ${os}`,
                icon: <MdPhoneIphone className="text-glaut-grey overflow-visible h-[14px] w-[14px] rounded-none" />
            }


        return {
            text: `Desktop - ${browser}`,
            icon: <IoMdDesktop className="text-glaut-grey overflow-visible h-[14px] w-[14px] rounded-none" />
        }
    }, [platform])
}