import React from "react"
import Options from "../Options"
import TextualSetting from "./TextualSetting"
import BooleanSetting from "./BooleanSetting"
import TextBox from "./TextBox"
import ColorSetting from "./ColorSetting"
import ImageSetting from "./ImageSetting"
import ChooseLanguage from "../ChooseLanguage"
import IntSetting from "./IntSetting"

const Setting = ({ setting, value, setValue, lang }) => {
    if (value === undefined) setValue(setting.default || "")

    switch (setting.type) {
        case "bool":
            return <BooleanSetting setting={setting} value={value} setValue={setValue} />
        case "int":
            return <IntSetting setting={setting} value={value} setValue={setValue} />
        case "text":
            return (
                <TextualSetting setting={setting} value={value} setValue={setValue} lang={lang} />
            )
        case "long-text":
            return <TextBox setting={setting} value={value} setValue={setValue} lang={lang} />
        case "options":
            return (
                <label className="long-bar">
                    {setting.description || setting.slug.replaceAll("_", " ")}
                    <Options options={setting.options} chosen={value} setChosen={setValue} />
                </label>
            )

        case "color":
            return <ColorSetting setting={setting} value={value} setValue={setValue} />
        case "img":
            return <ImageSetting setting={setting} value={value} setValue={setValue} />
        case "language":
            return <ChooseLanguage value={value} setValue={setValue} setting={setting} />
    }
}

export default Setting
