import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"
import { stringToColor } from "../../utils/miscellaneous"
import CustomTooltip from "./CustomTooltip"

const CustomLineChart = ({ data, dataKeys, unit, title }) => (
    <div className="box chart-container">
        <h2>{title}</h2>
        <LineChart width={900} height={30 * dataKeys.length + 200} data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="date_start" />
            <YAxis
                domain={[0, "auto"]}
                tickFormatter={tick => `${tick}${unit?.length < 3 ? unit : ""}`}
            />
            <Tooltip content={<CustomTooltip unit={unit} valueKey="count" />} />
            {dataKeys.map(key => (
                <Line key={key} type="monotone" dataKey={key} stroke={stringToColor(key)} />
            ))}
        </LineChart>
    </div>
)

export default CustomLineChart
