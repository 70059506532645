import { MdClose, MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"

interface IDataTabTableHeaderItemDateFilterProps {
    onClose?: () => void
}

const sortOptions = {
    desc: "Most recent first",
    asc: "Oldest first"
} as const

export default function DataTabTableHeaderItemDateFilter({
    onClose
}: Readonly<IDataTabTableHeaderItemDateFilterProps>) {
    // #region Contexts
    const { entriesSorts } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    // #endregion

    return (
        <>
            <div className="relative z-10">
                <div className={`absolute z-10 top-0 left-[100%] bg-white px-[15px] py-[10px] min-w-[200px] border-1 
                    rounded-[10px] border-glaut-grey`}>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between items-center">
                            <p className="font-semibold text-glaut-grey">Show</p>
                            <MdClose
                                className="text-glaut-grey"
                                style={{ width: "20px", height: "20px" }}
                                onClick={onClose} />
                        </div>
                        {Object.entries(sortOptions).map(([sortByOption, label]) => (
                            <div key={sortByOption}>
                                <hr />
                                <div className="flex flex-row gap-2 py-2 min-w-[128px]" onClick={() => {
                                    const sortBy = sortByOption as keyof typeof sortOptions
                                    dataTabDispatch({ type: "set-sort-date", sort: sortBy })
                                }}>
                                    {sortByOption === entriesSorts.date
                                        ? <MdRadioButtonChecked className="rounded-none text-glaut-midnight" />
                                        : <MdRadioButtonUnchecked className="rounded-none text-glaut-midnight" />}
                                    {label}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/** Invisible div for closing the filter modal. */}
            <div className="absolute inset-0 z-0" onClick={onClose}></div>
        </>
    )
}