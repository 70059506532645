import { useEffect, useState } from "react"
import { FaCheckSquare, FaRegSquare } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"

interface IDataTabTableHeaderItemPlatformFilterProps {
    onClose?: () => void
}

const filterOptions = {
    "mobile": "Mobile",
    "desktop": "Desktop"
} as const

export default function DataTabTableHeaderItemPlatformFilter({
    onClose
}: Readonly<IDataTabTableHeaderItemPlatformFilterProps>) {
    // #region Contexts
    const { entriesFilters } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    // #endregion

    // #region States
    const [stateChecked, setStateChecked] = useState<Record<keyof typeof filterOptions, boolean>>({
        mobile: entriesFilters.platform === undefined || entriesFilters.platform === "mobile",
        desktop: entriesFilters.platform === undefined || entriesFilters.platform === "desktop"
    })
    // #endregion

    // #region Effects
    useEffect(() => {
        const noneChecked = !stateChecked.mobile && !stateChecked.desktop
        if (noneChecked) return

        let platform: keyof typeof filterOptions | undefined
        const bothChecked = stateChecked.mobile && stateChecked.desktop
        if (!bothChecked)
            platform = stateChecked.mobile ? "mobile" : "desktop"

        dataTabDispatch({ type: "set-filter-platform", platform })
    }, [stateChecked, dataTabDispatch])
    // #endregion

    return (
        <>
            <div className="relative z-10">
                <div className={`absolute z-10 top-0 left-[100%] bg-white px-[15px] py-[10px] min-w-[155px] border-1
                    rounded-[10px] border-glaut-grey`}>
                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between items-center">
                            <p className="font-semibold text-glaut-grey">Show</p>
                            <MdClose
                                className="text-glaut-grey"
                                style={{ width: "20px", height: "20px" }}
                                onClick={onClose} />
                        </div>
                        {Object.entries(filterOptions).map(([state, label]) => (
                            <div key={state}>
                                <hr />
                                <div className="flex flex-row gap-2 py-2" onClick={() => {
                                    setStateChecked({ ...stateChecked, [state]: !stateChecked[state] })
                                }}>
                                    {stateChecked[state]
                                        ? <FaCheckSquare className="rounded-none text-glaut-midnight" />
                                        : <FaRegSquare className="rounded-none text-glaut-midnight" />}
                                    {label}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/** Invisible div for closing the filter modal. */}
            <div className="absolute inset-0 z-0" onClick={onClose}></div>
        </>
    )
}