import React, { useContext, useEffect, useMemo } from "react"
import { questionTypes } from "../../../utils/Variables"
import { BiTrash } from "react-icons/bi"

import OptionsInput from "../../inputs/OptionsInput"
import { ProjectContext } from "../../../pages/Project/AutoSaveProject"

const emptyFilter = { id: null, operator: null, value: null }

// Filter that determines if a question will be asked or not
export const QuestionFilter = ({ filter, setFilter, questionIdx, lang, disabled = false }) => {
    const { project } = useContext(ProjectContext)
    const question = useMemo(() => project.schema.find(q => q.id === filter.id), [filter.id, project.schema])

    useEffect(() => {
        if (!question) setFilter({ ...filter, operator: null })

        switch (question?.type) {
            case questionTypes.multipleChoice:
                setFilter({ ...filter, operator: "in" })
                break
            case questionTypes.select:
                setFilter({ ...filter, operator: "contains" })
                break
        }
    }, [question, filter, setFilter])

    return (
        <div className="bar bordered" style={{ width: "100%" }}>
            <OptionsInput
                options={project.schema.filter(
                    (q, idx) => idx < questionIdx && q.type === questionTypes.select && !q.source
                )}
                value={filter.id}
                setValue={qId => setFilter({ ...filter, id: qId })}
                single={true}
                objKey="value"
                initialOpen={false}
                lang={project.general_settings.language}
                disabled={disabled}
            />
            <span className="bordered">{filter.operator}</span>
            {["contains", "in"].includes(filter.operator) && (
                <OptionsInput
                    options={question?.options || []}
                    value={filter.value || []}
                    setValue={v =>
                        setFilter({
                            ...filter,
                            value: filter.value?.includes(v)
                                ? filter.value.filter(fv => fv !== v)
                                : [...(filter.value || []), v]
                        })
                    }
                    initialOpen={false}
                    objKey="label"
                    lang={lang}
                    disabled={disabled}
                />
            )}
            <span
                style={{
                    borderLeft: "1px solid var(--border-color)",
                    borderRadius: 0,
                    paddingLeft: "1em"
                }}
            >
                <BiTrash
                    className={["clickable danger small-icon", disabled ? "disabled" : ""].join(" ")}
                    onClick={() => !disabled && setFilter(null)}
                />
            </span>
        </div>
    )
}

// Menu that lets the user view and edit the filters that determine if a question will be asked or not
export const QuestionFilters = ({ question, setQuestion, lang, disabled = false }) => {
    const { project } = useContext(ProjectContext)

    const questionIdx = useMemo(
        () =>
            (index => (index !== -1 ? index : project.schema.length))(
                project.schema.findIndex(q => q.id === question.id)
            ),
        [project.schema, question.id]
    )

    const setFilter = (newFilter, idx) =>
        newFilter
            ? setQuestion(q => ({
                ...q,
                filters: q.filters.map((f, i) => (i === idx ? newFilter : f))
            }))
            : setQuestion(q => ({ ...q, filters: q.filters.filter((f, i) => i !== idx) }))

    // We can only filter with multiple choices ATM, so if there is no multiple choice question before we can't filter
    // this question
    if (
        !project.schema.find(
            (q, idx) => idx < questionIdx && q.type === questionTypes.select
        )
    ) return

    return (
        <>
            <div className="long-bar">
                <h3>Filters</h3>
                <span
                    className={"clickable-text"}
                    style={{
                        cursor: disabled ? "not-allowed" : undefined
                    }}
                    onClick={() =>
                        !disabled && setQuestion(q => ({
                            ...q,
                            filters: [...(q.filters || []), emptyFilter]
                        }))
                    }
                >
                    + Add filter
                </span>
            </div>

            {question.filters?.map((f, idx) => (
                <QuestionFilter
                    filter={f}
                    setFilter={f => setFilter(f, idx)}
                    project={project}
                    questionIdx={questionIdx}
                    key={idx}
                    lang={lang}
                    disabled={disabled}
                />
            ))}
        </>
    )
}
