import FloatingMenu from "@components/layouts/FloatingMenu"
import { useAuthInfo } from "@propelauth/react"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { BiDotsVerticalRounded } from "react-icons/bi"
import {
    MdAddChart,
    MdArrowRight,
    MdClose,
    MdFilterListAlt,
    MdOutlinePushPin,
    MdSaveAlt
} from "react-icons/md"
import { v4 as uuid } from "uuid"
import logo from "@assets/GlautLogoWhite.png"
import { useEditQuestion } from "@hooks/useEditQuestion"
import { useWebSocket } from "@hooks/useWebSocket"
import { ProjectContext } from "@pages/Project/AutoSaveProject"
import QuestionTitle from "@pages/Project/ProjectArea/components/AnalysisTabQuestionsColQuestionTitle"
import { useAnalysisTab } from "@pages/Project/ProjectArea/contexts/AnalysisTabProvider"
import { sanitizeQuestion } from "../../../sanitizers/QuestionSanitizers"
import { copy, getCopy } from "@utils/Copy"
import { questionTypes } from "@utils/Variables"
import { getLanguageKey, getProjectLang } from "@utils/language"
import Box, { BoxMenuLine } from "../../layouts/Box"
import Button from "../../layouts/Button"
import FloatingLayout from "../../layouts/FloatingLayout"
import LoadingBar from "../../loading/LoadingBar"
import TextualSetting from "../../menus/Setting/TextualSetting"
import InsightDialogue from "./InsightDialogue"
import SelectDialogue from "./SelectDialogue"
import DeleteQuestionModal from "./components/DeleteQuestionModal"

const boxMenuConfig = {
    [questionTypes.insight]: {
        titleKey: copy.coding.analysisMenu.openTextCoding,
        descriptionKey: copy.coding.analysisMenu.openTextCodingDetail
    },
    [questionTypes.select]: {
        titleKey: copy.coding.analysisMenu.multipleChoice,
        descriptionKey: copy.coding.analysisMenu.multipleChoiceDetail
    },
    [questionTypes.entity]: {
        titleKey: copy.coding.analysisMenu.entities,
        descriptionKey: copy.coding.analysisMenu.entitiesDetail
    },
    [questionTypes.number]: {
        titleKey: copy.coding.analysisMenu.numerical,
        descriptionKey: copy.coding.analysisMenu.numericalDetail
    }
}

// Lists all the available analysis type the user can choose from
const availableAnalysisTypes = [questionTypes.insight, questionTypes.select, questionTypes.entity, questionTypes.number]

function Question({ question, analysis, onClick, currentQuestion, currentAnalysis }) {
    // #region Contexts
    const { project, setProject, forceSave } = useContext(ProjectContext)
    const { crossQuestionFilters } = useAnalysisTab()
    // #endregion

    // #region States

    // Stores the new question we are asking to the column
    const [newQuestion, setNewQuestion] = useState(null)
    const [showQuestionMenu, setShowQuestionMenu] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    // Loading percentage for analysis
    const [loading, setLoading] = useState()

    const [isShowingHiddenQuestionOptions, setIsShowingHiddenQuestionOptions] = useState(false)

    // #endregion

    // #region WebSocket
    const { sendMessage, addCallback } = useWebSocket()
    // #endregion

    // #region Memos
    const shouldRenderParentQuestion = useMemo(
        () => project?.source === "CSV" || analysis !== undefined,
        [project, analysis]
    )
    const isHiddenQuestion = useMemo(
        () => analysis?.source === question.id,
        [analysis, question]
    )
    const lang = useMemo(() => getProjectLang(project), [project])
    const selected = useMemo(
        () => (
            // If analysis is selected, check if the current analysis is the same as the one in the component
            currentAnalysis && analysis && currentAnalysis.id === analysis.id
        )
            || (
                // If question is selected, check if the current question is the same as the one in the component
                !currentAnalysis && !analysis && currentQuestion && currentQuestion.id === question.id
            ),
        [currentQuestion, currentAnalysis, question, analysis]
    )
    const questionDivClassNames = useMemo(() => {
        // .project-area-question is used in testing
        let baseClassNames = `project-area-question 
            flex flex-col gap-1 rounded-[4px] cursor-pointer py-[10px] px-[12px] border-2 `
        if (!selected) {
            const borderStyleClassName = isHiddenQuestion ? "border-dashed" : "border-transparent"
            baseClassNames += analysis
                ? `bg-glaut-off-white border-glaut-grey ${borderStyleClassName}`
                : "bg-glaut-off-white border-transparent"
        }
        else {
            baseClassNames += "project-area-question-selected bg-glaut-text-midnight border-transparent"
        }

        return baseClassNames
    }, [analysis, selected, isHiddenQuestion])
    const questionLabelClassNames = useMemo(
        () => `text-[11.11px] font-medium
            ${selected ? "text-glaut-off-white" : "text-glaut-midnight"}`,
        [selected]
    )
    const questionBiDotsVerticalRoundedIconClassNames = useMemo(
        () => `project-area-question-bidots-icon w-4 h-4
            ${selected ? "text-glaut-off-white" : "text-glaut-midnight"}`,
        [selected]
    )
    const questionDescriptionClassNames = useMemo(
        () => `question-text font-medium text-[13.33px]
            ${selected ? "text-glaut-off-white" : "text-glaut-midnight"}`,
        [selected]
    )
    const hasRelatedCrossQuestionFilters = useMemo(() => {
        if (!analysis) return false

        const filters = crossQuestionFilters[analysis.id]
        return filters !== undefined && filters.length > 0
    }, [crossQuestionFilters, analysis])

    const shouldRenderAnalysisLoadingBar = useMemo(() => typeof loading === "number", [loading])
    const shouldRenderAnalyzeButton = useMemo(
        () => !analysis && question.analysis.length === 0 && question.type === questionTypes.shortText,
        [analysis, question]
    )
    // #endregion

    // #region Refs
    const analysisListRef = useRef()
    const questionDivRef = useRef()
    // #endregion

    // #region Other hooks
    const { user } = useAuthInfo()
    const { valid } = useEditQuestion(newQuestion, lang)
    // #endregion

    // #region Callbacks
    const closeMenu = () => {
        setIsShowingHiddenQuestionOptions(false)
        setShowQuestionMenu(false)
        setNewQuestion(null)
    }

    const cancelAnalysis = () => {
        sendMessage({ operation: "cancel_analysis" })
        setLoading(null)
    }

    const startAnalysis = e => {
        e.stopPropagation()

        setProject(p => {
            const sanitizedQuestion = sanitizeQuestion(newQuestion, lang)

            // finds the right index to insert the new question at
            const insertIndex = project.schema.findIndex(q => q.id === sanitizedQuestion.source) + 1
            const clonedQuestions = [...project.schema]
            clonedQuestions.splice(insertIndex, 0, sanitizedQuestion)

            // return the project with the updated schema
            const updatedProject = { ...p, schema: clonedQuestions }

            // Selects new question and closes the dialogue
            onClick(question, sanitizedQuestion)
            setShowQuestionMenu(false)
            setNewQuestion(null)

            // Force saves so the new question will show before tags come in
            forceSave(updatedProject)

            // sends it to the back the operation
            sendMessage({ operation: "new_question", question: sanitizedQuestion })

            // Updates the project
            return updatedProject
        })
    }

    const handleDeleteQuestion = useCallback(() => {
        setIsDeleteModalOpen(false)

        if (!analysis) return

        setProject(p => ({
            ...p,
            schema: p.schema.filter(q => q.id !== analysis.id)
        }))
    }, [analysis, setProject])
    // #endregion

    // #region Effects

    // Register the websocket message handling function
    useEffect(() => {
        const handleWSMessage = data => {
            if (analysis && data.job_status?.question_id === analysis.id) setLoading(data.job_status.completion)
        }

        addCallback(handleWSMessage)
    }, [])

    // #endregion

    // #region Element memos
    const newHiddenQuestionOptionsElement = useMemo(() => (
        <Box
            id="box--question-analysis"
            key="question-analysis"
            title={getCopy(copy.coding.addHiddenQuestionOptionsTitle)}
            onClose={closeMenu}
            parentStyle={{
                boxShadow: "0px 0px 8px 0px #00000026"
            }}
            style={{
                gap: "1px",
                padding: "0 14px"
            }}
            boxHeaderTitleClassName="text-[11.11px] font-medium"
            boxContentClassName="px-1"
        >
            {availableAnalysisTypes.map(a =>
                <BoxMenuLine
                    title={getCopy(boxMenuConfig[a].titleKey, lang)}
                    description={getCopy(boxMenuConfig[a].descriptionKey, lang)}
                    onClick={() => {
                        setNewQuestion({
                            type: a,
                            value: question.value,
                            id: uuid(),
                            source: question.id
                        })
                        setShowMenu(false)
                        setShowQuestionMenu(false)
                    }}
                    key={a}
                    menuLineTextClassName=""
                    menuLineTitleClassName="text-[13.33px]"
                    menuLineDescriptionClassName="text-[11.11px] text-[#666666]"
                />
            )}
        </Box>
    ), [
        lang,
        question.id,
        question.value
    ])
    // #endregion

    // Just to check
    if (!question) return []

    return [
        (shouldRenderParentQuestion && (
            <div
                key="question"
                className={questionDivClassNames}
                onClick={() => onClick(question, analysis)}
                ref={questionDivRef}
            >
                <div className="flex flex-row justify-between">
                    <QuestionTitle
                        questionLabel={!isHiddenQuestion
                            ? getCopy(copy.coding.questionOrAnalysisHeader)(question)
                            : getCopy(copy.coding.questionOrAnalysisHeader)(question, analysis)}
                        questionType={analysis?.type ?? question.type}
                        questionLabelClassName={questionLabelClassNames}
                    />
                    <div className="flex flex-row">
                        {hasRelatedCrossQuestionFilters && <MdFilterListAlt className="text-glaut-grey w-4 h-4" />}
                        <BiDotsVerticalRounded
                            onClick={() => setShowMenu(!showMenu)}
                            className={questionBiDotsVerticalRoundedIconClassNames}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-1">
                    <div className={questionDescriptionClassNames}>{
                        getLanguageKey(analysis ? analysis.value : question.value, lang) ||
                        (analysis ? analysis.header : question.header)
                    }</div>
                </div>
                {(shouldRenderAnalysisLoadingBar || shouldRenderAnalyzeButton) && (
                    <div>
                        {(
                            shouldRenderAnalysisLoadingBar ? (
                                <div className="bar">
                                    <LoadingBar percentage={loading} style={{ flex: 1 }} />
                                    <button
                                        className={`project-area-cancel-ongoing-analysis-button
                                        border-none shadow-none px-3 py-[6px] font-medium text-center 
                                        bg-glaut-pink text-glaut-off-white
                                        group hover:bg-glaut-off-white hover:text-glaut-pink`}
                                        onClick={cancelAnalysis}>
                                        Cancel
                                    </button>
                                </div>
                            ) : (
                                // If the question is not an analysis, has no analysis and is a short text question, 
                                // show the analyze button (short text is used only for CSV import)
                                shouldRenderAnalyzeButton && (
                                    <button
                                        className={`project-area-analyze-button border-none shadow-none px-[12px] py-[6px]
                                        h-max text-center bg-glaut-pink text-glaut-off-white
                                        group hover:bg-glaut-off-white hover:text-glaut-pink`}
                                        onClick={() => setShowQuestionMenu(true)}
                                        disabled={showMenu || !user.email.endsWith("@glaut.com")}
                                        id="analyze"
                                    >
                                        <div className="flex flex-row gap-1">
                                            <MdAddChart
                                                className="text-glaut-off-white group-hover:text-glaut-pink transition-colors"
                                            />
                                            <span className="font-medium">{getCopy(copy.coding.analyze)}</span>
                                        </div>
                                    </button>
                                )
                            )
                        )}
                    </div>
                )}
                {showMenu && (
                    ReactDOM.createPortal(
                        <FloatingMenu
                            onClose={() => {
                                if (isShowingHiddenQuestionOptions) return

                                setShowMenu(false)
                                setIsShowingHiddenQuestionOptions(false)
                            }}
                            anchorRef={questionDivRef}
                            className="relative border-1 border-glaut-grey shadow-[0px_0px_8px_0px_#00000026]"
                            elements={[
                                user.email.endsWith("@glaut.com") && !isHiddenQuestion && {
                                    icon: <MdAddChart className="w-4 h-4" />,
                                    text: (
                                        <>
                                            <div
                                                id="div--project-area-floating-menu-add-analysis"
                                                className="relative flex flex-row justify-between items-center"
                                                onMouseEnter={() => {
                                                    setIsShowingHiddenQuestionOptions(true)
                                                }}
                                            >
                                                <p className="w-[158px] text-[13.33px]">
                                                    {getCopy(copy.coding.addHiddenQuestion)}
                                                </p>
                                                <MdArrowRight
                                                    className="self-center w-4 h-4"
                                                    style={{ margin: 0, padding: 0, float: "none" }}
                                                />
                                            </div>
                                            {isShowingHiddenQuestionOptions && !newQuestion && question.analysis.length > 0 && (
                                                <div
                                                    id="div--project-area-floating-menu-add-analysis-options"
                                                    className="absolute top-0 left-[100%] w-80 ml-2"
                                                    onMouseLeave={() => {
                                                        if (isShowingHiddenQuestionOptions)
                                                            setIsShowingHiddenQuestionOptions(false)
                                                    }}>
                                                    {newHiddenQuestionOptionsElement}
                                                </div>
                                            )}
                                        </>
                                    ),
                                    onClick: question.analysis.length > 0 ? () => { } : undefined,
                                    class: "add-analysis"
                                },
                                {
                                    icon: <MdOutlinePushPin className="w-4 h-4" />,
                                    text: getCopy(copy.coding.pinQuestion),
                                    textClassName: "text-[13.33px]",
                                    class: "pin-question"
                                },
                                {
                                    icon: <MdSaveAlt className="w-4 h-4" />,
                                    text: getCopy(copy.coding.saveCodebook),
                                    textClassName: "text-[13.33px]",
                                    class: "save-codebook"
                                },
                                analysis && isHiddenQuestion && {
                                    icon: <MdClose className="w-4 h-4" />,
                                    text: getCopy(copy.coding.deleteQuestion),
                                    textClassName: "text-[13.33px]",
                                    class: "delete-question",
                                    onClick: () => {
                                        setIsDeleteModalOpen(true)
                                    }
                                }
                            ]}
                        />,
                        document.getElementById("portal")
                    )
                )}
            </div>
        )),
        (!showQuestionMenu && question.analysis.length > 0 && !analysis && (
            <div
                className="flex flex-col gap-2"
                key="question-analysis-list"
                ref={analysisListRef}
            >
                {question.analysis.map(a => <Question
                    key={a.id}
                    question={question}
                    analysis={a}
                    onClick={onClick}
                    currentQuestion={currentQuestion}
                    currentAnalysis={currentAnalysis}
                />)}
            </div>
        )),
        (showQuestionMenu && !newQuestion && (
            ReactDOM.createPortal(
                <FloatingLayout
                    id="div--project-area-floating-menu-add-analysis-options"
                    className="absolute w-80 ml-2 z-50 overflow-hidden"
                    anchorRef={questionDivRef}
                    onClose={() => { setShowQuestionMenu(false) }}
                    horizontalOffset={2}>
                    {newHiddenQuestionOptionsElement}
                </FloatingLayout>,
                document.getElementById("portal")
            )
        )),
        (newQuestion && (
            ReactDOM.createPortal(
                <FloatingLayout
                    id="div--project-area-floating-menu-add-hidden-question"
                    className="absolute w-[450px] ml-2 z-50 overflow-hidden drop-shadow-[0px_0px_8px_#00000026]"
                    anchorRef={questionDivRef}
                    onClose={() => { setShowQuestionMenu(false) }}
                    horizontalOffset={2}>
                    <Box
                        key="question-details"
                        style={{ gap: "1em" }}
                        title="Decide how to analize this information"
                        onClose={() => setNewQuestion(null)}
                        className="question-analysis"
                        boxHeaderTitleClassName="text-[11.11px] font-medium"
                    >
                        <BoxMenuLine
                            title={getCopy(boxMenuConfig[newQuestion.type].titleKey, lang)}
                            description={getCopy(boxMenuConfig[newQuestion.type].descriptionKey, lang)}
                            icon={boxMenuConfig[newQuestion.type].icon}
                            key="question-info"
                            menuLineClassName={`floating-menu-add-hidden-question-menu-line
                                bg-glaut-cards p-[8px] rounded-[4px] w-full`}
                            menuLineTextClassName=""
                            menuLineTitleClassName="font-medium text-[13.33px]"
                            menuLineDescriptionClassName="font-medium text-[11.11px] text-[#666666]"
                        />

                        <TextualSetting
                            setting={{
                                description: "Enter your question here",
                                descriptionClassName: "text-[13.33px] text-[#00000099] font-medium",
                                inputClassName: "py-[8px] px-[12px]",
                                labelStyle: {
                                    gap: 0
                                }
                            }}
                            value={newQuestion.value}
                            setValue={v => setNewQuestion(q => ({ ...q, value: v }))}
                            lang={lang}
                        />

                        {newQuestion.type === questionTypes.insight &&
                            <InsightDialogue
                                question={newQuestion}
                                setQuestion={setNewQuestion}
                                lang={lang}
                                descriptionClassName="text-[13.33px] text-[#00000099] font-medium"
                                textareaClassName="text-[12.7px] py-[8px] px-[12px] border-black"
                                labelStyle={{ gap: 0 }}
                                toggleInputOptionClassName="text-center"
                                toggleInputClassName="toggle rounded-[4px]"
                            />
                        }

                        {newQuestion.type === questionTypes.select &&
                            <SelectDialogue question={newQuestion} setQuestion={setNewQuestion} lang={lang} />
                        }
                        <Button
                            label="Run Glaut!"
                            className="primary"
                            icon={<img src={logo} alt="glaut-logo" />}
                            onClick={startAnalysis}
                            disabled={!valid}
                        />

                    </Box>
                </FloatingLayout>,
                document.getElementById("portal")
            )
        )),
        analysis && (
            <DeleteQuestionModal
                key="delete-question-modal"
                open={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false)
                }}
                onConfirm={handleDeleteQuestion}
            />
        )
    ]
}

export default Question
