interface IAnalysisTabAnalysisColNpsMetricsProps {
    promoters: number
    detractors: number
    nps: number
}

export default function AnalysisTabAnalysisColNpsMetrics({
    detractors,
    nps,
    promoters
}: Readonly<IAnalysisTabAnalysisColNpsMetricsProps>) {
    return (
        <div className={`flex flex-row justify-center items-center h-[75px] w-full gap-[38px] border-1 rounded-[10px]
            border-glaut-grey shadow-[0px_0px_8px_0px_#00000026]`}>
            <div className="flex flex-col items-center">
                <p className="text-[23.04px] font-medium">{String(promoters.toFixed(2)).padStart(2, "0")}%</p>
                <p className="text-[16px] font-normal">Promoters</p>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-[23.04px] font-medium">{String(detractors.toFixed(2)).padStart(2, "0")}%</p>
                <p className="text-[16px] font-normal">Detractors</p>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-[23.04px] font-medium">{String(nps.toFixed(2)).padStart(2, "0")}</p>
                <p className="text-[16px] font-normal">NPS</p>
            </div>
        </div>
    )
}