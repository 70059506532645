import { createContext, useContext, useEffect, useReducer } from "react"

// #region Types definitions
interface IConversationProviderProps {
    embedVideoId: string
    LKroom
    sendJSON
    isWebRTC: boolean
    children: React.ReactNode
}

interface IConversationProviderState {
    embedVideoId: string
    isRecording: boolean
    isWebRTC: boolean
    sendJSON
    LKroom
}

type IConversationProviderAction = {
    type: "set-is-recording"
    isRecording: boolean
} | {
    type: "UPDATE_LK"
    payload: {
        sendJSON
        LKroom
    }
}
// #endregion

// #region Contexts definitions
const ConversationContext = createContext({} as IConversationProviderState)
const ConversationContextDispatch = createContext({} as React.Dispatch<IConversationProviderAction>)
// #endregion

// #region Hooks definitions
export function useConversation() {
    return useContext(ConversationContext)
}

export function useConversationDispatch() {
    return useContext(ConversationContextDispatch)
}
// #endregion

// #region Provider definition
export default function ConversationProvider({
    embedVideoId,
    LKroom,
    sendJSON,
    isWebRTC,
    children
}: Readonly<IConversationProviderProps>) {
    const internalState = {
        embedVideoId,
        isWebRTC,
        sendJSON,
        LKroom,
        isRecording: false
    }

    const [state, dispatch] = useReducer(ConversationReducer, internalState)

    // Effect to update state when props change
    useEffect(() => {
        dispatch({ type: "UPDATE_LK", payload: { sendJSON, LKroom } })
    }, [sendJSON, LKroom])

    return <ConversationContext.Provider value={state}>
        <ConversationContextDispatch.Provider value={dispatch}>
            {children}
        </ConversationContextDispatch.Provider>
    </ConversationContext.Provider>
}
// #endregion

// #region Reducer definition
function ConversationReducer(
    state: IConversationProviderState,
    action: IConversationProviderAction
): IConversationProviderState {
    switch (action.type) {
        case "UPDATE_LK": {
            return { ...state, ...action.payload }
        }
        case "set-is-recording": {
            return {
                ...state,
                isRecording: action.isRecording
            }
        }
        default: {
            return state
        }
    }
}
// #endregion

