import React, { useEffect, useMemo, useRef, useState } from "react"
import { stringToColor } from "../../utils/miscellaneous"
import Bucket from "../values/Bucket"

const OptionsInput = ({
    options,
    value,
    setValue,
    single,
    lang,
    objKey = "name",
    initialOpen = true,
    disabled = false
}) => {
    const [open, setOpen] = useState(initialOpen)
    const dropdownRef = useRef(null)

    const selected = useMemo(
        () =>
            single
                ? options.find(o => o.id === value)
                : value?.map(v => options.find(o => o.id === v)),
        [value, options, single]
    )

    const selectedLabel = useMemo(
        () => (lang ? selected?.[objKey]?.[lang] : selected?.[objKey]),
        [lang, selected]
    )

    const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target))
            setOpen(false)

    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    return (
        <div ref={dropdownRef} className="options-input">
            <div
                onClick={() => setOpen(true)}
                className="bar"
                style={{ flexWrap: "wrap", cursor: "pointer", height: "100%" }}
            >
                {single
                    ? (
                        selected
                            ? (
                                <span
                                    className={["clickable small", disabled ? "disabled" : ""].join(" ")}
                                    onClick={() => !disabled && open && setValue(null)}
                                    style={{
                                        background: stringToColor(selectedLabel),
                                        color: "white"
                                    }}
                                >
                                    {selectedLabel}
                                </span>
                            )
                            : (
                                <span className="bordered">Choose</span>
                            )
                    )
                    : value?.length
                        ? (
                            selected.map((v, idx) => (
                                <span
                                    key={idx}
                                    style={{
                                        background: stringToColor(v?.[objKey]),
                                        color: "white"
                                    }}
                                    className={["clickable small", disabled ? "disabled" : ""].join(" ")}
                                    onClick={() => !disabled && open && setValue(v.id)}
                                >
                                    {lang ? v?.[objKey]?.[lang] : v?.[objKey]}
                                </span>
                            ))
                        )
                        : (
                            <span className="bordered">Choose</span>
                        )}
            </div>
            {!disabled && open && (
                <div style={{ minWidth: "300px" }}>
                    <div
                        className="column scroll"
                        style={{ maxHeight: "400px", width: "100%", gap: 0 }}
                    >
                        {options.map(o => (
                            <Bucket
                                key={o.id}
                                bucket={o}
                                setValue={setValue}
                                objKey={objKey}
                                lang={lang}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default OptionsInput
