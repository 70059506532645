import { useProjectService } from "@hooks/services/useProjectService"
import ProjectMetricsBoxGraphProportionEntry from "../ProjectMetricsBoxGraphProportionEntry"
import ProjectMetricsBoxTextEntry from "../ProjectMetricsBoxTextEntry"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ProjectContext } from "../../../AutoSaveProject"
import { IProject } from "src/@types/project"
import Loading from "@components/loading/Loading"
import ProjectMetricsBoxStatusLabel from "@pages/Project/ProjectArea/components/ProjectMetricsBoxStatusLabel"

export default function ProjectMetricsBox() {
    // #region Contexts
    const { project } = useContext(ProjectContext) as { project: IProject | null }
    // #endregion

    // #region Services
    const projectService = useProjectService()
    // #endregion

    // #region Types
    type MetricsState = Awaited<ReturnType<typeof projectService["getMetrics"]>>
    // #endregion

    // #region States
    const [metrics, setMetrics] = useState<MetricsState>()
    // #endregion

    // #region Memos
    const completionRate = useMemo(() => {
        if (metrics === undefined) return 0
        if (metrics.started === 0) return 0

        const percentage = (metrics.completed / (metrics.started)) * 100
        return Math.round(percentage)
    }, [metrics])

    const desktopPercentage = useMemo(() => {
        if (metrics?.platform_split.desktop === undefined) return 0

        const percentage = (
            metrics.platform_split.desktop / (
                metrics.platform_split.desktop +
                (metrics.platform_split.mobile ?? 0) +
                (metrics.platform_split.tablet ?? 0)
            )
        ) * 100
        return Math.round(percentage)
    }, [metrics])

    const mobilePercentage = useMemo(() => {
        if (metrics?.platform_split.mobile === undefined && metrics?.platform_split.tablet === undefined) return 0

        const mobile = metrics.platform_split.mobile ?? 0
        const tablet = metrics.platform_split.tablet ?? 0
        const desktop = metrics.platform_split.desktop ?? 0

        const percentage = (
            (mobile + tablet) / (desktop + mobile + tablet)
        ) * 100
        return Math.round(percentage)
    }, [metrics])

    const avgInterviewLengthMinutes = useMemo(() => {
        if (metrics === undefined || metrics.avg_interaction_time < 0) return undefined
        return String(Math.floor(metrics.avg_interaction_time / 60)).padStart(2, "0")
    }, [metrics])

    const avgInterviewLengthSeconds = useMemo(() => {
        if (metrics === undefined || metrics.avg_interaction_time < 0) return undefined
        return String(Math.round(metrics.avg_interaction_time % 60)).padStart(2, "0")
    }, [metrics])

    const avgInterviewLength = useMemo(() => {
        if (!avgInterviewLengthMinutes || !avgInterviewLengthSeconds) return "Not available"
        return `${avgInterviewLengthMinutes}:${avgInterviewLengthSeconds} min`
    }, [avgInterviewLengthMinutes, avgInterviewLengthSeconds])
    // #endregion

    // #region Callbacks
    const handleMetricsRetrieval = useCallback(() => {
        if (!project) return

        projectService.getMetrics({ projectId: project._id }).then(metrics => {
            setMetrics(metrics)
        })
    }, [project, projectService])
    // #endregion

    // #region Effects
    useEffect(() => {
        handleMetricsRetrieval()

        const timeout = 1000 * 60 * 1 // minutes
        const interval = setInterval(() => {
            handleMetricsRetrieval()
        }, timeout)

        return () => {
            clearInterval(interval)
        }
    }, [handleMetricsRetrieval])
    // #endregion

    if (metrics === undefined) return <Loading />
    if (project?.source !== "interview") return <></>

    return (
        <div className={`flex justify-center items-center gap-[0.5em] rounded-[0.625em] p-2 mt-12 border-1 mx-[21px]
            bg-glaut-off-white border-glaut-stroke-glaut`}>
            <ProjectMetricsBoxTextEntry description="Started" value={String(metrics.started)} />
            <ProjectMetricsBoxTextEntry description="Completed" value={String(metrics.completed)} />
            <ProjectMetricsBoxTextEntry description="Completion rate" value={`${completionRate}%`} />
            <ProjectMetricsBoxTextEntry
                description="Avg. interview length"
                value={avgInterviewLength}
            />
            <ProjectMetricsBoxStatusLabel status={project.status} />
            <ProjectMetricsBoxGraphProportionEntry
                desktopPercentage={desktopPercentage}
                mobilePercentage={mobilePercentage}
            />
        </div>
    )
}