import { MouseEvent as ReactMouseEvent } from "react"
import { FaRegPaperPlane } from "react-icons/fa"

interface ISendIconProps {
    onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
}

export default function SendIcon({
    disabled,
    onClick
}: Readonly<ISendIconProps>) {
    return (
        <button
            className={`submit bg-glaut-codes-pink border-none w-[3em] h-[3em] rounded-[0.875em] p-[0.9375em] 
                            shadow-[0_0_8px_0_rgba(0,0,0,0.15)] group
                            disabled:bg-glaut-cards disabled:border-glaut-grey disabled:border-1
                            disabled:shadow-none
                            md:w-[4em] md:h-[4em]
                            hover:bg-white`}
            onClick={onClick}
            disabled={disabled}
        >
            <FaRegPaperPlane
                className={`text-white
                    md:h-[1.313em] md:w-[1.313em]
                    group-hover:text-glaut-pink
                    group-disabled:text-glaut-grey 
                `}
            />
        </button>
    )
}