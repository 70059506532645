import React, { useEffect, useRef, useState } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { BiTrash } from "react-icons/bi"
import { PiDotsSixVertical } from "react-icons/pi"
import { IoIosAdd } from "react-icons/io"
import { normalizeTag } from "../../utils/strings"

export const Drag = ({ id, index, cumulativeIdx, parentScroll, ...props }) => {
    const startingScroll = useRef()
    const [initialWidth, setInitialWidth] = useState()
    const draggableRef = useRef()

    useEffect(() => setInitialWidth(draggableRef.current.offsetWidth), [])

    return (
        <Draggable draggableId={id} index={index} isDragDisabled={props.disabled ?? false}>
            {provided => {
                provided.draggableProps.style = {
                    ...provided.draggableProps.style,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    cursor: "grab !important"
                }

                // Sets the starting parent scroll when starting to drag the object
                if (provided.draggableProps?.style?.top && !startingScroll.current)
                    startingScroll.current = parentScroll
                else if (!provided.draggableProps?.style?.top && startingScroll.current)
                    startingScroll.current = null

                // I needed to accurately set the position in case we are moving this draggable
                if (provided.draggableProps?.style?.left && parentScroll !== undefined)
                    provided.draggableProps.style = {
                        ...provided.draggableProps.style,
                        left: 0,
                        top: (cumulativeIdx || index) * 50 + parentScroll - startingScroll.current,
                        position: "absolute"
                    }


                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...props}>
                        <div
                            className="bar"
                            ref={draggableRef}
                            style={{ width: initialWidth + "px" }}
                        >
                            <div
                                {...provided.dragHandleProps}
                                style={{ padding: "0.5em 0" }}
                                className="parent-hover"
                            >
                                <PiDotsSixVertical />
                            </div>
                            {props.children}
                        </div>
                    </div>
                )
            }}
        </Draggable>
    )
}

export const Drop = ({ id, type, ...props }) => (
    <Droppable droppableId={id} type={type} isDropDisabled={props.disabled ?? false}>
        {provided => (
            <div
                className="column"
                {...props}
                style={{
                    position: "relative",
                    paddingBottom: "5px",
                    width: "100%",
                    gap: "10px"
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
            >
                {props.children}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
)

const BucketInput = ({ index, bucket, addBucket, editBucket, deleteBucket, disabled = false }) => {
    const [name, setName] = useState(bucket.name)

    const normalizeAndEditBucket = name => {
        name = normalizeTag(name)
        setName(name)
        editBucket(normalizeTag(name), bucket.id)
    }

    return (
        <div key={index} className="option" style={{ width: "100%" }}>
            <span className="index">{index + 1}</span>
            <input
                key={index}
                placeholder="Tag"
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={e => normalizeAndEditBucket(e.target.value)}
                style={{ backgroundColor: "transparent" }}
                disabled={disabled}
            />
            {addBucket && (
                <IoIosAdd
                    className={["clickable", disabled ? "disabled" : ""].join(" ")}
                    onClick={e => !disabled && addBucket(e, bucket.id)}
                />
            )}
            <BiTrash
                className={["clickable danger", disabled ? "disabled" : ""].join(" ")}
                onClick={() => !disabled && deleteBucket(bucket.id)}
            />
        </div>
    )
}

export const RecursiveBucketDrag = ({
    bucket,
    editBucket,
    addBucket,
    deleteBucket,
    index,
    parentScroll,
    disabled = false
}) => (
    <Drag
        key={bucket.id}
        id={bucket.id}
        index={index}
        cumulativeIdx={bucket.index}
        parentScroll={parentScroll}
        disabled={disabled}
    >
        <div className="column" style={{ gap: 0, width: "100%" }}>
            <BucketInput
                index={index}
                bucket={bucket}
                editBucket={editBucket}
                deleteBucket={deleteBucket}
                addBucket={addBucket}
                disabled={disabled}
            />
            <Drop id={bucket.id} type="sub-tags" disabled={disabled}>
                {bucket.children &&
                    bucket.children.map((c, idx) => (
                        <Drag
                            key={c.id}
                            id={c.id}
                            index={idx}
                            cumulativeIdx={c.index}
                            parentScroll={parentScroll}
                            disabled={disabled}
                        >
                            <BucketInput
                                index={idx}
                                bucket={c}
                                editBucket={editBucket}
                                deleteBucket={deleteBucket}
                            />
                        </Drag>
                    ))}
            </Drop>
        </div>
    </Drag>
)
