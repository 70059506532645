import IProjectGateway from "@services/projects/IProjectGateway"
import { createContext, useContext, useReducer } from "react"

// #region Types
type ConversationsDataState = Awaited<ReturnType<IProjectGateway["getConversations"]>>
export interface EntriesFilters {
    id?: string
    status?: "completed" | "uncompleted"
    platform?: "mobile" | "desktop"
}

export interface EntriesSorts {
    date: "asc" | "desc"
}

interface IDataTabProviderProps {
    children: React.ReactNode
}

interface IDataTabContextState {
    conversationsData?: ConversationsDataState
    entriesFilters: EntriesFilters
    entriesSorts: EntriesSorts
}

type IDataTabContextAction = {
    type: "set-conversations-data"
    conversationsData: ConversationsDataState
} | {
    type: "remove-entry"
    entryId: string
} | {
    type: "set-filter-id"
    id?: EntriesFilters["id"]
} | {
    type: "set-filter-status"
    status?: EntriesFilters["status"]
} | {
    type: "set-filter-platform"
    platform?: EntriesFilters["platform"]
} | {
    type: "set-sort-date"
    sort: "asc" | "desc"
}
// #endregion

// #region Context definitions
const DataTabContext = createContext({} as IDataTabContextState)
const DataTabContextDispatch = createContext({} as React.Dispatch<IDataTabContextAction>)
// #endregion

// #region Hook definitions
export function useDataTab() {
    return useContext(DataTabContext)
}

export function useDataTabDispatch() {
    return useContext(DataTabContextDispatch)
}
// #endregion

// #region Provider definition
export default function DataTabProvider({ children }: Readonly<IDataTabProviderProps>) {
    const initialState: IDataTabContextState = { entriesFilters: {}, entriesSorts: { date: "desc" } }

    const [state, dispatch] = useReducer(DataTabReducer, initialState)

    return <DataTabContext.Provider value={state}>
        <DataTabContextDispatch.Provider value={dispatch}>
            {children}
        </DataTabContextDispatch.Provider>
    </DataTabContext.Provider>
}
// #endregion

// #region Reducer definition
function DataTabReducer(state: IDataTabContextState, action: IDataTabContextAction): IDataTabContextState {
    switch (action.type) {
        case "set-conversations-data": {
            return {
                ...state,
                conversationsData: action.conversationsData
            }
        }
        case "remove-entry": {
            if (state.conversationsData === undefined) return state

            return {
                ...state,
                conversationsData: {
                    ...state.conversationsData,
                    conversations: state.conversationsData.conversations.filter(
                        entry => entry._id !== action.entryId
                    )
                }
            }
        }
        case "set-filter-id": {
            return {
                ...state,
                entriesFilters: {
                    ...state.entriesFilters,
                    id: action.id
                }
            }
        }
        case "set-filter-status": {
            return {
                ...state,
                entriesFilters: {
                    ...state.entriesFilters,
                    status: action.status
                }
            }
        }
        case "set-filter-platform": {
            return {
                ...state,
                entriesFilters: {
                    ...state.entriesFilters,
                    platform: action.platform
                }
            }
        }
        case "set-sort-date": {
            return {
                ...state,
                entriesSorts: {
                    ...state.entriesSorts,
                    date: action.sort
                }
            }
        }
        default: {
            return state
        }
    }
}
// #endregion