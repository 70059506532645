import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"

interface IDataTabTableRowItemIdModalDetailsDeleteConfirmationModalProps {
    open: boolean
    onClose?: () => void
    onConfirm?: () => void
}

export default function DataTabTableRowItemIdModalDetailsDeleteConfirmationModal({
    open,
    onClose,
    onConfirm
}: Readonly<IDataTabTableRowItemIdModalDetailsDeleteConfirmationModalProps>) {
    return (
        <div
            className={`
        fixed inset-0 flex justify-center items-center transition-colors overflow-hidden
        ${open ? "visible bg-black/20" : "invisible"}
    `}
            onClick={onClose}
        >
            <div
                onClick={e => e.stopPropagation()}
                className={`
                    flex flex-col bg-white rounded-xl shadow px-4 py-6 gap-3 transition-all w-56 border-[1px] border-glaut-grey
                    ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}
                `}
            >
                <div className="flex justify-center items-center text-center">
                    <p>Are you sure you want to delete this entry?</p>
                </div>
                <div className="flex flex-row justify-between">
                    <GlautButtonSecondary onClick={onClose}>Cancel</GlautButtonSecondary>
                    <GlautButtonPrimary onClick={() => {
                        onConfirm?.()
                        onClose?.()
                    }}>
                        Delete
                    </GlautButtonPrimary>
                </div>
            </div>
        </div>
    )
}