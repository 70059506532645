import FloatingLayout from "@components/layouts/FloatingLayout"
import LoadingBar from "@components/loading/LoadingBar"
import { copy, getCopy } from "@utils/Copy"
import { useCallback, useMemo, useRef, useState } from "react"
import { FaSlackHash } from "react-icons/fa"
import { MdMoreVert, MdOutlineChat, MdOutlineFilterAlt } from "react-icons/md"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"

interface IAnalysisTabAnalysisColNpsContentItemProps {
    score: number
    occurrences: number
    percentage: number
    analysisId: string
}

export default function AnalysisTabAnalysisColNpsContentItem({
    occurrences,
    percentage,
    score,
    analysisId
}: Readonly<IAnalysisTabAnalysisColNpsContentItemProps>) {
    // #region Contexts
    const { crossQuestionFilters } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States
    const [showActionsMenu, setShowActionsMenu] = useState(false)
    // #endregion

    // #region Refs
    const containerRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Memos
    const leftIconClassName = useMemo(() => {
        const baseClassName = "w-[16px] h-[16px]"

        if (score >= 9 && score <= 10)
            return `${baseClassName} text-glaut-codes-green`
        else if (score >= 7 && score <= 8)
            return `${baseClassName} text-glaut-codes-yellow`

        return `${baseClassName} text-glaut-codes-red`
    }, [score])

    const loadingBarBackgroundClassName = useMemo(() => {
        if (score >= 9 && score <= 10)
            return "bg-glaut-codes-green"
        else if (score >= 7 && score <= 8)
            return "bg-glaut-codes-yellow"

        return "bg-glaut-codes-red"
    }, [score])

    const isScoreSelected = useMemo(() => {
        // undefined => no score selected at all
        const filters = crossQuestionFilters[analysisId]
        if (!filters || filters.length === 0) return

        return filters.some(f => Number(f.codeId) === score)
    }, [crossQuestionFilters, analysisId, score])
    // #endregion

    // #region Callbacks
    const handleFilterByScore = useCallback(() => {
        analysisTabDispatch({ type: "add-question-cross-filter", codeId: String(score), questionId: analysisId })
        setShowActionsMenu(false)
    }, [analysisTabDispatch, analysisId, score])

    const zeroValueIfNotSelected = useCallback((value: number) => {
        if (isScoreSelected === undefined) return value
        return isScoreSelected ? value : 0
    }, [isScoreSelected])
    // #endregion

    return (
        <div
            className="flex flex-row justify-between items-center border-t-1 border-t-glaut-stroke-glaut p-[0.5em]"
            ref={containerRef}
        >
            <div className="flex flex-row items-center gap-[0.625em] w-[15%]">
                <FaSlackHash className={leftIconClassName} />
                <p className="text-[23.04px] font-medium text-glaut-text-midnight">
                    {score}
                </p>
            </div>
            <div className="flex flex-row items-center flex-1">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight w-8">
                    {zeroValueIfNotSelected(percentage)}%
                </p>
                <LoadingBar
                    percentage={zeroValueIfNotSelected(percentage)}
                    showPercentage={false}
                    precision={0}
                    style={{ flex: 1 }}
                    loadingBarFullClassName={`loading-bar-full ${loadingBarBackgroundClassName}`}
                    loadingBarDotClassName={`loading-bar-dot ${loadingBarBackgroundClassName}`}
                />
            </div>
            <div className="flex flex-row gap-[0.1875em] justify-end items-center w-[15%]">
                <p className="text-[13.33px] font-medium text-glaut-text-midnight">
                    {zeroValueIfNotSelected(occurrences)}
                </p>
                <MdOutlineChat className="w-[1.125em] h-[1.125em] text-glaut-grey" />
                <MdMoreVert
                    className="w-[1em] h-[1em] text-glaut-dark-grey ml-[0.1875em]"
                    onClick={() => { setShowActionsMenu(true) }}
                />
            </div>
            {showActionsMenu && (
                <FloatingLayout
                    className={`absolute rounded-[0.25em] border-1 py-[0.5em] min-w-40
                        bg-glaut-off-white border-glaut-grey shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)]
                    `}
                    anchorRef={containerRef}
                    onClose={() => { setShowActionsMenu(false) }}
                >
                    <button
                        className={`flex flex-row gap-[0.5em] p-[0.5em] border-none rounded-none shadow-none w-full
                            justify-start
                            hover:bg-glaut-cards
                        `}
                        onClick={handleFilterByScore}
                    >
                        <MdOutlineFilterAlt className="h-[1.25em] w-[1.25em] text-glaut-text-midnight" />
                        <p className="text-[13.33px] text-glaut-text-midnight">
                            {getCopy(copy.coding.filterByScore)}
                        </p>
                    </button>
                </FloatingLayout>
            )}
        </div>
    )
}