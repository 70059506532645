import { AxiosInstance } from "axios"
import IProjectGateway, {
    IProjectGatewayDownloadExcelRequest,
    IProjectGatewayGetAnalysisRequest,
    IProjectGatewayGetAnalysisResponse,
    IProjectGatewayGetConversationsRequest,
    IProjectGatewayGetConversationsResponse,
    IProjectGatewayGetEntriesRequest,
    IProjectGatewayGetEntriesResponse,
    IProjectGatewayGetMetricsRequest,
    IProjectGatewayGetMetricsResponse
} from "../IProjectGateway"

interface IProjectsApiServiceProps {
    api: AxiosInstance
}

export default class ProjectsApiService implements IProjectGateway {
    private props: IProjectsApiServiceProps

    constructor(props: IProjectsApiServiceProps) {
        this.props = props
    }

    public async getEntries(request: IProjectGatewayGetEntriesRequest): Promise<IProjectGatewayGetEntriesResponse> {
        const { projectId, page, serializedFilters } = request

        return await this.props.api
            .get<IProjectGatewayGetEntriesResponse>(
                `datasets/${projectId}/entries`,
                {
                    params: { page, filters: serializedFilters }
                }
            )
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    entries: [],
                    n_pages: 0,
                    total_entries: 0
                }
            })
    }

    public async getMetrics(request: IProjectGatewayGetMetricsRequest): Promise<IProjectGatewayGetMetricsResponse> {
        const { projectId } = request

        return await this.props.api
            .get<IProjectGatewayGetMetricsResponse>(`datasets/${projectId}/metrics`)
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    completed: 0,
                    started: 0,
                    avg_interaction_time: 0,
                    platform_split: {}
                }
            })
    }

    public async getAnalysis(request: IProjectGatewayGetAnalysisRequest): Promise<IProjectGatewayGetAnalysisResponse> {
        const { projectId, serializedFilters } = request

        return await this.props.api
            .get<IProjectGatewayGetAnalysisResponse>(`datasets/${projectId}/analysis`, {
                params: {
                    filters: serializedFilters
                }
            })
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    analysis: {},
                    n_completed: 0,
                    n_started: 0
                }
            })
    }

    public async getConversations(
        request: IProjectGatewayGetConversationsRequest
    ): Promise<IProjectGatewayGetConversationsResponse> {
        const {
            projectId,
            identifier,
            include,
            languages,
            page,
            pageSize,
            platforms,
            sortBy,
            sortOrder
        } = request

        let actualSortOrder: number | undefined
        if (sortOrder !== undefined)
            actualSortOrder = sortOrder === "asc" ? 1 : -1

        const actualPlatforms: string[] = []
        if (platforms?.includes("desktop"))
            actualPlatforms.push("desktop")

        if (platforms?.includes("mobile") || platforms?.includes("tablet"))
            actualPlatforms.push("tablet")

        return await this.props.api
            .get<IProjectGatewayGetConversationsResponse>(`datasets/${projectId}/conversations`, {
                params: {
                    identifier,
                    include_completed: include?.completed,
                    include_uncompleted: include?.uncompleted,
                    page,
                    page_size: pageSize,
                    sort_by: sortBy,
                    sorting_order: actualSortOrder,
                    languages: languages ? JSON.stringify(languages) : undefined,
                    platforms: actualPlatforms.length > 0 ? JSON.stringify(actualPlatforms) : undefined
                }
            })
            .then(res => res.data)
            .catch(e => {
                console.log(e)

                return {
                    conversations: [],
                    n_pages: 0,
                    total_conversations: 0
                }
            })
    }

    public async downloadExcel(request: IProjectGatewayDownloadExcelRequest): Promise<string> {
        const { projectId } = request

        return await this.props.api
            .get<string>(`datasets/${projectId}/download-excel`, { responseType: "blob" })
            .then(response => response.data)
    }
}