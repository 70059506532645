import { ArrayElement } from "@utils/types/array-element"
import { useCallback, useEffect, useMemo, useState } from "react"
import { IEntry } from "src/@types/entry"
import GlautIcon from "@assets/GlautLogo.png"
import { MdOutlineSms } from "react-icons/md"
import { useInterviewService } from "@hooks/services/useInterviewService"
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6"
import { isSafari } from "@utils/device"
import { convertToMp3 } from "@utils/audio"

export interface IConversationTranscriptBoxMessageItemProps extends ArrayElement<IEntry["messages"]> {
    isFollowUp?: boolean
}

interface IConversationTranscriptBoxMessageProps {
    message: IConversationTranscriptBoxMessageItemProps
    shouldRenderFollowUpHeader?: boolean
    projectId?: string
}

export default function ConversationTranscriptBoxMessage({
    message,
    shouldRenderFollowUpHeader,
    projectId
}: Readonly<IConversationTranscriptBoxMessageProps>) {
    // #region States
    const [audioUrl, setAudioUrl] = useState<string>()
    const [isPlayingAudio, setIsPlayingAudio] = useState(false)
    // #endregion

    // #region Services
    const interviewService = useInterviewService()
    // #endregion

    // #region Memos
    const classNames = useMemo(
        () => {
            const baseClassNames = "py-[5px] px-[10px] max-w-[85%]" + (
                message.role === "assistant"
                    ? " bg-glaut-cards text-start rounded-r-[5px] rounded-tl-[5px] rounded-bl-none"
                    : " bg-glaut-midnight text-white text-end rounded-l-[5px] rounded-tr-[5px] rounded-br-none"
            )

            if (message.isFollowUp)
                return baseClassNames + " border-glaut-pink" + (
                    message.role === "assistant"
                        ? " border-[1px]"
                        : " border-[2px]"
                )

            return baseClassNames + (
                message.role === "assistant"
                    ? " border-[1px] border-glaut-grey"
                    : ""
            )
        },
        [message.role, message.isFollowUp]
    )

    const content = useMemo(() => message.content.replace(/<.+>$/, ""), [message.content])
    // #endregion

    // #region Callbacks
    const handleOnEndedAudioPlayback = useCallback(() => {
        setIsPlayingAudio(false)
    }, [])
    // #endregion

    // #region Effects

    // onPlayingAudioGetAudioUrlIfNotDefined
    useEffect(() => {
        if (!projectId || !message.recording_id || !isPlayingAudio || audioUrl) return

        interviewService.getRecordingUrl({ projectId, recordingId: message.recording_id }).then(response => {
            if (!isSafari()) {
                setAudioUrl(response.recordingUrl)
                return
            }

            convertToMp3(response.recordingUrl, "webm", "mp3").then(safariRecordingUrl => {
                setAudioUrl(safariRecordingUrl)
            })
        })
    }, [projectId, message, audioUrl, isPlayingAudio, interviewService])

    // #endregion

    return (
        <>
            {shouldRenderFollowUpHeader && (
                <div className={`border-t-1 border-dashed border-glaut-pink h-8 rounded-none 
                    px-[5px] pt-[10px] pb-[25px]`}>
                    <div className="flex flex-row items-center gap-1">
                        <img src={GlautIcon} alt="glaut-icon" className="h-4 w-4" />
                        <p className="text-[11.11px]">Follow up by Glaut</p>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-2 items-center">
                {message.recording_id ? (
                    <>
                        <div className="flex-1 flex flex-row-reverse">
                            <div className="p-[8px] transition-colors hover:bg-glaut-light-grey w-max">
                                {isPlayingAudio
                                    ? <FaCirclePause onClick={() => { setIsPlayingAudio(false) }} />
                                    : <FaCirclePlay onClick={() => { setIsPlayingAudio(true) }} />}
                            </div>
                        </div>
                        {isPlayingAudio && audioUrl && (
                            <audio
                                controls
                                autoPlay
                                key={audioUrl}
                                onEnded={handleOnEndedAudioPlayback}
                                className="hidden"
                            >
                                <source src={audioUrl} type={isSafari() ? "audio/mpeg" : "audio/webm"} />
                            </audio>
                        )}
                    </>
                ) : (
                    <div className={message.role === "user" ? "flex-1" : ""}></div>
                )}
                {message.isFollowUp && message.role === "assistant" && (
                    <MdOutlineSms className="text-glaut-grey mt-1 self-start" />
                )}
                <div key={message.date} className={classNames}>
                    <p>{content}</p>
                </div>
            </div>
        </>
    )
}