/**
 * Obtains the type of the embed URL.
 * 
 * @param {string} url
 * 
 * @returns {"youtube"|"vimeo"|null}
 */
export default function obtainEmbedType(url) {
    if (url.includes("youtube.com") || url.includes("youtu.be")) return "youtube"
    if (url.includes("vimeo.com")) return "vimeo"

    return null
}