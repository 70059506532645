import React, {
    FormEvent,
    MouseEvent as ReactMouseEvent,
    useCallback, useEffect, useMemo, useRef, useState
} from "react"
import { useConversation, useConversationDispatch } from "./Interview/contexts/ConversationProvider"
// import { isIos } from "../../utils/device"
import { useBrowserUserAgent } from "@hooks/useBrowserUserAgent"
import { MdMicNone, MdOutlineKeyboard } from "react-icons/md"
import { IMessage } from "src/@types/interviews"
import { copy, getCopy } from "../../utils/Copy"
import AudioRecorder from "./AudioRecorder"
import { tooltipId } from "./Interview/components/ConversationFooter"
import SendIcon from "./Interview/components/SendIcon"

interface IConversationInputProps {
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>
    isPlaying: boolean
    /**
     * Defines if the current active input is voice.
     */
    voiceInput: boolean
    setVoiceInput: React.Dispatch<React.SetStateAction<boolean>>
    running: boolean | null
    lastMessage: IMessage
    color: string
    lang: string
    setTopBarMessage: React.Dispatch<React.SetStateAction<string | null>>
    keyboardStatus: string
    setKeyboardStatus: React.Dispatch<React.SetStateAction<"close" | "closing" | "opening" | "open">>
    isTheFirstOpenQuestion: boolean
    embedVideoRef: React.Ref<HTMLIFrameElement> | undefined
    footerHeight?: number
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean | null>>
}

export default function ConversationInput({
    setMessages,
    isPlaying,
    voiceInput,
    setVoiceInput,
    running,
    lastMessage,
    lang,
    setTopBarMessage,
    keyboardStatus,
    setKeyboardStatus,
    isTheFirstOpenQuestion,
    embedVideoRef,
    footerHeight,
    setIsPlaying,
    color
}: Readonly<IConversationInputProps>) {
    // #region Contexts
    const { isRecording, sendJSON, isWebRTC } = useConversation()
    const conversationDispatch = useConversationDispatch()
    // #endregion

    // #region States
    const [reply, setReply] = useState("")
    // #endregion

    // #region Refs
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const footerRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Browser hooks
    const { devices: { isMobile } } = useBrowserUserAgent()
    // #endregion

    // #region Memos
    const canSend = useMemo(
        () => !isPlaying && reply.replace(/\s/g, "").length > 0,
        [reply, isPlaying]
    )
    // #endregion

    // #region Callbacks

    // Update the position of the footer to manage the keyboard opening
    const handleResize = useCallback(() => {
        if (isMobile && footerRef.current) {
            const footerOffset = keyboardStatus !== "close"
                ? window.innerHeight - (window.visualViewport?.height ?? 0)
                : 0

            // Update position of the footer
            footerRef.current.style.bottom = `${footerOffset}px`
            // Fix iOS scroll
            window.scrollTo(0, 0)
        }
    }, [isMobile, keyboardStatus])

    // Manage input focus toggle
    const onInputFocusChange = useCallback((opening: boolean) => {
        if (isMobile) {
            setKeyboardStatus(opening ? "opening" : "closing")
            setTimeout(() => {
                setKeyboardStatus(opening ? "open" : "close")

                handleResize()
            }, 300)
        }
    }, [handleResize, setKeyboardStatus, isMobile])

    // Appends a new interviewee-generated message
    const addMessage = useCallback((content: string) => {
        // We cannot add an empty message
        if (!content.length) return

        const message = { role: "user" as const, content }
        setMessages(ms => [...ms, message])

        if (isWebRTC) sendJSON({ message })
    }, [isWebRTC, setMessages, sendJSON])

    type IOnMessageSubmitEventProp =
        | React.KeyboardEvent<HTMLTextAreaElement>
        | ReactMouseEvent<HTMLButtonElement, MouseEvent>
        | FormEvent<HTMLFormElement>
    const onMessageSubmit = useCallback((e: IOnMessageSubmitEventProp) => {
        e.preventDefault()

        // Do not let user upload a message if the AI has not yet responded
        if (!running && lastMessage.role === "user") return

        // Do not let user send non existing messages
        if (reply.length < 1) return

        addMessage(reply)
        setReply("")
    }, [addMessage, lastMessage.role, reply, running])

    // Listens for Enter, in which case sends the message
    const handleTextInputFormKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && canSend && !event.shiftKey && reply)
            onMessageSubmit(event)
    }, [canSend, onMessageSubmit, reply])

    // #endregion

    // #region Effects

    // Add event listener to manage keyboard open 
    useEffect(() => {
        // for Android
        window.addEventListener("resize", handleResize)
        // and for iOS
        window.visualViewport?.addEventListener("resize", handleResize)

        // Be sure to run at least one time the method
        handleResize()

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("resize", handleResize)
            window.visualViewport?.removeEventListener("resize", handleResize)
        }
    }, [keyboardStatus, handleResize]) // Empty array ensures that effect is only run on mount and unmount

    // onStopPlayingFocusOnInput
    useEffect(() => {
        if (isPlaying === false) inputRef.current?.focus()
    }, [isPlaying])

    // #endregion

    return (
        <div
            className="flex flex-col md:w-[min(100%,800px)]"
            style={{
                paddingBottom: (!voiceInput) ? `${footerHeight}px` : 0
            }}
        >
            {!voiceInput && (
                <form
                    onSubmit={onMessageSubmit}
                    className="flex flex-row flex-1 justify-center mt-[1.5625em]">
                    {!isMobile && (
                        <MdOutlineKeyboard
                            className={`p-[0.906em] rounded-[0.875em] w-[1.688em] h-[1.688em] 
                                bg-glaut-cards text-glaut-grey box-content mr-[0.75em]`}
                        />
                    )}
                    <textarea
                        ref={inputRef}
                        className="message-input font-medium text-[16px] text-glaut-dark-grey"
                        onFocus={() => onInputFocusChange(true)}
                        onBlur={() => onInputFocusChange(false)}
                        placeholder={
                            running
                                ? getCopy(copy.interview.typeHere, lang)
                                : getCopy(copy.interview.conversationEnded, lang)
                        }
                        value={reply}
                        onChange={e => setReply(e.target.value.replace("\n", ""))}
                        onKeyDown={handleTextInputFormKeyDown}
                        disabled={isRecording || isPlaying}
                    />
                    {(isMobile || reply.length > 0) && (
                        <SendIcon
                            onClick={onMessageSubmit}
                            disabled={!canSend || isPlaying || reply.length === 0}
                        />
                    )}
                    {(!isMobile && reply.length === 0) && (
                        <MdMicNone
                            className={`p-[0.906em] rounded-[0.875em] w-[1.688em] h-[1.688em] text-glaut-midnight
                                shadow-[0px_0px_8px_0px_rgb(0,0,0,0.15)] box-content cursor-pointer 
                                md:h-[1.313em] md:w-[1.313em]
                                hover:text-glaut-pink
                            `}
                            onClick={() => {
                                setVoiceInput(!voiceInput)
                            }}
                            data-tooltip-id={tooltipId}
                            data-tooltip-content={voiceInput
                                ? getCopy(copy.interview.activateKeyboardMode, lang)
                                : getCopy(copy.interview.activateMicrophoneMode, lang)
                            }
                            data-tooltip-place="top"
                        />
                    )}
                </form>
            )}
            <div className="flex w-full justify-center md:mb-[13vh] md:mt-2">
                {(!isMobile || keyboardStatus === "close") && voiceInput && (
                    <AudioRecorder
                        lang={lang}
                        setMessages={setMessages}
                        setTopBarMessage={setTopBarMessage}
                        setVoiceInput={setVoiceInput}
                        isPlaying={isPlaying}
                        lastMessage={lastMessage}
                        setIsRecording={(isRecording: boolean) => {
                            conversationDispatch({ type: "set-is-recording", isRecording })
                        }}
                        isRecording={isRecording}
                        isTheFirstOpenQuestion={isTheFirstOpenQuestion}
                        embedVideoRef={embedVideoRef}
                        setIsPlaying={setIsPlaying}
                        color={color}
                    />
                )}
            </div>
        </div>
    )
}
