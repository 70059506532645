import { useFormattedDate } from "@hooks/useFormattedDate"

interface IDataTabTableRowItemDateProps {
    date: string
}

export default function DataTabTableRowItemDate({
    date
}: Readonly<IDataTabTableRowItemDateProps>) {
    // #region Table row hooks
    const { text: formattedDate } = useFormattedDate(date)
    // #endregion

    return (
        <td className="rounded-lg bg-glaut-cards" style={{ borderRight: "0px", padding: "0" }}>
            <p className={`text-[13.33px] text-center px-[12px] py-[4px]
                ${formattedDate !== "Not available" ? "text-glaut-midnight" : "text-glaut-light-grey"}    
            `}>
                {formattedDate}
            </p>
        </td>
    )
}