import {
    MdPersonOutline,
    MdSearch
} from "react-icons/md"
import { useDataTab, useDataTabDispatch } from "../../contexts/DataTabProvider"
import { useEffect, useMemo, useRef, useState } from "react"

export default function DataTabTableHeaderItemId() {
    // #region Contexts
    const { entriesFilters } = useDataTab()
    const dataTabDispatch = useDataTabDispatch()
    // #endregion

    // #region States
    const [searchId, setSearchId] = useState("")
    // #endregion

    // #region Refs
    const inputRef = useRef<HTMLInputElement>(null)
    // #endregion

    // #region Memos
    const dispatchSearchId = useMemo(() => searchId !== "" ? searchId : undefined, [searchId])
    const filterIconClassNames = useMemo(
        () => "w-[14px] h-[14px] " + (entriesFilters.id === undefined
            ? "text-glaut-grey"
            : "text-glaut-midnight bg-glaut-light-grey-50% rounded-sm"),
        [entriesFilters]
    )
    // #endregion

    // #region Effects

    // onAfterTypingDispatchSearch
    useEffect(() => {
        const timeoutFn = setTimeout(() => {
            dataTabDispatch({ type: "set-filter-id", id: dispatchSearchId })
        }, 1000)

        return () => clearTimeout(timeoutFn)
    }, [searchId, dataTabDispatch, dispatchSearchId])

    // #endregion

    return (
        <th scope="col" className="p-0 border-b-2" style={{ borderRight: "0px" }}>
            <div className="flex flex-row items-center">
                <div>
                    <MdPersonOutline className="text-glaut-grey mr-1 w-[14px] h-[14px]" />
                </div>
                <input
                    ref={inputRef}
                    className="flex-1 text-glaut-dark-grey text-[13.33px] font-[500] bg-transparent border-0 p-0 shadow-none focus:shadow-none rounded-none"
                    placeholder="ID"
                    value={searchId}
                    onChange={e => {
                        setSearchId(e.target.value)
                    }}>
                </input>
                <div>
                    <MdSearch
                        className={filterIconClassNames}
                        onClick={() => {
                            inputRef.current?.focus()
                        }}
                    />
                </div>
            </div>
        </th>
    )
}