interface IGlautButtonPrimaryProps {
    children?: React.ReactNode
    disabled?: boolean
    onClick?: () => void
}

export default function GlautButtonPrimary({
    children,
    disabled,
    onClick
}: Readonly<IGlautButtonPrimaryProps>) {
    return (
        <button
            className={`rounded-md bg-glaut-pink text-glaut-off-white font-medium border-none shadow-none
                hover:bg-glaut-pink hover:text-glaut-off-white
                disabled:bg-glaut-off-white disabled:text-glaut-grey`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}