import React from "react"

const CustomTooltip = ({ payload, unit, valueKey = "name" }) => {
    if (payload && payload.length)
        return (
            <div className="overlay-blur" style={{ padding: "0.2em 1em" }}>
                {payload.map((item, index) => (
                    <h3 key={`${item.payload[valueKey]}-${index}`} style={{ color: item.color }}>
                        {item.payload[valueKey]} {unit}
                    </h3>
                ))}
            </div>
        )


    return null
}

export default CustomTooltip
