import { useTableRowItemStatus } from "../../hooks/useTableRowItemStatus"

interface IDataTabTableRowItemStatusProps {
    isCompleted: boolean
}

export default function DataTabTableRowItemStatus({
    isCompleted
}: Readonly<IDataTabTableRowItemStatusProps>) {
    // #region Table row hooks
    const { icon, text } = useTableRowItemStatus(isCompleted)
    // #endregion

    return (
        // inline styling to ignore CSS modules
        <td style={{ borderRight: "0px", padding: "0" }}>
            <div
                className={isCompleted
                    ? "flex justify-center rounded-md h-full items-center px-[12px] py-[4px] gap-1 bg-glaut-subcodes-green"
                    : "flex justify-center rounded-md h-full items-center px-[12px] py-[4px] gap-1 bg-glaut-subcodes-orange"
                }>
                <span className="text-glaut-midnight text-[13.33px]">{text}</span> {icon}
            </div>
        </td>
    )
}