import GlautButtonPrimary from "@components/Buttons/GlautButtonPrimary"
import GlautButtonSecondary from "@components/Buttons/GlautButtonSecondary"
import ModalLayout from "@components/layouts/ModalLayout"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import LoadingBar from "@components/loading/LoadingBar"
import Loading from "@components/loading/Loading"
import { useProject } from "../../contexts/ProjectProvider"
import { IInsightAnalysis } from "src/@types/analysis"
import AnalysisTabAnalysisColReviewCodeBookModalThemeItem from "../AnalysisTabAnalysisColReviewCodeBookModalThemeItem"
import { MdAddCircle } from "react-icons/md"
import { IProjectGatewayGetAnalysisResponse } from "@services/projects/IProjectGateway"
import { v4 as uuid } from "uuid"
import { useAnalysisTab, useAnalysisTabDispatch } from "../../contexts/AnalysisTabProvider"
import { useWebSocket } from "@hooks/useWebSocket"
import { IWebSocketResponse } from "src/@types/websocket"
import { deepCopy } from "@utils/miscellaneous"

interface IWebSocketManualEditedTheme {
    name: string
    description: string
    id: string
}

interface IAnalysisTabAnalysisColReviewCodeBookModalProps {
    isOpen: boolean
    onClose?: () => void
    questionId: string
}

export default function AnalysisTabAnalysisColReviewCodeBookModal({
    isOpen,
    onClose,
    questionId
}: Readonly<IAnalysisTabAnalysisColReviewCodeBookModalProps>) {
    // #region Contexts
    const { analysisData } = useProject()
    const { codeBook: { edits } } = useAnalysisTab()
    const analysisTabDispatch = useAnalysisTabDispatch()
    // #endregion

    // #region States

    const [promptContent, setPromptContent] = useState("")
    const [isProcessingNewPrompt, setIsProcessingNewPrompt] = useState(false)
    const [isProcessingManualEdit, setIsProcessingManualEdit] = useState(false)

    const [promptProcessingProgress, setPromptProcessingProgress] = useState<number | null>(null)
    const [manualEditProcessingProgress, setManualEditProcessingProgress] = useState<number | null>(null)

    // As the final analysis will change, we need a deep copy of the current one
    const [newAnalysisData, setNewAnalysisData] = useState<IProjectGatewayGetAnalysisResponse>(
        deepCopy(analysisData)
    )

    // #endregion

    // #region WebSocket
    const { addCallback, sendMessage, removeCallback } = useWebSocket()
    // #endregion

    // #region Memos
    const currentQuestionAnalysis: IInsightAnalysis[] | undefined = useMemo(() => {
        if (!newAnalysisData) return undefined

        const analyses = newAnalysisData.analysis[questionId]
        if (!Array.isArray(analyses) || analyses.length === 0) return []
        if (!("children" in analyses[0])) return []

        return analyses as IInsightAnalysis[]
    }, [newAnalysisData, questionId])
    // #endregion

    // #region Callbacks
    const handleSavePrompt = useCallback(() => {
        if (promptContent === "") return

        sendMessage({
            operation: "group_into_themes",
            context: promptContent,
            question_id: questionId
        })

        setIsProcessingNewPrompt(true)
    }, [questionId, sendMessage, promptContent])

    const handleSaveManualEdit = useCallback(() => {
        if (Object.entries(edits).length === 0 || currentQuestionAnalysis === undefined) return

        const themes: IWebSocketManualEditedTheme[] = Object.entries(edits).map(([themeId, themeData]) => {
            const currentTheme = currentQuestionAnalysis.find(a => a.id === themeId)
            const name = (themeData.newLabel || currentTheme?.label) ?? ""
            const description = (themeData.instruction || currentTheme?.description) ?? ""

            return {
                id: themeId,
                name,
                description
            }
        })

        sendMessage({
            operation: "edit_themes",
            themes,
            question_id: questionId
        })

        setIsProcessingManualEdit(true)
    }, [questionId, sendMessage, edits, currentQuestionAnalysis])

    const handleAddThemeFromManualEdit = useCallback(() => {
        const themeId = uuid()
        setNewAnalysisData({
            ...newAnalysisData,
            analysis: {
                ...newAnalysisData.analysis,
                [questionId]: [
                    ...newAnalysisData.analysis[questionId] as IInsightAnalysis[],
                    {
                        id: themeId,
                        label: "",
                        value: 0,
                        occurrences: 0,
                        children: []
                    }
                ] as IInsightAnalysis[]
            }
        })
        analysisTabDispatch({
            type: "set-code-book-selected-theme",
            themeId
        })
    }, [setNewAnalysisData, analysisTabDispatch, newAnalysisData, questionId])

    const handleRemoveThemeFromManualEdit = useCallback((themeId: string) => {
        setNewAnalysisData({
            ...newAnalysisData,
            analysis: {
                ...newAnalysisData.analysis,
                [questionId]: [
                    ...(newAnalysisData.analysis[questionId] as IInsightAnalysis[]).filter(a => a.id !== themeId)
                ] as IInsightAnalysis[]
            }
        })
    }, [newAnalysisData, questionId])
    // #endregion

    // #region Effects

    // onNoEditsDoneRefreshAnalysisSnapshot
    useEffect(() => {
        if (!isOpen || !analysisData || Object.entries(edits).length > 0) return
        setNewAnalysisData(deepCopy(analysisData))
    }, [isOpen, analysisData, edits])

    // onProcessingPrompt
    useEffect(() => {
        if (!isProcessingNewPrompt) return

        const callback = (data: IWebSocketResponse) => {
            if (!("progress" in data)) return
            if (data.progress.action !== "perform_ta") {
                if (data.progress.completed) {
                    setPromptProcessingProgress(null)
                    setIsProcessingNewPrompt(false)

                    if (analysisData)
                        setNewAnalysisData(deepCopy(analysisData))

                    analysisTabDispatch({ type: "clear-code-book" })
                    onClose?.()
                }

                return
            }

            if (data.progress.completed) {
                setPromptProcessingProgress(null)
                setIsProcessingNewPrompt(false)

                if (analysisData)
                    setNewAnalysisData(deepCopy(analysisData))

                analysisTabDispatch({ type: "clear-code-book" })
                onClose?.()
            } else {
                setPromptProcessingProgress(data.progress.percentage!)
            }
        }

        addCallback(callback)

        return () => {
            removeCallback(callback)
        }
    }, [
        analysisTabDispatch,
        isProcessingNewPrompt,
        addCallback,
        removeCallback,
        onClose,
        analysisData
    ])

    // onProcessingManualEdit
    useEffect(() => {
        if (!isProcessingManualEdit) return

        const callback = (data: IWebSocketResponse) => {
            if (!("progress" in data)) return
            if (data.progress.action !== "edit_themes") return

            if (data.progress.completed) {
                setManualEditProcessingProgress(null)
                setIsProcessingManualEdit(false)

                if (analysisData)
                    setNewAnalysisData(deepCopy(analysisData))

                analysisTabDispatch({ type: "clear-code-book" })
                onClose?.()
            } else {
                setManualEditProcessingProgress(data.progress.percentage!)
            }
        }

        addCallback(callback)

        return () => {
            removeCallback(callback)
        }
    }, [
        analysisTabDispatch,
        isProcessingManualEdit,
        addCallback,
        removeCallback,
        onClose,
        analysisData
    ])

    // #endregion

    // #region Element memos
    const themeListElement = useMemo(() => {
        if (!currentQuestionAnalysis) return <Loading />

        const isNoAnswerPresent = currentQuestionAnalysis.find(a => a.id === "empty") !== undefined

        return currentQuestionAnalysis
            .filter(analysis => analysis.id !== "empty") // No "No answer"
            .map((analysis, idx) => (
                <AnalysisTabAnalysisColReviewCodeBookModalThemeItem
                    key={analysis.id}
                    label={analysis.label}
                    colorIndex={!isNoAnswerPresent ? idx : idx + 1}
                    themeId={analysis.id}
                    removeCodeCallback={handleRemoveThemeFromManualEdit}
                />
            ))
    }, [currentQuestionAnalysis, handleRemoveThemeFromManualEdit])
    // #endregion

    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            className={`
                flex flex-col bg-white rounded-xl shadow p-[18px] gap-3 transition-all border-[1px] border-glaut-grey
                w-[552px] max-h-screen overflow-auto no-scrollbar
                ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
            `}
        >
            <span className="mb-[11px] text-[16px]">Review themes and codes</span>
            <Tabs
                className="w-full border-0"
                selectedTabClassName="border-b-glaut-pink border-b-2"
            >
                <TabList
                    className="w-full grid grid-cols-2 border-b-1 border-b-glaut-stroke-glaut rounded-none"
                    style={{ columnGap: 0, padding: 0, marginBottom: "12px" }}>
                    <Tab className="w-full flex flex-row justify-center rounded-none mb-0 h-[36px]">
                        <p className="justify-self-center font-medium">Manual edit</p>
                    </Tab>
                    <Tab className="w-full flex flex-row justify-center rounded-none mb-0 h-[36px]">
                        <p className="justify-self-center font-medium">Prompt Glaut</p>
                    </Tab>
                </TabList>
                <TabPanel>
                    <p className="text-glaut-dark-grey text-[13.33px] font-medium">
                        Add, edit or delete themes directly
                    </p>
                    {themeListElement}
                    <div className={`flex w-full justify-center py-[8px] border-t-1 rounded-none
                        border-t-glaut-stroke-glaut    
                    `}>
                        <GlautButtonSecondary onClick={handleAddThemeFromManualEdit}>
                            <MdAddCircle className="w-[20px] h-[20px]" /> Add theme
                        </GlautButtonSecondary>
                    </div>
                    {isProcessingManualEdit && (
                        <div className="flex flex-col items-center">
                            <LoadingBar
                                style={{ margin: "12px 5px" }}
                                loadingBarStyle={{ width: "100%" }}
                                percentage={manualEditProcessingProgress ?? undefined}
                            />
                        </div>
                    )}
                    <div className="flex flex-row justify-between">
                        <GlautButtonSecondary onClick={onClose}>
                            Close
                        </GlautButtonSecondary>
                        <GlautButtonPrimary
                            onClick={handleSaveManualEdit}
                            disabled={Object.entries(edits).length === 0 || isProcessingManualEdit}
                        >
                            Save
                        </GlautButtonPrimary>
                    </div>
                </TabPanel>
                <TabPanel>
                    <p className="text-glaut-dark-grey text-[13.33px] font-medium mb-[4px]">
                        Instruct Glaut how to review current themes or generate a new proposal
                    </p>
                    <textarea
                        className="rounded-[4px] border-1 border-glaut-light-grey"
                        style={{ // @TODO: remove global styling applied to textarea component
                            borderColor: "#BCBCBC",
                            padding: "8px 12px"
                        }}
                        value={promptContent}
                        onChange={event => {
                            setPromptContent(event.target.value)
                        }}
                        placeholder={"Create a new XYZ theme that includes all codes mentioning ABC concept, or "
                            + "reorganize the themes grouping codes according to dimension DEF"}
                    />
                    <div className="flex flex-row justify-between">
                        <GlautButtonSecondary onClick={onClose}>
                            Close
                        </GlautButtonSecondary>
                        <GlautButtonPrimary
                            onClick={handleSavePrompt}
                            disabled={promptContent.length === 0 || isProcessingNewPrompt}
                        >
                            Save
                        </GlautButtonPrimary>
                    </div>
                    {isProcessingNewPrompt && (
                        <div className="flex flex-col items-center">
                            <LoadingBar
                                style={{ margin: "12px 5px" }}
                                loadingBarStyle={{ width: "100%" }}
                                percentage={promptProcessingProgress ?? undefined}
                            />
                        </div>
                    )}
                </TabPanel>
            </Tabs>
        </ModalLayout>
    )
}