import { useEffect, useRef } from "react"

/*
    Automatically drags the element
*/
const useDragScroll = scrollRef => {
    const scrollInterval = useRef(null)

    
    const sensitivity = 50      // Space in pixels at the top and bottom of the container that will trigger the scroll
    const speed = 10            // Speed of the scroll

    useEffect(() => {

        const scrollContainer = scrollRef.current
        if (!scrollContainer) return

        const startAutoScroll = direction => {

            // Stops previous autoscroll, just to be sure
            if (scrollInterval.current) clearInterval(scrollInterval.current)

            // Starts a an interval to smoothly scroll the container at the correct speed
            scrollInterval.current = setInterval(() => {
                scrollContainer.scrollTop += direction === "up" ? -speed : speed
            }, 10)
        }

        const stopAutoScroll = () => scrollInterval.current && clearInterval(scrollInterval.current)

        const handleMouseMove = e => {

            // Gets the boundaries of the scroll item, where the mouse need to be located
            const bounds = scrollContainer.getBoundingClientRect()
            const mouseY = e.clientY - bounds.top

            // If the mouse is at the top of the scollable element, scolls up
            if (mouseY < sensitivity)  startAutoScroll("up")

            // If the mouse is at the bottom of the scollable element, scolls up
            else if (mouseY > bounds.height - sensitivity)  startAutoScroll("down")

            // Else stops scrolling
            else  stopAutoScroll()   
        }

        // Listens to the mouse movements
        scrollContainer.addEventListener("drag", handleMouseMove, true)
        scrollContainer.addEventListener("mouseleave", stopAutoScroll)

        // Removes event listener
        return () => {
            scrollContainer.removeEventListener("mousemove", handleMouseMove)
            scrollContainer.removeEventListener("mouseleave", stopAutoScroll)
            stopAutoScroll()
        }
    }, [scrollRef])

    return null
}

export default useDragScroll
