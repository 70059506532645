import { useParams } from "react-router-dom"
import useAxiosAuth from "../hooks/useAxiosAuth"
import React, { useEffect, useState } from "react"
import { SelectedStats } from "./Project/Analysis"
import Divider from "../components/layouts/Divider"
import AnalysisBox from "../components/AnalysisBox"
import ReactMarkdown from "react-markdown"
import Loading from "../components/loading/Loading"

const Block = ({ block, report, filters, setFilters, style, lang }) => {
    switch (block.type) {
        case "divider":
            return <Divider key={block.id} />

        case "text":
            return <ReactMarkdown key={block.id}>{block.text}</ReactMarkdown>

        case "img":
            return <img key={block.id} className="message-image" style={style} src={block.src} />

        case "callout":
            return (
                <div className="cell-box" key={block.id}>
                    {block.text}
                </div>
            )

        case "question":
            return (
                <AnalysisBox
                    key={block.id}
                    value={report.analysis[block.question] || block.value}
                    question={report.schema.find(q => q.id === block.question)}
                    setShownQuestion={() => {}}
                    filters={filters}
                    setFilters={setFilters}
                    lang={lang}
                />
            )

        case "row":
            return (
                <div className="row">
                    {block.blocks.map(b => (
                        <div
                            key={block.id}
                            style={{
                                width: `${100 / block.blocks.length}%`,
                                height: `${800 / (block.blocks.length + 2)}px`,
                                padding: "0 0.5em",
                                alignItems: "stretch"
                            }}
                        >
                            <Block
                                block={b}
                                report={report}
                                filters={filters}
                                setFilters={setFilters}
                                style={{ objectFit: "cover", height: "100%", width: "100%" }}
                            />
                        </div>
                    ))}
                </div>
            )
    }
}

const Report = () => {
    const [report, setReport] = useState(null)
    const [filters, setFilters] = useState([])

    const { reportId } = useParams()
    const axiosAuth = useAxiosAuth()

    useEffect(() => {
        const serializedFilters = encodeURIComponent(JSON.stringify(filters))
        axiosAuth
            .get(`reports/${reportId}?filters=${serializedFilters}`)
            .then(res => setReport(res.data))
            .catch(e => console.log(e))
    }, [reportId, filters])

    if (!report) return <Loading />

    return (
        <div className="page">
            <div className="column centered" style={{ width: "800px" }}>
                <SelectedStats
                    selected={report.selected}
                    filters={filters}
                    setFilters={setFilters}
                    schema={report.schema}
                    projectLang={report.lang}
                />
                {report.blocks?.map(block => (
                    <Block
                        key={block.id}
                        block={block}
                        report={report}
                        filters={filters}
                        setFilters={setFilters}
                        lang={report.lang}
                    />
                ))}
                <a target="_blank" href="https://www.glaut.com" rel="noreferrer">
                    Built with Glaut
                </a>
            </div>
        </div>
    )
}

export default Report
