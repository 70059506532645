import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import { useAuthInfo } from "@propelauth/react"
import moment from "moment"

const Reports = () => {
    const [reports, setReports] = useState([])

    const { projectId } = useParams()
    const axiosAuth = useAxiosAuth()
    const { isLoggedIn } = useAuthInfo()

    useEffect(() => {
        axiosAuth
            .get(`/datasets/${projectId}/reports`)
            .then(res => setReports(res.data))
            .catch(e => console.log(e))
    }, [isLoggedIn])

    return (
        <div className="slim-page">
            <h2>Coming soon</h2>
            {reports.map(r => (
                <div className="box" key={r._id}>
                    <a target="_blank" href={`${window.location.origin}/r/${r._id}`} rel="noreferrer">
                        <h2 className="hover-underline">{r.title}</h2>
                    </a>

                    {moment(r.created_at).format("DD MMM YYYY HH:mm")}
                </div>
            ))}
        </div>
    )
}

export default Reports
