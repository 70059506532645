import GlautInfoBottomIcon from "@assets/glaut-info-bottom-icon.svg"
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter"
import EmptySpaceBox from "@components/EmptySpaceBox"
import { ArrayElement } from "@utils/types/array-element"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { FaExclamationCircle, FaTags } from "react-icons/fa"
import {
    MdCheck,
    MdChevronLeft,
    MdClose,
    MdLightbulbOutline,
    MdOutlineDoubleArrow,
    MdOutlineSkipNext
} from "react-icons/md"
import { Tooltip } from "react-tooltip"
import { INPSAnalysis, INumberAnalysis } from "src/@types/analysis"
import { IProject } from "src/@types/project"
import { IQuestion, IQuestionInsight } from "src/@types/question"
import { v4 as uuid } from "uuid"
import Code from "../../../../../components/Coding/Code"
import Box from "../../../../../components/layouts/Box"
import Button from "../../../../../components/layouts/Button"
import Input from "../../../../../components/layouts/Input"
import useDragScroll from "../../../../../hooks/useDragScroll"
import { useWebSocket } from "../../../../../hooks/useWebSocket"
import { copy, getCopy } from "../../../../../utils/Copy"
import { questionTypes } from "../../../../../utils/Variables"
import { ProjectContext } from "../../../AutoSaveProject"
import { useAnalysisTab } from "../../contexts/AnalysisTabProvider"
import { useProject } from "../../contexts/ProjectProvider"
import {
    IQuestionAnalysisCodeFromSchema,
    IQuestionAnalysisFromSchema
} from "../../utils/questions-from-schema"
import AnalysisTabAnalysisColCodeOccurrencesModeToggle from "../AnalysisTabAnalysisColCodeOccurrencesModeToggle"
import AnalysisTabAnalysisColNpsContent from "../AnalysisTabAnalysisColNpsContent"
import AnalysisTabAnalysisColNpsMetrics from "../AnalysisTabAnalysisColNpsMetrics"
import AnalysisTabAnalysisColNumberMetrics from "../AnalysisTabAnalysisColNumberMetrics"
import AnalysisTabAnalysisColReviewCodeBookModal from "../AnalysisTabAnalysisColReviewCodeBookModal"

interface ICode {
    id: string
}

interface ISubthemeTheme {
    id: string
    colorIndex: number
}

type IMergeCode = ArrayElement<IQuestionInsight["codes"]>

interface IAnalysisTabAnalysisColProps {
    analysis: IQuestionAnalysisFromSchema
    lang: string
}

export default function AnalysisTabAnalysisCol({
    analysis,
    lang
}: Readonly<IAnalysisTabAnalysisColProps>) {
    // #region Contexts
    const { setProject } = useContext(ProjectContext) as { setProject: React.Dispatch<React.SetStateAction<IProject>> }
    const { analysisData: projectAnalysisData } = useProject()
    const { selectedCode, codeBook: { edits } } = useAnalysisTab()
    // #endregion

    // #region WebSocket
    const { sendMessage } = useWebSocket()
    // #endregion

    // #region State
    const [addTheme, setAddTheme] = useState(false)
    const [addThemeName, setAddThemeName] = useState("")

    const [createSubthemeStep, setCreateSubthemeStep] = useState(0)
    const [createSubthemeTheme, setCreateSubthemeTheme] = useState<ISubthemeTheme>()
    const [createSubthemeCodes, setCreateSubthemeCodes] = useState<ICode[]>([])
    const [createSubthemeName, setCreateSubthemeName] = useState("")

    const [mergeTargetCode, setMergeTargetCode] = useState<IMergeCode>()
    const [mergeCode, setMergeCode] = useState<IMergeCode>()

    const [isReviewCodeBookModalOpen, setIsReviewCodeBookModalOpen] = useState(false)

    const [hasClosedCodeBookProposalAlert, setHasClosedCodeBookProposalAlert] = useState(false)
    // #endregion

    // #region Refs
    const scrollRef = useRef<HTMLDivElement>(null)
    // #endregion

    // #region Scroll hooks

    // Automatically scrolls the code menu
    useDragScroll(scrollRef)

    // #endregion

    // #region Memos
    const showAnalysis = useMemo(
        () => {
            const allowedTypes: string[] = [questionTypes.shortText]
            return !allowedTypes.includes(analysis.type)
        },
        [analysis.type]
    )

    const themeElements: IQuestionAnalysisCodeFromSchema[] = useMemo(() => {
        if (!showAnalysis) return []

        return (analysis.codes ?? [])
            .filter(c => c.parent === "root")
            .sort((a, b) => b.occurrences - a.occurrences)
    }, [showAnalysis, analysis.codes])

    const rawAnalysisData = useMemo(() => projectAnalysisData?.analysis[analysis.id], [analysis, projectAnalysisData])
    const isNumberAnalysis = useMemo(() => {
        if (Array.isArray(rawAnalysisData) || rawAnalysisData === undefined) return false

        return "mean" in rawAnalysisData
    }, [rawAnalysisData])
    const isNpsAnalysis = useMemo(() => {
        if (Array.isArray(rawAnalysisData) || rawAnalysisData === undefined) return false

        return "nps" in rawAnalysisData
    }, [rawAnalysisData])

    const glautCodeBookAlertClassNames = useMemo(() => {
        const baseClassNames = `flex flex-row justify-between rounded-[4px] border-1 transition-all
            border-glaut-dummie-color bg-glaut-dummie-color`

        if (hasClosedCodeBookProposalAlert)
            return `${baseClassNames} opacity-0 invisible h-0 w-full p-0 mb-0 gap-0`

        return `${baseClassNames} opacity-100 visible h-max w-full p-[12px] mb-[12px] gap-[12px]`
    }, [hasClosedCodeBookProposalAlert])
    // #endregion

    // #region Callbacks

    // Therminate the addthemeh flow
    const resetAddTheme = () => {
        setAddTheme(false)
        setAddThemeName("")
    }
    // Therminate the merge with flow
    const resetCodesMerge = () => {
        setMergeCode(undefined)
        setMergeTargetCode(undefined)
    }
    // Therminate the create subtheme flow
    const resetCreateSubtheme = () => {
        setCreateSubthemeTheme(undefined)
        setCreateSubthemeCodes([])
        setCreateSubthemeName("")
        setCreateSubthemeStep(0)
    }
    // Alternative click on code function used turing subtheme creation
    const createSubthemeAddRemoveCode = code => {
        if (createSubthemeCodes.find(c => c.id === code.id))
            setCreateSubthemeCodes(createSubthemeCodes.filter(c => c.id !== code.id))
        else
            setCreateSubthemeCodes([...createSubthemeCodes, code])
    }

    // Create subtheme
    const createSubthemeConfirm = () => {
        const newBucketId = uuid()
        setProject(p => ({
            ...p,
            schema: p.schema.map(
                s => {
                    if (s.id === analysis.id && s.type === "insight")
                        return {
                            ...s,
                            buckets: s.buckets.map(b => {
                                if (createSubthemeCodes.find(c => c.id === b.id)) return {
                                    ...b,
                                    parent: newBucketId
                                }
                                return b
                            }).concat([{
                                parent: createSubthemeTheme?.id,
                                id: newBucketId,
                                name: createSubthemeName
                            }])
                        }
                    return s
                }
            )
        }))
        resetCreateSubtheme()
    }
    // Rename code
    const setCodeName = (qId, cId, newName) => {
        setProject(p => ({
            ...p,
            schema: p.schema.map(
                s => {
                    if (s.id === qId && s.type === "insight")
                        return {
                            ...s,
                            buckets: s.buckets.map(b => {
                                if (b.id === cId) return {
                                    ...b,
                                    name: newName
                                }
                                return b
                            })
                        }
                    return s
                }
            )
        }))
    }

    const makeTheme = (qId, cId) => {
        setProject(p => ({
            ...p,
            schema: p.schema.map(
                s => {
                    if (s.id === qId && s.type === "insight")
                        return {
                            ...s,
                            buckets: s.buckets.map(b => {
                                if (b.id === cId) return {
                                    ...b,
                                    parent: "root"
                                }
                                return b
                            })
                        }
                    return s
                }
            )
        }))
    }

    // Delete code
    const deleteCode = (qId, cId) => {
        setProject(p => ({
            ...p,
            schema: p.schema.map(
                s => {
                    if (s.id === qId && s.type === "insight")
                        return {
                            ...s,
                            buckets: s.buckets.filter(b => b.id !== cId)
                        }
                    return s
                }
            )
        }))
    }

    // Add theme
    const addThemeConfirm = () => {
        setProject(p => ({
            ...p,
            schema: p.schema.map(
                s => {
                    if (s.id === analysis.id && s.type === "insight")
                        return {
                            ...s,
                            buckets: s.buckets?.concat([{
                                parent: "root",
                                id: uuid(),
                                name: addThemeName
                            }])
                        }
                    return s
                }
            )
        }))
        resetAddTheme()
    }

    // Performs the merging (current code into the target code) via websocket
    const mergeCodes = () => {
        if (mergeCode === undefined || mergeTargetCode === undefined) return

        sendMessage({
            operation: "merge_tags",
            question_id: analysis.id,
            tag_to_merge: mergeCode.id,
            merge_into: mergeTargetCode.id
        })
        resetCodesMerge()
    }
    // #endregion

    // #region Effects
    useEffect(() => monitorForElements({
        onDrop: ({ source, location }) => {
            const destination = location.current.dropTargets[0]
            if (!source.data || !destination?.data) return

            // Checks that the source and target question coincide
            const sourceQuestion = source.data.question as IQuestion
            const destinationQuestion = destination.data.question as IQuestion
            if (sourceQuestion.id !== destinationQuestion.id) return
            if (sourceQuestion.type !== "insight") return

            // To avoid circular trees
            const sourceCode = source.data.code as ICode
            const destinationCode = destination.data.code as ICode | undefined
            if (sourceCode.id === destinationCode?.id) return

            if (destinationCode === undefined) return

            // This block is used to move a code into another theme 
            setProject(p => ({
                ...p,
                schema: p.schema.map(
                    s => {
                        if (s.type === "insight" && s.id === sourceQuestion.id)
                            return {
                                ...s,
                                buckets: s.buckets.map(b => {
                                    if (b.id === sourceCode.id) return {
                                        ...b,
                                        parent: destinationCode.id
                                    }
                                    return b
                                })
                            }

                        return s
                    }
                )
            }))
        }
    }))

    useEffect(() => {
        if (Object.entries(edits).length === 0 || hasClosedCodeBookProposalAlert) return
        setHasClosedCodeBookProposalAlert(true)
    }, [edits, hasClosedCodeBookProposalAlert])
    // #endregion

    // #region Element memos

    const analysisTabContentElement = useMemo(() => {
        if (!showAnalysis || isNumberAnalysis) return undefined

        // NPS analysis
        if (isNpsAnalysis)
            return (
                <AnalysisTabAnalysisColNpsContent
                    analysisId={analysis.id}
                />
            )

        // Any analysis with themes/codes
        return themeElements.length > 0
            ? (
                <div className="codes">
                    {themeElements.map(c => <Code
                        question={analysis}
                        code={c}
                        type="stack"
                        key={`${analysis.id}-${c.id || c}`}
                        lang={lang}
                        setCodeName={setCodeName}
                        deleteCode={deleteCode}
                        makeTheme={makeTheme}
                        onMergeWith={(qId, cId) => setMergeCode(analysis.codes.find(c => c.id === cId))}
                        onMergeToTarget={(qId, cId) => setMergeTargetCode(
                            analysis.codes.find(c => c.id === cId))}
                        onCreateSubtheme={(qId, cId) => setCreateSubthemeTheme(
                            analysis.codes.find(c => c.id === cId))}
                        onCreateSubthemeAddRemove={(qId, cId) => createSubthemeAddRemoveCode(
                            analysis.codes.find(c => c.id === cId))}
                        codeToMerge={mergeCode}
                        createSubthemeTheme={createSubthemeTheme}
                        isClickable={true}
                        selectedCode={selectedCode}
                    />)
                    }
                </div>
            ) : (
                <EmptySpaceBox
                    message={getCopy(copy.coding.emptyAnalysisColumnMessage)}
                    bottomIcon={<img src={GlautInfoBottomIcon} alt="Info" />}
                />
            )
    }, [
        showAnalysis,
        isNpsAnalysis,
        themeElements,
        analysis,
        lang,
        setCodeName,
        deleteCode,
        makeTheme,
        mergeCode,
        createSubthemeTheme,
        selectedCode,
        createSubthemeAddRemoveCode,
        isNumberAnalysis
    ])

    // #endregion

    if (!analysis.codes) return

    return (
        <div className="analysis">
            <div className="header mb-4 flex flex-row justify-between h-[25px] items-center">
                <span className="text text-sm text-[#00000099]">
                    {getCopy(copy.coding.analysisColumnHeader)}
                </span>
                <div className="flex flex-row gap-[0.5em] items-center">
                    {analysis.type === "insight" && (
                        <button
                            className="shadow-none border-none bg-glaut-cards text-glaut-pink font-medium"
                            onClick={() => {
                                setIsReviewCodeBookModalOpen(true)
                            }}>
                            <FaTags />Review codebook
                        </button>
                    )}
                    {(!["nps", "number"].includes(analysis.type) && (
                        <AnalysisTabAnalysisColCodeOccurrencesModeToggle />
                    ))}
                </div>
            </div>
            {analysis.type === "insight" && (
                <div className={glautCodeBookAlertClassNames}>
                    <div className="flex flex-row gap-[12px] items-center">
                        <MdLightbulbOutline className="text-black w-[16px] h-[16px]" />
                        <p className="text-[11.11px] text-black font-normal">
                            Here is Glaut’s proposal of themes and codes. You can review and edit it now, or accept it
                            and return to it at any time.
                        </p>
                    </div>
                    <div className="flex flex-col justify-start">
                        <MdClose
                            className="text-glaut-stroke-button w-[14px] h-[14px]"
                            onClick={() => {
                                setHasClosedCodeBookProposalAlert(true)
                            }}
                        />
                    </div>
                </div>
            )}
            {addTheme && <Box
                className="add-theme-select-name"
                title={<span>{getCopy(copy.coding.addTheme.title)}</span>}
                onClose={resetAddTheme}
                footer={
                    <div className="flex-wrap">
                        <Button
                            label={getCopy(copy.coding.addTheme.confirm)}
                            onClick={addThemeConfirm}
                            icon={<MdOutlineSkipNext />}
                            disabled={addThemeName.length === 0}
                        />
                    </div>
                }
            >
                <Input
                    className="input small"
                    placeholder={getCopy(copy.coding.addTheme.placeholder)}
                    icon={
                        <FaTags
                            style={addThemeName.length > 0 ? { color: "var(--accent-color)" } : {}}
                        />
                    }
                    value={addThemeName}
                    onChange={e => setAddThemeName(e.target.value)}
                />
            </Box>}
            {mergeCode && !mergeTargetCode && <Box
                className="merge-help"
                title={<span><FaExclamationCircle />{getCopy(copy.coding.merge.mergeCodeWithTitle)}</span>}
                footer={getCopy(copy.coding.merge.mergeCodeWithDescription)}
                onClose={resetCodesMerge}
            />}
            {mergeCode && mergeTargetCode && <Box
                className="merge-confirm"
                title={<span><FaExclamationCircle />{getCopy(copy.coding.merge.confirmTitle)}</span>}
                footer={<div className="flex-wrap">
                    <Button
                        className="secondary"
                        label={getCopy(copy.coding.merge.cancelButton)}
                        onClick={resetCodesMerge}
                        icon={<MdClose />}
                    />
                    <Button
                        label={getCopy(copy.coding.merge.confirmButton)}
                        onClick={mergeCodes}
                        icon={<MdCheck />}
                    />
                </div>}
            >
                <Code
                    question={analysis}
                    code={mergeTargetCode}
                    selectedCode={mergeTargetCode}
                    lang={lang}
                    showMenuButton={false}
                />
                <MdOutlineDoubleArrow className="arrow centered" style={{ rotate: "-90deg" }} />
                <Code
                    question={analysis}
                    code={mergeCode}
                    selectedCode={mergeCode}
                    lang={lang}
                    showMenuButton={false}
                />
            </Box>}
            {createSubthemeTheme && createSubthemeStep === 0 && <Box
                className="create-subtheme-select-code"
                title={<span>{getCopy(copy.coding.createSubtheme.selectCodes)}</span>}
                onClose={resetCreateSubtheme}
                footer={createSubthemeCodes.length > 0 && <div className="flex-wrap"><Button
                    label={getCopy(copy.coding.createSubtheme.continue)}
                    onClick={() => setCreateSubthemeStep(1)}
                    icon={<MdOutlineSkipNext />}
                    disabled={createSubthemeCodes.length === 1}
                /></div>}
            >
                {createSubthemeCodes.map(c => (
                    <Code
                        key={c.id}
                        question={analysis}
                        code={c}
                        selectedCode={c}
                        lang={lang}
                        showMenuButton={false}
                    />
                ))}
            </Box>}
            {createSubthemeTheme && createSubthemeStep === 1 && <Box
                className="create-subtheme-select-name"
                title={<span>{getCopy(copy.coding.createSubtheme.selectName)}</span>}
                onClose={resetCreateSubtheme}
                footer={createSubthemeCodes.length > 0 && (
                    <div>
                        <Button
                            className="text left"
                            reverse={true}
                            label={getCopy(copy.coding.createSubtheme.back)}
                            onClick={() => setCreateSubthemeStep(0)}
                            icon={<MdChevronLeft />}
                        />
                        <Button
                            className="primary right"
                            label={getCopy(copy.coding.createSubtheme.confirm)}
                            onClick={createSubthemeConfirm}
                            icon={<MdOutlineSkipNext />}
                            disabled={createSubthemeName.length === 0}
                        />
                    </div>
                )}
            >
                <Input
                    className="input small"
                    placeholder={getCopy(copy.coding.createSubtheme.selectNamePlaceholder)}
                    icon={
                        <FaTags style={
                            createSubthemeName.length > 0
                                ? { color: `var(--color${createSubthemeTheme.colorIndex})` }
                                : {}
                        } />}
                    value={createSubthemeName}
                    onChange={e => setCreateSubthemeName(e.target.value)}
                />
            </Box>}
            {showAnalysis && isNumberAnalysis && (
                <AnalysisTabAnalysisColNumberMetrics
                    mean={(rawAnalysisData as INumberAnalysis).mean}
                    median={(rawAnalysisData as INumberAnalysis).median}
                />
            )}
            {showAnalysis && isNpsAnalysis && (
                <AnalysisTabAnalysisColNpsMetrics
                    promoters={(rawAnalysisData as INPSAnalysis).promoters}
                    detractors={(rawAnalysisData as INPSAnalysis).detractors}
                    nps={(rawAnalysisData as INPSAnalysis).nps}
                />
            )}
            <div className="overflow-auto pr-2 h-full" ref={scrollRef}>
                {analysisTabContentElement}
            </div>

            <Tooltip id="tooltip--analysis-tab" className="z-50" />
            {analysis.type === "insight" && projectAnalysisData !== undefined && ( // analysisData is needed internally
                <AnalysisTabAnalysisColReviewCodeBookModal
                    isOpen={isReviewCodeBookModalOpen}
                    onClose={() => { setIsReviewCodeBookModalOpen(false) }}
                    questionId={analysis.id}
                />
            )}
        </div>
    )
}

