import { useState } from "react"
import { MdOpenInNew } from "react-icons/md"
import { IEntry } from "src/@types/entry"
import DataTabTableRowItemIdModalDetails from "../DataTabTableRowItemIdModalDetails"

interface IDataTabTableRowItemIdProps {
    id: string
    entry: IEntry
}

export default function DataTabTableRowItemId({
    id,
    entry
}: Readonly<IDataTabTableRowItemIdProps>) {
    // #region States
    const [modalOpen, setModalOpen] = useState(false)
    // #endregion

    return (
        <td style={{ borderRight: "0px", padding: "0" }}>
            {/** @TODO: transform the div below into button after removing the Styling folder */}
            <div
                className={`flex flex-row justify-between bg-white rounded-md group hover:bg-glaut-midnight
                    px-[12px] py-[4px] align-center cursor-pointer gap-2`}
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                <span className="text-glaut-midnight group-hover:text-white text-[13.33px] text-ellipsis overflow-hidden whitespace-nowrap">
                    {id}
                </span>
                <div className="flex flex-col justify-center">
                    <MdOpenInNew
                        className="text-glaut-text-midnight group-hover:text-white align-middle w-[14px] h-[14px]"
                    />
                </div>
            </div>

            <DataTabTableRowItemIdModalDetails open={modalOpen} onClose={() => setModalOpen(false)} entry={entry} />
        </td>
    )
}