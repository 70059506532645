import "../../styling/Verbatims.scss"

export const VerbatimHeader = ({headers, selectedColumn}) => (
    <div className="header">
        {headers.map((c, i) => i ? 
            <div key={i} className={["content", selectedColumn === i - 1 ? "selected" : ""].join(" ")}>
                {c || `column ${i}`}
            </div> :
            <div key={i} className="identifier">{c}</div>
        )}
    </div>)

export const VerbatimRow = ({row, codes, selectedColumn}) => (
    <div className="verbatim">
        {row.map((c, i) => i ? 
            <div 
                key={i}
                className={["content", c ? "" : "empty", selectedColumn === i - 1 ? "selected" : ""].join(" ")}
            >
                <div className="inner-content">
                    <span className="text">{c ? "\"" + c + "\"" : "empty"}</span>
                    {codes}
                </div>
            </div> :
            <div key={i} className="identifier">{c}</div>
        )}
    </div>)

