import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import useAxiosAuth from "../../hooks/useAxiosAuth"
import Popup from "../../components/Popups/Popup"
import Setting from "../../components/menus/Setting"
import { ProjectContext } from "./AutoSaveProject"

const generalSettings = []
const interviewSettings = [
    {
        slug: "max_responses",
        type: "int",
        default: 1000,
        min: 20,
        step: 100,
        title: "Response limit",
        description: "If active, it will limit the number of responses this project can receive.",
        active: false
    }
]

export function DeleteProjectPopup({ id, close }) {
    const [name, setName] = useState("")

    const { project, org } = useContext(ProjectContext)
    const axiosAuth = useAxiosAuth()
    const navigate = useNavigate()

    const deleteProject = e => {
        e.preventDefault()
        if (name !== project.name) return

        axiosAuth
            .delete(`/datasets/${id}`)
            .then(() => navigate("/w/" + org.org_id))
            .catch(e => console.log(e))
    }

    return (
        <Popup close={close}>
            <h2>Delete project</h2>
            <form onSubmit={deleteProject}>
                To confirm, type &quot;
                {project.name}
                &quot; in the box below
                <input
                    value={name}
                    onChange={e => setName(e.target.value)}
                    style={{ width: "100%" }}
                />
                <button
                    onClick={deleteProject}
                    disabled={name !== project.name}
                    className="danger-button"
                    style={{ width: "100%" }}
                >
                    Delete this project
                </button>
            </form>
        </Popup>
    )
}

function Settings() {
    const [openDelete, setOpenDelete] = useState(false)

    const { project, setProject } = useContext(ProjectContext)

    return (
        <div className="overflow-auto w-full">
            <div className="slim-page">
                <div className="box project-settings">
                    <h2 style={{ marginBottom: "1em" }}>Project settings</h2>
                    <div className="column left-aligned" style={{ gap: "2em" }}>
                        {generalSettings?.map((s, idx) => (
                            <Setting
                                key={idx}
                                setting={s}
                                value={project.general_settings?.[s.slug]}
                                setValue={v => setProject(p => ({
                                    ...p,
                                    general_settings: { ...p.general_settings, [s.slug]: v }
                                }))}
                            />
                        ))}
                        {project.source !== "CSV" && interviewSettings?.map((s, idx) => (
                            <Setting
                                key={idx}
                                setting={s}
                                value={project.general_settings?.[s.slug]}
                                setValue={v => setProject(p => ({
                                    ...p,
                                    general_settings: { ...p.general_settings, [s.slug]: v }
                                }))}
                            />
                        ))}
                    </div>
                </div>

                <div className="box danger-zone" style={{ border: "1px solid var(--danger-color)" }}>
                    <h2>Danger zone</h2>
                    <div className="long-bar">
                        <div className="column" style={{ gap: "4px", alignItems: "flex-start" }}>
                            <span style={{ fontWeight: "bold" }}>Delete this project</span>
                            <span>There is no going back. Please be certain.</span>
                        </div>
                        <button className="danger-button" onClick={() => setOpenDelete(true)}>
                            Delete
                        </button>
                    </div>
                </div>
                {openDelete && (
                    <DeleteProjectPopup
                        id={project._id}
                        close={() => setOpenDelete(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default Settings
