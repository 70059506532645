import { IQuestionEntityEntity, IQuestionInsightBucket, IQuestionSelectOption } from "src/@types/question"
import { normalizeCode } from "./normalize-code"
import { IAnalysisResponseValue } from "./types/get-analysis-response-value"
import { IEntityAnalysis, IInsightAnalysis, ISelectAnalysis } from "src/@types/analysis"

export type ICodeBook = IQuestionInsightBucket[] | IQuestionEntityEntity[] | IQuestionSelectOption[]

/**
 * Normalize the codes obtained from a analysis.
 */
export const normalizeCodesFromResults = (
    codeBook: ICodeBook,
    analysisResults: IAnalysisResponseValue,
    analysisId: string,
    level: number = 0,
    parentIndex?: number
) => {
    // Flat array of codes
    if (!Array.isArray(analysisResults))
        return [[], []]

    let codes: ReturnType<typeof normalizeCode>[] = []

    const rootCodes = analysisResults.map((
        analysisResult: (IInsightAnalysis | ISelectAnalysis | IEntityAnalysis),
        index: number
    ) => {
        const isInsightAnalysisResult = "children" in analysisResult

        const codeFromCodebook = codeBook.find(c => c.id === analysisResult.id)
        const code = normalizeCode(
            codeFromCodebook, (parentIndex ?? index), analysisId, analysisResult
        )
        // Only theme can create subtheme
        code.canCreateSubtheme = level === 0 && isInsightAnalysisResult && analysisResult.children?.length !== 0
        // Only codes can be merged
        code.canMergeWith = level > 0 && isInsightAnalysisResult && analysisResult.children?.length === 0
        // If the root parent has order 10, the l1 children must have order 11
        code.order += level
        codes.push(code)

        // Recursively iterate over children and add them as subcodes
        if (isInsightAnalysisResult) {
            const [subrootCode, subcodes] = normalizeCodesFromResults(
                codeBook,
                analysisResult.children,
                analysisId, level + 1,
                parentIndex ?? index
            )

            code.subcodes = subrootCode
            codes = codes.concat(subcodes)
        }

        return code
    })

    return [rootCodes, codes]
}